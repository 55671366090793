.h2-login {
  font-size: 24px;
  color: whitesmoke;
  font-weight: 100; }

.login-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px; }

.login-column {
  min-width: 280px;
  margin-right: auto;
  margin-left: auto; }

@media only screen and (max-width: 609px) {
  .center {
    text-align: center; }
  .h2-login {
    font-size: 20px; }
  .login-container {
    padding-top: 50px; } }

.fill-height {
  min-height: 100%;
  height: auto !important;
  /* cross-browser */
  height: 100%;
  /* cross-browser */
  display: flex;
  padding-right: 0px;
  padding-left: 0px; }

.login ~ .onesignal-bell-container {
  display: none; }
