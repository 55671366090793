.campaignCompanyAndStoreSelector {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignCompanyAndStoreSelector > span:first-child {
    margin-right: 10px; }
  .campaignCompanyAndStoreSelector #segmentOptionSelector {
    width: 37%; }
    @media (max-width: 321px) {
      .campaignCompanyAndStoreSelector #segmentOptionSelector {
        width: 71% !important; } }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #segmentOptionSelector {
        width: 76%; } }
  .campaignCompanyAndStoreSelector #storeSelector {
    width: 48%;
    margin-left: 16px; }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #storeSelector {
        width: 100%;
        margin-left: 0; } }
  .campaignCompanyAndStoreSelector #canTargetCompanyStoreSelector {
    width: 86%; }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #canTargetCompanyStoreSelector {
        width: 76%; } }
  .campaignCompanyAndStoreSelector #storeSelector, .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector {
    position: relative; }
    .campaignCompanyAndStoreSelector #storeSelector.error input:not(#selectSearchInput), .campaignCompanyAndStoreSelector #storeSelector.error .caret, .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector.error input:not(#selectSearchInput), .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector.error .caret {
      color: var(--danger) !important;
      border-color: var(--danger); }
    .campaignCompanyAndStoreSelector #storeSelector.error > label:not(#selectSearchInput), .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector.error > label:not(#selectSearchInput) {
      left: 8px;
      bottom: -5px;
      top: inherit;
      color: var(--danger); }
  .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector {
    width: 300px; }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector {
        width: 100%; } }
