.dashboard-container {
  padding-bottom: 50px;
  margin-top: 1.5rem; }
  @media (max-width: 500px) {
    .dashboard-container {
      margin-top: 0.5rem; } }
  .dashboard-container section {
    margin-top: 1.5rem; }
  .dashboard-container .filters .filters-info {
    margin-bottom: 20px; }
  .dashboard-container .filters .filters-first-line {
    margin-bottom: 10px; }
    .dashboard-container .filters .filters-first-line.mobile {
      margin-bottom: 0; }
  .dashboard-container .dashboard-masonry {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 0; }
    .dashboard-container .dashboard-masonry h3.dashboard-title {
      color: black;
      border-bottom: 1px solid;
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-weight: 500; }
    .dashboard-container .dashboard-masonry h3.dashboard-title-bottom-off {
      color: black;
      border-bottom: 1px solid;
      font-weight: 500;
      margin-bottom: 0px;
      padding-bottom: 10px; }
      @media (min-width: 992px) {
        .dashboard-container .dashboard-masonry h3.dashboard-title-bottom-off {
          width: 98%;
          margin-left: 2%; } }
  .dashboard-container .row-btn {
    background-color: var(--primary);
    padding: 0.15rem;
    font-size: 0.6rem;
    color: #fff;
    font-weight: bold; }
    .dashboard-container .row-btn-mobile {
      font-size: 0.6rem; }
  .dashboard-container .dashboard-element-container {
    height: inherit;
    margin-bottom: 20px; }
    .dashboard-container .dashboard-element-container.h-33 {
      height: 33.333%; }
    .dashboard-container .dashboard-element-container.h-50 {
      height: 50%; }
      @media (max-width: 758px) {
        .dashboard-container .dashboard-element-container.h-50 {
          margin-bottom: 10px; } }
    .dashboard-container .dashboard-element-container .data-card-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      margin-bottom: 0; }
      .dashboard-container .dashboard-element-container .data-card-container.align-center {
        justify-content: center; }
      .dashboard-container .dashboard-element-container .data-card-container.align-end {
        justify-content: flex-end; }
    .dashboard-container .dashboard-element-container .data-card {
      min-height: 220px !important;
      margin-bottom: 10px !important; }
  .dashboard-container .empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .dashboard-container .empty-state h1 {
      font-size: 1.5em; }
  .dashboard-container .link-to-old-dashboard {
    margin-bottom: 0; }
    .dashboard-container .link-to-old-dashboard h2 {
      font-size: 1.2em;
      margin: 0; }
      @media (max-width: 758px) {
        .dashboard-container .link-to-old-dashboard h2 {
          font-size: 1em; } }
  .dashboard-container .graph-card {
    padding: 10px;
    margin: 0 0.3rem; }
    .dashboard-container .graph-card .card-footer {
      font-size: 11px;
      border: none;
      color: black;
      font-weight: 500;
      min-height: 45px;
      padding: .5rem;
      margin-top: 5px; }
      .dashboard-container .graph-card .card-footer.green {
        background-color: #D3F0D7 !important; }
      .dashboard-container .graph-card .card-footer.grey {
        background-color: #F3F3F3 !important; }
      @media (max-width: 991px) {
        .dashboard-container .graph-card .card-footer {
          min-height: 8vh; } }
      .dashboard-container .graph-card .card-footer a {
        color: #337ab7;
        cursor: pointer;
        border-bottom: 1px solid; }
      .dashboard-container .graph-card .card-footer h3 {
        font-size: 13px; }
  .dashboard-container .filters-title {
    text-align: left; }
  @media (min-width: 992px) {
    .dashboard-container .filters {
      padding: 0 15px; } }
  @media (max-width: 330px) {
    .dashboard-container .filters .dropdown-menu {
      max-width: 290px; } }
  @media (max-width: 991px) {
    .dashboard-container .col-12 {
      padding: 0 !important; } }
  .dashboard-container .data-card h4 {
    min-height: 35px; }
  .dashboard-container .dashboard-reviews-widget {
    width: 100%; }
  .dashboard-container .link-like-btn {
    color: #337ab7 !important;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out; }
    .dashboard-container .link-like-btn:hover {
      color: #0056b3; }
  .dashboard-container .hidden-card {
    background: none;
    box-shadow: none; }
  .dashboard-container .reviews-graph_header .col-md-5, .dashboard-container .reviews-graph_header .col-md-7, .dashboard-container .reviews-graph_header .col-md-4, .dashboard-container .reviews-graph_header .col-md-8 {
    padding: 0; }
  .dashboard-container .reviews-graph_header .filters {
    padding-left: 0; }
    @media (max-width: 768px) {
      .dashboard-container .reviews-graph_header .filters {
        padding-left: 1rem; } }
    .dashboard-container .reviews-graph_header .filters-actions .btn {
      font-size: 0.8rem !important; }
  .dashboard-container .reviews-graph_header .graph-pagination {
    padding-left: 15px; }
    .dashboard-container .reviews-graph_header .graph-pagination .page-item {
      font-size: 0.7rem; }
