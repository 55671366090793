#adminActivity .botbit-table-card .card-header:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  opacity: 1;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out; }

#adminActivity .botbit-table-card .card-header.success:after {
  background: var(--success); }

#adminActivity .botbit-table-card .card-header.failed:after {
  background: var(--danger); }

tbody tr pre {
  line-height: 18px;
  background-color: inherit;
  border: inherit; }
