.upgrade-plan {
    $primary: #05C2D7;
    $tilt: red;
    font-weight: 400;
    .plan-badge {
        border-radius: 35px;
        border: 2px solid $primary ;
        padding: 0.2rem 0.5rem;
        @media (max-width: 768px) {
            font-size: 0.7rem;
            text-align: center;
        }
        b {
            color: $primary;
            text-transform: uppercase;
        }
    }
    &_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &_feature-title {
        font-size: 1rem;
        color: $primary;
        font-weight: bold;
    }
    &_presentation-card {
        // margin-top: 2rem;
        &_container {
            padding: 0.8rem 2rem;
            @media (max-width: 768px) {
                padding: 1rem;
            }
        }
        &_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            .image {
                width: 80px;
                margin-right: 1rem;
                @media (max-width: 768px) {
                    width: 60px;
                    margin-right: 0.5rem;
                }
            }
            .header-description {
                .plan-title {
                    color: $primary;
                    font-size: 1.2rem;
                    font-weight: bold;
                }
            }
        }
        &_features {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: unset;
                justify-content: unset;
            }
        }
    }
    &_feature-list {
        &_container {
            padding: 1rem 2rem;
            @media (max-width: 768px) {
                padding: 1rem;
            }
            h3 {
                font-weight: bold;
                font-size: 1rem;
                text-transform: uppercase;
            }
            ul {
                margin: 0;
                padding-left: 1rem;
                :first-child {
                    color: $primary
                }
                .inline-badge {
                    margin-left: 4px;
                    background-color: $primary;
                    color: white;
                    font-size: 0.6rem;
                    padding: 2px 3px;
                    text-transform: uppercase;
                }
                &.split {
                    columns: 2;
                    -webkit-columns: 2;
                    -moz-columns: 2;
                }
            }
            .highlight {
                color: $primary;
            }
        }
    }
    .upgrade-btn {
        font-weight: bold;
        font-size: 1rem;
        text-transform: none;
            margin: auto;
            display: block;
    }
    &_vector-image {
        width: 40%;
    }
}
.modal-plan-upgrade {
    width: 800px;
    z-index: 5000000;
}