.leaderboard {
    padding-bottom: 6rem;
    margin-top: 1rem;

    // Removing margin from container. Don't know where this rule is coming from.
    .container{
        margin-top: 0;
    }

    .mobile-row_container {
        .mobile-row_heading {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .mobile-row_admin-avatar {
                margin-right: 1rem;
            }
        }

        .mobile-row_metrics-list {
            list-style: none;
            padding-left: calc(45px + 1rem);
        }

        .mobile-row_metrics-list_item {
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }

            .badge-default {
                background-color: #38B394 !important;
                float: right;
            }
        }
    }

    // Removing "cursor: pointer"
    .botbit-collection .botbit-table .card.botbit-table-card {
        cursor: default;

        .card-header.cursor-pointer{
            cursor: default;

            .row-container{
                align-items: center;
            }
        }
    }

    .botbit-collection .botbit-table .botbit-table-column {
        padding-right: 0;
    }


    .botbit-collection .card-header {
        @media (max-width: 768px) {
            padding: .75rem;
        }
    }

    .botbit-table {
        .element-content {
            cursor: pointer;
        }
    }

}