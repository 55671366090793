#company-header-selector{
  #single-company{
    font-size: 16px;
    color: #676a6c;
    font-weight: 500;
  }
  display: flex;
  align-items: center;
  .select-wrapper{
    margin: 7px 0;
    width: 260px;
    @media (max-width: 991px){
      width: 200px;
    }
  }
  img{
    height: 30px;
    margin-left: 20px;
  }
  .caret{
    z-index: 3;
  }
  .select-dropdown:not(.dropdown-content){
    caret-color: transparent;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    border: 1px solid #e5e6e7;
    border-radius: 4px;
    color: #555555;
    margin-bottom: 0;
    display: block;
    height: 28px;
    font-size: 13px;
    overflow: hidden;
    line-height: 24px;
    box-shadow: none;
    padding: 0 0 0 8px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown-content{
    width: 100%;
    top: 30px !important;
    padding: 0 10px;
  } 
}