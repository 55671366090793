@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap);
#login-logo {
  max-width: 150px !important; }

@media (max-width: 768px) {
  #login-logo {
    margin: auto; } }

.logo-container {
  display: flex;
  justify-content: center; }

@media (max-width: 768px) {
  .logo-container {
    max-height: 100px; } }

.h2-login {
  font-size: 24px;
  color: whitesmoke;
  font-weight: 100; }

.login-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px; }

.login-column {
  min-width: 280px;
  margin-right: auto;
  margin-left: auto; }

@media only screen and (max-width: 609px) {
  .center {
    text-align: center; }
  .h2-login {
    font-size: 20px; }
  .login-container {
    padding-top: 50px; } }

.fill-height {
  min-height: 100%;
  height: auto !important;
  /* cross-browser */
  height: 100%;
  /* cross-browser */
  display: flex;
  padding-right: 0px;
  padding-left: 0px; }

.login ~ .onesignal-bell-container {
  display: none; }

.loading-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  display: block; }

.loading-container {
  margin: auto;
  width: 400px;
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%); }
  @media (max-width: 768px) {
    .loading-container {
      left: 50%;
      transform: perspective(1px) translate(-50%, -50%); } }

.spinner-v2 .loader-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.spinner-v2 .loader {
  border: 8px solid #2383E2;
  border-left-color: transparent;
  border-radius: 50%; }

.spinner-v2 .loader {
  border: 8px solid #2383E2;
  border-left-color: transparent;
  width: 80px;
  height: 80px; }

.spinner-v2 .loader {
  border: 8px solid #2383E2;
  border-left-color: transparent;
  width: 80px;
  animation: spin89345 1s linear infinite; }

@keyframes spin89345 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

body.body-overflow-auto {
  overflow-y: auto !important; }

.footer-logo {
  max-height: 17px; }

.nav-item-right-arrow {
  margin-Top: 3px; }

.page-footer {
  margin-top: 12vh; }

.nav-header-avatar {
  max-height: 75px;
  max-width: 65px; }

.nav-header-logo {
  width: 75px; }

#navContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh; }
  #navContainer .user-settings-dropdown {
    margin-right: 5px; }
    #navContainer .user-settings-dropdown .nav-link {
      margin: -10px; }
    #navContainer .user-settings-dropdown .user-avatar-img {
      width: 35px;
      height: 35px;
      background-color: lightgray;
      padding: 4px; }
  #navContainer .invite-users-icon {
    margin-right: 8px;
    margin-left: 3px; }

@media (max-width: 768px) {
  .nav-header-select {
    min-width: 35vw;
    width: 35vw; }
    .nav-header-select .css-dopzeu-control,
    .nav-header-select .css-de7kfp-control,
    .nav-header-select .css-kj6f9i-menu,
    .nav-header-select .css-lvpw7o-control {
      width: 50% !important; }
    .nav-header-select > div:not(.css-1wy0on6):not(.css-16pqwjk-indicatorContainer),
    .nav-header-select > div > div:not(.css-1wy0on6):not(.css-16pqwjk-indicatorContainer),
    .nav-header-select > div > div > div:not(.css-1wy0on6):not(.css-16pqwjk-indicatorContainer) {
      min-width: 35vw;
      width: 35vw; } }

.nav-header-select-header {
  margin: 5px 5px 5px 20px; }

.nav-header-text-header {
  margin: 18px 5px 5px 20px; }

.nav-header-store-logo {
  height: 30px;
  max-height: 30px;
  margin-bottom: 4px;
  margin-left: 4px; }

.nav-header-select-icon {
  display: inline-block;
  text-align: center;
  color: #979B9D;
  margin-bottom: 1px; }

@media (max-width: 300px) {
  .nav.navbar-top-links li.dropdown {
    display: none; } }

#selectedStoreText {
  font-size: 16px;
  flex: 0 0 100%;
  max-width: 100%; }

.nav-header-select .row {
  flex-wrap: inherit; }

#HeaderReviewButtonFloatCircle a i {
  background-color: var(--deep-orange);
  color: #fff; }

body:not(.mini-navbar) .navbar-default {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: scroll;
  height: 100%; }
  body:not(.mini-navbar) .navbar-default::-webkit-scrollbar {
    display: none; }

#company-header-selector {
  display: flex;
  align-items: center; }
  #company-header-selector #single-company {
    font-size: 16px;
    color: #676a6c;
    font-weight: 500; }
  #company-header-selector .select-wrapper {
    margin: 7px 0;
    width: 260px; }
    @media (max-width: 991px) {
      #company-header-selector .select-wrapper {
        width: 200px; } }
  #company-header-selector img {
    height: 30px;
    margin-left: 20px; }
  #company-header-selector .caret {
    z-index: 3; }
  #company-header-selector .select-dropdown:not(.dropdown-content) {
    caret-color: transparent;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    border: 1px solid #e5e6e7;
    border-radius: 4px;
    color: #555555;
    margin-bottom: 0;
    display: block;
    height: 28px;
    font-size: 13px;
    overflow: hidden;
    line-height: 24px;
    box-shadow: none;
    padding: 0 0 0 8px;
    position: relative;
    text-decoration: none;
    white-space: nowrap; }
  #company-header-selector .dropdown-content {
    width: 100%;
    top: 30px !important;
    padding: 0 10px; }

.review-request {
  width: 580px; }
  .review-request .md-form {
    width: 100%; }
  .review-request .whatsapp-btn {
    background-color: #25D366 !important;
    min-width: 16rem; }
  .review-request.no-animation {
    transition: none !important; }
  .review-request .intl-tel-input {
    width: 100% !important; }
    @media (max-width: 768px) {
      .review-request .intl-tel-input {
        z-index: 1000000 !important; } }
    .review-request .intl-tel-input .country-list {
      z-index: 1000 !important; }
    .review-request .intl-tel-input input {
      padding-right: 0 !important;
      width: calc(100% - 52px); }
  .review-request .form-field {
    margin: 2.5rem 0; }
    .review-request .form-field input {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .review-request .form-field .md-form {
      margin: 0; }
    .review-request .form-field .form-error {
      display: block;
      color: var(--danger); }
    .review-request .form-field .md-form-label {
      position: absolute;
      top: 0;
      left: 0;
      cursor: text;
      transition: transform .2s ease-out, color .2s ease-out;
      transform: translateY(12px);
      transform-origin: 0% 100%;
      font-size: 11pt;
      font-weight: 400;
      color: black; }
      .review-request .form-field .md-form-label.active {
        transform: translateY(-16px) scale(0.8);
        font-size: 1rem; }
        .review-request .form-field .md-form-label.active.focused {
          color: var(--primary); }
  .review-request .submit-btn {
    display: block;
    margin: 3rem auto 0 auto;
    min-width: 18rem; }
  .review-request .submit-btn-2 {
    display: block;
    margin: 1.5rem auto;
    min-width: 18rem; }
  .review-request .modal-content {
    max-height: calc(100vh - 28px *2); }
  .review-request .modal-content .modal-header {
    border: none; }
  .review-request .modal-content .modal-header .close {
    padding: 0.5rem .8rem 0 .8rem; }
  .review-request .modal-content .modal-body {
    padding-top: 0;
    max-height: none;
    overflow-y: visible;
    overflow-y: initial; }
  .review-request .modal-content .botbit-select {
    margin-bottom: 3rem; }
  .review-request .modal-content .select-wrapper > label.mdb-main-label.active {
    font-size: 1rem;
    text-transform: uppercase; }
  .review-request .modal-content .select-wrapper input.select-dropdown {
    font-size: 1.6rem;
    text-transform: uppercase;
    top: 19px; }
  .review-request .modal-content .form-control + label {
    font-size: 1rem;
    text-transform: uppercase; }
  .review-request .modal-content .md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
  .review-request .modal-content .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid #ced4da;
    box-shadow: 0 1px 0 0 #ced4da; }
  .review-request .modal-content .md-form input[type="text"]:not(.browser-default) {
    color: #000;
    font-size: 1.6rem; }
  .review-request .modal-content .md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label {
    color: rgba(0, 0, 0, 0.7); }
  .review-request .modal-content .md-form label.active {
    transform: translateY(-25px); }
  .review-request .modal-content .md-form label.active {
    color: #000;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase; }
  .review-request .modal-content .storeOnly {
    color: #000;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: -23px;
    border-bottom: 1px solid #ced4da; }
    .review-request .modal-content .storeOnly span {
      color: #000;
      display: block;
      font-size: 1.6rem; }
  .review-request .modal-content .intl-tel-input.allow-dropdown input {
    font-size: 1.6rem; }
  .review-request.rr-cheerup p {
    font-size: 1rem; }
  .review-request.rr-cheerup .modal-content .modal-body {
    overflow-y: auto; }

.rr-cheerup img {
  margin: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

.rr-cheerup .step-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

#adminActivity .botbit-table-card .card-header:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  opacity: 1;
  transition: 0.3s all ease-in-out; }

#adminActivity .botbit-table-card .card-header.success:after {
  background: var(--success); }

#adminActivity .botbit-table-card .card-header.failed:after {
  background: var(--danger); }

tbody tr pre {
  line-height: 18px;
  background-color: inherit;
  border: inherit; }

.menu {
  cursor: pointer;
  z-index: 10000;
  margin-top: -1rem;
  margin-left: -1rem;
  min-width: 180px; }

.menu-content > *:hover {
  border-left: 3px solid transparent; }

.animated-short {
  animation-duration: 300ms;
  animation-fill-mode: both; }

.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 1px;
  border-radius: 4px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  max-height: 22px;
  height: 22px;
  font-size: 12px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
  padding: 0; }

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 9px;
  color: #ccc; }

.pack-purchase-button {
  font-size: 0.9rem;
  white-space: normal;
  word-wrap: break-word; }

.pack-purchase-pop-quantity-row {
  margin-top: 0.3rem; }

.pack-purchase-pop-button-col {
  position: relative; }

iframe {
  height: 85vh;
  border: none; }

.column-logo {
  width: 100%; }

.purchase-button {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: smaller; }

.text-cell {
  display: flex;
  justify-content: center;
  align-items: center; }

.mobile-row {
  padding: 5%; }

.mobile-column {
  display: flex;
  justify-content: center;
  align-items: center; }

.mobile-expand-row {
  padding: 5%; }

.mobile-purchase-button {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 0;
  font-size: smaller; }

.header-actions-container.mobile {
  margin: 0; }

.container.botbit-table.fluid {
  margin: 0; }

.center-image-container {
  display: flex;
  margin: auto; }

.fade.modal.show.top {
  z-index: 1000000 !important; }

div.mp-modal_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px; }
  div.mp-modal_header h4 {
    color: #000;
    font-size: 1.3rem; }

.modal-dialog {
  margin-bottom: auto !important; }

.email-widget {
  width: 80%;
  margin-right: auto;
  margin-left: auto; }

.email-delivered-color {
  background-color: #4285f4; }

.email-open-count {
  background-color: #db4437; }

.email-clicked-count {
  background-color: #f4b400; }

.api-config-table {
  border: 1px solid black;
  background-color: black;
  padding: 5px;
  table-layout: fixed;
  width: 100%; }

.api-config-title {
  width: 20%; }

.api-config-value {
  width: 80%; }

@media (min-width: 992px) {
  .api-config-table {
    width: 70%; } }

@media (min-width: 1200px) {
  .api-config-table {
    width: 60%; } }

.login {
  background-color: #F3F3F4 !important;
  font-family: open sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  position: absolute; }

.login-set {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.authenticated {
  padding-right: 0;
  padding-left: 0;
  background-color: #2f4050; }

#wrapper {
  background-color: #2f4050; }

.collection-visualizer-header.mobile {
  display: block; }
  .collection-visualizer-header.mobile .botbit-action-button {
    width: 100%;
    margin: 0;
    margin-top: 10px; }

.reactivate-modal {
  max-width: 800px; }

.modal:not(.client-action-modal) {
  display: flex !important;
  align-items: center; }

.filters-first-line.mobile {
  display: block;
  margin-bottom: 0 !important;
  text-align: left; }
  .filters-first-line.mobile .botbit-action-button {
    width: 100%;
    margin: 0;
    margin-top: 10px; }

@media (min-width: 992px) {
  #reactivate-modal-container .modal-content {
    width: 600px; }
  #reactivate-modal-container .modal-dialog {
    margin-top: auto; }
  #reactivate-modal-container .modal {
    display: flex !important; } }

@media (max-width: 991px) {
  .filters-title-container {
    margin-right: 0 !important; }
  .modal-body {
    max-height: 100% !important;
    width: 100vw !important; } }

.padding-5px {
  padding: 5px !important; }

img.rounded-circle {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1 / 1; }

#notes-input {
  background: #f3f3f4; }

.time-picker-clock > span::before {
  background-color: transparent; }

.picker__footer > button.clockpicker-button {
  background-color: #1ab394; }

@media screen and (max-width: 480px) {
  .cards-list-sm {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent; }
    .cards-list-sm .profile-image {
      margin: 0 auto !important;
      float: none; }
      .cards-list-sm .profile-image img.rounded-circle {
        height: 95px;
        width: 95px; }
  .cards-list-sm thead {
    display: none; }
  .cards-list-sm tr {
    display: block;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(to left, #FCFCFC 95%, #EBEBEB);
    border: 2px solid #EBEBEB;
    border-radius: 10px;
    position: relative; }
    .cards-list-sm tr td.card-top-right-corner {
      position: absolute;
      top: 0;
      right: 0; }
  .cards-list-sm .card-sm > td {
    display: block;
    padding-left: 9%;
    border: none;
    text-align: left !important; }
  .cards-list-sm td::before {
    content: attr(data-label);
    margin-right: 0.5rem;
    font-weight: bold;
    display: inline-block; }
  tr.expanding-row > td {
    display: table !important;
    margin: auto !important;
    padding: 0 !important; } }

@media screen and (max-width: 768px) {
  .cards-list-md {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent; }
    .cards-list-md .profile-image {
      margin: 0 auto !important;
      float: none; }
      .cards-list-md .profile-image img.rounded-circle {
        height: 95px;
        width: 95px; }
  .cards-list-md thead {
    display: none; }
  .cards-list-md tr {
    display: block;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(to left, #FCFCFC 95%, #EBEBEB);
    border: 2px solid #EBEBEB;
    border-radius: 10px;
    position: relative; }
    .cards-list-md tr td.card-top-right-corner {
      position: absolute;
      top: 0;
      right: 0; }
  .cards-list-md .card-md > td {
    display: block;
    padding-left: 9%;
    border: none;
    text-align: left !important; }
  .cards-list-md td::before {
    content: attr(data-label);
    margin-right: 0.5rem;
    font-weight: bold;
    display: inline-block; }
  tr.expanding-row > td {
    display: table !important;
    margin: auto !important;
    padding: 0 !important; } }

@media screen and (max-width: 1024px) {
  .cards-list-lg {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent; }
    .cards-list-lg .profile-image {
      margin: 0 auto !important;
      float: none; }
      .cards-list-lg .profile-image img.rounded-circle {
        height: 95px;
        width: 95px; }
  .cards-list-lg thead {
    display: none; }
  .cards-list-lg tr {
    display: block;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(to left, #FCFCFC 95%, #EBEBEB);
    border: 2px solid #EBEBEB;
    border-radius: 10px;
    position: relative; }
    .cards-list-lg tr td.card-top-right-corner {
      position: absolute;
      top: 0;
      right: 0; }
  .cards-list-lg .card-lg > td {
    display: block;
    padding-left: 9%;
    border: none;
    text-align: left !important; }
  .cards-list-lg td::before {
    content: attr(data-label);
    margin-right: 0.5rem;
    font-weight: bold;
    display: inline-block; }
  tr.expanding-row > td {
    display: table !important;
    margin: auto !important;
    padding: 0 !important; } }

tr.expanding-row, .expanding-row > td {
  background-color: #f3f3f4;
  background-image: none; }

.card-header .row .profile-image > img {
  height: 45px;
  width: 45px; }

.order > .dropup > .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 4px dashed;
  border-right: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent; }

div.react-bootstrap-table {
  padding: 0px; }

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0; }

.dz-success-mark, .dz-error-mark {
  display: none; }

.clients-store-selector-action-container .select-in-dropdown-container {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1em; }

.clients-store-selector-action-container button {
  border-radius: 3px;
  text-transform: none;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  border-width: 1px !important;
  margin: 0 5px;
  padding: 5px 10px !important; }

@media (max-width: 992px) {
  .clients-store-selector-action-container {
    margin: 5px 0 0 0;
    width: 100%;
    float: left; }
    .clients-store-selector-action-container button {
      margin: 0 !important;
      padding: 15px 10px !important;
      width: 100%; } }

.client-actions-backdrop {
  z-index: 1000000 !important;
  display: block !important; }

div.fade + div[role="dialog"] {
  z-index: 1000000 !important; }

.user-reservation-form-input.errors input {
  border-color: var(--danger) !important; }

.user-reservation-form-input.errors label {
  color: var(--danger) !important; }

.tags-scroll .scroll-menu-arrow {
  visibility: hidden; }

@media (min-width: 768px) {
  .modal-lg {
    width: 800px !important; } }

.intl-tel-input {
  width: 100% !important; }
  @media (max-width: 768px) {
    .intl-tel-input {
      z-index: 1000000 !important; } }
  .intl-tel-input .country-list {
    z-index: 1000 !important; }
  .intl-tel-input input {
    padding-right: 0 !important;
    width: calc(100% - 52px); }
  .intl-tel-input .iti-flag {
    background-image: url("/static/img/flags.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .intl-tel-input .iti-flag {
      background-image: url("/static/img/flags@2x.png"); } }

body>div[role=dialog]{
	z-index:1000000;
	}

@charset "UTF-8";
@media (max-width: 920px) {
  .campaignPreviewerModal .modal-content {
    height: 98%; } }

#segmentFormInput {
  width: 39px; }
  #segmentFormInput .md-form {
    width: inherit !important; }

.campaign-card .form-error {
  color: red; }

.campaign-name {
  font-size: 14px !important;
  font-family: Arial !important;
  font-style: normal !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.6) !important; }

/* main image */
.image-container:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 0.5;
  z-index: 2; }

.image-container :not(img) {
  z-index: 2; }

.image-container img {
  z-index: 1; }

.image-container {
  position: relative; }

.notificationModalBody {
  font-size: 14px;
  line-height: 16px;
  padding: 18px !important; }

.notificationModalContent {
  background: #323232;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  opacity: 0.9;
  top: 89vh; }

.undoButtonNotifModal {
  color: #20BFC2;
  font-weight: 600 !important; }

/* la imagen del lado de edicion siempre tiene que ocupar lo mismo */
.card.face.back .image-container img {
  max-height: 100px;
  min-height: 100px;
  height: 100px; }

/* botones pegados abajo de la tarjeta */
.campaign-card .buttonbar {
  padding: 5px;
  width: 100%;
  position: absolute;
  bottom: 0px; }

/* fix para el range input, cuando tiene labels de dos renglones */
.campaign-card .md-form.md-range-input-fix label {
  position: relative;
  top: 25px;
  margin-top: 0px;
  margin-bottom: 10px; }

.campaign-card .md-form.md-range-input-fix {
  margin-top: -16px;
  margin-bottom: 0px; }

/* fixes de fonts en formularios */
.campaign-card .form-control, .campaign-card {
  font-size: 13px; }

.campaign-card .select-wrapper input.select-dropdown {
  font-size: 13px;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #495057; }

.campaign-card .select-wrapper + label.active {
  transform: translateY(-14px);
  font-size: .8rem;
  top: 1.7rem;
  left: 15px; }

.campaign-card .md-form label.active {
  color: #757575; }

/* fix de padding para el control de fecha */
.react-bootstrap-daterangepicker-container #reportrange {
  padding-left: 5px;
  padding-right: 5px; }

.modal-header h4 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

/* nombre de campaña centrado abajo en el header de tarjeta modo vista */
.campaign-header {
  display: inline-flex;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%; }

.campaign-header > .campaign-title {
  position: absolute;
  width: 70%;
  margin: 0px;
  padding: 0px;
  opacity: 0.90;
  margin-left: auto;
  margin-right: auto;
  bottom: 10px;
  left: 0;
  right: 0; }

/* centrar verticalmente el nombre de campaña en el modo edicion */
.campaign-header-edit {
  top: 0;
  bottom: 0;
  left: 10%;
  right: 0;
  margin: auto;
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

/* main image siempre ocupa todo el espacio */
.campaign-header > img {
  max-width: 100%;
  max-height: 100%; }

/* switch de activacion de campaña arriba a la izquierda del header */
.campaign-activate-switch {
  position: absolute;
  top: 5%;
  left: -0.7em;
  width: 80px;
  padding: 0px;
  width: 80px;
  display: inline-block; }

.campaign-activate-switch .switch label .lever {
  margin-right: 0px;
  margin-left: 5px; }

.campaign-activate-switch > .row > .col {
  padding: 2px; }

.activateCampaignCardContainer {
  width: 154px;
  height: 280px;
  border-radius: 5px;
  float: left; }
  .activateCampaignCardContainer:hover {
    background-color: #d3e6e1;
    cursor: pointer; }

#activateParagraph {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 0; }
  @media (max-width: 576px) {
    #activateParagraph {
      font-size: 12px; } }

#activateTitle {
  color: black;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px; }
  @media (max-width: 576px) {
    #activateTitle {
      font-size: 20px; } }

.activateCampaignCardComponent {
  display: inline-block !important;
  margin-right: 5px;
  margin-top: 8px; }
  .activateCampaignCardComponent .activateCampaignCardImage {
    width: 138px;
    height: 138px;
    border-radius: 4px;
    background-position: center;
    background-size: cover; }
  .activateCampaignCardComponent .activateCampaignCardTitle {
    color: black;
    margin: 10% 0% 7% 0%; }
  .activateCampaignCardComponent p {
    font-size: 13px;
    font-weight: normal;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical; }

.campaignsActionButton {
  font-size: 14px;
  font-weight: 600;
  box-shadow: none; }
  .campaignsActionButton:active, .campaignsActionButton:hover {
    box-shadow: none !important; }

@media (min-width: 1200px) {
  .noResultsActivateFirstCampaign {
    /*lg para arriba*/ }
    .noResultsActivateFirstCampaign svg + h1 {
      padding: 0;
      padding-bottom: 1rem; } }

@media (max-width: 768px) {
  .noResultsActivateFirstCampaign {
    /*sm para abajo*/ }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign {
      width: 100%;
      padding-top: 40px; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage + h1 {
      padding: 0;
      padding-bottom: 1rem; } }

@media (max-height: 700px) {
  .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage {
    display: flex;
    justify-content: center; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage svg {
      width: 250px;
      height: auto; } }

@media (max-height: 600px) {
  .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign {
    padding-top: 0; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign h1 {
      font-size: 20px; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage {
      display: flex;
      justify-content: center; }
      .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage svg {
        width: 150px;
        height: auto; } }

.noResultsActivateFirstCampaign svg {
  padding: 0;
  width: 317px;
  height: auto; }
  .noResultsActivateFirstCampaign svg + h1 {
    margin-bottom: 0;
    margin-top: 47px !important;
    padding: 0 !important; }

.noResultsActivateFirstCampaign h6 {
  margin-bottom: 0;
  margin-top: 8px !important;
  padding: 0 !important;
  font-size: 14px;
  font-weight: 400; }

.noResultsActivateFirstCampaign button {
  margin: 0;
  margin-top: 16px !important; }

.multipleCampaignCardContainer {
  width: 100%;
  float: left; }

.no-scroll {
  overflow-y: hidden !important; }

#collVisContainer .modal {
  z-index: 2999 !important; }

#collVisContainer .botbit-table-card-header > div {
  padding: 0 !important; }

#collVisContainer .lastEdited {
  max-width: 11%; }
  #collVisContainer .lastEdited .lastEditedContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  #collVisContainer .lastEdited > .table-title > div {
    padding-left: 2rem; }

#collVisContainer .no-results-container {
  margin-top: 45px; }

@media (max-width: 576px) {
  #collVisContainer #activateFirstCampaignDiv {
    margin-top: 5px; } }

#collVisContainer .minimalEditorModalContainer .modal {
  z-index: 2999 !important; }

#collVisContainer .background-smart {
  background-color: #e9aa43 !important; }

#collVisContainer .confirmationModal > .modal-content {
  min-height: inherit; }

#collVisContainer .mobileColumn .switchContent {
  right: 6px;
  position: absolute; }

#collVisContainer .mobileColumn .imgContainer {
  height: 100%;
  position: relative; }
  #collVisContainer .mobileColumn .imgContainer img {
    width: 105px;
    max-height: 200px;
    border-radius: 2%; }
  #collVisContainer .mobileColumn .imgContainer .smartCampaignBadge {
    right: 0;
    bottom: 0;
    font-size: large;
    position: absolute;
    box-shadow: none; }

#collVisContainer .mobileColumn .campaignInfo {
  margin-left: 13px;
  width: 50vw;
  color: #212121; }
  #collVisContainer .mobileColumn .campaignInfo p {
    line-height: 16px;
    margin: 0;
    margin-bottom: 3px;
    font-style: normal; }
  #collVisContainer .mobileColumn .campaignInfo .campaignName {
    height: auto;
    font-size: 14px;
    font-weight: 600; }
  #collVisContainer .mobileColumn .campaignInfo p:not(.campaignName):not(.effectivity) {
    font-weight: normal;
    font-size: 12px; }
  #collVisContainer .mobileColumn .campaignInfo .segmentStores {
    text-decoration: underline; }
  #collVisContainer .mobileColumn .campaignInfo .clientsThatReturned {
    font-weight: normal;
    font-size: 12px; }
  #collVisContainer .mobileColumn .campaignInfo .effectivity {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px; }

@media (max-width: 576px) {
  #collVisContainer .rowActionsNew {
    bottom: 8px;
    top: inherit; } }

#collVisContainer .botbit-action-button {
  font-size: 14px;
  font-weight: 600;
  box-shadow: none; }

#collVisContainer .botbit-actions-container {
  margin-top: -30px; }

#collVisContainer .botbit-table-card > .card-header:hover::after, #collVisContainer .botbit-table-expanded-row::after {
  display: none; }

#collVisContainer .card-header {
  padding: 17px 0px; }

#collVisContainer .card.botbit-table-card {
  background-color: inherit;
  box-shadow: none; }
  #collVisContainer .card.botbit-table-card:hover {
    background-color: #E9F6F3; }

/* valores de filtros en italica */
.filter-text {
  margin-bottom: 0.4em; }

.filter-text > .filter-value {
  font-style: italic; }

/* separacion entre secciones de la tarjeta modo vista*/
.campaign-panel {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

/* padding del contenido de la tarjeta en modo vista y modo edicion */
.campaign-card .card-body {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 8px; }

.campaignButton {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  margin-left: 15px; }

.campaignBenefitContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignBenefitContainer .benefitSelector {
    width: 25%;
    display: inline-block; }
    @media (max-width: 576px) {
      .campaignBenefitContainer .benefitSelector {
        width: 100%;
        display: block;
        margin: 19px 0 35px 0 !important; } }
  .campaignBenefitContainer .productSelector {
    width: 25%;
    margin-left: 5% !important;
    display: inline-block; }
    .campaignBenefitContainer .productSelector input#discount {
      padding-bottom: 8px; }
    @media (max-width: 576px) {
      .campaignBenefitContainer .productSelector {
        width: 100%;
        margin-left: 0 !important;
        display: block;
        padding-bottom: 15px; } }
    .campaignBenefitContainer .productSelector .md-form {
      margin: 0 !important; }
  .campaignBenefitContainer .customProductNameInput {
    display: inline-block;
    margin-left: 4%;
    width: 40%; }
    @media (max-width: 576px) {
      .campaignBenefitContainer .customProductNameInput {
        width: 100%;
        margin-left: 0;
        display: block;
        padding-bottom: 15px; } }
    .campaignBenefitContainer .customProductNameInput input {
      margin-bottom: 0; }
      .campaignBenefitContainer .customProductNameInput input + label {
        font-size: 11pt;
        font-weight: 400;
        color: black; }
    .campaignBenefitContainer .customProductNameInput.hasError input {
      border-color: var(--danger) !important; }
      .campaignBenefitContainer .customProductNameInput.hasError input:focus {
        box-shadow: 0 1px 0 0 var(--danger); }
      .campaignBenefitContainer .customProductNameInput.hasError input + label {
        color: var(--danger) !important; }
    .campaignBenefitContainer .customProductNameInput.hasError .errorLabel {
      position: absolute;
      font-weight: 400; }
  .campaignBenefitContainer #discount.hasError {
    border-color: var(--danger) !important; }
    .campaignBenefitContainer #discount.hasError:focus {
      box-shadow: 0 1px 0 0 var(--danger); }
    .campaignBenefitContainer #discount.hasError + label {
      color: var(--danger) !important; }

.minimalEditorContainer {
  font-family: Open Sans !important; }
  .minimalEditorContainer .inputLabel {
    font-weight: 600;
    min-width: 140px; }
    @media (max-width: 576px) {
      .minimalEditorContainer .inputLabel {
        margin-bottom: 6px;
        min-width: 100%; } }
  .minimalEditorContainer .campaignMessage {
    margin-top: 8px;
    margin-bottom: 10px; }
  .minimalEditorContainer .testEmailModalParent .modal-content {
    min-height: 0 !important;
    color: black;
    padding: 20px; }
    .minimalEditorContainer .testEmailModalParent .modal-content strong {
      font-weight: 600; }
  .minimalEditorContainer .container {
    padding: 0; }
  .minimalEditorContainer .modalSaveBtnsFooter {
    justify-content: space-around;
    padding: 9px 9px 60px 9px;
    border-top: 1px solid #E7E7E7;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    left: 0;
    width: 100%; }
    .minimalEditorContainer .modalSaveBtnsFooter .btn {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 10px 20px; }
    .minimalEditorContainer .modalSaveBtnsFooter > button {
      width: 46%; }
    .minimalEditorContainer .modalSaveBtnsFooter > .btn-outline-primary {
      width: 49%; }
  .minimalEditorContainer .modalBtnsFooter {
    justify-content: space-around;
    padding: 9px 9px 60px 9px;
    border-top: 1px solid #E7E7E7;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    left: 0;
    width: 100%; }
    .minimalEditorContainer .modalBtnsFooter .btn {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 10px 20px; }
    .minimalEditorContainer .modalBtnsFooter > button {
      width: 44%; }
      @media (max-width: 576px) {
        .minimalEditorContainer .modalBtnsFooter > button.previsualizeBtn {
          line-height: 23px; } }
    .minimalEditorContainer .modalBtnsFooter > .dropdown {
      width: 49%; }
      .minimalEditorContainer .modalBtnsFooter > .dropdown > button {
        width: 100%; }
  .minimalEditorContainer #backIcon {
    padding: 22px 0 0 0;
    color: black;
    font-size: 16px; }
    @media (max-width: 576px) {
      .minimalEditorContainer #backIcon {
        padding-left: 16px; } }
  @media (max-width: 500px) {
    .minimalEditorContainer > .modal-content > .modal-body {
      padding: 0; }
      .minimalEditorContainer > .modal-content > .modal-body > .container {
        padding: 0; } }
  .minimalEditorContainer .md-form.md-range-input-fix > .active {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000; }
  .minimalEditorContainer .no-scroll {
    overflow-y: hidden !important; }
  .minimalEditorContainer #imageGalleryContainer {
    display: inherit;
    flex-direction: inherit;
    justify-content: inherit;
    display: inline-block;
    position: absolute;
    bottom: -3px;
    right: -4px;
    width: auto; }
  .minimalEditorContainer #messageNotCustomModal {
    border-radius: 5px;
    overflow-y: hidden; }
    .minimalEditorContainer #messageNotCustomModal > .modal-dialog > .modal-content {
      padding: 8px; }
      @media (max-width: 576px) {
        .minimalEditorContainer #messageNotCustomModal > .modal-dialog > .modal-content {
          padding: 3px !important; } }
      @media (min-width: 576px) {
        .minimalEditorContainer #messageNotCustomModal > .modal-dialog > .modal-content {
          bottom: 20vh;
          left: 37vw;
          width: 465px; } }
    @media (min-width: 576px) {
      .minimalEditorContainer #messageNotCustomModal .botbit-modal.modal-dialog-centered {
        display: inherit;
        align-items: inherit; } }
    .minimalEditorContainer #messageNotCustomModal .modal-content {
      min-height: 0 !important; }
  .minimalEditorContainer #bodyContainer {
    max-width: 745px;
    padding-bottom: 20px; }
    @media (max-width: 576px) {
      .minimalEditorContainer #bodyContainer {
        height: calc( 100vh - 185px);
        overflow-y: auto;
        padding-bottom: 19px;
        padding-left: 15px;
        padding-right: 15px; } }
    .minimalEditorContainer #bodyContainer > .image {
      padding: 25px 15px; }
  .minimalEditorContainer .saveCloseBtn {
    display: flex;
    justify-content: flex-end; }
    .minimalEditorContainer .saveCloseBtn > button {
      padding: 0 22px 0 22px;
      height: 36px;
      font-weight: 600;
      font-size: 14px;
      border-width: 1px !important; }
  .minimalEditorContainer .editorBoxContainer {
    border: 1px solid #ccc;
    padding: 14px 20px;
    width: 100%;
    position: relative;
    border-radius: 4px;
    max-height: 400px;
    transition: 1s max-height; }
    .minimalEditorContainer .editorBoxContainer.big {
      max-height: 401px; }
      @media (max-width: 576px) {
        .minimalEditorContainer .editorBoxContainer.big {
          border: none;
          border-radius: none;
          max-height: none; } }
    @media (max-width: 576px) {
      .minimalEditorContainer .editorBoxContainer .editingTitle {
        font-weight: bold !important;
        font-size: 24px !important;
        line-height: 33px !important;
        color: #000000 !important; } }
    .minimalEditorContainer .editorBoxContainer .boxTitle {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 9px; }
      @media (max-width: 576px) {
        .minimalEditorContainer .editorBoxContainer .boxTitle {
          max-width: 90%; } }
    .minimalEditorContainer .editorBoxContainer > i {
      color: #19AA89;
      position: absolute;
      right: 21px;
      top: 21px;
      cursor: pointer; }
    .minimalEditorContainer .editorBoxContainer .content {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 19px !important;
      color: #000000 !important; }
    .minimalEditorContainer .editorBoxContainer .greenHighlighted {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #19AA89; }
    .minimalEditorContainer .editorBoxContainer .md-form {
      margin: 0; }
    .minimalEditorContainer .editorBoxContainer .campaignFromToContainer .fromOrTo {
      padding-bottom: 10px; }
    .minimalEditorContainer .editorBoxContainer .campaignFromToContainer #fromEmailSelector + div label {
      bottom: -20px; }
    .minimalEditorContainer .editorBoxContainer .fromDisplayInputContainer {
      position: relative; }
      .minimalEditorContainer .editorBoxContainer .fromDisplayInputContainer.hasError input {
        border-bottom: 1px solid var(--danger);
        box-shadow: none; }
      .minimalEditorContainer .editorBoxContainer .fromDisplayInputContainer.hasError .error-message {
        color: var(--danger);
        position: absolute;
        top: 87px; }
    .minimalEditorContainer .editorBoxContainer .fromDisplayLabel {
      min-width: 140px;
      max-width: 140px;
      display: inline-block;
      font-weight: 600; }
      @media (max-width: 576px) {
        .minimalEditorContainer .editorBoxContainer .fromDisplayLabel {
          width: 100%;
          max-width: 100%;
          margin-top: 10px; } }
  .minimalEditorContainer .saveCancelBtn {
    padding: 0 24px 0 24px;
    height: 33px;
    font-weight: 600; }
  .minimalEditorContainer .btn {
    box-shadow: none !important;
    text-transform: inherit !important; }
  .minimalEditorContainer #webPreviewerContainer .btn {
    text-transform: uppercase !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important; }
  .minimalEditorContainer .badge-warning {
    padding: 5px 14px;
    font-size: 12px;
    font-weight: 600; }
  .minimalEditorContainer .minimalEditorHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600; }
    @media (max-width: 576px) {
      .minimalEditorContainer .minimalEditorHeader {
        width: 100%;
        margin-top: inherit; } }
    .minimalEditorContainer .minimalEditorHeader .btn {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      width: 165px;
      padding: 13px 0; }
      .minimalEditorContainer .minimalEditorHeader .btn.btn-primary {
        line-height: 23px; }
    .minimalEditorContainer .minimalEditorHeader .editCampaignTitle {
      width: 100%;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
      display: inline-block; }
      @media (max-width: 576px) {
        .minimalEditorContainer .minimalEditorHeader .editCampaignTitle {
          width: 100%; } }
      @media (max-width: 576px) {
        .minimalEditorContainer .minimalEditorHeader .editCampaignTitle > span:not(.badge) {
          width: 75%;
          display: block;
          float: left; } }
    .minimalEditorContainer .minimalEditorHeader .smartBadge {
      display: block;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      @media (max-width: 576px) {
        .minimalEditorContainer .minimalEditorHeader .smartBadge {
          height: 100%; } }
    .minimalEditorContainer .minimalEditorHeader .maximalEditorBtn {
      padding-left: 4%;
      padding-right: 4%;
      width: 160px; }
  .minimalEditorContainer .closeModalIcon {
    cursor: pointer; }

.webPreviewerModal .modal-title {
  display: flex; }
  .webPreviewerModal .modal-title .back-button {
    width: 18px;
    margin-bottom: 5px;
    margin-right: 15px; }
    .webPreviewerModal .modal-title .back-button i {
      font-size: 16px;
      color: black; }

.webPreviewerModal #webPreviewerContainer {
  height: 87vh !important; }

.loading-overlay {
  z-index: 999999; }

.campaignCompanyAndStoreSelector {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignCompanyAndStoreSelector > span:first-child {
    margin-right: 10px; }
  .campaignCompanyAndStoreSelector #segmentOptionSelector {
    width: 37%; }
    @media (max-width: 321px) {
      .campaignCompanyAndStoreSelector #segmentOptionSelector {
        width: 71% !important; } }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #segmentOptionSelector {
        width: 76%; } }
  .campaignCompanyAndStoreSelector #storeSelector {
    width: 48%;
    margin-left: 16px; }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #storeSelector {
        width: 100%;
        margin-left: 0; } }
  .campaignCompanyAndStoreSelector #canTargetCompanyStoreSelector {
    width: 86%; }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #canTargetCompanyStoreSelector {
        width: 76%; } }
  .campaignCompanyAndStoreSelector #storeSelector, .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector {
    position: relative; }
    .campaignCompanyAndStoreSelector #storeSelector.error input:not(#selectSearchInput), .campaignCompanyAndStoreSelector #storeSelector.error .caret, .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector.error input:not(#selectSearchInput), .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector.error .caret {
      color: var(--danger) !important;
      border-color: var(--danger); }
    .campaignCompanyAndStoreSelector #storeSelector.error > label:not(#selectSearchInput), .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector.error > label:not(#selectSearchInput) {
      left: 8px;
      bottom: -5px;
      top: inherit;
      color: var(--danger); }
  .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector {
    width: 300px; }
    @media (max-width: 576px) {
      .campaignCompanyAndStoreSelector #cantTargetCompanyStoreSelector {
        width: 100%; } }

.campaignSegmentContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignSegmentContainer #segmentDaysInput {
    width: 25px;
    padding: 0 0 0 8px;
    margin-left: 9px; }
  @media (min-width: 576px) {
    .campaignSegmentContainer #targetStoresParent {
      max-width: 95%; } }
  .campaignSegmentContainer #segmentTargetStores {
    display: contents;
    color: #19aa89;
    font-weight: 600; }
  .campaignSegmentContainer #segmentDescription {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000; }

.campaignFromToContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  display: inline-block;
  width: 100%;
  margin-right: 0;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  font-weight: 600; }
  .campaignFromToContainer div > span {
    width: auto;
    padding-top: 8px; }
  .campaignFromToContainer .fromOrTo {
    float: left;
    width: 100%; }
  .campaignFromToContainer .fromToLabel {
    min-width: 140px;
    max-width: 140px;
    display: inline-block;
    font-weight: 600; }
    @media (max-width: 576px) {
      .campaignFromToContainer .fromToLabel {
        width: 100%; } }
    @media (max-width: 576px) {
      .campaignFromToContainer .fromToLabel {
        width: 100%;
        max-width: 100%;
        margin-top: 10px; } }
  .campaignFromToContainer .fromToMessage {
    word-break: break-all;
    max-width: 100%; }
  .campaignFromToContainer .replyToAddress .error {
    border-color: var(--danger) !important; }
    .campaignFromToContainer .replyToAddress .error:focus {
      box-shadow: 0 1px 0 0 var(--danger) !important; }
  .campaignFromToContainer .replyToAddress label {
    color: var(--danger);
    font-weight: 400;
    bottom: -7px; }
  .campaignFromToContainer #fromEmailSelector {
    display: inline-block;
    width: 35%; }
    @media (max-width: 576px) {
      .campaignFromToContainer #fromEmailSelector {
        width: 100%; } }
    @media (min-width: 576px) {
      .campaignFromToContainer #fromEmailSelector input {
        margin-bottom: 0; } }
    .campaignFromToContainer #fromEmailSelector .caret {
      top: inherit; }
    .campaignFromToContainer #fromEmailSelector + div {
      /* input container */
      display: inline-block;
      margin-left: 5%;
      width: 60%;
      position: relative; }
      .campaignFromToContainer #fromEmailSelector + div .error {
        border-color: var(--danger) !important; }
        .campaignFromToContainer #fromEmailSelector + div .error:focus {
          box-shadow: 0 1px 0 0 var(--danger) !important; }
      @media (max-width: 576px) {
        .campaignFromToContainer #fromEmailSelector + div {
          width: 100%;
          margin-left: 0; }
          .campaignFromToContainer #fromEmailSelector + div .md-form {
            margin: 0; } }
      .campaignFromToContainer #fromEmailSelector + div label {
        position: absolute;
        color: var(--danger);
        font-weight: 400;
        bottom: -7px; }
  .campaignFromToContainer .fromEmailCompanyLabel {
    font-weight: 400; }

.campaignCompanyContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignCompanyContainer .fieldWrapper {
    display: flex; }
    @media (max-width: 576px) {
      .campaignCompanyContainer .fieldWrapper {
        display: block; } }
    .campaignCompanyContainer .fieldWrapper .companyLabel {
      display: block;
      min-width: 140px;
      max-width: 140px;
      display: inline-block;
      font-weight: 600; }
      @media (max-width: 576px) {
        .campaignCompanyContainer .fieldWrapper .companyLabel {
          width: 100%;
          max-width: 100%;
          margin-top: 10px; } }
      @media (max-width: 576px) {
        .campaignCompanyContainer .fieldWrapper .companyLabel {
          margin-bottom: 10px; } }

.MuiTooltip-popper {
  font-size: 15px; }

.campaignValidityContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  display: inline-block;
  position: relative; }
  @media (max-width: 576px) {
    .campaignValidityContainer {
      display: block;
      padding-bottom: 25px; } }
  .campaignValidityContainer .validityLabel {
    font-weight: 600;
    min-width: 0;
    display: inline-block;
    margin-right: 7px;
    line-height: 47px; }
  .campaignValidityContainer .wtValidToContainer {
    display: inline-block;
    position: relative; }
    .campaignValidityContainer .wtValidToContainer.hasError {
      margin-bottom: 9px; }
      .campaignValidityContainer .wtValidToContainer.hasError .DayPickerInput button {
        border-bottom: 2px solid var(--danger); }
      .campaignValidityContainer .wtValidToContainer.hasError .error-message {
        position: absolute;
        left: 0;
        bottom: -23px;
        color: var(--danger);
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content; }
  .campaignValidityContainer .validityInputContainer {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px; }
    .campaignValidityContainer .validityInputContainer .validityInput {
      max-width: 45px;
      margin-bottom: 0; }
    .campaignValidityContainer .validityInputContainer span:not(.error-message) {
      margin-left: 10px;
      margin-bottom: 6px; }
    .campaignValidityContainer .validityInputContainer.hasError .validityInput {
      border-color: var(--danger) !important; }
      .campaignValidityContainer .validityInputContainer.hasError .validityInput:focus {
        box-shadow: 0 1px 0 0 var(--danger); }
    .campaignValidityContainer .validityInputContainer .error-message {
      position: absolute;
      top: 44px;
      left: 148px;
      right: 0;
      width: 230px;
      color: var(--danger); }
      @media (max-width: 576px) {
        .campaignValidityContainer .validityInputContainer .error-message {
          left: 73px; } }
  .campaignValidityContainer .DayPickerInput {
    margin-right: 15px; }
    .campaignValidityContainer .DayPickerInput .dayPickerTrigger {
      color: black;
      border: none;
      background: none;
      padding: 7px 8px;
      border-bottom: 1px solid #ced4da;
      width: 100%; }
      .campaignValidityContainer .DayPickerInput .dayPickerTrigger::-webkit-input-placeholder {
        color: black; }
      .campaignValidityContainer .DayPickerInput .dayPickerTrigger::-ms-input-placeholder {
        color: black; }
      .campaignValidityContainer .DayPickerInput .dayPickerTrigger::placeholder {
        color: black; }
      .campaignValidityContainer .DayPickerInput .dayPickerTrigger:focus {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;
        border-bottom: 2px solid var(--primary); }
    .campaignValidityContainer .DayPickerInput .DayPickerInput-OverlayWrapper {
      z-index: 3; }
    .campaignValidityContainer .DayPickerInput .DayPicker-Footer {
      display: flex;
      justify-content: center; }
  .campaignValidityContainer .validityDisclaimer {
    color: dodgerblue; }

.campaignSubject {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignSubject .inputSpan {
    width: 80%;
    padding-top: 8px; }
    @media (max-width: 576px) {
      .campaignSubject .inputSpan {
        width: 100%; } }
  .campaignSubject .inputLabel {
    min-width: 140px;
    max-width: 140px;
    display: inline-block;
    font-weight: 600;
    padding-top: 8px; }
    @media (max-width: 576px) {
      .campaignSubject .inputLabel {
        width: 100%; } }
    @media (max-width: 576px) {
      .campaignSubject .inputLabel {
        width: 100%;
        max-width: 100%;
        margin-top: 10px; } }
  .campaignSubject .md-form > input {
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 0; }
  .campaignSubject .tags-input.has-error > .md-form {
    margin-bottom: 30px; }

.campaignMessage .benefitMessageContainer {
  display: flex;
  align-items: center;
  min-width: 80%; }
  @media (max-width: 576px) {
    .campaignMessage .benefitMessageContainer {
      display: block; } }
  .campaignMessage .benefitMessageContainer .benefitMessageInput {
    position: relative;
    width: 65% !important; }
    .campaignMessage .benefitMessageContainer .benefitMessageInput .tags-input {
      width: 100%; }
      .campaignMessage .benefitMessageContainer .benefitMessageInput .tags-input .md-form {
        margin: 0; }
    .campaignMessage .benefitMessageContainer .benefitMessageInput input {
      margin-bottom: -2px; }
      @media (max-width: 576px) {
        .campaignMessage .benefitMessageContainer .benefitMessageInput input {
          margin-bottom: 7px; } }
    .campaignMessage .benefitMessageContainer .benefitMessageInput.hasError input {
      border-bottom: 1px solid var(--danger);
      box-shadow: none; }
    @media (max-width: 576px) {
      .campaignMessage .benefitMessageContainer .benefitMessageInput {
        margin-top: 10px;
        width: 100% !important; } }
    .campaignMessage .benefitMessageContainer .benefitMessageInput .error-message {
      position: absolute;
      left: 0;
      bottom: -26px;
      color: var(--danger); }
      @media (max-width: 576px) {
        .campaignMessage .benefitMessageContainer .benefitMessageInput .error-message {
          bottom: -18px; } }
  .campaignMessage .benefitMessageContainer .messageBenefitLabel {
    width: 65%;
    display: flex;
    font-weight: 400;
    align-items: center; }
    @media (max-width: 576px) {
      .campaignMessage .benefitMessageContainer .messageBenefitLabel {
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px; } }
  .campaignMessage .benefitMessageContainer .benefitMessageIsCustomSelector {
    width: 30%;
    margin-right: 5%; }
    @media (max-width: 576px) {
      .campaignMessage .benefitMessageContainer .benefitMessageIsCustomSelector {
        width: 100%; } }
    .campaignMessage .benefitMessageContainer .benefitMessageIsCustomSelector input {
      margin-bottom: 0; }

#campaignNameContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 15px; }
  @media (max-width: 576px) {
    #campaignNameContainer {
      display: block; } }
  #campaignNameContainer .md-form {
    width: 100%; }
    #campaignNameContainer .md-form input.hasError {
      border-color: var(--danger) !important; }
      #campaignNameContainer .md-form input.hasError:focus {
        box-shadow: 0 1px 0 0 var(--danger); }
  #campaignNameContainer label {
    min-width: 140px;
    max-width: 140px;
    display: inline-block;
    font-weight: 600; }
    @media (max-width: 576px) {
      #campaignNameContainer label {
        width: 100%;
        max-width: 100%;
        margin-top: 10px; } }
  #campaignNameContainer .error-message {
    color: var(--danger);
    position: absolute;
    bottom: 0;
    left: 140px; }
    @media (max-width: 576px) {
      #campaignNameContainer .error-message {
        left: 0; } }
  #campaignNameContainer #readOnlyLabel {
    font-weight: unset; }

.segmentCollVis {
  margin-top: 39px; }
  @media (max-width: 576px) {
    .segmentCollVis {
      margin-top: 35px; } }
  @media (max-width: 576px) {
    .segmentCollVis #bigTableContainer {
      display: flex;
      flex-direction: column; } }
  .segmentCollVis #bigTableContainer > div > .row {
    margin-right: 0;
    margin-left: 0; }
  .segmentCollVis #bigTableContainer .botbit-table {
    padding: 0 !important; }
    .segmentCollVis #bigTableContainer .botbit-table .botbit-table-card-header {
      padding-bottom: 22px; }
      .segmentCollVis #bigTableContainer .botbit-table .botbit-table-card-header .table-title > div {
        font-size: 12px;
        font-weight: normal; }
  .segmentCollVis .segmentMobile {
    display: flex;
    font-size: 14px; }
    .segmentCollVis .segmentMobile .w-90 {
      width: 90% !important; }
    .segmentCollVis .segmentMobile.storesColumn {
      padding-right: 12px; }
    .segmentCollVis .segmentMobile.nameColumn {
      padding-right: 17px; }
      .segmentCollVis .segmentMobile.nameColumn .segmentRadioButton > .form-check > label {
        padding: 0;
        padding-right: 30px;
        margin: 0 !important; }
      .segmentCollVis .segmentMobile.nameColumn > div:not(.segmentRadioButton) > span {
        display: block; }
        .segmentCollVis .segmentMobile.nameColumn > div:not(.segmentRadioButton) > span:nth-child(2) {
          font-weight: normal; }
  .segmentCollVis .collection-visualizer-header {
    margin-bottom: 34px; }
    .segmentCollVis .collection-visualizer-header.mobile {
      margin: 0; }
    .segmentCollVis .collection-visualizer-header .collection-visualizer-title-container {
      margin-right: 52px; }
    .segmentCollVis .collection-visualizer-header .botbit-action-button {
      padding: 7px 13px; }
  .segmentCollVis .botbit-table-card {
    font-weight: 600;
    color: black;
    font-size: 12px;
    box-shadow: none;
    background: inherit; }
    .segmentCollVis .botbit-table-card > .card-header:hover::after, .segmentCollVis .botbit-table-card .botbit-table-expanded-row::after {
      display: none; }
    .segmentCollVis .botbit-table-card:hover {
      background-color: #E9F6F3; }
  .segmentCollVis .botbit-actions-container .dropdown button.dropdown-item i {
    width: 25px;
    text-align: center; }
  .segmentCollVis .segmentRadioButton > .form-check {
    margin: 0 !important;
    padding: 0; }
  .segmentCollVis .segmentDetailContainer {
    padding: 20px;
    font-size: 16px;
    font-weight: 500; }
    .segmentCollVis .segmentDetailContainer h4 {
      font-size: 18px; }
    .segmentCollVis .segmentDetailContainer .filter-text {
      font-size: 16px;
      font-weight: 500; }

.cron_builder {
  width: 100%;
  border: none;
  background: inherit;
  font-weight: normal;
  padding: 0; }
  .cron_builder .cron_builder_bordering {
    min-height: 129px; }
  .cron_builder [type="checkbox"]:not(:checked), .cron_builder [type="checkbox"]:checked, .cron_builder [type="radio"]:not(:checked), .cron_builder [type="radio"]:checked {
    position: unset !important;
    opacity: 1 !important;
    pointer-events: unset !important; }
  .cron_builder select {
    display: unset !important; }
  .cron_builder li.active {
    background: #EBF5F3; }
  .cron_builder li > a {
    color: #19AA89 !important;
    text-transform: none !important;
    text-transform: initial !important;
    border: none !important;
    padding: 12px 40px !important; }

.cronExpressionBuilder {
  padding-bottom: 25px; }
  .cronExpressionBuilder .mainSelector {
    display: inline-block; }
    @media (max-width: 576px) {
      .cronExpressionBuilder .mainSelector {
        width: 100%; } }
  .cronExpressionBuilder > div > div {
    margin: 0; }
  .cronExpressionBuilder .DayPickerInput, .cronExpressionBuilder .rc-time-picker {
    padding: 0 10px; }
  .cronExpressionBuilder .rc-time-picker {
    display: inline-block;
    width: 102px; }
  .cronExpressionBuilder .sendEveryContainer {
    display: flex !important;
    align-items: center;
    min-height: 66px; }
    .cronExpressionBuilder .sendEveryContainer .everyInputContainer {
      display: flex;
      align-items: center; }
      .cronExpressionBuilder .sendEveryContainer .everyInputContainer .select-wrapper.mdb-select.md-form > input {
        margin: 0; }
      .cronExpressionBuilder .sendEveryContainer .everyInputContainer > div.md-form {
        display: inline-block;
        margin: 0;
        padding: 0 10px; }
        .cronExpressionBuilder .sendEveryContainer .everyInputContainer > div.md-form:not(.mdb-select) {
          width: 80px; }
      .cronExpressionBuilder .sendEveryContainer .everyInputContainer .rc-time-picker input {
        width: 100%; }
      @media (max-width: 576px) {
        .cronExpressionBuilder .sendEveryContainer .everyInputContainer ul.dropdown-content.select-dropdown.fadeElement.fadeIn {
          min-width: 120px; } }
    .cronExpressionBuilder .sendEveryContainer .monthlyOptionMobile {
      display: contents; }
      .cronExpressionBuilder .sendEveryContainer .monthlyOptionMobile .md-form {
        padding: 0 10px; }

#new-campaign {
  font-family: Open Sans;
  font-style: normal; }
  #new-campaign #activateFirstCampaignDiv {
    margin: 0 !important; }
    #new-campaign #activateFirstCampaignDiv .multipleCampaignCardContainer {
      margin: 0 !important; }
    #new-campaign #activateFirstCampaignDiv > h3 {
      margin-bottom: 15px; }
  #new-campaign h3 {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 50px; }
  #new-campaign .back-button {
    color: black;
    font-size: 16px;
    margin-top: 20px; }
  #new-campaign .new-campaign-header {
    margin-top: 22px; }
    #new-campaign .new-campaign-header h1 {
      margin: 0;
      color: black;
      font-family: Open Sans;
      font-size: 24px;
      font-weight: 600;
      display: inline-block; }
    #new-campaign .new-campaign-header .select-company {
      display: inline-block;
      margin: 0;
      margin-left: 8px; }
      #new-campaign .new-campaign-header .select-company .select-dropdown,
      #new-campaign .new-campaign-header .select-company .caret {
        color: var(--primary) !important;
        border-color: var(--primary) !important; }
      #new-campaign .new-campaign-header .select-company .select-dropdown {
        font-size: 24px; }
      @media (max-width: 576px) {
        #new-campaign .new-campaign-header .select-company {
          margin-left: 0; } }
  #new-campaign #customCampaignsContainer {
    margin-top: 30px; }
    #new-campaign #customCampaignsContainer .nav-item {
      border-left: 0; }
      #new-campaign #customCampaignsContainer .nav-item.active, #new-campaign #customCampaignsContainer .nav-item:hover {
        background: none; }
        #new-campaign #customCampaignsContainer .nav-item.active .nav-link, #new-campaign #customCampaignsContainer .nav-item:hover .nav-link {
          border-color: #20bfc2;
          color: #20bfc2 !important; }
    #new-campaign #customCampaignsContainer .nav-link {
      padding: 14px 40px;
      border-color: white;
      color: #676a6c; }
    @media (max-width: 576px) {
      #new-campaign #customCampaignsContainer .nav-item {
        max-width: 33.3333%; }
        #new-campaign #customCampaignsContainer .nav-item .nav-link {
          padding: 14px; } }
    #new-campaign #customCampaignsContainer .tab-content {
      box-shadow: none;
      background-color: inherit; }
    #new-campaign #customCampaignsContainer .nav {
      margin-top: 0 !important; }
    #new-campaign #customCampaignsContainer .nav li:first-child {
      margin-left: 0; }
    #new-campaign #customCampaignsContainer .nav li a {
      text-transform: inherit;
      font-size: 16px;
      line-height: 19px;
      color: #676a6c; }
    @media (max-width: 576px) {
      #new-campaign #customCampaignsContainer .nav li > ul {
        display: flex;
        justify-content: center; } }
  #new-campaign .smart-campaign-wizard-container {
    max-width: 750px;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #000000 !important; }
    @media (max-width: 950px) {
      #new-campaign .smart-campaign-wizard-container {
        padding: 0; } }
    #new-campaign .smart-campaign-wizard-container .title {
      font-weight: 300;
      font-size: 30px;
      line-height: 36px;
      margin-top: 60px;
      color: black;
      margin-bottom: 25px; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .title {
          margin-top: 15px; } }
    #new-campaign .smart-campaign-wizard-container .sendDatesContainer {
      text-transform: lowercase; }
    #new-campaign .smart-campaign-wizard-container .subject-message-and-button-form .tags-input input {
      font-weight: 600;
      color: black;
      font-size: 16px;
      line-height: 22px; }
    #new-campaign .smart-campaign-wizard-container .subject-message-and-button-form .inputLabel {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: black; }
    #new-campaign .smart-campaign-wizard-container .campaignBanner > svg {
      position: fixed;
      right: 2%;
      bottom: 5%;
      max-width: 20%;
      height: auto; }
      @media (max-width: 950px) {
        #new-campaign .smart-campaign-wizard-container .campaignBanner > svg {
          display: none; } }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .md-range-input-fix {
      margin: 0; }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentDescription {
      display: flex;
      align-items: center; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentDescription {
          display: block; } }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentFormInput .md-form {
      margin: 0;
      margin-left: 10px; }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentFormInput + .d-inline-block {
      margin-left: 15px; }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .campaignCompanyAndStoreSelector {
      margin-top: 10px; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .campaignCompanyAndStoreSelector #storeSelector {
          margin-top: 0; }
        #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .campaignCompanyAndStoreSelector #segmentOptionSelector {
          width: 100% !important; } }
    #new-campaign .smart-campaign-wizard-container .campaignButton {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm div:not(.error) .mdb-select {
      color: var(--primary); }
      #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm div:not(.error) .mdb-select input:not(#selectSearchInput),
      #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm div:not(.error) .mdb-select .caret {
        color: var(--primary); }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm .mdb-select .select-dropdown {
      padding: 0 5px; }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm input:not(#selectSearchInput).form-control {
      color: var(--primary); }
      #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm input:not(#selectSearchInput).form-control + label {
        color: var(--primary); }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm .campaignBenefitContainer .productSelector #discount {
      padding-bottom: 8px; }
    #new-campaign .smart-campaign-wizard-container .fromToDisplayForm {
      padding-bottom: 27px; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .fromToDisplayForm #fromEmailSelector + div .md-form {
          margin-bottom: 1.5rem; } }
    #new-campaign .smart-campaign-wizard-container .benefitContainer {
      width: 75%;
      display: inline-block; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .benefitContainer {
          width: 100%; } }
      #new-campaign .smart-campaign-wizard-container .benefitContainer .productSelector {
        padding-bottom: 40px; }
    #new-campaign .smart-campaign-wizard-container .btn {
      text-transform: none; }
  @media (max-width: 576px) {
    #new-campaign .activateCampaignCardComponent {
      margin-right: 0; } }
  #new-campaign #exitModal {
    border-radius: 5px;
    overflow-y: hidden; }
    #new-campaign #exitModal > .modal-dialog > .modal-content {
      padding: 8px; }
      @media (max-width: 576px) {
        #new-campaign #exitModal > .modal-dialog > .modal-content {
          padding: 3px !important; } }
      @media (min-width: 576px) {
        #new-campaign #exitModal > .modal-dialog > .modal-content {
          bottom: 20vh;
          margin: auto;
          width: 465px; } }
    @media (min-width: 576px) {
      #new-campaign #exitModal .botbit-modal.modal-dialog-centered {
        bottom: 40%;
        display: inherit;
        align-items: inherit; } }
    #new-campaign #exitModal .modal-content {
      min-height: 0 !important; }

.new-campaign-template-card {
  border-radius: 5px;
  width: 100%;
  padding: 11px; }
  .new-campaign-template-card:hover {
    background-color: #e9f6f3;
    cursor: pointer; }
  .new-campaign-template-card .previewImage {
    height: 132px;
    background-size: cover;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box; }
  .new-campaign-template-card img {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box; }
  .new-campaign-template-card span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: black;
    display: block;
    margin-top: 17px; }

.campaign-name-tilt {
  color: var(--primary); }

.testEmailModalBody {
  overflow: visible !important; }
  .testEmailModalBody .modalTitle {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600; }
  .testEmailModalBody .modalButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 13px;
    margin-top: 20px; }
    .testEmailModalBody .modalButtons button {
      background: #19AA89;
      box-shadow: none;
      border-radius: 3px;
      padding: 10px 43px;
      text-transform: none;
      text-transform: initial;
      font-weight: 600;
      margin-right: 18px; }
    .testEmailModalBody .modalButtons a {
      color: #19AA89; }
  .testEmailModalBody #extraEmailsInput > div {
    margin-bottom: 0; }
    .testEmailModalBody #extraEmailsInput > div input.error {
      border-bottom: 1px solid var(--danger); }
      .testEmailModalBody #extraEmailsInput > div input.error:focus {
        box-shadow: none; }
  .testEmailModalBody #extraEmailsInput.error label {
    color: var(--danger);
    font-weight: 400; }

.testEmailModal {
  overflow: visible !important; }

@media (max-width: 576px) {
  #maximal-editor .campaignNotSavedModal .modal-content {
    height: 26vh;
    min-height: 0; } }

#maximal-editor .campaignNotSavedModal .modalTitle {
  font-size: 20px;
  line-height: 28px;
  color: black; }

#maximal-editor .campaignNotSavedModal .modalContent {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px; }

#maximal-editor .campaignNotSavedModal .modalButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 13px;
  margin-top: 20px; }
  #maximal-editor .campaignNotSavedModal .modalButtons button {
    background: #19AA89;
    box-shadow: none;
    border-radius: 3px;
    padding: 10px 43px;
    text-transform: none;
    text-transform: initial;
    font-weight: 600;
    margin-right: 18px; }
  #maximal-editor .campaignNotSavedModal .modalButtons a {
    color: #19AA89; }

#maximal-editor #toastContent div:first-child {
  margin-top: 2px; }

#maximal-editor > .container {
  font-family: Open Sans;
  padding-top: 22px;
  padding-bottom: 22px;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  font-weight: 400; }
  @media (max-width: 576px) {
    #maximal-editor > .container {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  #maximal-editor > .container .previewerModal .modal-body {
    max-height: 100vh !important; }
  #maximal-editor > .container #header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    #maximal-editor > .container #header .right-side .primary-actions .botbit-action-link {
      margin-right: 15px; }
    @media (max-width: 576px) {
      #maximal-editor > .container #header .right-side {
        background-color: white;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        padding: 12px 0px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14); }
        #maximal-editor > .container #header .right-side .botbit-actions-container {
          width: 100%; }
        #maximal-editor > .container #header .right-side .primary-actions {
          display: flex;
          justify-content: space-evenly; }
          #maximal-editor > .container #header .right-side .primary-actions > .dropdown {
            height: 100%;
            width: 42%; }
            #maximal-editor > .container #header .right-side .primary-actions > .dropdown button {
              width: 100%;
              padding: 11px; }
          #maximal-editor > .container #header .right-side .primary-actions > button {
            padding: 0;
            width: 42%;
            padding: 13px 0; }
        #maximal-editor > .container #header .right-side button:not(.dropdown-item) {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-transform: none;
          text-transform: initial;
          box-shadow: none;
          border-radius: 3px;
          margin: 0; } }
    #maximal-editor > .container #header .left-side {
      max-width: calc( 100% - 577px); }
      @media (max-width: 576px) {
        #maximal-editor > .container #header .left-side {
          max-width: 100%;
          width: 100%; } }
      #maximal-editor > .container #header .left-side .smartBadge {
        padding: 5px 13px;
        font-size: 13px; }
      #maximal-editor > .container #header .left-side .icon-container i {
        color: black;
        font-size: 16px;
        margin-bottom: 25px;
        cursor: pointer; }
      #maximal-editor > .container #header .left-side .titleContainer {
        width: 100%;
        display: flex;
        align-items: center; }
        #maximal-editor > .container #header .left-side .titleContainer h1 {
          margin: 0;
          font-size: 24px;
          color: black;
          font-weight: 600;
          margin-bottom: 5px; }
  #maximal-editor > .container .classic-tabs .nav li:first-child {
    margin-left: 0; }
  #maximal-editor > .container #tabsContainer {
    margin-top: 40px; }
    #maximal-editor > .container #tabsContainer .fixed-action-btn {
      bottom: 67px !important; }
    #maximal-editor > .container #tabsContainer > .nav {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    #maximal-editor > .container #tabsContainer > .tab-content {
      padding: 0 !important; }
      @media (max-width: 576px) {
        #maximal-editor > .container #tabsContainer > .tab-content {
          padding: 0 !important; } }
    #maximal-editor > .container #tabsContainer .segmentCollVis .collection-visualizer-title {
      font-weight: 300;
      font-size: 20px; }
    #maximal-editor > .container #tabsContainer .section-tab-item {
      border-left: 0;
      background: none; }
      #maximal-editor > .container #tabsContainer .section-tab-item.active .nav-link, #maximal-editor > .container #tabsContainer .section-tab-item:hover .nav-link {
        border-color: #20BFC2;
        color: #20BFC2 !important; }
        #maximal-editor > .container #tabsContainer .section-tab-item.active .nav-link.invalidTab, #maximal-editor > .container #tabsContainer .section-tab-item:hover .nav-link.invalidTab {
          border-color: var(--danger) !important;
          color: var(--danger) !important; }
      #maximal-editor > .container #tabsContainer .section-tab-item .nav-link {
        padding: 14px 19px;
        border-color: transparent;
        color: #676A6C;
        text-transform: none;
        font-size: 16px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .section-tab-item .nav-link {
            font-size: 14px; } }
        #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i {
          margin-left: 12px;
          font-size: 18px; }
          #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i.fa-check-circle {
            color: #20BFC2; }
          #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i.fa-exclamation-circle {
            color: var(--danger); }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i {
              margin-left: 0;
              font-size: 14px; } }
    @media (max-width: 576px) {
      #maximal-editor > .container #tabsContainer .section-tab-item {
        width: 33.3333%; }
        #maximal-editor > .container #tabsContainer .section-tab-item .nav-link {
          padding: 13px 0px; } }
    #maximal-editor > .container #tabsContainer .tab-content {
      box-shadow: none;
      background-color: inherit; }
    #maximal-editor > .container #tabsContainer .email-content {
      margin-top: 30px; }
      #maximal-editor > .container #tabsContainer .email-content .card {
        padding-bottom: 39px !important; }
      #maximal-editor > .container #tabsContainer .email-content .md-form {
        margin-top: 0px;
        margin-bottom: 0px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo {
        display: flex;
        align-items: baseline; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo .replyToAddress {
          width: 70%; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo .replyToAddress {
              width: 100%; } }
      #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer #fromEmailSelector + div label {
        bottom: -20px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignMessage, #maximal-editor > .container #tabsContainer .email-content .campaignSubject {
        display: flex;
        align-items: center;
        margin-top: 20px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .campaignMessage, #maximal-editor > .container #tabsContainer .email-content .campaignSubject {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignMessage .input-with-tags, #maximal-editor > .container #tabsContainer .email-content .campaignSubject .input-with-tags {
          width: -webkit-fill-available; }
          #maximal-editor > .container #tabsContainer .email-content .campaignMessage .input-with-tags .error-message, #maximal-editor > .container #tabsContainer .email-content .campaignSubject .input-with-tags .error-message {
            bottom: -16px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignSubject {
        margin-top: 15px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignCompanyContainer {
        margin-bottom: 20px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignBenefitContainer #discount {
        font-weight: 400; }
      #maximal-editor > .container #tabsContainer .email-content .campaignValidityContainer {
        margin-top: 15px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer {
        display: flex;
        align-items: center;
        margin-top: 33px;
        padding-bottom: 30px;
        width: 100%;
        height: auto; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer {
            position: relative; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer .error-message {
          color: var(--danger);
          position: absolute;
          bottom: 27px;
          left: 24px;
          width: 100%; }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer .hasError img {
          border: 1px solid var(--danger); }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer .hasError #imageGalleryContainer > button {
          background-color: var(--danger) !important; }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer img {
          float: left !important;
          margin-left: 0 !important;
          margin-top: 0 !important; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer img {
              float: right !important; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer #imageGalleryContainer {
          flex-direction: inherit;
          justify-content: inherit;
          display: inline-block;
          position: absolute;
          bottom: 41px;
          left: 105px;
          width: auto; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer #imageGalleryContainer {
              left: unset;
              right: -24px; } }
          #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer #imageGalleryContainer .nav-link {
            border-bottom: 0; }
      #maximal-editor > .container #tabsContainer .email-content .inputLabel {
        min-width: 140px;
        max-width: 140px;
        display: inline-block;
        font-weight: 600; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .inputLabel {
            width: 100%;
            max-width: 100%;
            margin-top: 10px; } }
      #maximal-editor > .container #tabsContainer .email-content .buttonContainer {
        display: flex;
        align-items: center;
        margin-top: 17px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .buttonContainer {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .buttonContainer .campaignButton div:first-child {
          margin-bottom: -15px; }
      #maximal-editor > .container #tabsContainer .email-content .benefitContainer {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .benefitContainer {
            width: 100%;
            display: block; } }
      #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer {
        margin-top: 25px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer {
            margin-top: 0; } }
        #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer #countAsWtSwitch {
          display: inline-block;
          margin-left: 20px; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer #countAsWtSwitch {
              margin-top: 17px;
              margin-left: -11px;
              display: block; } }
      #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer {
        margin-top: 31px;
        display: flex; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer {
            display: block;
            margin-top: 10px; } }
        #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer span.inputLabel {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center; }
          #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer span.inputLabel i {
            margin-left: 5px;
            color: #1e90ff; }
        #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown {
          width: 320px; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown {
              margin: 0;
              margin-top: 8px;
              width: 100%; } }
          #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown button {
            border: none !important;
            box-shadow: none;
            border-radius: 0;
            color: black !important;
            padding: 0;
            text-transform: capitalize;
            border-bottom: 1px solid #ced4da !important;
            padding: 0 27px; }
            @media (max-width: 576px) {
              #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown button {
                padding-left: 0;
                margin-left: 0;
                width: 100%;
                padding: 0;
                margin: 5px 0 0 0; } }
      #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer {
        display: flex;
        align-items: center;
        margin-top: 26px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyLabel {
          font-weight: 600; }
        #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer {
          display: flex;
          align-items: center; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer .usersNotReelegibleDaysQttyInput {
              margin-left: 0; } }
          #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer input {
            margin-bottom: 0;
            max-width: 45px;
            margin: 0 10px; }
          #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer span {
            font-weight: 600; }
      #maximal-editor > .container #tabsContainer .email-content #html-editor-container {
        margin-top: 20px; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container #htmlContentDisclaimer {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          display: flex;
          background-color: #FFF3CD;
          padding: 12px;
          border-radius: 5px;
          margin: 30px 0; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container .custom-toolbar-extra a {
          display: flex;
          align-items: center; }
          #maximal-editor > .container #tabsContainer .email-content #html-editor-container .custom-toolbar-extra a span {
            padding: 0 !important; }
          #maximal-editor > .container #tabsContainer .email-content #html-editor-container .custom-toolbar-extra a i {
            font-family: "Font Awesome 5 Free";
            font-size: 21px;
            font-weight: 900; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container #tags-container i {
          font-size: 16px; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container #imageGalleryContainer {
          padding: 0 !important; }
      #maximal-editor > .container #tabsContainer .email-content .tags-modal .p-2 {
        padding: 0 !important; }
      #maximal-editor > .container #tabsContainer .email-content .tags-modal .form-check label {
        padding-left: 0; }
        #maximal-editor > .container #tabsContainer .email-content .tags-modal .form-check label:before {
          display: none; }
      #maximal-editor > .container #tabsContainer .email-content .deliveries-modal .botbit-expanded-select li img {
        width: 90%; }
      #maximal-editor > .container #tabsContainer .email-content .deliveries-modal .form-check label {
        padding-left: 0; }
        #maximal-editor > .container #tabsContainer .email-content .deliveries-modal .form-check label:before {
          display: none; }
    #maximal-editor > .container #tabsContainer .fromDisplayContainer {
      margin-top: 32px; }
      #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer {
        display: flex;
        margin-bottom: 17px;
        position: relative; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer {
            display: inherit; }
            #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer .md-form {
              margin-top: 17px; } }
        #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer .md-form {
          width: 100%; }
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer .md-form input {
            padding-top: 0;
            margin: 0; }
        #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError {
          margin-bottom: 35px; }
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError input {
            border-bottom: 1px solid var(--danger);
            box-shadow: none; }
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError .error-message {
            color: var(--danger);
            position: absolute;
            left: 140px;
            top: 35px; }
            @media (max-width: 576px) {
              #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError .error-message {
                top: 79px;
                left: 0; } }
      #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayLabel {
        min-width: 140px;
        max-width: 140px;
        display: inline-block;
        font-weight: 600; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayLabel {
            width: 100%;
            max-width: 100%;
            margin-top: 10px; } }
  #maximal-editor > .container .deliverySettings {
    margin-top: 30px; }
    #maximal-editor > .container .deliverySettings .deliveryTitle {
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      color: #000000; }
    #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 26px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 25px; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard > div:first-child {
        font-size: 34px; }
        #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard > div:first-child i {
          color: var(--primary);
          margin-bottom: 7px; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard span {
        margin-left: 27px; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard input.select-dropdown {
        position: static;
        position: initial; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard .promocodeSelect {
        width: 100%;
        padding-left: 20px; }
        #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard .promocodeSelect input {
          font-weight: 600; }
    #maximal-editor > .container .deliverySettings .deliveryContainer #inactiveCampaignDisclaimer {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      display: flex;
      background-color: #FFF3CD;
      padding: 12px;
      border-radius: 5px;
      margin: 30px 0;
      display: flex;
      align-items: center; }
      #maximal-editor > .container .deliverySettings .deliveryContainer #inactiveCampaignDisclaimer i {
        font-size: 25px;
        margin-right: 13px; }
    #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 26px; }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer {
          padding-left: 18px; }
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .rc-time-picker {
            margin-left: 0px; } }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError {
          position: relative;
          margin-bottom: 10px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .DayPickerInput input, #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .rc-time-picker input {
        border-bottom: 2px solid var(--danger); }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .error-message {
        font-weight: 400;
        color: var(--danger);
        margin-left: 13px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .error-message {
            position: absolute;
            left: 0;
            bottom: -22px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyContainer {
        margin-top: 20px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .error-message {
            width: 100%;
            right: -8px; } }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyContainer {
            margin-bottom: 17px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyInput {
        margin: 0px; }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards {
          padding-right: 15px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .fa.fa-angle-down.fa-lg {
        display: none; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .fa.fa-angle-up.fa-lg {
        display: none; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: white; }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer > div {
          padding: 1rem; }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer .form-check {
          margin: 0 !important;
          display: flex;
          align-items: center;
          width: 44px;
          padding-right: 46px;
          padding-left: 20px !important;
          padding-top: 20px; }
          @media (max-width: 576px) {
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer .form-check {
              padding-right: 35px; } }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .DayPickerInput {
          width: 76%; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .DayPickerInput > input {
        border: none;
        padding: 7px 8px;
        border-bottom: 2px solid var(--primary);
        width: 100%; }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .mobileFromToCron .rc-time-picker {
          width: 76%;
          margin-left: 27px; } }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .rc-time-picker {
          width: 76%;
          margin-left: 16px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .rc-time-picker input {
        border: none;
        border-bottom: 2px solid var(--primary);
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        border-radius: 0;
        color: black;
        padding: 7px; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .bbCollapsable #collapse {
        padding: 5px 54px 16px 54px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .bbCollapsable #collapse {
            padding: 5px 16px; } }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .bbCollapsable #collapse > div {
          padding: 0 !important; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer {
        display: flex; }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer {
          display: flex; }
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer .md-form {
            width: 68px;
            margin: 0;
            margin-top: -13px;
            padding: 0 9px; }
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError {
            padding-bottom: 18px;
            position: relative; }
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .usersNotReelegibleDaysQttyInput {
              border-bottom: 1px solid var(--danger); }
              #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .usersNotReelegibleDaysQttyInput:focus {
                box-shadow: none; }
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .error-message {
              right: -60px;
              position: absolute;
              top: 31px;
              color: var(--danger);
              font-weight: 400; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer {
        display: block;
        margin-bottom: 20px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError {
            position: relative;
            margin-bottom: 27px; } }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .DayPickerInput input, #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .rc-time-picker input {
          border-bottom: 2px solid var(--danger); }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .error-message {
          font-weight: 400;
          color: var(--danger);
          margin-left: 13px; }
          @media (max-width: 576px) {
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .error-message {
              position: absolute;
              left: 0;
              bottom: -22px; } }
  #maximal-editor > .container .deletionModal > .modal-content {
    min-height: inherit; }
  #maximal-editor > .container .deletionModal .modal-body p:first-child {
    font-size: 20px;
    line-height: 28px;
    color: #ED5565;
    font-weight: 600; }
  #maximal-editor > .container .deletionModal .modal-body p:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.541327); }
  #maximal-editor > .container .deletionModal .modal-body > div {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    #maximal-editor > .container .deletionModal .modal-body > div button {
      border-radius: 3px;
      box-shadow: none;
      text-transform: none;
      text-transform: initial;
      font-size: 14px;
      line-height: 19px;
      text-align: center; }
      #maximal-editor > .container .deletionModal .modal-body > div button.link-styled {
        font-size: 14px;
        line-height: 19px;
        color: #19AA89;
        font-weight: 600;
        margin-right: 10px;
        margin-left: 14px;
        text-decoration: none; }
    #maximal-editor > .container .deletionModal .modal-body > div a {
      font-size: 14px;
      line-height: 19px;
      color: #19AA89;
      font-weight: 600;
      margin-right: 10px;
      margin-left: 14px; }

.LOWCard {
  background: #ED5564; }

.MEDIUMCard {
  background: var(--warning); }

.HIGHCard {
  background: #20BFC2; }

.noDeliveryCard {
  background: linear-gradient(246.62deg, #63D5FF -58.04%, rgba(176, 102, 254, 0.630208) 269.73%, rgba(196, 196, 196, 0) 460.36%); }

#dateApplyBtn {
  margin: 0;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: none;
  text-transform: initial; }
  @media (max-width: 991px) {
    #dateApplyBtn {
      position: fixed;
      bottom: 53px; } }

#analyticsEmptyStateContainer {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  #analyticsEmptyStateContainer svg {
    width: 317px;
    height: auto; }
    @media (max-width: 991px) {
      #analyticsEmptyStateContainer svg {
        width: 250px; } }
  #analyticsEmptyStateContainer span {
    font-size: 30px;
    margin-top: 30px;
    font-weight: 100; }

#analyticsContainer {
  padding: 0 60px;
  padding-bottom: 100px; }
  #analyticsContainer .clientsAnalyticsModal {
    max-width: 1250px; }
    #analyticsContainer .clientsAnalyticsModal .modal-content {
      max-height: 100vh; }
    #analyticsContainer .clientsAnalyticsModal i {
      font-size: 16px;
      cursor: pointer; }
    #analyticsContainer .clientsAnalyticsModal #close-modal {
      margin-top: 20px;
      margin-left: 20px; }
  #analyticsContainer .minimalEditorModal .modal-body {
    padding: 0; }
    #analyticsContainer .minimalEditorModal .modal-body #messageNotCustomModal .modal-body {
      padding: 1rem; }
  #analyticsContainer .deletionModal > .modal-content {
    min-height: inherit; }
  #analyticsContainer .deletionModal .modal-body p:first-child {
    font-size: 20px;
    line-height: 28px;
    color: #ED5565;
    font-weight: 600; }
  #analyticsContainer .deletionModal .modal-body p:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.541327); }
  #analyticsContainer .deletionModal .modal-body > div {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    #analyticsContainer .deletionModal .modal-body > div button {
      border-radius: 3px;
      box-shadow: none;
      text-transform: none;
      text-transform: initial;
      font-size: 14px;
      line-height: 19px;
      text-align: center; }
    #analyticsContainer .deletionModal .modal-body > div button.link-styled {
      font-size: 14px;
      line-height: 19px;
      color: #19AA89;
      font-weight: 600;
      margin-right: 10px;
      margin-left: 14px;
      background: none;
      border: none;
      cursor: pointer; }
  #analyticsContainer .modal #closeModalIcon {
    padding: 22px 0 15px 20px;
    font-size: 18px;
    width: 100%; }
  #analyticsContainer .modal #timePeriodModalTitle {
    padding-left: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000; }
  #analyticsContainer .modal #botbit-timeperiod-selector {
    margin-top: 15px; }
  #analyticsContainer .modal #expandedWithCustomSelector > div:nth-child(2) {
    height: calc(57vh - 7px);
    overflow-y: auto; }
  #analyticsContainer .webPreviewerModal .modal-body {
    overflow-y: hidden; }
  @media (max-width: 991px) {
    #analyticsContainer {
      padding: 0;
      padding-bottom: 50px; } }
  #analyticsContainer .analyticsHeader #deployDateWarning {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    background-color: #FFF3CD;
    padding: 12px;
    border-radius: 5px;
    margin: 30px 0; }
    #analyticsContainer .analyticsHeader #deployDateWarning > div:first-child {
      display: flex;
      align-items: center;
      padding-right: 10px; }
      #analyticsContainer .analyticsHeader #deployDateWarning > div:first-child i {
        font-size: 26px; }
    #analyticsContainer .analyticsHeader #deployDateWarning > div:nth-child(2) span {
      font-size: 14px;
      font-weight: 400; }
      #analyticsContainer .analyticsHeader #deployDateWarning > div:nth-child(2) span a {
        color: dodgerblue; }
  #analyticsContainer .analyticsHeader .dropdown > button.dropdown-toggle {
    box-shadow: none;
    color: grey;
    background-color: inherit !important;
    padding: 0; }
  #analyticsContainer .analyticsHeader #nameBadgeContainer {
    margin-top: 14px; }
    #analyticsContainer .analyticsHeader #nameBadgeContainer .mobileSwitch {
      float: right;
      margin-top: 6px;
      margin-right: -16px; }
    #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer {
      width: 335px;
      display: flex;
      justify-content: flex-end; }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer .dropdown-menu {
        padding: 0;
        min-width: 303px; }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer > button {
        box-shadow: none !important;
        text-transform: inherit;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        border-radius: 4px;
        padding: 0 !important;
        width: 204px;
        height: 35px;
        border: 1px solid #1ab394 !important; }
      @media (max-height: 728px) {
        #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer #expandedWithCustomSelector > div:nth-child(2) {
          height: 227px;
          overflow-y: auto; } }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer #botbit-timeperiod-selector {
        padding-bottom: 8px; }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer.show > button.dropdown-toggle {
        background-color: #EBF5F3 !important;
        color: #1ab394 !important;
        border-color: #EBF5F3 !important;
        box-shadow: none !important; }
  #analyticsContainer .analyticsHeader .badge {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 4px 11px;
    display: block; }
  #analyticsContainer .analyticsHeader .greenText {
    line-height: 16px;
    color: #19AA89; }
  #analyticsContainer .analyticsHeader .delete {
    font-weight: 500;
    color: red; }
  #analyticsContainer .analyticsHeader .headerActions {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;
    color: #19AA89;
    font-weight: 600; }
    #analyticsContainer .analyticsHeader .headerActions span {
      cursor: pointer; }
  #analyticsContainer .analyticsHeader #backIcon {
    color: black;
    cursor: pointer;
    font-size: 18px;
    padding-top: 4px; }
  #analyticsContainer .analyticsHeader #campaignName {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000; }
  #analyticsContainer .analyticsHeader #timePeriodLabel {
    text-transform: none;
    text-transform: initial;
    font-size: 14px;
    color: #19AA89;
    line-height: 19px;
    font-weight: 600; }
  #analyticsContainer .analyticsHeader i.fa.fa-cog {
    font-size: 16px;
    margin-left: 16px; }
  #analyticsContainer .campaignSummary {
    margin-top: 20px; }
    #analyticsContainer .campaignSummary.isSmart {
      margin-top: 40px; }
    @media (max-width: 991px) {
      #analyticsContainer .campaignSummary {
        margin-top: 35px; }
        #analyticsContainer .campaignSummary.isSmart {
          margin-top: 50px; } }
    #analyticsContainer .campaignSummary #bodyCards {
      display: flex;
      margin-top: 1.5rem;
      min-height: 337px; }
      @media (max-width: 991px) {
        #analyticsContainer .campaignSummary #bodyCards {
          height: auto; } }
      #analyticsContainer .campaignSummary #bodyCards #effectivityCard {
        display: inline-block;
        width: 23%;
        border-radius: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 46px 22px 0px 22px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard {
            width: 49%;
            padding: 35px 14px 16px 14px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px; }
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard div:first-child {
              margin-bottom: 21px; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #effectivity {
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          display: block; }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #noDeliveryEffectivity {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px; }
          @media screen {
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard #noDeliveryEffectivity {
              font-weight: 600; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #effectivityDesc {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 25px;
          display: block;
          line-height: 28px;
          position: relative; }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #effectivityDesc > i {
            font-size: 21px;
            height: 100%;
            top: 4px;
            right: 0;
            position: absolute; }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi {
          padding-top: 27px; }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > span {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            width: 80%; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > span {
                width: 100%;
                margin: 0;
                font-weight: 600; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-exclamation-circle {
            right: 0px;
            font-size: 18px;
            top: 30px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-exclamation-circle {
                top: 5px;
                left: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-money-bill-alt {
            right: -5px;
            font-size: 18px;
            top: 30px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-money-bill-alt {
                top: 5px;
                left: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-check-circle {
            right: -6px;
            top: 42px;
            font-size: 13px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-check-circle {
                top: 16px;
                left: 18px; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          padding-top: 17px; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough {
              padding-top: 35px;
              font-weight: 600; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.d-block {
            width: 80%; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.d-block {
                width: 100%;
                margin-right: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.position-absolute {
            right: -4px;
            top: 20px;
            font-size: 18px;
            font-weight: 700; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.position-absolute {
                left: 0;
                top: 15px; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate {
          padding-top: 17px;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate {
              padding-top: 37px;
              font-weight: 600; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > span.d-block {
            width: 80%; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > span.d-block {
                width: 100%;
                margin-right: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-exclamation-circle {
            right: 1px;
            font-size: 18px;
            top: 20px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-exclamation-circle {
                left: 0;
                top: 13px; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-envelope {
            right: 1px;
            font-size: 18px;
            top: 20px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-envelope {
                left: 0;
                top: 13px; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-check-circle {
            right: -6px;
            top: 33px;
            font-size: 13px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-check-circle {
                left: 11px;
                top: 24px; } }
      #analyticsContainer .campaignSummary #bodyCards #firstCard {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: white;
        display: inline-block;
        color: black;
        margin-left: 2%;
        border-radius: 4px 0px 0px 4px;
        width: 39%;
        height: auto;
        border-right: 1px solid #EBEBEB;
        padding: 30px 3%; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #bodyCards #firstCard {
            width: 49%;
            padding: 0;
            border-radius: 7px; } }
        #analyticsContainer .campaignSummary #bodyCards #firstCard .campaignMainImage {
          width: 100%;
          border-radius: 4px;
          height: 114px;
          background-size: cover;
          background-position: center; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #firstCard .campaignMainImage {
              height: 96px;
              border-radius: inherit;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0; } }
        #analyticsContainer .campaignSummary #bodyCards #firstCard #campaignMessage {
          width: 100%;
          word-break: break-word;
          contain: content;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          font-weight: 600; }
        #analyticsContainer .campaignSummary #bodyCards #firstCard strong {
          color: #19AA89;
          font-weight: 600; }
        #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts {
          padding: 15px 12px; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts {
              height: calc( 100% - 100px);
              display: flex;
              flex-direction: column;
              justify-content: space-between; } }
          #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts > span:not(#campaignMessage) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin-top: 19px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts > span:not(#campaignMessage) {
                font-size: 14px;
                line-height: 19px;
                margin-top: 9px; } }
    #analyticsContainer .campaignSummary #secondCard {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: white;
      display: inline-block;
      color: black;
      width: 36%;
      border-radius: 0px 4px 4px 0;
      padding: 5% 5% 3% 5%;
      position: relative; }
      @media (max-width: 991px) {
        #analyticsContainer .campaignSummary #secondCard {
          width: 100%;
          margin-top: 11px;
          border-radius: 4px;
          padding: 17px 28px; } }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError {
        font-size: 22px; }
        #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError > i {
          color: red;
          font-size: 40px;
          margin-bottom: 17px; }
        #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError a {
          color: dodgerblue; }
        #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError span.link-styled {
          color: #19AA89;
          font-weight: 600;
          margin-right: 10px;
          text-decoration: none;
          background: none;
          border: none;
          cursor: pointer; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity .preloader-wrapper.active {
        width: 25px;
        height: 25px;
        margin-right: 15px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity > i {
        font-size: 13px;
        color: dodgerblue;
        margin-left: 8px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #clientsNumber {
        font-size: 20px;
        font-weight: 600; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity .couldRecieveCampaign {
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        font-size: 17px;
        margin-bottom: 16px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity strong {
        font-size: 30px;
        font-weight: 600; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts span#clientsQuantity + span {
        font-size: 17px;
        margin-bottom: 16px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .campaignSegmentContainer {
        font-size: 13px !important;
        line-height: 16px !important;
        color: #000000 !important;
        font-weight: 400 !important; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .campaignSegmentContainer {
            font-size: 14px;
            line-height: 19px; } }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .filter-text {
        font-size: 12px !important; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .filter-text {
            font-size: 14px !important; } }
  #analyticsContainer #title {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000; }
    @media (max-width: 991px) {
      #analyticsContainer #title {
        font-size: 20px; } }
  #analyticsContainer #campaignStatus #emptyTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    justify-content: center; }
  #analyticsContainer #campaignStatus #chartContainer {
    margin-top: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    #analyticsContainer #campaignStatus #chartContainer table {
      border-bottom: none; }
  #analyticsContainer #campaignStatus .statusDesc {
    background: white;
    height: 200px;
    width: 99.8%;
    margin-left: 0.2%;
    display: flex; }
    #analyticsContainer #campaignStatus .statusDesc button.seeClientsBtn {
      text-transform: inherit;
      background-color: white !important;
      border-radius: 5px;
      padding: 7px 16px;
      position: absolute;
      bottom: 13px;
      font-weight: 500;
      font-weight: 600;
      font-size: 14px;
      color: #676A6C;
      border: 1px solid #676A6C;
      box-shadow: none; }
      @media (max-width: 991px) {
        #analyticsContainer #campaignStatus .statusDesc button.seeClientsBtn {
          bottom: 0;
          font-size: 0.7em; } }
    @media (max-width: 991px) {
      #analyticsContainer #campaignStatus .statusDesc {
        height: 148px;
        border-radius: 5px; } }
    #analyticsContainer #campaignStatus .statusDesc > div {
      width: calc(100%/3);
      height: 100%;
      display: inline-block;
      border-right: 1px solid #EBEBEB;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991px) {
        #analyticsContainer #campaignStatus .statusDesc > div {
          width: 50%;
          border-right: 1px solid #D5D5D5; } }
      #analyticsContainer #campaignStatus .statusDesc > div.noWalkthrough {
        width: 50%; }
        @media (max-width: 991px) {
          #analyticsContainer #campaignStatus .statusDesc > div.noWalkthrough {
            width: 50%; } }
      #analyticsContainer #campaignStatus .statusDesc > div > span {
        display: block; }
      #analyticsContainer #campaignStatus .statusDesc > div > span:first-child {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #676A6C; }
      @media (max-width: 992px) {
        #analyticsContainer #campaignStatus .statusDesc > div strong {
          font-size: 16px !important; } }
      #analyticsContainer #campaignStatus .statusDesc > div strong {
        font-size: 30px;
        line-height: 36px;
        color: #000000;
        font-weight: 600; }
      #analyticsContainer #campaignStatus .statusDesc > div > span:nth-child(2) {
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        font-weight: 500; }
      #analyticsContainer #campaignStatus .statusDesc > div > span:nth-child(3) {
        font-size: 14px;
        line-height: 18px;
        color: #676A6C;
        font-weight: 600; }
    #analyticsContainer #campaignStatus .statusDesc #returned {
      border-right: none;
      position: relative; }
    #analyticsContainer #campaignStatus .statusDesc #recieved {
      position: relative; }
    #analyticsContainer #campaignStatus .statusDesc #opened {
      position: relative; }
    #analyticsContainer #campaignStatus .statusDesc #clicked {
      position: relative; }
    @media (max-width: 991px) {
      #analyticsContainer #campaignStatus .statusDesc #returned, #analyticsContainer #campaignStatus .statusDesc #opened, #analyticsContainer #campaignStatus .statusDesc #clicked, #analyticsContainer #campaignStatus .statusDesc #recieved {
        display: flex;
        justify-content: flex-end; }
      #analyticsContainer #campaignStatus .statusDesc #returned span:nth-child(1), #analyticsContainer #campaignStatus .statusDesc #opened span:nth-child(1), #analyticsContainer #campaignStatus .statusDesc #clicked span:nth-child(1), #analyticsContainer #campaignStatus .statusDesc #recieved span:nth-child(1) {
        margin-top: 25px; }
      #analyticsContainer #campaignStatus .statusDesc #returned span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #opened span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #clicked span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #recieved span:nth-child(3) {
        text-align: center; } }
    @media (min-width: 992px) {
      #analyticsContainer #campaignStatus .statusDesc #returned span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #opened span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #clicked span:nth-child(2) {
        margin-bottom: 18px; } }
  #analyticsContainer #campaignStatus #statusDescSub {
    height: 81px;
    background: #F6F7F9;
    display: flex;
    border-radius: 0 0 6px 6px; }
    #analyticsContainer #campaignStatus #statusDescSub .badRate {
      background: #FDECF0; }
    #analyticsContainer #campaignStatus #statusDescSub > div {
      padding: 0 30px;
      width: calc(100%/3);
      height: 100%;
      border-right: 1px solid #EBEBEB;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      font-weight: 400; }
    #analyticsContainer #campaignStatus #statusDescSub i {
      position: absolute;
      left: -17px;
      top: 1px; }

#backIcon.closeModalIcon {
  padding: 22px 0 15px 20px;
  font-size: 18px;
  width: 100%;
  color: black;
  cursor: pointer;
  font-size: 18px;
  padding-top: 4px; }

.notFirstCard {
  margin-left: 25px; }

.bestDaysCardContainer {
  margin-top: 21px;
  display: flex;
  justify-content: space-evenly; }

.bestDay {
  border: 2px solid #E9AA43; }

.daysCard {
  text-align: center;
  height: 394px;
  width: 31%;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-top: 35px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  position: relative; }
  @media (max-width: 992px) {
    .daysCard {
      width: 95%;
      height: 394px;
      margin: 7px 0px; } }
  .daysCard span {
    display: block; }
  .daysCard .timeDetail {
    margin-top: 25px; }
    .daysCard .timeDetail .restOfDaySpan {
      bottom: 18px;
      position: absolute;
      left: 0;
      right: 0; }
      .daysCard .timeDetail .restOfDaySpan strong {
        color: #CCCCCC; }
  .daysCard .chartInfo {
    display: flex;
    justify-content: center;
    margin-top: 31px;
    position: relative; }
    .daysCard .chartInfo > div:nth-child(2) {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .daysCard .chartInfo > div:nth-child(2) > span:first-child {
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        font-weight: 600; }
      .daysCard .chartInfo > div:nth-child(2) > span:nth-child(2) {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #000000; }
  .daysCard .dateInfo {
    min-height: 60px; }
    .daysCard .dateInfo > :first-child {
      font-size: 30px;
      line-height: 41px;
      color: #000000;
      font-weight: 600; }
    .daysCard .dateInfo > :nth-child(2) {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #676A6C; }
      .daysCard .dateInfo > :nth-child(2) > i {
        color: #E9AA43;
        font-size: 14px;
        line-height: 16px;
        margin-right: 6px; }

.clientsAnalyticsCollVisContainer .mobile-column {
  display: flex;
  align-items: center; }
  .clientsAnalyticsCollVisContainer .mobile-column .mobile-image-container {
    width: 10%;
    display: inline-block;
    margin-right: 16px; }
  .clientsAnalyticsCollVisContainer .mobile-column .mobile-info-container {
    display: inline-block;
    width: 80%; }
    .clientsAnalyticsCollVisContainer .mobile-column .mobile-info-container span {
      display: block; }

.clientsAnalyticsCollVisContainer .seeActivityBtn {
  padding: 5px 13px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-transform: inherit;
  font-weight: 600; }

.clientsAnalyticsCollVisContainer .avatarField {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-size: cover; }

.clientsAnalyticsCollVisContainer .botbit-table .botbit-table-card-header .table-title > div {
  font-size: 12px;
  font-weight: normal; }

.clientsAnalyticsCollVisContainer .collection-visualizer-header {
  margin-bottom: 34px; }
  .clientsAnalyticsCollVisContainer .collection-visualizer-header.mobile {
    margin: 0; }
  .clientsAnalyticsCollVisContainer .collection-visualizer-header .collection-visualizer-title-container {
    width: 100%;
    text-align: center; }
    .clientsAnalyticsCollVisContainer .collection-visualizer-header .collection-visualizer-title-container .collection-visualizer-title {
      font-weight: 300;
      font-size: 25px;
      margin-right: 0; }
      @media (max-width: 576px) {
        .clientsAnalyticsCollVisContainer .collection-visualizer-header .collection-visualizer-title-container .collection-visualizer-title {
          font-size: 18px; } }
  .clientsAnalyticsCollVisContainer .collection-visualizer-header .botbit-action-button {
    padding: 7px 13px; }

.clientsAnalyticsCollVisContainer .header-actions-container.mobile {
  margin: 0; }

.clientsAnalyticsCollVisContainer .botbit-table-card {
  font-weight: 600;
  color: black;
  font-size: 12px;
  box-shadow: none;
  background: inherit; }
  .clientsAnalyticsCollVisContainer .botbit-table-card > .card-header:hover::after, .clientsAnalyticsCollVisContainer .botbit-table-card .botbit-table-expanded-row::after {
    display: none; }
  .clientsAnalyticsCollVisContainer .botbit-table-card:hover {
    background-color: #E9F6F3; }

@media (max-width: 576px) {
  #segmentsView {
    padding: 0 !important; } }

.orders-iframe {
  transition: width 0.4s;
  width: calc( 100vw - 220px);
  height: calc( 100vh - 102px);
  padding: 0;
  margin: 0;
  margin-left: -15px; }
  @media (max-width: 991px) {
    .orders-iframe {
      width: 100vw;
      height: calc( 100vh - 101px); } }

@media (max-width: 991px) {
  .mini-navbar .orders-iframe {
    width: calc(100vw - 69px); } }

@media (max-width: 991px) {
  .configurations-container {
    padding: 0; } }

.configurations-container * {
  color: black; }

.configurations-container .parent > h3 {
  margin-bottom: 10px; }

.configurations-container .childs p {
  cursor: pointer;
  margin-bottom: 4px;
  background: #fff;
  border-radius: .25rem;
  padding: .5rem 0;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px; }
  .configurations-container .childs p:hover {
    background: #FBFBFB; }
  .configurations-container .childs p img {
    width: 32px;
    margin-right: 13px; }

.value{
    font-weight: 500
}
.alice-carousel__dots {
  margin-top: 5px; }

.reviews-summary-card-3-sources {
  height: 210px; }

.reviews-summary-card-2-sources {
  height: 170px; }

.chart-summary-row {
  height: 130px; }

.super-admin-card {
  height: 285px; }

.dashboard-loading {
  height: 252px; }

.detractors-loading {
  height: 265px; }

.reviews-loading {
  height: 220px; }

.summary-legend {
  font-size: 10px;
  color: #6d6d6d;
  padding-top: 10px; }

.minor-paddings {
  padding: 0 0 0 0.5em; }

.reviews-summary-card h1, .reviews-summary-card h2 {
  font-weight: 400;
  margin-top: 0; }

.reviews-summary-card h1 {
  font-size: 40px; }

.summary-tooltip {
  padding: 5px 5px 5px 5px;
  font-family: 'Arial';
  white-space: nowrap; }

.summary-tooltip-big {
  font-size: 12px;
  font-weight: bold; }

.summary-tooltip-small {
  font-size: 9px; }

.auto-reply-color {
  color: red; }

.manual-reply-color {
  color: blue; }

h4.subtitle {
  font-size: 1.2em; }

.hide-actions + ul {
  display: none; }

footer.page-footer {
  height: 9vh; }

#mainNavbarCollapse .dropdown-menu .dropdown-item:hover a {
  color: white; }

main {
  overflow-y: auto;
  margin-top: 10vh !important;
  min-height: 81vh; }

.metrics-container .alice-carousel__wrapper {
  height: 260px; }
  .metrics-container .alice-carousel__wrapper .alice-carousel__stage {
    height: 100%; }
    .metrics-container .alice-carousel__wrapper .alice-carousel__stage .alice-carousel__stage-item .card {
      height: 93%; }

.align-center {
  text-align: center; }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
    width: 600px; } }

.reply-warning {
  margin-top: -20px;
  font-size: 0.9em;
  color: #969696; }

.biggerFont {
  font-size: 140%; }

.botbit-review-reply {
  border-left: 3px solid #e5e5e5;
  padding: 5px 0 0 12px;
  line-height: 18px; }

.botbit-generated-review, .botbit-review-testimonial {
  background: #F5F9FA; }

.botbit-generated-review > .card-header:hover::after, .botbit-generated-review .botbit-table-expanded-row::after {
  background: #33A0B4; }

.cursor-pointer, .botbit-link {
  cursor: pointer; }

.botbit-link {
  color: #1c84c6; }

.row-title {
  font-weight: bold;
  font-size: 1.1em;
  padding-bottom: 0.25em; }

.is-invalid-important {
  border-color: red !important; }

.available-tags-title {
  font-size: 1rem;
  font-weight: bold; }

.margins-reduced > .md-form {
  margin-top: 0.25em;
  margin-bottom: 0.75em; }

.margins-reduced > .md-form.md-outline {
  margin-top: 0.25;
  margin-bottom: 0.75em; }

.md-outline input, .md-outline > textarea {
  font-size: 11pt !important; }
  .md-outline input + label, .md-outline > textarea + label {
    font-size: 11pt !important; }
  .md-outline input:focus, .md-outline > textarea:focus {
    border-color: #1ab394 !important;
    box-shadow: inset 0px 0px 0px 1px #1ab394 !important; }
    .md-outline input:focus + label, .md-outline > textarea:focus + label {
      color: #1ab394 !important; }

.answer-stat {
  font-style: italic;
  font-size: 11px; }

.review-text {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  font-weight: 300 !important; }
  .review-text .btn-floating {
    border-radius: 50%;
    box-shadow: none; }
  .review-text strong {
    font-weight: bold; }
  .review-text .btn-agregar-template {
    color: #676a6c !important; }
  .review-text label {
    font-weight: 300; }

.review-info {
  font-size: 0.9rem; }
  .review-info strong {
    font-weight: bold; }

.reply-template.no-animation {
  transition: none !important; }

.col-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.column-container {
  display: flex !important;
  align-items: center; }

.google-header-icon {
  height: 1.4em !important; }

.google-header-button {
  background-color: #4285f4 !important; }

.container {
  margin-top: 30px; }

.socialaccounts-active-badge {
  margin-left: 10px; }

.no-results-container {
  margin-top: 2rem; }

@media only screen and (max-width: 499px) {
  .socialaccounts-active-badge {
    margin-left: 0 !important;
    margin-bottom: 10px; } }

#settings .margins-reduced .md-form {
  margin-top: 0.25em;
  margin-bottom: 0.75em; }

#settings .margins-reduced .md-form.md-outline {
  margin-top: 0.25;
  margin-bottom: 0.75em; }

#settings .is-invalid-important {
  border-color: red !important; }
  #settings .is-invalid-important input {
    border-color: red !important; }

#settings .md-outline input, #settings .md-outline > textarea {
  font-size: 11pt !important; }
  #settings .md-outline input + label, #settings .md-outline > textarea + label {
    font-size: 11pt !important; }
  #settings .md-outline input:focus, #settings .md-outline > textarea:focus {
    border-color: #1ab394 !important;
    box-shadow: inset 0px 0px 0px 1px #1ab394 !important; }
    #settings .md-outline input:focus + label, #settings .md-outline > textarea:focus + label {
      color: #1ab394 !important; }

#settings .btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429; }

#settings .dropdown-menu {
  left: -50px !important; }

.nps-cards .color-number {
  font-size: 65px; }

.nps-cards .text-primary {
  color: #1ab394 !important; }

.nps-container .card-header {
  cursor: inherit !important; }

.dashboard-container {
  padding-bottom: 50px;
  margin-top: 1.5rem; }
  @media (max-width: 500px) {
    .dashboard-container {
      margin-top: 0.5rem; } }
  .dashboard-container section {
    margin-top: 1.5rem; }
  .dashboard-container .filters .filters-info {
    margin-bottom: 20px; }
  .dashboard-container .filters .filters-first-line {
    margin-bottom: 10px; }
    .dashboard-container .filters .filters-first-line.mobile {
      margin-bottom: 0; }
  .dashboard-container .dashboard-masonry {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 0; }
    .dashboard-container .dashboard-masonry h3.dashboard-title {
      color: black;
      border-bottom: 1px solid;
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-weight: 500; }
    .dashboard-container .dashboard-masonry h3.dashboard-title-bottom-off {
      color: black;
      border-bottom: 1px solid;
      font-weight: 500;
      margin-bottom: 0px;
      padding-bottom: 10px; }
      @media (min-width: 992px) {
        .dashboard-container .dashboard-masonry h3.dashboard-title-bottom-off {
          width: 98%;
          margin-left: 2%; } }
  .dashboard-container .row-btn {
    background-color: var(--primary);
    padding: 0.15rem;
    font-size: 0.6rem;
    color: #fff;
    font-weight: bold; }
    .dashboard-container .row-btn-mobile {
      font-size: 0.6rem; }
  .dashboard-container .dashboard-element-container {
    height: inherit;
    margin-bottom: 20px; }
    .dashboard-container .dashboard-element-container.h-33 {
      height: 33.333%; }
    .dashboard-container .dashboard-element-container.h-50 {
      height: 50%; }
      @media (max-width: 758px) {
        .dashboard-container .dashboard-element-container.h-50 {
          margin-bottom: 10px; } }
    .dashboard-container .dashboard-element-container .data-card-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      margin-bottom: 0; }
      .dashboard-container .dashboard-element-container .data-card-container.align-center {
        justify-content: center; }
      .dashboard-container .dashboard-element-container .data-card-container.align-end {
        justify-content: flex-end; }
    .dashboard-container .dashboard-element-container .data-card {
      min-height: 220px !important;
      margin-bottom: 10px !important; }
  .dashboard-container .empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .dashboard-container .empty-state h1 {
      font-size: 1.5em; }
  .dashboard-container .link-to-old-dashboard {
    margin-bottom: 0; }
    .dashboard-container .link-to-old-dashboard h2 {
      font-size: 1.2em;
      margin: 0; }
      @media (max-width: 758px) {
        .dashboard-container .link-to-old-dashboard h2 {
          font-size: 1em; } }
  .dashboard-container .graph-card {
    padding: 10px;
    margin: 0 0.3rem; }
    .dashboard-container .graph-card .card-footer {
      font-size: 11px;
      border: none;
      color: black;
      font-weight: 500;
      min-height: 45px;
      padding: .5rem;
      margin-top: 5px; }
      .dashboard-container .graph-card .card-footer.green {
        background-color: #D3F0D7 !important; }
      .dashboard-container .graph-card .card-footer.grey {
        background-color: #F3F3F3 !important; }
      @media (max-width: 991px) {
        .dashboard-container .graph-card .card-footer {
          min-height: 8vh; } }
      .dashboard-container .graph-card .card-footer a {
        color: #337ab7;
        cursor: pointer;
        border-bottom: 1px solid; }
      .dashboard-container .graph-card .card-footer h3 {
        font-size: 13px; }
  .dashboard-container .filters-title {
    text-align: left; }
  @media (min-width: 992px) {
    .dashboard-container .filters {
      padding: 0 15px; } }
  @media (max-width: 330px) {
    .dashboard-container .filters .dropdown-menu {
      max-width: 290px; } }
  @media (max-width: 991px) {
    .dashboard-container .col-12 {
      padding: 0 !important; } }
  .dashboard-container .data-card h4 {
    min-height: 35px; }
  .dashboard-container .dashboard-reviews-widget {
    width: 100%; }
  .dashboard-container .link-like-btn {
    color: #337ab7 !important;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out; }
    .dashboard-container .link-like-btn:hover {
      color: #0056b3; }
  .dashboard-container .hidden-card {
    background: none;
    box-shadow: none; }
  .dashboard-container .reviews-graph_header .col-md-5, .dashboard-container .reviews-graph_header .col-md-7, .dashboard-container .reviews-graph_header .col-md-4, .dashboard-container .reviews-graph_header .col-md-8 {
    padding: 0; }
  .dashboard-container .reviews-graph_header .filters {
    padding-left: 0; }
    @media (max-width: 768px) {
      .dashboard-container .reviews-graph_header .filters {
        padding-left: 1rem; } }
    .dashboard-container .reviews-graph_header .filters-actions .btn {
      font-size: 0.8rem !important; }
  .dashboard-container .reviews-graph_header .graph-pagination {
    padding-left: 15px; }
    .dashboard-container .reviews-graph_header .graph-pagination .page-item {
      font-size: 0.7rem; }

.data-card {
  background: white;
  width: 100%;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 180px;
  border-radius: 0.25rem; }
  .data-card.loading {
    justify-content: center; }
  .data-card .card-body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .data-card .card-body-container p {
      margin: 0; }
    .data-card .card-body-container .data-card-body .data-alert {
      padding-top: 5rem; }
    .data-card .card-body-container .data-card-body h2 {
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 25px; }
      .data-card .card-body-container .data-card-body h2.GOOD {
        color: #3F7B25; }
      .data-card .card-body-container .data-card-body h2.MEDIUM {
        color: #F4CE59; }
      .data-card .card-body-container .data-card-body h2.BAD {
        color: #FF0000; }
    .data-card .card-body-container .data-card-body .small-icon {
      font-size: 12px; }
    .data-card .card-body-container .data-card-body .star-ratings {
      margin-bottom: .5em; }
    .data-card .card-body-container .data-card-body h3 {
      font-weight: bold;
      font-size: 13px; }
  .data-card h2 {
    font-size: 21px; }
  .data-card h4 {
    margin-top: 0; }
  .data-card .small-title {
    min-height: unset !important; }
  @media (max-width: 991px) {
    .data-card {
      width: 100%; } }
  .data-card i {
    font-size: 22px;
    display: inline-table;
    padding: 5px 7px;
    border-radius: 40px; }
    .data-card i.arrow-up {
      color: var(--primary);
      border: 3px solid var(--primary); }
    .data-card i.arrow-down {
      color: red;
      border: 3px solid red; }
    .data-card i.minus {
      color: gray;
      border: 3px solid gray; }
  .data-card .card-footer {
    font-size: 11px;
    border: none;
    color: black;
    font-weight: 500;
    padding: .4rem;
    margin-top: 10px; }
    .data-card .card-footer.green {
      background-color: #D3F0D7 !important; }
    .data-card .card-footer.grey {
      background-color: #F3F3F3 !important; }
    .data-card .card-footer.compact {
      margin: 0; }
    .data-card .card-footer a {
      color: #337ab7;
      cursor: pointer;
      border-bottom: 1px solid; }
    .data-card .card-footer h3 {
      font-size: 13px; }
  .data-card .sents-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .data-card .sents-container .first-row {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .data-card .sents-container .first-row .detailed {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 11px;
        font-weight: bold; }
        .data-card .sents-container .first-row .detailed img {
          width: 20px;
          height: 20px; }
    .data-card .sents-container .sent-number {
      font-size: 40px !important; }
    .data-card .sents-container .variation-icon {
      font-size: 10px;
      padding: 0; }

.data-card-container {
  margin-bottom: 20px; }
  .data-card-container .data-cards {
    display: flex;
    justify-content: space-evenly;
    min-height: 220px; }
    .data-card-container .data-cards .data-card {
      width: 32%; }
      @media (max-width: 991px) {
        .data-card-container .data-cards .data-card {
          width: 100%;
          margin: auto; } }
    @media (max-width: 576px) {
      .data-card-container .data-cards .empty-state {
        height: 300px; } }
  .data-card-container .data-cards-footer {
    background: #d0e0e3;
    padding: 10px;
    margin-top: 15px;
    color: black;
    font-weight: 400; }
    .data-card-container .data-cards-footer a {
      color: #337ab7;
      cursor: pointer;
      border-bottom: 1px solid; }
  @media (max-width: 991px) {
    .data-card-container .alice-carousel__wrapper {
      padding-right: 0 !important; } }
  @media (max-width: 991px) {
    .data-card-container .alice-carousel__stage-item {
      width: 100% !important;
      padding: 0 15px; } }
  @media (max-width: 991px) {
    .data-card-container .alice-carousel {
      width: calc(100% + 30px) !important; } }
  @media (max-width: 991px) {
    .data-card-container .dashboard-element-container {
      width: 100% !important;
      padding: 0 15px; } }
  .data-card-container .filters-first-line mobile {
    padding: 0 15 px; }
  .data-card-container .mobile header-actions-container {
    padding: 0 15 px; }

.link-to-helpcenter {
  background-color: #D0E0E3;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0.3rem; }
  .link-to-helpcenter a {
    margin: 0 4px; }
  .link-to-helpcenter span {
    color: black; }

.modal-88w {
  width: 88%;
  max-width: 88%; }

.show-modal {
  position: fixed;
  right: 20px;
  bottom: 20px; }

.user-modal h1.modal-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: black;
  margin: 0; }

@media (max-width: 768px) {
  .user-modal .botbit-table .botbit-table-column {
    padding: 0; } }

.user-modal .modal-body .container {
  margin-bottom: 60px; }

@media only screen and (max-width: 768px) {
  .user-modal section {
    margin-top: 0; }
  .user-modal .element-content {
    display: block; }
  .user-modal .element-data-container {
    width: 100%; } }

#user-review-history {
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 991px) {
  #user-review-history {
    width: 100%;
    padding: 15px; } }

.review-history-modal .modal-body {
  padding: 0 !important; }

.user-review-history {
  --side-pad: 20px;
  --side-pad-mobile: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px 15px;
  width: 90%; }
  @media (max-width: 768px) {
    .user-review-history {
      margin: 0;
      width: 100%; } }
  .user-review-history_header {
    margin: 0;
    padding: 15px var(--side-pad);
    background-color: #137f69;
    color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    @media (max-width: 768px) {
      .user-review-history_header {
        padding: 15px var(--side-pad-mobile); } }
    .user-review-history_header #user-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .user-review-history_header #user-details .text-container {
        display: flex;
        flex-direction: column; }
        .user-review-history_header #user-details .text-container span label {
          margin: 0;
          font-size: 0.8rem;
          font-weight: 400; }
          .user-review-history_header #user-details .text-container span label b {
            font-size: 1rem; }
          @media (max-width: 768px) {
            .user-review-history_header #user-details .text-container span label {
              font-size: 0.8rem; }
              .user-review-history_header #user-details .text-container span label b {
                font-size: 0.9rem; } }
      .user-review-history_header #user-details .profile-pic {
        border-radius: 100%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        width: 45px;
        margin-right: 15px; }
      .user-review-history_header #user-details .premium-tag {
        color: #cbb679;
        margin-bottom: 0.8rem; }
        .user-review-history_header #user-details .premium-tag-container {
          width: 100%;
          transition: 1s;
          overflow: hidden;
          font-size: 0.8rem; }
  .user-review-history .message-container div {
    overflow: visible !important; }
  .user-review-history .flex-column {
    display: flex;
    flex-direction: column; }
  .user-review-history .chat-messages {
    padding: var(--side-pad);
    background-color: #e1e1e1; }
    .user-review-history .chat-messages div {
      overflow: visible !important; }
    @media (max-width: 768px) {
      .user-review-history .chat-messages {
        padding: var(--side-pad-mobile); } }
  .user-review-history ::-webkit-scrollbar {
    width: 8px; }
  .user-review-history ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px; }
  .user-review-history ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; }
  .user-review-history ::-webkit-scrollbar-thumb:hover {
    background: #555; }
  .user-review-history .test {
    display: none; }
  .user-review-history .empty-state {
    margin: auto;
    font-size: 1.2rem; }
    .user-review-history .empty-state .noResultsImage {
      margin-bottom: 1rem; }
      @media (max-width: 768px) {
        .user-review-history .empty-state .noResultsImage svg {
          width: 250px; } }

.google-views-graph {
  position: relative;
  margin-top: 0 !important; }
  .google-views-graph #botbit-logo-floating {
    display: none; }
    .google-views-graph #botbit-logo-floating img {
      width: 50px; }
      @media (max-width: 996px) {
        .google-views-graph #botbit-logo-floating img {
          width: 35px; } }
  .google-views-graph .empty-state {
    height: 300px; }
  .google-views-graph .graph-card {
    display: flex;
    justify-content: space-between; }
    .google-views-graph .graph-card .disclaimer {
      font-size: 10px; }

.minimal-leaderboard_card {
  padding: 10px;
  margin: 0 0.3rem; }
  .minimal-leaderboard_card .admins-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: flex-end; }
    .minimal-leaderboard_card .admins-container .admin-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer; }
      .minimal-leaderboard_card .admins-container .admin-item_container {
        position: relative; }
        .minimal-leaderboard_card .admins-container .admin-item_container .admin-medal {
          font-size: 1.2rem;
          position: absolute;
          right: 0;
          bottom: 0; }
          @media (min-width: 768px) {
            .minimal-leaderboard_card .admins-container .admin-item_container .admin-medal {
              font-size: 1.5rem; } }
      .minimal-leaderboard_card .admins-container .admin-item .total-sents {
        display: block; }
        @media (min-width: 768px) {
          .minimal-leaderboard_card .admins-container .admin-item .total-sents {
            font-size: 1.2rem; } }
      .minimal-leaderboard_card .admins-container .admin-item img {
        object-fit: cover;
        object-position: center;
        width: 68px;
        aspect-ratio: 1 / 1; }
        @media (min-width: 768px) {
          .minimal-leaderboard_card .admins-container .admin-item img {
            width: 75px; } }
      .minimal-leaderboard_card .admins-container .admin-item:nth-child(1) {
        order: 2;
        margin-bottom: 1rem; }
      .minimal-leaderboard_card .admins-container .admin-item:nth-child(2) {
        order: 1; }
      .minimal-leaderboard_card .admins-container .admin-item:nth-child(3) {
        order: 3; }

.minimal-leaderboard .minpodium-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center; }

.source-card {
  width: 100%;
  height: 430px; }
  .source-card .source-image-container {
    border-bottom: 1px solid grey;
    height: 140px; }
    .source-card .source-image-container .source-image {
      max-width: 90%;
      margin: auto;
      background-size: contain; }
  .source-card .card-title {
    font-size: 18px;
    font-weight: 700; }
  .source-card .card-body {
    padding: 1rem;
    display: flex;
    font-size: 14px;
    flex-direction: column; }
  .source-card .btn-container {
    display: flex;
    justify-content: center;
    margin-top: auto; }
  .source-card .shopify-button {
    background-color: #fafafa !important;
    display: flex;
    align-items: center;
    color: black !important; }
  .source-card .gmail-button {
    background-color: #4285F4 !important;
    display: flex;
    align-items: center; }
  .source-card .rdstation-button {
    background-color: #fafafa !important;
    display: flex;
    align-items: center;
    color: black !important; }
  .source-card .source-img {
    max-height: 35px;
    margin-right: 5px; }

.source-thumbnail {
  width: 100%;
  height: 200px; }
  .source-thumbnail_image-container {
    border-bottom: 1px solid #ccc; }
    .source-thumbnail_image-container .source-image {
      height: 180px;
      max-width: 98%;
      margin: auto;
      background-size: contain !important; }
  .source-thumbnail .card-title {
    font-size: 20px;
    font-weight: 700; }
  .source-thumbnail .thumbnail-body {
    position: relative; }
  .source-thumbnail .status-badge {
    position: absolute;
    right: 5px;
    top: -25px; }

@media only screen and (max-width: 499px) {
  .source-thumbnail {
    width: 100%; }
    .source-thumbnail_image-container .source-image {
      height: 120px; }
    .source-thumbnail .card-title {
      font-size: 14px;
      font-weight: bold !important;
      margin: 0;
      text-align: center !important; }
    .source-thumbnail .card-body {
      padding: 12px 10px; } }

.source-card-modal .modal-body {
  padding: 0 !important; }
  .source-card-modal .modal-body .source-card {
    box-shadow: none !important;
    position: absolute;
    top: 0; }
  .source-card-modal .modal-body .back-button {
    color: black;
    font-size: 20px;
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    top: 1rem;
    left: 1rem; }

#integrations .botbit-table {
  margin-top: 40px; }

#integrations .facebook_messenger_instagram-button {
  background-color: #1877F2 !important;
  display: flex;
  align-items: center;
  justify-content: center; }

#integrations .facebook_messenger_instagram-icon {
  height: 75%; }

@media only screen and (max-width: 499px) {
  #integrations .botbit-table {
    margin-top: 0; }
  .collection-visualizer-header .collection-visualizer-title {
    font-size: 20px;
    margin-bottom: 10px; }
  .collection-visualizer-header .collection-visualizer-subtitle {
    margin-bottom: 30px; } }

.documentation-modal .back-button {
  color: black;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 5px; }

.documentation-modal .btn {
  display: block;
  margin: auto; }

.documentation-container {
  border: 2px solid #ccc;
  overflow: auto;
  margin: 10px 0;
  padding: 5px;
  width: 100%; }
  .documentation-container img {
    margin: auto;
    margin-bottom: 15px;
    width: 100%;
    max-width: 500px;
    display: block; }
  .documentation-container.no-border {
    border: none; }

.match-source-with-stores {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .match-source-with-stores .completed-or-not {
    border-radius: 50%;
    color: white;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .match-source-with-stores .completed-or-not {
        width: 30px;
        height: 30px;
        font-size: 17px; } }
    .match-source-with-stores .completed-or-not.completed {
      background: var(--success); }
    .match-source-with-stores .completed-or-not.incomplete {
      background: var(--danger); }
  .match-source-with-stores .mb-0 .table-title {
    margin-bottom: 10px;
    font-weight: 700; }
  .match-source-with-stores .modal.show.top, .match-source-with-stores .modal {
    z-index: 4000 !important; }
  .match-source-with-stores h1 {
    font-size: 22px;
    margin-bottom: 50px; }

#finish-integration {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc( 100vh - 101px);
  margin-top: 0;
  margin-bottom: 0; }
  #finish-integration .finish-integration-card {
    width: 880px;
    padding: 50px;
    border-radius: 15px; }
    @media (max-width: 992px) {
      #finish-integration .finish-integration-card {
        padding: 25px; } }
    @media (max-width: 992px) {
      #finish-integration .finish-integration-card {
        margin-top: 20px;
        margin-bottom: 50px; } }
  #finish-integration #result-message h1, #finish-integration #result-message h2 {
    text-align: center;
    color: black; }
  #finish-integration #result-message h1 {
    margin-top: 0;
    font-weight: 700; }
  #finish-integration #result-message .result-image-construct {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px; }
    #finish-integration #result-message .result-image-construct .source-logo {
      margin-right: 70px;
      width: 180px; }
    #finish-integration #result-message .result-image-construct .botbit-logo {
      margin-left: 70px;
      width: 180px; }
    @media (max-width: 992px) {
      #finish-integration #result-message .result-image-construct {
        flex-direction: column; }
        #finish-integration #result-message .result-image-construct .source-logo {
          margin-right: 0;
          margin-bottom: 20px; }
        #finish-integration #result-message .result-image-construct .botbit-logo {
          margin-left: 0;
          margin-top: 10px; } }
    #finish-integration #result-message .result-image-construct .result-icon {
      position: relative;
      width: 100px;
      display: flex;
      justify-content: center; }
      #finish-integration #result-message .result-image-construct .result-icon .plug-image {
        width: 100px; }
        @media (max-width: 992px) {
          #finish-integration #result-message .result-image-construct .result-icon .plug-image {
            width: 85px; } }
      #finish-integration #result-message .result-image-construct .result-icon .state-icon {
        position: absolute;
        left: -10px;
        top: -20px; }
        @media (max-width: 992px) {
          #finish-integration #result-message .result-image-construct .result-icon .state-icon {
            left: 3px;
            top: -5px; } }
        #finish-integration #result-message .result-image-construct .result-icon .state-icon i {
          color: #00ff00; }
          #finish-integration #result-message .result-image-construct .result-icon .state-icon i.fa-times-circle {
            color: red; }
          @media (max-width: 992px) {
            #finish-integration #result-message .result-image-construct .result-icon .state-icon i {
              font-size: 95px; } }

#main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  font-size: x-large; }

#inner-container {
  height: 20%;
  margin-top: 2%;
  text-align: center; }

.page-heading {
  display: none; }

#main-reactivate {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }
  #main-reactivate .checkbox-label::before {
    top: 0.125rem; }
  #main-reactivate .checkbox-label::after {
    top: 0.125rem; }
  #main-reactivate #storesContainer {
    margin-top: 5%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
  #main-reactivate #title {
    font-size: large; }
  #main-reactivate #push {
    height: 30px; }
  @media (max-width: 992px) {
    #main-reactivate #storesContainer {
      min-height: 140px;
      margin-top: 10%; }
    #main-reactivate #title {
      margin-top: 5%;
      font-size: medium; }
    #main-reactivate #push {
      height: 75px; } }
  @media (min-width: 991px) {
    #main-reactivate #reactivateButtonContainer {
      margin-top: 5%; } }

#integrations-pre-optin {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc( 100vh - 101px);
  margin-top: 0;
  margin-bottom: 0; }
  #integrations-pre-optin .card {
    width: 880px;
    padding: 50px;
    border-radius: 15px; }
    @media (max-width: 992px) {
      #integrations-pre-optin .card {
        padding: 25px; } }
    #integrations-pre-optin .card .integrations-pre-optin_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
    #integrations-pre-optin .card .integrations-pre-optin_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
    #integrations-pre-optin .card .title {
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem; }
    #integrations-pre-optin .card input {
      width: 18rem; }
    #integrations-pre-optin .card .link-like-btn {
      text-decoration: none;
      color: var(--default); }
    #integrations-pre-optin .card .connect-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%; }
    #integrations-pre-optin .card .field-with-error {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
    #integrations-pre-optin .card .compiledPrefixAndSufix {
      display: none; }
    #integrations-pre-optin .card .prefix, #integrations-pre-optin .card .sufix {
      padding: 0 0.4rem;
      color: #444;
      font-size: 14px; }
    #integrations-pre-optin .card .page-heading {
      display: none; }
    #integrations-pre-optin .card .md-form {
      margin: 0.4rem 0; }
    #integrations-pre-optin .card .error-message {
      color: red;
      font-size: 14px; }

@media (max-width: 499px) {
  #integrations-pre-optin .card .prefix, #integrations-pre-optin .card .sufix {
    display: none; }
  #integrations-pre-optin .card input {
    width: 100%; }
  #integrations-pre-optin .card .field-with-error {
    width: 100%; }
  #integrations-pre-optin .card .compiledPrefixAndSufix {
    display: block;
    width: 100%; }
  #integrations-pre-optin .card .connect-field {
    flex-direction: column;
    align-items: flex-start; }
  #integrations-pre-optin .card .md-form {
    width: 100%; } }

#integrations-list .column-container {
  display: inline-flex !important; }

#integrations-list .shopify-header-button {
  background-color: #fafafa !important;
  display: flex;
  align-items: center;
  color: black;
  justify-content: center; }

#integrations-list .shopify-header-icon {
  font-size: 1.5em !important; }

#integrations-list .gmail-header-button {
  background-color: #4285F4 !important;
  display: flex;
  align-items: center;
  justify-content: center; }

#integrations-list .gmail-header-icon {
  font-size: 2em !important; }

#integrations-list .rdstation-header-button {
  background-color: #fafafa !important;
  display: flex;
  align-items: center;
  color: black;
  justify-content: center; }

#integrations-list .facebook_messenger_instagram-header-button {
  background-color: #1877F2 !important;
  display: flex;
  align-items: center;
  justify-content: center; }

#integrations-list .facebook_messenger_instagram-header-icon {
  font-size: 1.5em !important; }

@media (max-width: 991px) {
  #integrations-list .header-actions-container {
    display: none !important; } }

.botbit-actions .fixed-action-btn.active ul {
  height: unset; }

.dropdown-item {
  width: 100%; }

.triggerBtn {
  left: 108px;
  position: absolute;
  bottom: -8px; }

.deleteBtn {
  left: 152px;
  position: absolute;
  bottom: -3px; }

.row .img-thumbnail {
  margin-left: 12px; }

.company-container {
  padding-bottom: 5rem; }
  .company-container_head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem; }
    .company-container_head h1.page-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: black;
      margin: 0; }
    .company-container_head button.btn {
      text-transform: none;
      border-radius: 3px;
      font-weight: 600;
      box-shadow: none; }
  .company-container_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .company-container_header-actions, .company-container_header-actions:hover, .company-container_header-actions:focus, .company-container_header-actions:active {
    color: #A7B1C1 !important;
    border: 1px solid #A7B1C1 !important;
    border-radius: 3px;
    text-transform: none;
    font-size: 14px !important;
    font-weight: 600; }
  .company-container_logo img {
    max-width: 120px; }
  .company-container_title {
    text-transform: uppercase;
    color: #000; }
    .company-container_title h2 {
      font-weight: bold !important;
      font-size: 0.9rem !important; }
  .company-container-section {
    padding: 0 1rem;
    width: 100%;
    margin-bottom: 1rem; }
    .company-container-section hr {
      margin: 0.5rem 0 !important; }
  .company-container_image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.8rem; }
    .company-container_image-container img {
      width: 100%;
      border-radius: 3px;
      margin: auto; }
    .company-container_image-container a {
      width: 50%;
      max-width: 120px; }
  .company-container_actions-row {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .company-container_color-box {
    display: block;
    width: 30px;
    height: 30px; }

@media only screen and (max-width: 576px) {
  .company-container_title h1 {
    font-size: 1rem; }
  .company-container_actions-row .btn {
    padding: 15px 15px; } }

.qr-settings-container .col {
  padding: 0 0.8rem; }

.qr-settings-container .row {
  margin-bottom: 1rem; }

.qr-settings-container .md-form {
  margin: 0; }

#reviews-widget {
  padding: 15px; }
  @media (min-width: 992px) {
    #reviews-widget .filters {
      padding: 15px; } }
  #reviews-widget .premium-tag {
    color: #ac9437;
    margin-bottom: 0.8rem;
    font-weight: 400;
    margin-top: -1rem;
    margin-bottom: 1rem; }
    #reviews-widget .premium-tag-container {
      width: 100%;
      transition: 1s;
      overflow: hidden;
      font-size: 0.8rem; }
  #reviews-widget .header-actions-container {
    margin-right: auto !important; }
  #reviews-widget .filters-form {
    margin-top: 0 !important; }
  #reviews-widget .filters-button {
    padding: 10px 30px; }
  #reviews-widget .filters-first-line {
    margin-bottom: 0; }
  #reviews-widget .flex {
    display: flex;
    align-items: center; }
  #reviews-widget .separator {
    margin-top: 0; }
  @media (min-width: 992px) {
    #reviews-widget .code {
      width: 80%; } }
  #reviews-widget textarea {
    display: none; }
  #reviews-widget .title {
    font-weight: bold;
    color: black;
    margin-bottom: 25px; }
    @media (max-width: 991px) {
      #reviews-widget .title {
        font-size: 20px; } }
  @media (max-width: 991px) {
    #reviews-widget .md-form {
      margin-bottom: 0; } }

.user-review-history-container {
  --side-pad: 20px;
  --side-pad-mobile: 10px; }
  .user-review-history-container .user-review-history-page {
    display: flex;
    flex-direction: column;
    margin: 10px 15px;
    width: 100%;
    padding-bottom: 3.5rem !important; }
    @media (max-width: 768px) {
      .user-review-history-container .user-review-history-page {
        margin: 10px 0;
        width: 100%;
        padding: 0 !important;
        padding-bottom: 3.5rem !important; } }
    .user-review-history-container .user-review-history-page_header {
      margin: 0;
      padding: 15px var(--side-pad);
      background-color: #137f69;
      color: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
      @media (max-width: 768px) {
        .user-review-history-container .user-review-history-page_header {
          padding: 15px var(--side-pad-mobile); } }
      .user-review-history-container .user-review-history-page_header #user-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start; }
        .user-review-history-container .user-review-history-page_header #user-details .text-container {
          display: flex;
          flex-direction: column; }
          .user-review-history-container .user-review-history-page_header #user-details .text-container span label {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400; }
            .user-review-history-container .user-review-history-page_header #user-details .text-container span label b {
              font-size: 1rem; }
            @media (max-width: 768px) {
              .user-review-history-container .user-review-history-page_header #user-details .text-container span label {
                font-size: 0.8rem; }
                .user-review-history-container .user-review-history-page_header #user-details .text-container span label b {
                  font-size: 0.9rem; } }
        .user-review-history-container .user-review-history-page_header #user-details .profile-pic {
          border-radius: 100%;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          width: 45px;
          margin-right: 15px; }
    .user-review-history-container .user-review-history-page .message-container div {
      overflow: visible !important; }
    .user-review-history-container .user-review-history-page .flex-column {
      display: flex;
      flex-direction: column; }
    .user-review-history-container .user-review-history-page .chat-messages {
      padding: var(--side-pad);
      background-color: #e1e1e1; }
      .user-review-history-container .user-review-history-page .chat-messages div {
        overflow: visible !important; }
      @media (max-width: 768px) {
        .user-review-history-container .user-review-history-page .chat-messages {
          padding: var(--side-pad-mobile); } }
    .user-review-history-container .user-review-history-page ::-webkit-scrollbar {
      width: 8px; }
    .user-review-history-container .user-review-history-page ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px; }
    .user-review-history-container .user-review-history-page ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px; }
    .user-review-history-container .user-review-history-page ::-webkit-scrollbar-thumb:hover {
      background: #555; }
    .user-review-history-container .user-review-history-page .test {
      display: none; }
    .user-review-history-container .user-review-history-page .chat-history {
      max-height: 60vh; }
    .user-review-history-container .user-review-history-page .empty-state {
      margin: auto;
      font-size: 1.2rem;
      padding-bottom: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .user-review-history-container .user-review-history-page .empty-state .noResultsImage {
        margin-bottom: 1rem; }
        @media (max-width: 768px) {
          .user-review-history-container .user-review-history-page .empty-state .noResultsImage svg {
            width: 150px;
            height: 120px; } }

.upgrade-plan {
  font-weight: 400; }
  .upgrade-plan .plan-badge {
    border-radius: 35px;
    border: 2px solid #05C2D7;
    padding: 0.2rem 0.5rem; }
    @media (max-width: 768px) {
      .upgrade-plan .plan-badge {
        font-size: 0.7rem;
        text-align: center; } }
    .upgrade-plan .plan-badge b {
      color: #05C2D7;
      text-transform: uppercase; }
  .upgrade-plan_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .upgrade-plan_feature-title {
    font-size: 1rem;
    color: #05C2D7;
    font-weight: bold; }
  .upgrade-plan_presentation-card_container {
    padding: 0.8rem 2rem; }
    @media (max-width: 768px) {
      .upgrade-plan_presentation-card_container {
        padding: 1rem; } }
  .upgrade-plan_presentation-card_header {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .upgrade-plan_presentation-card_header .image {
      width: 80px;
      margin-right: 1rem; }
      @media (max-width: 768px) {
        .upgrade-plan_presentation-card_header .image {
          width: 60px;
          margin-right: 0.5rem; } }
    .upgrade-plan_presentation-card_header .header-description .plan-title {
      color: #05C2D7;
      font-size: 1.2rem;
      font-weight: bold; }
  .upgrade-plan_presentation-card_features {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .upgrade-plan_presentation-card_features {
        flex-direction: column;
        align-items: unset;
        justify-content: unset; } }
  .upgrade-plan_feature-list_container {
    padding: 1rem 2rem; }
    @media (max-width: 768px) {
      .upgrade-plan_feature-list_container {
        padding: 1rem; } }
    .upgrade-plan_feature-list_container h3 {
      font-weight: bold;
      font-size: 1rem;
      text-transform: uppercase; }
    .upgrade-plan_feature-list_container ul {
      margin: 0;
      padding-left: 1rem; }
      .upgrade-plan_feature-list_container ul :first-child {
        color: #05C2D7; }
      .upgrade-plan_feature-list_container ul .inline-badge {
        margin-left: 4px;
        background-color: #05C2D7;
        color: white;
        font-size: 0.6rem;
        padding: 2px 3px;
        text-transform: uppercase; }
      .upgrade-plan_feature-list_container ul.split {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2; }
    .upgrade-plan_feature-list_container .highlight {
      color: #05C2D7; }
  .upgrade-plan .upgrade-btn {
    font-weight: bold;
    font-size: 1rem;
    text-transform: none;
    margin: auto;
    display: block; }
  .upgrade-plan_vector-image {
    width: 40%; }

.modal-plan-upgrade {
  width: 800px;
  z-index: 5000000; }

.chargebee-header-container div#app[aria-labelledby=dialog1Title] {
  margin-bottom: 0; }

.podiumWrapper {
  margin-top: 3rem;
  margin-bottom: 2rem; }
  @media (max-width: 768px) {
    .podiumWrapper {
      margin-top: 0;
      margin-bottom: 0; } }
  .podiumWrapper .podium-first {
    order: 1;
    margin-bottom: 2rem;
    margin-top: -2rem; }
    @media (max-width: 768px) {
      .podiumWrapper .podium-first {
        margin-bottom: 0;
        margin-top: 0; } }
  @media (min-width: 768px) {
    .podiumWrapper .col-sm-4:nth-child(3) {
      order: 2; } }
  .podiumWrapper .card {
    cursor: pointer; }
    @media (max-width: 768px) {
      .podiumWrapper .card {
        margin-top: 2.5rem;
        margin-bottom: .5rem; } }
    .podiumWrapper .card img {
      background-color: #fff;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: -2rem;
      left: 0;
      right: 0;
      text-align: center;
      width: 100px;
      height: 100px; }
    .podiumWrapper .card .pos {
      font-size: 1.2rem; }
      .podiumWrapper .card .pos span {
        display: block;
        font-weight: 900;
        font-size: 2rem; }
  .podiumWrapper .card-body {
    margin-top: 3.5rem;
    margin-bottom: 1rem; }
  @media (max-width: 768px) {
    .podiumWrapper .alice-carousel__wrapper {
      padding-right: 0 !important; } }

.podium-empty-state {
  margin: 0 auto;
  text-align: center;
  padding: 0 15px; }
  .podium-empty-state img {
    max-width: 100%; }

.leaderboard {
  padding-bottom: 6rem;
  margin-top: 1rem; }
  .leaderboard .container {
    margin-top: 0; }
  .leaderboard .mobile-row_container .mobile-row_heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .leaderboard .mobile-row_container .mobile-row_heading .mobile-row_admin-avatar {
      margin-right: 1rem; }
  .leaderboard .mobile-row_container .mobile-row_metrics-list {
    list-style: none;
    padding-left: calc(45px + 1rem); }
  .leaderboard .mobile-row_container .mobile-row_metrics-list_item {
    margin-bottom: .5rem; }
    .leaderboard .mobile-row_container .mobile-row_metrics-list_item:last-child {
      margin-bottom: 0; }
    .leaderboard .mobile-row_container .mobile-row_metrics-list_item .badge-default {
      background-color: #38B394 !important;
      float: right; }
  .leaderboard .botbit-collection .botbit-table .card.botbit-table-card {
    cursor: default; }
    .leaderboard .botbit-collection .botbit-table .card.botbit-table-card .card-header.cursor-pointer {
      cursor: default; }
      .leaderboard .botbit-collection .botbit-table .card.botbit-table-card .card-header.cursor-pointer .row-container {
        align-items: center; }
  .leaderboard .botbit-collection .botbit-table .botbit-table-column {
    padding-right: 0; }
  @media (max-width: 768px) {
    .leaderboard .botbit-collection .card-header {
      padding: .75rem; } }
  .leaderboard .botbit-table .element-content {
    cursor: pointer; }

.admin-invite.embedded {
  padding: 0 1rem; }

.admin-invite.page-view .admin-invite_container {
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 0.35rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 1rem; }
  .admin-invite.page-view .admin-invite_container h2 {
    font-weight: bold; }

@media (max-width: 768px) {
  .admin-invite.page-view {
    padding-bottom: 4rem; }
    .admin-invite.page-view .admin-invite_container {
      padding: 0.5rem 1rem; } }

.admin-invite_title {
  font-size: 1.6rem !important;
  color: #000 !important; }

.admin-invite .submit-btn {
  margin: 0;
  margin-bottom: 2rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

.admin-invite .error-msg {
  color: var(--danger);
  font-size: 0.8rem;
  margin-top: -0.6rem; }

.admin-invite .md-form {
  margin-bottom: 0; }

.admin-invite div[name=role] {
  margin-top: 2rem; }
  .admin-invite div[name=role] input.select-dropdown {
    margin-bottom: 0.5rem;
    height: 1.375rem; }
  .admin-invite div[name=role] label.active {
    margin-top: -0.5rem; }

.admin-invite .role-container {
  margin: 0 0 1rem 0;
  padding: 0; }
  .admin-invite .role-container .form-check {
    margin: 0;
    padding: 0; }

@media (min-width: 768px) {
  .admin-invite-modal {
    max-width: 520px !important;
    width: 520px !important; } }

.admin-invite-modal_title {
  font-size: 1.4rem !important;
  color: #000 !important; }

.admin-invite-modal .modal-header {
  border-bottom: none !important; }

.task-disabled {
  background: #e0e0e0; }

.task-title {
  font-weight: bold;
  font-size: 1.5em; }

.task-title-disabled {
  font-weight: bold;
  font-size: 1.5em;
  color: gray; }

.arrow-disabled {
  color: gray; }

.task-content {
  padding: 1rem; }

@media (min-width: 768px) {
  .width-md-50 {
    width: 50%; } }

@media (max-width: 768px) {
  .chart-container .chart-and-info-container {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 4rem; } }

.chart-container .data-container .details-cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 12rem; }
  @media (max-width: 768px) {
    .chart-container .data-container .details-cards {
      height: unset; } }
  .chart-container .data-container .details-cards .funnel-detail-card {
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff; }
    @media (max-width: 768px) {
      .chart-container .data-container .details-cards .funnel-detail-card {
        width: calc(100%/3);
        padding: 8px;
        height: 8.5rem;
        color: #000000;
        font-size: 0.7rem;
        text-align: center; }
        .chart-container .data-container .details-cards .funnel-detail-card .btn.btn-sm {
          padding: 0.2rem 1rem;
          font-size: 0.64rem; } }
    .chart-container .data-container .details-cards .funnel-detail-card .card-content {
      font-size: 1.8rem;
      font-weight: bold;
      color: #000000; }
      @media (max-width: 768px) {
        .chart-container .data-container .details-cards .funnel-detail-card .card-content {
          font-size: 1.2rem; } }

@media (min-width: 768px) {
  .funnel-detail-modal .modal-content {
    height: 90vh; } }

@media (min-width: 768px) {
  .captive-graph {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; } }

.captive-graph .loader {
  height: 36vh; }

.captive-graph .filters .filters-and-more-actions.mobile.mobile:not(.more-actions) .filters-actions-container .botbit-dropdown .btn {
  padding: 5px !important;
  border-color: #1ab394 !important;
  border-radius: 3px;
  width: 100%; }

.captive-graph .empty-state {
  height: 380px; }

.captive-graph .filters-first-line {
  margin-bottom: 0 !important; }

.captive-graph .filters .filters-and-more-actions.mobile.mobile:not(.more-actions) .filters-actions-container .botbit-dropdown {
  width: 100%; }

.captive-graph .registration-total {
  color: #000;
  font-weight: bold;
  font-size: 1.6rem; }
  @media (max-width: 768px) {
    .captive-graph .registration-total {
      font-size: 1.2rem; } }

.captive-graph .graph-card {
  display: flex;
  justify-content: space-between;
  padding: 10px; }
  .captive-graph .graph-card .card-footer {
    font-size: 11px;
    border: none;
    color: #000;
    font-weight: 500;
    min-height: 45px;
    padding: .5rem;
    margin-top: 5px;
    background-color: #f3f3f3 !important; }

.captive-graph-view {
  padding-bottom: 4rem;
  margin: 1rem 1rem 0 0; }
  @media (max-width: 768px) {
    .captive-graph-view {
      margin-right: 0; } }

.messenger-wrapper {
  background-color: #ffffff; }
  .messenger-wrapper .sections-container .chats-container {
    overflow-y: scroll;
    max-height: 85vh;
    width: 100%; }
    @media (min-width: 768px) {
      .messenger-wrapper .sections-container .chats-container {
        width: 40%; } }
    .messenger-wrapper .sections-container .chats-container .chats-tabs {
      border-top: var(--primary) 5px solid; }
    .messenger-wrapper .sections-container .chats-container .chats-container-header {
      width: 100%; }
      .messenger-wrapper .sections-container .chats-container .chats-container-header .store-selector-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.4rem; }
        .messenger-wrapper .sections-container .chats-container .chats-container-header .store-selector-container button {
          border-radius: 3px;
          text-transform: none;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
          border-width: 1px !important;
          margin: 0 5px;
          padding: 5px 1rem; }
      .messenger-wrapper .sections-container .chats-container .chats-container-header .conversation-filters-container {
        background-color: whitesmoke; }
        .messenger-wrapper .sections-container .chats-container .chats-container-header .conversation-filters-container .filter-label {
          display: flex;
          align-items: center; }
      .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs {
        margin: 0; }
        .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs ul.nav {
          margin: 0;
          box-shadow: none; }
          @media (max-width: 768px) {
            .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs ul.nav li a {
              padding: 7px 10px 7px 10px; } }
        .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs .conversation-actions-container .filter-chips {
          max-width: unset;
          width: 100%;
          padding-top: 0.5rem;
          padding-bottom: 1rem; }
        .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs .filters-info {
          margin: 5px; }
        .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs .filters-actions .dropdown-menu {
          max-height: 70vh;
          overflow-y: scroll;
          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */ }
          .messenger-wrapper .sections-container .chats-container .chats-container-header .chats-tabs .filters-actions .dropdown-menu::-webkit-scrollbar {
            display: none; }
      .messenger-wrapper .sections-container .chats-container .chats-container-header .filters .filters-first-line {
        margin-bottom: 0;
        margin-top: 5px; }
    .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item {
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid #ccc;
      padding: 0.4rem 0.6rem; }
      .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item .avatar-container {
        margin-right: 1rem; }
      .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item .conversation-issue-assignment {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px; }
      .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item .text-content {
        width: 100%;
        padding: 0.2rem 0;
        overflow: hidden; }
        .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item .text-content p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0;
          max-width: 90%; }
        .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item .text-content h4 {
          margin: 0; }
      .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item .conversation-details span.time {
        display: block; }
      .messenger-wrapper .sections-container .chats-container .chats-list .conversation-item.selected-item {
        background-color: #ccc; }
  .messenger-wrapper .sections-container .current-chat-container {
    width: 100%; }
    @media (min-width: 768px) {
      .messenger-wrapper .sections-container .current-chat-container {
        width: 60%; } }

.messenger-core {
  height: 85vh;
  background-image: url("/static/img/whatsapp-background.png");
  background-position: 0% 0%;
  background-size: auto auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-color: transparent;
  position: relative; }
  .messenger-core .btn-flat.btn.Ripple-parent.single-default-action.btn-circle {
    color: white !important; }
  .messenger-core .conversation-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary);
    color: #ffffff;
    padding: 0.5rem 0.8rem;
    margin-bottom: 1rem; }
    .messenger-core .conversation-header .conversation-info {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .messenger-core .conversation-header .conversation-info .avatar-container {
        margin-right: 1rem; }
    .messenger-core .conversation-header .conversation-actions {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .messenger-core .conversation-header .conversation-actions a[role='button'] {
        margin: 0; }
        @media (max-width: 768px) {
          .messenger-core .conversation-header .conversation-actions a[role='button'] {
            width: 32px; } }
    .messenger-core .conversation-header .collapse.show,
    .messenger-core .conversation-header .collapsing {
      position: absolute;
      background-color: white;
      top: 1.5rem;
      z-index: 1;
      width: 100%;
      border-radius: 0 0 0.25rem 0.25rem; }
    .messenger-core .conversation-header .card {
      max-width: 300px;
      height: 1.7rem;
      cursor: pointer; }
      .messenger-core .conversation-header .card #header {
        display: block !important;
        margin: auto;
        width: 100%; }
        .messenger-core .conversation-header .card #header > div {
          width: 100%;
          text-align: center; }
        .messenger-core .conversation-header .card #header .fa {
          display: none; }
    .messenger-core .conversation-header .not-collapsed-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      padding: 3px; }
  .messenger-core .administrator-selector-container .select-in-dropdown-container {
    width: 20vw;
    overflow-y: auto;
    padding: 1em; }
  .messenger-core .administrator-selector-container .key-administrator-selector {
    border-radius: 3px;
    text-transform: none;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    border-width: 1px !important;
    margin: 0 5px;
    padding: 5px 10px !important;
    background-color: white !important; }
  @media (max-width: 992px) {
    .messenger-core .administrator-selector-container {
      margin: 5px 0 0 0;
      width: 100%;
      float: left; }
      .messenger-core .administrator-selector-container .key-administrator-selector {
        margin: 0 !important;
        padding: 15px 10px !important;
        width: 100%; } }
  .messenger-core .image-file-input {
    position: absolute;
    overflow: hidden;
    height: 0px;
    width: 0px;
    opacity: 0;
    left: 0px;
    top: 0px;
    display: none; }
  .messenger-core .img-previewer-container {
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0px 60px 0px 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    box-sizing: border-box; }
    .messenger-core .img-previewer-container .close-icon {
      color: white;
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 40px;
      font-weight: bold;
      opacity: 0.2;
      cursor: pointer; }
    .messenger-core .img-previewer-container .img-container {
      margin: auto;
      padding: 0;
      width: 90%;
      height: 100%;
      max-height: 100%;
      text-align: center; }
      .messenger-core .img-previewer-container .img-container .img-object {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .messenger-core .img-previewer-container .img-container .img-object img {
          max-height: 100%;
          max-width: 100%;
          -ms-user-select: none;
              user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none; }
        .messenger-core .img-previewer-container .img-container .img-object embed {
          max-height: 100%;
          max-width: 100%;
          height: 80%;
          width: 100%;
          -ms-user-select: none;
              user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none; }
  .messenger-core .messages-container {
    overflow: auto;
    max-height: 63%; }
    .messenger-core .messages-container ul {
      padding: 0; }
      .messenger-core .messages-container ul .msg-container {
        overflow: auto;
        margin-bottom: 0.5rem; }
    .messenger-core .messages-container .msg-bubble {
      max-width: 70%;
      padding: 0.4rem 0.8rem;
      float: left;
      background-color: #ffffff;
      border-radius: 0.5rem 0.5rem 0.5rem 0;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 6px;
      list-style: none;
      color: #000000;
      font-weight: 400;
      margin: 0 1rem;
      word-break: break-word;
      position: relative; }
      .messenger-core .messages-container .msg-bubble .img-message-container {
        display: flex;
        justify-content: center;
        align-items: center; }
        .messenger-core .messages-container .msg-bubble .img-message-container .img-message {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 300px;
          object-fit: contain; }
      .messenger-core .messages-container .msg-bubble .message-audio-container {
        display: block;
        align-items: left; }
        @media (max-width: 500px) {
          .messenger-core .messages-container .msg-bubble .message-audio-container .message-audio {
            transform: scale(0.75);
            transform-origin: left; } }
      .messenger-core .messages-container .msg-bubble .message-file-container .message-file-preview {
        text-align: center; }
        .messenger-core .messages-container .msg-bubble .message-file-container .message-file-preview img {
          max-width: 60px;
          max-height: 60px; }
      .messenger-core .messages-container .msg-bubble .message-file-container .message-file-description-container {
        max-width: 200px;
        overflow: hidden; }
        .messenger-core .messages-container .msg-bubble .message-file-container .message-file-description-container .message-file-description {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%; }
      .messenger-core .messages-container .msg-bubble .botbit-image {
        cursor: pointer; }
      .messenger-core .messages-container .msg-bubble.direction-out {
        float: right;
        border-radius: 0.5rem 0.5rem 0 0.5rem;
        background-color: #1ab394;
        color: #ffffff; }
      .messenger-core .messages-container .msg-bubble.direction-none {
        background-color: rgba(240, 240, 240, 0.7);
        border-radius: 0.5rem;
        width: 80%;
        max-width: 80%;
        text-align: center;
        margin: 0 10%; }
      .messenger-core .messages-container .msg-bubble .viewed-for {
        display: block;
        font-size: 0.6rem;
        opacity: 0.7; }
      .messenger-core .messages-container .msg-bubble .check-icon {
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-size: 0.6rem; }
        .messenger-core .messages-container .msg-bubble .check-icon.icon-blue {
          color: #0b30ea; }
        .messenger-core .messages-container .msg-bubble .check-icon.icon-gray {
          opacity: 0.7; }
    .messenger-core .messages-container .referred-message-wrapper {
      background-color: rgba(0, 0, 0, 0.12);
      padding: 3px;
      border-radius: 0.5rem 0.5rem 0 0;
      padding-bottom: 1px; }
      .messenger-core .messages-container .referred-message-wrapper li.msg-container {
        margin: 0 !important; }
        .messenger-core .messages-container .referred-message-wrapper li.msg-container span.msg-bubble {
          max-width: unset !important;
          box-shadow: rgba(0, 0, 0, 0.12); }
  .messenger-core .custom-input-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0.5rem; }
    .messenger-core .custom-input-container .text-input-bar {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      background-color: #ffffff;
      border: 2px solid rgba(0, 0, 0, 0.36);
      border-radius: 5px;
      transition: 0.3s; }
      .messenger-core .custom-input-container .text-input-bar:focus, .messenger-core .custom-input-container .text-input-bar:active {
        border-color: var(--primary); }
      .messenger-core .custom-input-container .text-input-bar .textarea-container {
        width: 100%; }
        .messenger-core .custom-input-container .text-input-bar .textarea-container .md-form {
          margin: 0; }
          .messenger-core .custom-input-container .text-input-bar .textarea-container .md-form textarea {
            resize: none;
            margin: 0;
            background-color: none;
            border: none;
            padding-left: 0;
            padding: 0.5rem 0.725rem; }
            .messenger-core .custom-input-container .text-input-bar .textarea-container .md-form textarea:hover, .messenger-core .custom-input-container .text-input-bar .textarea-container .md-form textarea:focus {
              border: none !important;
              border-color: unset !important;
              box-shadow: unset !important; }
      .messenger-core .custom-input-container .text-input-bar .actions-row {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 2px solid rgba(0, 0, 0, 0.12); }
        .messenger-core .custom-input-container .text-input-bar .actions-row .input-actions {
          display: flex;
          flex-direction: row; }
          .messenger-core .custom-input-container .text-input-bar .actions-row .input-actions.left {
            flex: 1 1; }
            @media screen and (min-width: 768px) {
              .messenger-core .custom-input-container .text-input-bar .actions-row .input-actions.left .btn-flat {
                margin-left: 0.6rem;
                font-size: 0.8rem;
                text-transform: unset; } }
        .messenger-core .custom-input-container .text-input-bar .actions-row .dropdown .dropdown-menu {
          transform: translateY(-0.6rem) !important;
          max-height: 70vh;
          overflow-y: auto;
          min-width: 100% !important;
          background-attachment: local, local, scroll, scroll; }
        .messenger-core .custom-input-container .text-input-bar .actions-row .template-selector-trigger {
          position: absolute;
          overflow: hidden;
          height: 0px;
          width: 0px;
          opacity: 0;
          left: 0px;
          top: 0px;
          display: none;
          transform: translate(1rem, -5rem); }
        .messenger-core .custom-input-container .text-input-bar .actions-row a.btn-flat[role='button'] {
          padding: 0;
          margin: 0;
          background-color: unset;
          color: rgba(0, 0, 0, 0.42); }
          .messenger-core .custom-input-container .text-input-bar .actions-row a.btn-flat[role='button'] i {
            color: inherit;
            font-size: 1.2rem; }
    .messenger-core .custom-input-container .emoji-picker-react {
      position: absolute;
      bottom: 2.5rem;
      left: 0.5rem;
      z-index: 1; }
  .messenger-core #ReactSimpleImageViewer {
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.8); }
  .messenger-core .img-previewer-input-container {
    position: absolute;
    width: 480px;
    max-width: 95%;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%); }
    .messenger-core .img-previewer-input-container .custom-input-container {
      left: 0; }
      .messenger-core .img-previewer-input-container .custom-input-container .text-input-bar {
        background-color: #ffffff; }
  .messenger-core .chat-empty-state {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-left: 1px solid #ccc; }
  .messenger-core .assign-conversation .dropdown-content {
    max-height: 60vh; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%); }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: var(--primary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0); }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite; }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite; }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite; }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }

@media (max-width: 768px) {
  .conversation-creation .new-contact {
    width: 100%; }
  .conversation-creation .autocomplete-input {
    margin-left: 0px !important; }
  .conversation-creation .react-autosuggest__suggestions-container {
    position: relative !important; } }

.conversation-creation {
  background-color: rgba(255, 255, 255, 0.6); }
  .conversation-creation #cancel-creation {
    position: absolute;
    z-index: 1;
    padding: 0;
    right: 10px; }
  .conversation-creation .pl-075 {
    padding-left: 0.75rem !important; }
  .conversation-creation .md-form {
    margin-bottom: 0.4rem; }
    .conversation-creation .md-form label {
      color: #676a6c; }
  .conversation-creation .react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    background-color: white; }
    .conversation-creation .react-autosuggest__suggestions-container .react-autosuggest__suggestion--highlighted {
      background-color: lavender; }
    .conversation-creation .react-autosuggest__suggestions-container ul {
      padding: 0;
      list-style: none; }
      .conversation-creation .react-autosuggest__suggestions-container ul li {
        padding: 0;
        cursor: pointer; }

.issues-dropdown-menu {
  width: 200px !important; }

.cursor-default {
  cursor: default; }

.conversation-dashboard-container {
  padding-bottom: 50px;
  margin-top: 1.5rem; }
  .conversation-dashboard-container .big-number {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 10px;
    align-self: center; }
  .conversation-dashboard-container .source-icon {
    width: 24px;
    height: 24px; }
  .conversation-dashboard-container .channel-detail {
    margin-bottom: 10px;
    align-self: center;
    font-size: large;
    display: flex; }
    .conversation-dashboard-container .channel-detail .channel-subdetail {
      margin-left: 10px;
      text-align: center;
      margin-bottom: 0px;
      font-size: x-small; }
  .conversation-dashboard-container .conversation-insight-container {
    display: flex;
    justify-content: center; }
  .conversation-dashboard-container .conversation-insight {
    max-width: 200px;
    min-width: 200px; }
  .conversation-dashboard-container .conversation-insight-container {
    justify-content: center; }
  .conversation-dashboard-container .admin-stats-container {
    justify-content: center; }
  .conversation-dashboard-container .cursor-pointer {
    cursor: default !important; }
  .conversation-dashboard-container .admin-stats-table {
    max-width: 800px; }
  .conversation-dashboard-container .admin-avatar {
    width: 35px; }
  .conversation-dashboard-container .insight-title {
    text-align: center; }
  .conversation-dashboard-container .cell-wrapper {
    display: table; }
  .conversation-dashboard-container .cell-content {
    display: table-cell;
    vertical-align: middle; }
  .conversation-dashboard-container .mobile-row_container .mobile-row_heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .conversation-dashboard-container .mobile-row_container .mobile-row_heading .mobile-row_admin-avatar {
      margin-right: 1rem; }
  .conversation-dashboard-container .mobile-row_container .mobile-row_metrics-list {
    list-style: none;
    padding-left: calc(45px + 1rem); }
  .conversation-dashboard-container .mobile-row_container .mobile-row_metrics-list_item {
    margin-bottom: .5rem; }
    .conversation-dashboard-container .mobile-row_container .mobile-row_metrics-list_item:last-child {
      margin-bottom: 0; }
    .conversation-dashboard-container .mobile-row_container .mobile-row_metrics-list_item .badge-default {
      background-color: #38B394 !important;
      float: right; }

.issue-dashboard-container {
  padding-bottom: 50px;
  margin-top: 1.5rem; }
  .issue-dashboard-container .issues-container {
    display: inline-block; }
  .issue-dashboard-container .mobile-contact-button {
    position: absolute;
    right: 0;
    top: 0; }

.flow-container .btn-sm {
  padding: 0.5rem;
  font-size: 1rem; }

.leaderboard {
  padding-bottom: 6rem;
  margin-top: 1rem; }
  .leaderboard .container {
    margin-top: 0; }
  .leaderboard .mobile-row_container .mobile-row_heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .leaderboard .mobile-row_container .mobile-row_heading .mobile-row_admin-avatar {
      margin-right: 1rem; }
  .leaderboard .mobile-row_container .mobile-row_metrics-list {
    list-style: none;
    padding-left: calc(45px + 1rem); }
  .leaderboard .mobile-row_container .mobile-row_metrics-list_item {
    margin-bottom: .5rem; }
    .leaderboard .mobile-row_container .mobile-row_metrics-list_item:last-child {
      margin-bottom: 0; }
    .leaderboard .mobile-row_container .mobile-row_metrics-list_item .badge-default {
      background-color: #38B394 !important;
      float: right; }
  .leaderboard .botbit-collection .botbit-table .card.botbit-table-card {
    cursor: default; }
    .leaderboard .botbit-collection .botbit-table .card.botbit-table-card .card-header.cursor-pointer {
      cursor: default; }
      .leaderboard .botbit-collection .botbit-table .card.botbit-table-card .card-header.cursor-pointer .row-container {
        align-items: center; }
  .leaderboard .botbit-collection .botbit-table .botbit-table-column {
    padding-right: 0; }
  @media (max-width: 768px) {
    .leaderboard .botbit-collection .card-header {
      padding: .75rem; } }
  .leaderboard .botbit-table .element-content {
    cursor: pointer; }

.administrator-edit {
  overflow-y: scroll;
  padding-bottom: 7rem;
  height: 80vh; }
  .administrator-edit .feedback {
    position: relative;
    top: -1.5rem;
    height: 0px; }
  .administrator-edit .image-and-upload-container {
    display: flex; }
  .administrator-edit #imageGalleryContainer {
    position: relative; }
    .administrator-edit #imageGalleryContainer .change-logo-btn {
      position: absolute;
      right: -19px;
      bottom: -10px; }
  .administrator-edit #add-image {
    margin-top: 75px;
    margin-left: -15px;
    position: relative;
    border: none;
    background-color: #5DC3C6;
    border-radius: 5px;
    transition: 0.25s; }
  @media (min-width: 992px) {
    .administrator-edit .image-cropper {
      width: 450px !important;
      height: 450px; } }
  @media (max-width: 992px) {
    .administrator-edit .image-cropper {
      width: 100% !important;
      height: 70vh; } }

.leaderboard {
  padding-bottom: 6rem;
  margin-top: 1rem; }
  .leaderboard .container {
    margin-top: 0; }
  .leaderboard .mobile-row_container .mobile-row_heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .leaderboard .mobile-row_container .mobile-row_heading .mobile-row_admin-avatar {
      margin-right: 1rem; }
  .leaderboard .mobile-row_container .mobile-row_metrics-list {
    list-style: none;
    padding-left: calc(45px + 1rem); }
  .leaderboard .mobile-row_container .mobile-row_metrics-list_item {
    margin-bottom: .5rem; }
    .leaderboard .mobile-row_container .mobile-row_metrics-list_item:last-child {
      margin-bottom: 0; }
    .leaderboard .mobile-row_container .mobile-row_metrics-list_item .badge-default {
      background-color: #38B394 !important;
      float: right; }
  .leaderboard .botbit-collection .botbit-table .card.botbit-table-card {
    cursor: default; }
    .leaderboard .botbit-collection .botbit-table .card.botbit-table-card .card-header.cursor-pointer {
      cursor: default; }
      .leaderboard .botbit-collection .botbit-table .card.botbit-table-card .card-header.cursor-pointer .row-container {
        align-items: center; }
  .leaderboard .botbit-collection .botbit-table .botbit-table-column {
    padding-right: 0; }
  @media (max-width: 768px) {
    .leaderboard .botbit-collection .card-header {
      padding: .75rem; } }
  .leaderboard .botbit-table .element-content {
    cursor: pointer; }

.issue-type-edition {
  padding-bottom: 6rem;
  margin-top: 1rem; }
  .issue-type-edition .title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: black; }
  .issue-type-edition .subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: black;
    margin-right: 1em;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0; }
  .issue-type-edition .adjust-switch {
    margin-top: 11px; }
  .issue-type-edition .state-block {
    background-color: #ededed;
    margin-top: 5px; }
  .issue-type-edition .form-label {
    width: 90px; }
  .issue-type-edition .remove-state {
    align-self: center;
    cursor: pointer; }

.sentiment-container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  /** Calendar background and text styles */ }
  .sentiment-container .fontSize-12px {
    font-size: 12px; }
  .sentiment-container .div-centered {
    display: flex;
    align-items: center;
    justify-content: center; }
  .sentiment-container .fixed-height-350 {
    height: 350px; }
  .sentiment-container .fixed-height-725 {
    height: 725px; }
  .sentiment-container .fixed-height-400 {
    height: 400px; }
  .sentiment-container .bottom-comment-fixed {
    position: absolute;
    bottom: 10px; }
  .sentiment-container .last-update-box {
    font-style: italic; }
  .sentiment-container .principal-title {
    margin-top: 5px;
    font-weight: 800;
    font-size: 16px;
    margin-left: 5px; }
  .sentiment-container .store-ranking-section .nav-tabs li a {
    padding: 5px; }
  .sentiment-container .store-ranking-section .tab-content {
    padding: 5px; }
  .sentiment-container .store-ranking-section .store-card-item-1 {
    margin-top: 0.3rem; }
  .sentiment-container .store-ranking-section .store-card {
    margin-left: 0.3rem;
    margin-right: 0.3rem; }
    .sentiment-container .store-ranking-section .store-card .store-card-body {
      padding: 0.3rem; }
  .sentiment-container .store-ranking-section .positive-mentions {
    color: #1a751a; }
  .sentiment-container .store-ranking-section .neutral-mentions {
    color: dimgray; }
  .sentiment-container .store-ranking-section .negative-mentions {
    color: #942b19; }
  .sentiment-container .store-ranking-section .info {
    color: dimgray;
    font-size: smaller; }
  .sentiment-container .store-ranking-section .apexcharts-tooltip-content {
    font-size: 12px;
    margin: 5px; }
  .sentiment-container .card-content {
    padding: 10px; }
    .sentiment-container .card-content .keyword-help-link {
      color: #337ab7;
      cursor: pointer; }
  .sentiment-container .insight {
    text-align: center;
    font-size: larger;
    margin-bottom: 5px;
    font-weight: bold; }
  .sentiment-container .month-calendar {
    top: 12%;
    position: relative;
    text-align: -webkit-center; }
  .sentiment-container .keyword-summary-item {
    text-align: center; }
    .sentiment-container .keyword-summary-item .keyword {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer; }
      .sentiment-container .keyword-summary-item .keyword:hover {
        -webkit-filter: brightness(50%);
                filter: brightness(50%); }
    .sentiment-container .keyword-summary-item .selected-keyword {
      font-size: 14px;
      font-weight: 900;
      text-decoration: underline; }
  .sentiment-container .gradient-help-container {
    margin-top: 20px; }
    .sentiment-container .gradient-help-container .gradient {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .sentiment-container .gradient-help-container .gradient .bar {
        display: flex;
        width: 80%;
        height: 10px; }
        .sentiment-container .gradient-help-container .gradient .bar .fixed-box {
          width: 20%;
          height: 100%; }
      .sentiment-container .gradient-help-container .gradient .labels {
        display: flex;
        justify-content: space-between;
        width: 80%; }
        .sentiment-container .gradient-help-container .gradient .labels .bottom-label {
          display: inline-block;
          width: 95%;
          text-transform: lowercase;
          font-size: 12px;
          font-style: italic; }
        .sentiment-container .gradient-help-container .gradient .labels .left-label {
          text-align: left; }
        .sentiment-container .gradient-help-container .gradient .labels .right-label {
          text-align: right; }
  .sentiment-container .sentiment-day-summary .mentions-count {
    font-weight: 800;
    font-size: 13px; }
  .sentiment-container .sentiment-day-summary .red-circle {
    color: #942b19;
    font-size: 16px; }
  .sentiment-container .sentiment-day-summary .green-circle {
    color: #1a751a;
    font-size: 16px; }
  .sentiment-container .sentiment-day-summary .gray-circle {
    color: dimgray;
    font-size: 16px; }
  .sentiment-container .sentiment-day-summary .black-circle {
    color: black;
    font-size: 16px; }
  .sentiment-container .sentiment-day-carousel-container {
    padding: 0px 0px;
    position: relative; }
    .sentiment-container .sentiment-day-carousel-container .slide-btn {
      position: absolute;
      top: 150px;
      font-size: 14px;
      font-weight: 900;
      color: black;
      cursor: pointer;
      border-radius: 50%;
      border-width: 1px;
      width: 30px;
      height: 30px; }
      .sentiment-container .sentiment-day-carousel-container .slide-btn:hover {
        background-color: white; }
    .sentiment-container .sentiment-day-carousel-container .slide-btn-prev {
      left: -10px; }
    .sentiment-container .sentiment-day-carousel-container .slide-btn-next {
      right: -10px; }
    .sentiment-container .sentiment-day-carousel-container .slide-btn-hide {
      display: none; }
  .sentiment-container .sentiment-day-card-container {
    display: flex;
    justify-content: center; }
    .sentiment-container .sentiment-day-card-container .border-card {
      border-width: 1px; }
    .sentiment-container .sentiment-day-card-container .border-positive {
      border-color: #2dc82d; }
    .sentiment-container .sentiment-day-card-container .border-negative {
      border-color: #dd4e36; }
    .sentiment-container .sentiment-day-card-container .border-neutral {
      border-color: #9c9c9c; }
    .sentiment-container .sentiment-day-card-container .sentiment-day-card {
      margin: 5px;
      max-width: 350px; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .day-card-keywords-container-positive {
        border-top: 1px dashed lightgray; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .reviewer-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .border-left-positive {
        border-left-color: #50d850; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .border-left-negative {
        border-left-color: #e47562; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .border-left-neutral {
        border-left-color: #b6b6b6; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .day-card-keywords-container {
        display: flex;
        align-items: center;
        min-height: 45px;
        padding-left: 10px;
        border-left-width: 3px;
        border-left-style: solid;
        border-bottom: 1px dashed lightgray;
        border-right: 1px dashed lightgray; }
        .sentiment-container .sentiment-day-card-container .sentiment-day-card .day-card-keywords-container .sentiment {
          width: 90px;
          min-width: 90px;
          max-width: 90px;
          color: darkgray;
          font-weight: bold;
          font-size: 14px; }
        .sentiment-container .sentiment-day-card-container .sentiment-day-card .day-card-keywords-container .words {
          flex-grow: 1;
          font-weight: 600; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .col-in-the-middle {
        padding-left: 5px;
        padding-right: 5px; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .profile-image {
        width: 35px;
        height: 35px;
        margin-right: 10px; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .review-card-body {
        padding: 0.5rem; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .review-global-sentiment {
        font-size: 14px;
        font-weight: 800; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .positive-keyword {
        color: #1a751a; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .neutral-keyword {
        color: dimgray; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .negative-keyword {
        color: #942b19; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .send-feedback-action {
        cursor: pointer;
        margin-left: 5px; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .other-feedback {
        color: lightgray; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .good-feedback {
        color: green; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .bad-feedback {
        color: red; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .store-name {
        font-weight: bold;
        padding-top: 10px; }
      .sentiment-container .sentiment-day-card-container .sentiment-day-card .moment {
        font-size: 10px;
        font-weight: bold; }
  .sentiment-container .calendar-other-month {
    background: #f0f0f0;
    color: #f0f0f0; }
  .sentiment-container .calendar-no-stats {
    background: #D2D2D2 !important; }
  .sentiment-container .calendar-day-number-color {
    color: black; }
  .sentiment-container .background-zero-to-ten-0 {
    background: #EB6951; }
  .sentiment-container .background-zero-to-ten-1 {
    background: #E07150; }
  .sentiment-container .background-zero-to-ten-2 {
    background: #D67A50; }
  .sentiment-container .background-zero-to-ten-3 {
    background: #CB824F; }
  .sentiment-container .background-zero-to-ten-4 {
    background: #C18B4E; }
  .sentiment-container .background-zero-to-ten-5 {
    background: #B6934E; }
  .sentiment-container .background-zero-to-ten-6 {
    background: #AB9B4D; }
  .sentiment-container .background-zero-to-ten-7 {
    background: #A1A44C; }
  .sentiment-container .background-zero-to-ten-8 {
    background: #96AC4B; }
  .sentiment-container .background-zero-to-ten-9 {
    background: #8CB54B; }
  .sentiment-container .background-zero-to-ten-10 {
    background: #81BD4A; }
  .sentiment-container .background-only-neutrals {
    background-color: gray; }
  .sentiment-container .foreground-zero-to-ten-0 {
    color: #EB6951; }
  .sentiment-container .foreground-zero-to-ten-1 {
    color: #E07150; }
  .sentiment-container .foreground-zero-to-ten-2 {
    color: #D67A50; }
  .sentiment-container .foreground-zero-to-ten-3 {
    color: #CB824F; }
  .sentiment-container .foreground-zero-to-ten-4 {
    color: #C18B4E; }
  .sentiment-container .foreground-zero-to-ten-5 {
    color: #B6934E; }
  .sentiment-container .foreground-zero-to-ten-6 {
    color: #AB9B4D; }
  .sentiment-container .foreground-zero-to-ten-7 {
    color: #A1A44C; }
  .sentiment-container .foreground-zero-to-ten-8 {
    color: #96AC4B; }
  .sentiment-container .foreground-zero-to-ten-9 {
    color: #8CB54B; }
  .sentiment-container .foreground-zero-to-ten-10 {
    color: #81BD4A; }

.keywords-container {
  margin-top: 30px; }
  .keywords-container .keywords-table {
    width: 60%;
    margin-left: 20%; }
  .keywords-container .row-container {
    height: 100%; }
  .keywords-container .keyword-cell {
    font-size: large;
    align-self: center; }
  .keywords-container .keyword-delete {
    width: 100%;
    text-align: right; }

.question-stats-container .stats-card-header .stats-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0; }

.question-stats-container .stats-card-body {
  padding: 0.25rem; }

div.schedule label {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0.5rem;
  top: -1rem;
  color: #000; }

div.schedule input {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400; }

div.schedule label.active {
  top: 0; }

.topicTypeList ul {
  margin-top: 3rem;
  list-style: none; }

.fa-plus-square {
  padding-right: 10px; }

.gbp-container {
  padding-bottom: 5rem;
  /* first-config-page-container */
  /* spinner */
  /* error-page-container */
  color: #1a1a1a !important; }
  .gbp-container .botbit-table-actions > .dropdown .botbit-dropdown-content {
    min-width: 400px; }
  .gbp-container .header-actions-container.mobile {
    margin-bottom: 1rem; }
  .gbp-container .badge-info {
    background-color: #4b89ff !important; }
  .gbp-container .text-blue {
    color: #1eb8ff; }
  .gbp-container .text-black {
    color: #1a1a1a; }
  .gbp-container .first-config-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh; }
  .gbp-container .first-config-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px; }
  .gbp-container .first-config-page-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 90px;
    margin-bottom: 100px; }
  .gbp-container .first-config-page-body-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 330px; }
  .gbp-container .first-config-page-body-card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 130px;
    width: 300px; }
  .gbp-container .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .gbp-container .spinner {
    animation: spin 1s linear infinite; }
  .gbp-container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%; }
  .gbp-container .title-text {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .gbp-container .last-update {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 12px;
    gap: 12px; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }
  .gbp-container .loading-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    grid-gap: 50px;
    gap: 50px; }
  .gbp-container .loading-page-header {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .gbp-container .loading-page-body {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .gbp-container .error-page-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 90vh; }
  .gbp-container .error-page-background {
    background-color: transparent; }
  .gbp-container .error-page-background > * {
    opacity: 0.5;
    pointer-events: none; }
  .gbp-container .error-page-front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; }
  .gbp-container .error-page-header {
    display: flex; }
  .gbp-container .error-page-body {
    display: flex; }
  .gbp-container .botbit-table-actions > .dropdown .botbit-dropdown-content {
    min-width: 400px; }
  .gbp-container .header-actions-container.mobile {
    margin-bottom: 1rem; }
  .gbp-container .badge-info {
    background-color: #4b89ff !important; }
  .gbp-container .botbit-table .botbit-table-card-header {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    background-color: transparent;
    font-weight: bold; }
    .gbp-container .botbit-table .botbit-table-card-header .table-title {
      width: 100%; }
      .gbp-container .botbit-table .botbit-table-card-header .table-title > div {
        width: 100%; }
  .gbp-container .text-center.botbit-table-column > div {
    width: 100%; }
  .gbp-container .v2-wrapper.dropdown .dropdown-toggle .dropdown-toggle-label {
    width: 90%; }
  .gbp-container h1 {
    font-family: Open Sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    text-align: left; }
  .gbp-container p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left; }
  .gbp-container h2 {
    font-family: Open Sans;
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;
    text-align: left; }
  .gbp-container h3 {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: left; }
  .gbp-container h4 {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 58px;
    text-align: left; }
  .gbp-container h6 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #1a1a1a; }
  .gbp-container h5,
  .gbp-container .input-prefix {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left; }
  .gbp-container .botbit-table .score-container {
    background-color: transparent; }
  .gbp-container .botbit-table .botbit-table-card .element-content .row-container {
    display: flex;
    align-items: center; }
  .gbp-container .filterContainer {
    display: flex;
    margin-top: 1rem; }
  .gbp-container .metric-container {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 1rem;
    max-width: 154px; }
  .gbp-container .metric-display-container {
    justify-content: flex-start !important; }
  .gbp-container .outstanding-score-container {
    padding-left: 1rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    min-height: 121px;
    min-width: 251px;
    align-items: flex-start;
    border-radius: 4px;
    justify-content: center; }
  .gbp-container .caption-container {
    grid-gap: 0px;
    gap: 0px; }
  .gbp-container .outstanding-container {
    align-items: stretch;
    grid-gap: 0px !important;
    gap: 0px !important;
    padding: 10px 0px;
    max-width: 251px; }
  .gbp-container .caption-metrics-container {
    min-height: 121px;
    height: 121px;
    grid-gap: 0px;
    gap: 0px; }
  .gbp-container .outstanding-score-link {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: 700 !important; }
  .gbp-container .score-value {
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: left; }
  .gbp-container .score-value-porcentage {
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: left; }
  .gbp-container .score-value-container {
    padding: 0px 12px; }
  .gbp-container .score-table .score-value-porcentage {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px; }
  .gbp-container .score-table .score-value {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px; }
  .gbp-container .refresh-button {
    background-color: #2383e2;
    cursor: pointer;
    color: white;
    width: 32px;
    height: 32px;
    padding: 4px 8px 4px 8px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 3px;
    border: none; }
  .gbp-container .cursor-pointer {
    cursor: default !important; }
  .gbp-container .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
  .gbp-container .table-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 75%; }
  .gbp-container .fs-14 {
    font-size: 14px; }
  .gbp-container .cta-improve-impact-list {
    margin: 0px !important; }
  .gbp-container .cta-improve-impact-list__title {
    font-family: Open Sans !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    text-align: left !important;
    color: #1a1a1a !important;
    margin-bottom: 0px !important; }
  .gbp-container__insignia {
    border: 2px solid #dc8a1d;
    border-top: 0px; }
  .gbp-container__header-insignia {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffc9534a;
    color: #1a1a1a;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 2px solid #dc8a1d;
    border-bottom: 0px;
    grid-gap: 8px;
    gap: 8px; }
  .gbp-container__message-insignia {
    font-size: 14px;
    font-weight: 400; }
  .gbp-container__container-icon-insignia {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #13589d;
    border-radius: 3px;
    padding: 2px; }
  .gbp-container .all-updated-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #1a1a1a; }
  .gbp-container .cta-improve-impact-list {
    margin: 0;
    margin-right: 0.5rem; }
  .gbp-container .filters .filters-and-more-actions.mobile.mobile:not(.more-actions) .filters-actions-container .botbit-dropdown {
    width: 100%; }
  .gbp-container .filters .filters-first-line {
    margin-bottom: 10px;
    color: white; }
  .gbp-container .progress-bar-container {
    background-color: #afafaf;
    color: #ffffff; }
    .gbp-container .progress-bar-container .color-progress-bar {
      background-color: #00c000;
      color: #ffffff; }

.botbit-table__highlighted {
  background-color: #FFFCF5;
  border: 2px solid #dc8a1d;
  border-top: 0px; }

.botbit-table__header-insignia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFC9534A;
  color: #1A1A1A;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid #dc8a1d;
  border-bottom: 0px;
  grid-gap: 8px;
  gap: 8px; }

.botbit-table__message-insignia {
  font-size: 14px;
  font-weight: 400; }

.botbit-table__container-icon-insignia {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: #13589D;
  border-radius: 3px;
  padding: 2px; }

.botbit-table .btn-actionable-insignia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #2E4050;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 75%; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: none !important;
    width: 987px !important;
    margin: auto !important; }
  .card-container {
    padding-right: 0px; }
  .score-screen-container {
    grid-gap: 12px;
    gap: 12px; }
  .score-container-current {
    display: flex;
    background-color: rgba(255, 255, 255, 0.4);
    height: 110px;
    grid-gap: 12px;
    gap: 12px;
    padding-top: 2rem; }
  .score-info {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px; }
  .score-icon {
    flex-shrink: 0;
    -webkit-filter: blur(2px);
            filter: blur(2px); }
  .score-text {
    font-size: 18px;
    font-weight: 600;
    color: #9291a5;
    text-align: left;
    -webkit-filter: blur(2px);
            filter: blur(2px); }
  .progress-bar {
    width: 100%;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    background-color: #e5e7e9; }
  .even-row {
    background-color: #f8f8fa; }
  .odd-row {
    background-color: white; }
  .item-list {
    display: flex; }
  .item-item {
    display: flex;
    margin-bottom: 10px; }
  .item-icon {
    margin-right: 10px; }
  .item-description {
    display: flex;
    font-size: 16px;
    color: #333;
    padding-right: 10px;
    line-height: normal;
    font-weight: 300 !important; } }

.row-value {
  font-weight: bold;
  font-size: 18px;
  color: #1a1a1a; }

.row-description {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center; }

.row-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 12px;
  gap: 12px; }

.row-tbody {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px; }

.no-border {
  border: none !important; }

.card-list {
  display: flex; }
  .card-list.rows {
    flex-direction: row;
    grid-gap: 32px;
    gap: 32px; }
  .card-list.columns {
    flex-direction: column;
    grid-gap: 1%;
    gap: 1%; }

.card-list.columns .card-container {
  grid-gap: 8px;
  gap: 8px; }
  .card-list.columns .card-container .title-and-description {
    display: inline;
    grid-gap: 8px;
    gap: 8px; }
  .card-list.columns .card-container .card-title {
    padding-left: 1%;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    color: #000000;
    display: inline-block; }
  .card-list.columns .card-container .card-description {
    padding-left: 10%;
    font-size: 14px;
    line-height: 19.07px;
    text-align: left;
    font-weight: 500;
    color: #000000; }

.card-list.rows .card-container {
  width: 290px;
  height: 200px;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px; }
  .card-list.rows .card-container .title-and-description {
    display: inline;
    grid-gap: 8px;
    gap: 8px; }
  .card-list.rows .card-container .card-title {
    padding-left: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    color: #000000;
    display: inline-block; }
  .card-list.rows .card-container .card-description {
    padding-left: 0;
    font-size: 14px;
    line-height: 19.07px;
    color: #000000; }

.card-0 {
  padding: 0px;
  margin: 0px; }

.card-description-video {
  margin-bottom: 8px;
  padding-left: 0;
  font-size: 14px;
  line-height: 19.07px;
  font-weight: 500;
  color: #000000; }

.card-video {
  width: 100%;
  height: 100%;
  top: 35px;
  padding: 8px 0px 0px 0px;
  grid-gap: 0px;
  gap: 0px;
  padding: 20px;
  border-radius: 4px;
  justify-content: space-between;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16), 0px -1px 0px rgba(0, 0, 0, 0); }

.ray-spinner .red-spinner {
  border-color: #ff0000; }

.ray-spinner .blue-spinner {
  border-color: #13589D; }

.ray-spinner .grey-spinner {
  border-color: #808080; }

.ray-spinner .green-spinner {
  border-color: #008000; }

.ray-spinner .yellow-spinner {
  border-color: #ffff00; }

.ray-spinner .purple-spinner {
  border-color: #800080; }

.ray-spinner .orange-spinner {
  border-color: #ffa500; }

.ray-spinner .pink-spinner {
  border-color: #ffc0cb; }

.ray-spinner .teal-spinner {
  border-color: #008080; }

.ray-spinner .brown-spinner {
  border-color: #a52a2a; }

.ray-spinner .cyan-spinner {
  border-color: #00ffff; }

.ray-spinner .lime-spinner {
  border-color: #00ff00; }

.gbp-details-container .grid-container {
  display: grid;
  grid-template-columns: 758px 410px;
  grid-gap: 32px;
  gap: 32px;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem; }

.gbp-details-container .first-column {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px; }

.gbp-details-container .checkbox-input-example {
  background-color: #c8f3e0 !important;
  border: 1px solid #00974e !important;
  border-radius: 4px !important;
  width: 14px;
  height: 14px; }

.gbp-details-container .checkbox-label-example {
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a; }

.gbp-details-container .p-modal-body {
  padding: 0px !important;
  margin-bottom: 0px !important; }

.gbp-details-container .title-text {
  margin-bottom: 0.5rem; }

.gbp-details-container .title-img {
  margin-bottom: 0.5rem; }

.gbp-details-container .insignia-container-header {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px; }

.gbp-details-container .insignia-no-applied {
  background-color: #fcefde;
  border: 1px solid #ff9407;
  border-bottom: none;
  border-radius: 4px; }

.gbp-details-container .insignia-field .message-footer {
  border: 1px solid #ff9407 !important;
  border-top: none !important; }

.gbp-details-container .insignia-applied {
  background-color: #e5e7e9;
  border: 1px solid #e5e7e9;
  border-radius: 4px; }

.gbp-details-container .outstanding-container {
  padding: 12px 12px 12px 0;
  margin-bottom: 12px; }

.gbp-details-container .outstanding-score-container {
  align-items: center;
  flex-direction: column;
  width: 750px;
  padding-left: 12px;
  align-items: flex-start;
  padding: 13px;
  border-radius: 4px; }

.gbp-details-container .outstanding-score-link {
  color: black !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  font-size: 12px !important;
  font-weight: 700 !important; }

.gbp-details-container .first-column .row-3 {
  width: 757px;
  height: auto;
  min-height: 106px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #dcdcdc; }

.gbp-details-container .first-column .row-4 {
  width: 757px;
  height: auto;
  min-height: 103px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #d3d3d3; }

.gbp-details-container .first-column .row-5 {
  width: 757px;
  height: auto;
  min-height: 272px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #c9c9c9; }

.gbp-details-container .first-column .row-6 {
  width: 757px;
  height: auto;
  min-height: 112px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #bfbfbf; }

.gbp-details-container .first-column .row-7 {
  width: 757px;
  height: auto;
  min-height: 78px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #b3b3b3; }

.gbp-details-container .first-column .row-8 {
  width: 757px;
  height: auto;
  min-height: 83px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #a9a9a9; }

.gbp-details-container .second-column {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px; }

.gbp-details-container .second-column .row-9 {
  width: 100%;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px; }

.gbp-details-container .second-column .row-9 iframe {
  width: 100%;
  height: 100%;
  max-height: 250px;
  border: none; }

.gbp-details-container .second-column .row-10 {
  width: 420px;
  height: 389px;
  padding: 0px 0px 30px 0px;
  grid-gap: 12px;
  gap: 12px;
  border: 0px 0px 1px 0px;
  grid-gap: 12px;
  gap: 12px; }

.gbp-details-container .input-container {
  position: relative;
  border: none !important;
  width: 100%; }

.gbp-details-container .children-container .input-prefix {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: none;
  border-bottom: none;
  border-radius: 0 0 0 0;
  background-color: white;
  padding-top: 9px; }

.gbp-details-container .form-group .input-prefix {
  border: 1px solid #d9d9d9;
  border-radius: 0;
  background-color: white;
  border-right: none;
  padding: 9px; }

.gbp-details-container .form-group .custom-text-input {
  border-radius: 0;
  border: 1px solid #d9d9d9;
  border-left: none; }

.gbp-details-container .edit-button {
  position: absolute;
  background-color: transparent;
  color: #2383e2;
  top: 17%;
  right: 1%;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  border: none;
  cursor: pointer; }

.gbp-details-container .upload-button2 {
  position: absolute;
  background-color: transparent;
  color: #13589D;
  font-weight: 600 !important;
  top: 17%;
  right: 1%;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  border: none;
  cursor: pointer; }

.gbp-details-container .action-buttons {
  position: absolute;
  top: 17%;
  right: 1%;
  margin-right: 10px;
  display: flex;
  grid-gap: 12px;
  gap: 12px; }

.gbp-details-container .action-buttons-text-area {
  position: absolute;
  top: 85%;
  right: 2%;
  background-color: transparent;
  font-size: 14px;
  color: #2383e2;
  font-weight: normal;
  border: none;
  cursor: pointer; }

.gbp-details-container .cancel-button {
  background: none;
  border: none;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: right;
  color: #9291a5;
  cursor: pointer;
  padding: 4px 10px; }

.gbp-details-container .apply-button {
  background: none;
  border: none;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: right;
  color: #2383e2;
  cursor: pointer;
  padding: 4px 10px;
  text-decoration: underline;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

.gbp-details-container .button-back {
  display: flex;
  align-items: center;
  background-color: transparent;
  grid-gap: 4px;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 10px 10px 0px;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer; }

.gbp-details-container .button-back:hover {
  color: #1b6bba; }

.gbp-details-container .card-categories {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 12px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 0; }

.gbp-details-container .card-button-categories {
  background-color: #2383e2;
  color: white;
  font-size: 14px;
  font-weight: normal;
  margin-right: 10px;
  margin-top: 10px;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  width: 35%;
  cursor: pointer; }

.gbp-details-container .card-button-modal-1 {
  background-color: #2383e2;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  width: auto;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease; }

.gbp-details-container .card-button-modal-2 {
  background-color: white;
  color: #2383e2;
  border: 1px solid #2383e2 !important;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  width: auto;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease; }

.gbp-details-container .card-button-modal:disabled {
  background-color: #2383f5;
  color: white;
  cursor: not-allowed;
  opacity: 0.4; }

.gbp-details-container .no-highlighted-button-textarea {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px; }

.gbp-details-container .character-counter {
  font-size: 12px;
  font-weight: 600 !important;
  position: absolute;
  right: 675px; }
  .gbp-details-container .character-counter.error {
    color: #ff4444; }
  .gbp-details-container .character-counter.success {
    color: #9291a5; }

.gbp-details-container .justify-between {
  display: flex;
  justify-content: space-between; }

.gbp-details-container .modal-custom-size {
  width: 405px; }

.gbp-details-container .justify-around {
  display: flex;
  justify-content: space-around; }

.gbp-details-container .mt-40 {
  margin-top: 40px; }

.gbp-details-container .modal-categories {
  max-width: none !important;
  width: 600px !important;
  height: 617px !important;
  margin: auto !important; }
  .gbp-details-container .modal-categories .modal-footer {
    border-radius: 4px !important; }

.gbp-details-container .modal-insignia-spinner-title {
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32.68px;
  color: #1a1a1a;
  margin: 0px;
  width: 75%; }

.gbp-details-container .modal-insignia-container-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 40px; }

.gbp-details-container .modal-insignia-container-spinner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 40px 0px;
  grid-gap: 8px;
  gap: 8px; }

.gbp-details-container .modal-insignia-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px;
  grid-gap: 8px;
  gap: 8px;
  width: 100%; }

.gbp-details-container .modal-insignia-content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  width: 100%; }

.gbp-details-container .modal-insignia-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  width: 100%; }

.gbp-details-container .modal-group-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
  width: 100%; }

.gbp-details-container .modal-danger-message {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  color: #e12429;
  margin-bottom: 0px; }

.gbp-details-container .modal-danger-message-bold {
  font-weight: 700; }

.gbp-details-container .modal-insignia-bonus-row {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center; }

.gbp-details-container .modal-insignia {
  width: 775px !important;
  height: 400px !important;
  border-radius: 8px;
  padding: 24px; }

.gbp-details-container .modal-insignia-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32.68px;
  text-align: left;
  color: #1a1a1a;
  margin: 0px; }

.gbp-details-container .modal-insignia-subtitle {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 0px; }

.gbp-details-container .modal-insignia-text {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 0px; }

.gbp-details-container .modal-insignia-bonus {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #13589d;
  margin-bottom: 0px; }

.gbp-details-container .modal-insignia-button {
  background-color: #2383e2;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  width: 150px;
  cursor: pointer; }

.gbp-details-container .modal-insignia-button:hover {
  background-color: #2078d0; }

.gbp-details-container .modal-insignia-outline-button {
  border: 1px solid #2383e2;
  border-radius: 4px;
  padding: 4px 10px;
  font-weight: 600;
  color: #2383e2;
  width: 150px;
  cursor: pointer; }

.gbp-details-container .flex-row-modal {
  display: flex;
  flex-direction: row;
  width: 100%; }

.gbp-details-container .margin-0 {
  margin: 0px; }

.gbp-details-container .w-50 {
  width: 50%; }

.gbp-details-container .w-75 {
  width: 75%; }

.gbp-details-container .d-flex-column {
  display: flex;
  flex-direction: column; }

.gbp-details-container .d-flex-row {
  display: flex;
  flex-direction: row; }

.gbp-details-container .gap-2 {
  grid-gap: 8px;
  gap: 8px; }

.gbp-details-container .gap-4 {
  grid-gap: 16px;
  gap: 16px; }

.gbp-details-container .m-0 {
  margin: 0px; }

.gbp-details-container .mt-4 {
  margin-top: 8px; }

.gbp-details-container .modal-categories-header {
  border-bottom: none;
  padding-bottom: 0px; }

.gbp-details-container .modal-pt-0 {
  padding-top: 0px !important; }

.gbp-details-container .mb-0 {
  margin-bottom: 0px; }

.gbp-details-container .pt-0 {
  padding-top: 0px; }

.gbp-details-container .pb-0 {
  padding-bottom: 0px; }

.gbp-details-container .spacer {
  padding: 16px 0px 16px 0px; }

.gbp-details-container .chips-success {
  background-color: #c8f3e0;
  border: 1px solid #00974e !important;
  border-radius: 4px !important;
  padding: 1px 8px;
  font-weight: 600;
  color: #1a1a1a; }

.gbp-details-container .chips-secondary {
  background-color: #ffffff;
  border: 1px solid #e5e7e9 !important;
  border-radius: 4px !important;
  padding: 1px 8px;
  font-weight: 600;
  color: #1a1a1a; }

.gbp-details-container .bg-suggestions {
  background-color: #edf6ff;
  border-radius: 6px;
  padding: 16px;
  width: 100%; }

.gbp-details-container .bg-current {
  border-radius: 6px;
  padding: 16px; }

.gbp-details-container .font-bold-600 {
  font-weight: 600;
  color: #1a1a1a; }

.gbp-details-container .font-normal {
  font-weight: 400;
  color: #676a6c;
  margin-bottom: 0px !important; }

.gbp-details-container .mb-0-important {
  margin-bottom: 0px !important; }

.gbp-details-container .mt-0-important {
  margin-top: 0px !important; }

.gbp-details-container .subtitle-modal {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 12px; }

.gbp-details-container .modal-finish {
  width: 583px !important;
  height: 361px !important;
  border-radius: 8px !important; }

.gbp-details-container .modal-finish-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-gap: 8px;
  gap: 8px; }

.gbp-details-container .modal-finish-title {
  font-size: 16px;
  font-weight: 700;
  color: #000000; }

.gbp-details-container .modal-insignia-column {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center; }

.gbp-details-container .modal-finish-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #000000; }

.gbp-details-container .modal-finish-bonus {
  display: flex;
  border-radius: 24px;
  background-color: #13589d;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  padding: 0px 20px; }

.gbp-details-container .modal-insignia-container-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #13589d;
  border-radius: 4px; }

.gbp-details-container .verification-container {
  margin-top: 20px;
  width: 750px;
  height: 43px;
  padding: 8px 12px 8px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; }

.gbp-details-container .verification-left {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; }

.gbp-details-container .verification-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

.gbp-details-container .verification-right {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer; }

.gbp-details-container .tutorial-text {
  color: #1eb8ff;
  font-weight: 500;
  padding: 8px;
  padding: 8px; }

.gbp-details-container .card-categories {
  background-color: #ffffff; }

.gbp-details-container .actionable-editor-card {
  padding: 12px 12px 12px 0px; }

.gbp-details-container .save-and-return {
  background-color: #2383e2;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  width: 175px;
  justify-self: flex-end;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease; }

.gbp-details-container .save-and-return:hover {
  background-color: #1b6bba;
  color: white; }

.gbp-details-container .return-to-list-insignia {
  background-color: #2383e2;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  width: 175px; }

.gbp-details-container .return-to-list-insignia:hover {
  background-color: #1b6bba;
  color: white; }

.gbp-details-container .title-categories {
  align-self: flex-start;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.34px;
  color: #1a1a1a; }

.gbp-details-container .highlight-insignia {
  background-color: #fcefde;
  border: 1px solid #dc8a1d; }

.gbp-details-container .container-alert-insignia {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f7d5ab;
  border-radius: 4px;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 20px; }

.gbp-details-container .container-alert-insignia-success {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #edf6ff;
  border-radius: 4px;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 20px; }

.gbp-details-container .container-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 46px;
  background-color: #13589d;
  border-radius: 4px 0px 0px 4px; }

.gbp-details-container .mini-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 70%; }

.gbp-details-container .alert-title-insignia {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #1a1a1a; }

.gbp-details-container .button-alert-insignia {
  padding: 4px 16px;
  border-radius: 3px;
  font-weight: 600;
  background-color: #2e4050;
  border: 0px;
  color: white;
  margin-right: 8px;
  cursor: pointer; }

.gbp-details-container .button-alert-insignia:hover {
  background-color: #24323e; }

.gbp-details-container .circle-categories {
  width: 22px;
  height: 22px;
  background: #D9D9D9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.gbp-details-container .cross-categories-1 {
  border: 1px solid var(--Light-Text, #1F2D3D);
  width: 14px;
  height: 2px;
  transform: rotate(45deg);
  position: absolute;
  border-radius: 2px; }

.gbp-details-container .cross-categories-2 {
  border: 1px solid var(--Light-Text, #1F2D3D);
  width: 14px;
  height: 2px;
  transform: rotate(-45deg);
  position: absolute;
  border-radius: 2px; }

.gbp-details-container .additional-text {
  align-self: center;
  font-size: 12px;
  color: #1a1a1a;
  font-weight: 400;
  margin-top: 16px;
  width: 150px; }

.gbp-details-container .suggestions-and-advice-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%; }

.gbp-details-container .logo-container-gbp {
  width: 754px;
  min-height: 127px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #d9d9d9;
  border-radius: 4px 4px 0 0;
  overflow: hidden; }

.gbp-details-container .logo-content-gbp {
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  width: 100%;
  height: auto;
  grid-gap: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1A1A1A;
  background-color: #FFFFFF; }

.gbp-details-container .logo-recommendation-text {
  text-align: center;
  font-size: 12px;
  color: #1A1A1A;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .gbp-details-container .logo-recommendation-text .recommendation-label {
    font-weight: 700; }

.gbp-details-container .logo-preview-container {
  width: 100%;
  height: 127px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  background-color: #FFFFFF; }
  .gbp-details-container .logo-preview-container .company-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 0; }

.gbp-details-container .card-example-highlighted,
.gbp-details-container .input-example-highlighted,
.gbp-details-container .textarea-example-no-highlighted,
.gbp-details-container .logo-container-gbp,
.gbp-details-container .card-categories,
.gbp-details-container .input-container,
.gbp-details-container .logo-preview-container {
  border-radius: 0 !important; }

.gbp-details-container .logo-container-gbp {
  border-radius: 0 !important;
  border-bottom: none !important; }

.gbp-details-container .card-categories {
  border-bottom: none !important; }

.gbp-details-container .actionable-editor-card .children-container .textarea-example-no-highlighted.insignia-no-applied {
  border-radius: 4px 4px 0 0 !important;
  background-color: #fcefde !important;
  border: 1px solid #ff9407 !important;
  border-bottom: none !important; }

.tooltip__views {
  text-decoration: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  color: #9291A5;
  text-decoration-thickness: 1px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer; }

.tooltip__views:hover {
  color: #858496; }

.tooltip__container {
  position: relative;
  display: inline-block; }

.tooltip__actionable {
  position: absolute;
  top: calc(-100% - 40px);
  font-size: 12px !important;
  transform: translateX(-35%);
  padding: 10px;
  z-index: 1;
  background-color: #FFFFFF;
  min-width: 400px;
  border-radius: 4px;
  color: #1A1A1A;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  pointer-events: auto; }

.tooltip__normal-text {
  font-weight: 400;
  font-size: 12px; }

.tooltip__text-bold {
  font-weight: bold;
  font-size: 12px; }

.tooltip__text-highlighted {
  color: #2383E2;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px; }

.tooltip__text-highlighted:hover {
  color: #1d70c3; }

.error-message {
  color: #ff4444;
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
  display: block; }

.reputation-table-container {
  width: 920px;
  height: 534px; }
  .reputation-table-container .reputation-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .reputation-table-container .period-selector {
    margin-right: 1rem;
    padding-top: 1rem; }
  .reputation-table-container .pagination-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center; }
  .reputation-table-container .reputation-table-title {
    width: 274px;
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    padding-bottom: 10px; }
  .reputation-table-container .reputation-table-title-tooltip-container {
    margin-top: 6px; }
  .reputation-table-container .reputation-table-title-container {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .reputation-table-container .reputation-table-title-span {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16.34px;
    letter-spacing: 0;
    color: #3F6586; }
  .reputation-table-container .period-selector-label-container .period-selector-label {
    font-size: 12px;
    font-weight: 600;
    margin-right: 1rem;
    line-height: 16.34px;
    color: #1A1A1A; }
  .reputation-table-container .period-selector-label-container .period-selector-select {
    width: 153px;
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 18px; }
    .reputation-table-container .period-selector-label-container .period-selector-select::-ms-expand {
      display: none; }
    .reputation-table-container .period-selector-label-container .period-selector-select:focus {
      outline: none;
      border: 1px solid #E3E3E3; }
  .reputation-table-container .custom-headers {
    display: flex;
    padding: 10px;
    justify-content: space-between; }
    .reputation-table-container .custom-headers .header-cell {
      flex: 1 1;
      font-weight: bold; }
  .reputation-table-container .botbit-table-card-header {
    background-color: #F8F9FA; }
  .reputation-table-container .reputation-table-details-button {
    text-decoration: none;
    border: none;
    cursor: pointer !important;
    z-index: 2;
    background-color: transparent; }
  .reputation-table-container .reputation-table-various-spans-number {
    font-size: 12px;
    font-weight: 400;
    color: #1F2D3D;
    line-height: 16.34px;
    text-align: center;
    margin-top: 0px; }
  .reputation-table-container .reputation-table-various-spans-number-rating-maps {
    font-size: 12px;
    font-weight: 400;
    color: #1F2D3D;
    line-height: 16.34px;
    text-align: center;
    margin-top: 10px; }
  .reputation-table-container .reputation-table-various-spans-stars-number {
    font-size: 12px;
    font-weight: 400;
    color: #1F2D3D;
    line-height: 16.34px;
    text-align: center;
    margin-top: 10px; }
  .reputation-table-container .reputation-table-various-spans-number-bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 16.34px;
    color: #1F2D3D;
    text-align: center; }
  .reputation-table-container .reputation-table-various-spans-number-bold-bigger {
    font-weight: 700;
    font-size: 14px;
    line-height: 19.07px;
    color: #1F2D3D;
    text-align: center;
    margin-top: 8px; }
  .reputation-table-container .performance-indicator__container {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px; }
    .reputation-table-container .performance-indicator__container .performance-indicator__text {
      color: #000000; }
  .reputation-table-container .card-header.cursor-pointer:hover {
    background-color: transparent !important;
    cursor: default !important; }
  .reputation-table-container .botbit-table-card .card-header:hover::after {
    content: none !important; }
  .reputation-table-container .botbit-table {
    box-shadow: none !important; }
    .reputation-table-container .botbit-table .card {
      box-shadow: none !important;
      border-color: #E9ECEF !important; }
  .reputation-table-container .page-item.active .page-link {
    background-color: #868E96; }
    .reputation-table-container .page-item.active .page-link:focus {
      box-shadow: none; }
    .reputation-table-container .page-item.active .page-link:hover {
      background-color: #868E96; }
  .reputation-table-container .table-title {
    display: flex;
    align-items: flex-start; }
    .reputation-table-container .table-title .tooltip__container {
      margin-top: 24px;
      margin-left: 4px; }

.text-left {
  text-align: left !important; }


.local-seo-table-container {
  width: 914px;
  height: 445px;
  padding-top: 8px;
  padding-bottom: 12px; }
  .local-seo-table-container .local-seo-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 910px;
    height: 57px;
    grid-gap: 16px;
    gap: 16px;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding-right: 12px; }
  .local-seo-table-container .local-seo-table-title {
    width: 193px;
    height: 25px;
    font-family: Open Sans;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #353C48; }
  .local-seo-table-container .period-selector {
    grid-gap: 4px;
    gap: 4px; }
  .local-seo-table-container .pagination-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center; }
  .local-seo-table-container .local-seo-table-title-container {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
    overflow-wrap: break-word; }
  .local-seo-table-container .local-seo-table-title-span {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0;
    color: #13589D;
    display: inline-block;
    grid-gap: 4px;
    gap: 4px;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word; }
  .local-seo-table-container .period-selector-label-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 264;
    height: 40;
    grid-gap: 4px;
    gap: 4px; }
    .local-seo-table-container .period-selector-label-container .period-selector-label {
      margin-bottom: 0px;
      width: 107;
      height: 16;
      color: #1A1A1A;
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0%; }
    .local-seo-table-container .period-selector-label-container .period-selector-select {
      font-family: 'Open Sans';
      width: 153px;
      height: 40px;
      border-radius: 4px;
      padding: 10px 16px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      background-color: #FFFFFF;
      border: 1px solid #E3E3E3;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 18px;
      grid-gap: 2px;
      gap: 2px; }
      .local-seo-table-container .period-selector-label-container .period-selector-select::-ms-expand {
        display: none; }
      .local-seo-table-container .period-selector-label-container .period-selector-select:focus {
        outline: none;
        border: 1px solid #E3E3E3; }
  .local-seo-table-container .custom-headers {
    display: flex;
    padding: 10px;
    justify-content: space-between; }
    .local-seo-table-container .custom-headers .header-cell {
      flex: 1 1;
      font-weight: bold; }
  .local-seo-table-container .botbit-table-card-header {
    background-color: #F8F9FA;
    width: 910;
    height: 58;
    grid-gap: 44px;
    gap: 44px;
    padding: 12px; }
    .local-seo-table-container .botbit-table-card-header .flex-1 {
      display: flex;
      grid-column-gap: 5 px;
      -webkit-column-gap: 5 px;
              column-gap: 5 px; }
  .local-seo-table-container .local-seo-table-details-button {
    border: none;
    cursor: pointer !important;
    z-index: 2;
    background-color: transparent; }
  .local-seo-table-container .local-seo-table-various-spans-number {
    display: flex;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #494949; }
  .local-seo-table-container .local-seo-table-various-spans-number-bold {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    white-space: pre-line; }
  .local-seo-table-container .local-seo-table-various-spans-number-bold-bigger {
    font-weight: 700;
    font-size: 12px;
    line-height: 19.07px;
    color: #1F2D3D;
    text-align: center;
    margin-top: 8px; }
  .local-seo-table-container .performance-indicator__container {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px; }
    .local-seo-table-container .performance-indicator__container .performance-indicator__text {
      color: #000000; }
  .local-seo-table-container .card-header.cursor-pointer:hover {
    background-color: transparent !important;
    cursor: default !important; }
  .local-seo-table-container .botbit-table-card .card-header {
    padding: 12px; }
    .local-seo-table-container .botbit-table-card .card-header:hover::after {
      content: none !important; }
  .local-seo-table-container .botbit-table {
    box-shadow: none !important; }
    .local-seo-table-container .botbit-table .card {
      box-shadow: none !important;
      border-color: #E9ECEF !important; }
  .local-seo-table-container .page-item.active .page-link {
    background-color: #868E96; }
    .local-seo-table-container .page-item.active .page-link:focus {
      box-shadow: none; }
    .local-seo-table-container .page-item.active .page-link:hover {
      background-color: #868E96; }
  .local-seo-table-container .table-title {
    display: flex;
    align-items: flex-start;
    white-space: normal;
    overflow-wrap: break-word; }
    .local-seo-table-container .table-title .tooltip__container {
      margin-top: 24px;
      margin-left: 4px; }
  .local-seo-table-container .local-seo-table-title-span-store-name {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #13589D; }
  .local-seo-table-container .text-truncate {
    white-space: normal;
    overflow-wrap: break-word; }

.local-seo-chart-wrapper {
  background: white;
  padding: 20px 20px 20px 0px;
  width: 961px;
  height: 516px; }
  .local-seo-chart-wrapper .local-seo-chart-header-title {
    margin: 0;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 42px;
    line-height: 100%;
    letter-spacing: 0;
    color: #000000; }

.close-modal-button-local-seo-chart-modal {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background-color: #000000 !important;
  color: #FFFFFF !important;
  border: none;
  width: 72px;
  height: 32px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0;
  color: #FFFFFF; }
  .close-modal-button-local-seo-chart-modal span {
    width: 40px;
    height: 16px; }

.modal-body {
  overflow-x: hidden; }

.modal-footer {
  border: none; }

.local-seo-chart-modal-container {
  background: white;
  padding: 20px;
  width: 961px;
  height: 416px; }
  .local-seo-chart-modal-container .local-seo-chart-modal-header-title {
    margin-top: 40px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 42px;
    line-height: 100%;
    letter-spacing: 0;
    color: #000000; }

.local-seo-chart-header-container-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }
  .local-seo-chart-header-container-modal .local-seo-chart-container {
    margin: 20px 0;
    min-height: 400px; }
  .local-seo-chart-header-container-modal .local-seo-icon-and-subtitle {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px; }
  .local-seo-chart-header-container-modal .google-maps-subtitle-modal {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.34px;
    color: #353C48; }

.local-seo-store-view-container {
  background: white;
  padding-top: 20px;
  width: 961px;
  height: 616px; }

.local-seo-chart-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }

.local-seo-chart-container {
  margin: 20px 0;
  min-height: 400px; }

.local-seo-icon-and-subtitle {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px; }

.google-maps-subtitle {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 16.34px;
  color: #353C48; }

.google-maps-subtitle-modal {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.34px;
  color: #353C48; }

.local-seo-store-view-and-period-selector-and-store-selector-container {
  display: grid;
  width: 961px;
  height: 616px;
  grid-template-columns: repeat(20, 1fr);
  grid-gap: 5px;
  gap: 5px; }
  .local-seo-store-view-and-period-selector-and-store-selector-container .location-selector-label-container {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px; }
    .local-seo-store-view-and-period-selector-and-store-selector-container .location-selector-label-container .location-selector-label {
      font-size: 12px;
      font-weight: 600;
      margin-right: 1rem;
      line-height: 16.34px;
      color: #1A1A1A;
      margin-bottom: 1rem; }
    .local-seo-store-view-and-period-selector-and-store-selector-container .location-selector-label-container .location-selector-select {
      width: 153px;
      height: 40px;
      border-radius: 4px;
      padding: 10px 16px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      margin-right: 1rem;
      margin-bottom: 1rem;
      background-color: #FFFFFF;
      border: 1px solid #E3E3E3;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 18px; }
      .local-seo-store-view-and-period-selector-and-store-selector-container .location-selector-label-container .location-selector-select::-ms-expand {
        display: none; }
      .local-seo-store-view-and-period-selector-and-store-selector-container .location-selector-label-container .location-selector-select:focus {
        outline: none;
        border: 1px solid #E3E3E3; }
  .local-seo-store-view-and-period-selector-and-store-selector-container .local-seo-store-view-container {
    grid-column: 1 / 20;
    grid-row: 1; }
  .local-seo-store-view-and-period-selector-and-store-selector-container .location-selector {
    grid-column: 9 / 15;
    grid-row: 1;
    justify-self: end;
    padding-top: 3.5rem;
    z-index: 1; }
  .local-seo-store-view-and-period-selector-and-store-selector-container .period-selector {
    grid-column: 14 / 20;
    grid-row: 1;
    justify-self: end;
    padding-top: 3.5rem;
    z-index: 1; }

.local-seo-store-view-and-period-selector-container {
  display: grid;
  width: 961px;
  height: 500px;
  grid-template-columns: repeat(20, 1fr);
  grid-gap: 15px;
  gap: 15px;
  padding-top: 30px; }
  .local-seo-store-view-and-period-selector-container .local-seo-store-view-container {
    grid-column: 1 / 20;
    grid-row: 1; }
  .local-seo-store-view-and-period-selector-container .period-selector {
    grid-column: 14 / 20;
    grid-row: 1;
    justify-self: end;
    padding-top: 20px;
    z-index: 1; }

.dashboard-v2-table-and-period-selector-container {
  display: grid;
  width: 100%;
  max-width: 920px;
  grid-template-columns: repeat(20, 1fr);
  grid-gap: 15px;
  gap: 15px;
  padding-top: 1rem; }
  .dashboard-v2-table-and-period-selector-container .dashboard-v2-table-container {
    grid-column: 1 / 20;
    grid-row: 1; }
  .dashboard-v2-table-and-period-selector-container .period-selector {
    grid-column: 14 / 21;
    grid-row: 1;
    padding-top: 10px;
    margin-top: 5px;
    z-index: 1; }

.period-selector-label-container {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px; }
  .period-selector-label-container .period-selector-label {
    font-size: 12px;
    font-weight: 600;
    margin-right: 1rem;
    line-height: 16.34px;
    color: #1A1A1A;
    margin-bottom: 1rem; }
  .period-selector-label-container .period-selector-select {
    width: 153px;
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 18px; }
    .period-selector-label-container .period-selector-select::-ms-expand {
      display: none; }
    .period-selector-label-container .period-selector-select:focus {
      outline: none;
      border: 1px solid #E3E3E3; }

.dashboard-v2-grid-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  min-height: 100vh;
  background-color: white; }
  .dashboard-v2-grid-container .dashboard-v2-titles-and-tables-and-charts-container {
    width: 100%; }
  .dashboard-v2-grid-container .dashboard-v2-header-title-and-kpis-container h2 {
    font-family: 'Open Sans';
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;
    color: #1A1A1A; }
  .dashboard-v2-grid-container .dashboard-v2-header-title-and-kpis-container h5 {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    color: #1A1A1A; }
  .dashboard-v2-grid-container .dashboard-v2-header-title-and-kpis-container .dashboard-v2-header-title-container {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px; }
  .dashboard-v2-grid-container .dashboard-v2-actionables-container {
    background-color: white;
    border-radius: 4px;
    max-width: 375px; }
    .dashboard-v2-grid-container .dashboard-v2-actionables-container .dashboard-v2-actionables-container-title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.78px;
      color: #1A1A1A; }
  .dashboard-v2-grid-container .dashboard-v2-local-seo-table-and-title-container {
    padding-top: 0.75rem; }
  .dashboard-v2-grid-container .dashboard-v2-reputation-table-and-title-container {
    padding-top: 4rem; }
  .dashboard-v2-grid-container .dashboard-v2-reputation-store-view-selector {
    margin-top: -0.5rem;
    white-space: nowrap;
    z-index: 1; }
  .dashboard-v2-grid-container .dashboard-v2-reputation-table-period-selector-container {
    margin-top: -0.5rem;
    white-space: nowrap;
    z-index: 1; }
  .dashboard-v2-grid-container .dashboard-v2-global-metrics-container {
    background-color: #fff;
    width: 920px; }
    .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .card__score {
      color: #1A1A1A; }
    .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-header {
      background-color: #DEE3E8;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.75rem;
      margin-top: 1.75rem; }
      .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-header h2 {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        color: #1a1a1a;
        margin: 0; }
    .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-content-container {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 1rem;
      gap: 1rem;
      width: 920px; }
      .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-content-container .cards-row .card__metrics:nth-child(1) {
        width: 183px; }
      .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-content-container .cards-row .card__metrics:nth-child(2) {
        width: 170px; }
      .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-content-container .cards-row .card__metrics:nth-child(3) {
        width: 212px; }
      .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-content-container .cards-row .card__metrics:nth-child(4) {
        width: 185px; }
      .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-content-container .cards-row .card__metrics:nth-child(5) {
        width: 170px; }
    .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-period-selector-container {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
      margin-top: 0.4rem;
      order: 2; }
    .dashboard-v2-grid-container .dashboard-v2-global-metrics-container .dashboard-v2-global-metrics-store-selector-container {
      margin-top: 0.4rem;
      margin-left: 9rem;
      order: 1; }
  .dashboard-v2-grid-container .data-group-tabs__container {
    background-color: #fff; }
  .dashboard-v2-grid-container .data-group-tabs__tab {
    background-color: #fff; }
  .dashboard-v2-grid-container .data-group-tabs__content {
    background-color: #fff; }

.period-selector {
  grid-column: 14 / 21;
  grid-row: 2/3;
  justify-self: end;
  padding-top: 10px;
  z-index: 1; }

.period-selector-label-container {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px; }
  .period-selector-label-container .period-selector-label {
    font-size: 12px;
    font-weight: 600;
    margin-right: 1rem;
    line-height: 16.34px;
    color: #1A1A1A;
    margin-bottom: 1rem; }
  .period-selector-label-container .period-selector-select {
    width: 153px;
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 18px; }
    .period-selector-label-container .period-selector-select::-ms-expand {
      display: none; }
    .period-selector-label-container .period-selector-select:focus {
      outline: none;
      border: 1px solid #E3E3E3; }

.location-selector {
  grid-column: 14 / 21;
  grid-row: 2/3;
  justify-self: end;
  padding-top: 10px;
  z-index: 1;
  margin-right: 1rem; }

.location-selector-label-container {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px; }
  .location-selector-label-container .location-selector-label {
    font-size: 12px;
    font-weight: 600;
    margin-right: 1rem;
    line-height: 16.34px;
    color: #1A1A1A;
    margin-bottom: 1rem; }
  .location-selector-label-container .location-selector-select {
    width: 153px;
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 18px; }
    .location-selector-label-container .location-selector-select::-ms-expand {
      display: none; }
    .location-selector-label-container .location-selector-select:focus {
      outline: none;
      border: 1px solid #E3E3E3; }


.actionable-side-bar-container {
  display: flex;
  flex-direction: column;
  width: 375px;
  grid-gap: 32px;
  gap: 32px;
  border-radius: 8px;
  border-width: 1px;
  padding-top: 18px;
  padding-right: 12px;
  padding-bottom: 18px;
  padding-left: 12px;
  background-color: #F3F6FB;
  border: 1px solid #E9E9E9; }
  .actionable-side-bar-container .section-header__title__h4 {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #1A1A1A;
    margin-top: 0px; }
  .actionable-side-bar-container .score-and-actionable-container {
    width: 351px;
    height: 277px;
    display: flex;
    flex-direction: column;
    grid-gap: 44px;
    gap: 44px; }
    .actionable-side-bar-container .score-and-actionable-container .title-and-board-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 351px;
      height: 204px;
      grid-gap: 24px;
      gap: 24px; }
      .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .side-bar-title {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-align: center;
        color: #1A1A1A; }
      .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container {
        display: flex;
        flex-direction: column;
        grid-gap: 24px;
        gap: 24px; }
        .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container {
          display: flex;
          align-items: center;
          width: 290px;
          height: 116px;
          grid-gap: 41px;
          gap: 41px; }
          .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .chart-container {
            width: 116px;
            height: 116px; }
          .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container {
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 133px;
            height: 74px;
            background-color: #F3F6FB; }
            .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container .score-above-p {
              margin-bottom: 0px;
              font-family: Open Sans;
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0%;
              color: #1A1A1A; }
            .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container .score-span {
              font-family: Open Sans;
              font-weight: 700;
              font-size: 36px;
              line-height: 100%;
              letter-spacing: 0%;
              vertical-align: middle;
              color: #1A1A1A; }
            .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container .score-below-p {
              margin-bottom: 0px;
              font-family: Open Sans;
              font-weight: 400;
              font-size: 12px;
              line-height: 100%;
              letter-spacing: 0%;
              text-align: center;
              color: #1A1A1A; }
        .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .help-message {
          margin: 0;
          font-family: Open Sans;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0%;
          color: #1F2D3D; }
          .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .help-message .help-message-span {
            font-family: Open Sans;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0%;
            text-decoration: underline;
            -webkit-text-decoration-style: solid;
                    text-decoration-style: solid;
            text-decoration-offset: 0%;
            text-decoration-thickness: 5.5%; }
    .actionable-side-bar-container .score-and-actionable-container .todo-actionable-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px 0 30px;
      width: 351px;
      height: 29px;
      grid-gap: 4px;
      gap: 4px; }
      .actionable-side-bar-container .score-and-actionable-container .todo-actionable-container .text-and-tooltip-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 297px;
        height: 19px;
        border-radius: 12px;
        padding-right: 8px;
        padding-left: 8px; }
        .actionable-side-bar-container .score-and-actionable-container .todo-actionable-container .text-and-tooltip-container .todo-actionable-text {
          margin-bottom: 0px;
          font-family: Open Sans;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0%;
          text-align: center;
          color: #1A1A1A; }
  .actionable-side-bar-container .actionable-list-container {
    width: 334px;
    grid-gap: 32px;
    gap: 32px; }
    .actionable-side-bar-container .actionable-list-container .empty-actionables-message-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 334px;
      height: 98px;
      grid-gap: 16px;
      gap: 16px;
      color: #1A1A1A;
      margin-top: 140px;
      margin-bottom: 140px; }
      .actionable-side-bar-container .actionable-list-container .empty-actionables-message-container .empty-actionables-message-span {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center; }
      .actionable-side-bar-container .actionable-list-container .empty-actionables-message-container .empty-actionables-message {
        font-family: Open Sans;
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center; }
    .actionable-side-bar-container .actionable-list-container .reputation-list-container .cta-list .cta-item .cta-item__icon-container .cta-item__percentage {
      font-family: Open Sans;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0%;
      color: #AA2B64; }
    .actionable-side-bar-container .actionable-list-container .loading-spinner-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 334px;
      height: 98px;
      grid-gap: 16px;
      gap: 16px;
      margin-top: 140px;
      margin-bottom: 140px; }
      .actionable-side-bar-container .actionable-list-container .loading-spinner-container .loading-actionables-message {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1A1A1A; }
  .actionable-side-bar-container .cta-item {
    display: flex;
    width: 334px;
    /*height: 66px;*/
    justify-content: space-between;
    border-radius: 4px;
    padding: 12px;
    max-width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 6px 0px #0000000D;
    justify-content: start; }
    .actionable-side-bar-container .cta-item .cta-item__details {
      max-width: 177px;
      /*height: 42px;*/
      grid-gap: 3px;
      gap: 3px;
      margin-right: 45px;
      white-space: normal;
      overflow-wrap: break-word; }
      .actionable-side-bar-container .cta-item .cta-item__details .cta-item__title {
        display: block;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-width: 100%;
        font-family: Open Sans;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1A1A1A; }
      .actionable-side-bar-container .cta-item .cta-item__details .cta-item__subtitle {
        font-family: Open Sans;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1A1A1A; }
    .actionable-side-bar-container .cta-item .cta-item__icon-container {
      display: flex;
      justify-content: end;
      grid-gap: 10px;
      gap: 10px; }
      .actionable-side-bar-container .cta-item .cta-item__icon-container .cta-item__percentage {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1F858B;
        width: 48px;
        text-align: right; }

ul.horizontal {
  align-content: center;
  align-items: center;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-size: 1em; }

li.horizontal {
  background: #2f4050;
  display: flex;
  flex-grow: 1;
  height: .3em;
  position: relative; }

li.horizontal::before {
  color: white;
  background: #2f4050;
  border-radius: 50%;
  counter-increment: stepCount;
  content: counter(stepCount);
  left: -2em;
  line-height: 2em;
  position: absolute;
  text-align: center;
  top: -.85em;
  width: 2em;
  height: 2em;
  font-size: 1.5em; }

li.horizontal.active {
  background-color: lightblue; }

li.horizontal.active ~ li {
  background-color: lightblue; }

li.horizontal.active ~ li::before {
  background-color: lightblue; }

li.horizontal:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  /* Shorthand: flex: 0 1 0; */ }

/****** VERTICAL******* */
ul.vertical {
  align-content: flex-start;
  align-items: flex-start;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  flex-direction: column; }

li.vertical {
  display: flex;
  background: white;
  flex-grow: 1;
  height: 6em;
  position: relative;
  border-left: .3em #2f4050 solid;
  padding-left: 1.5em;
  font-size: 1em; }

li.vertical::after {
  color: white;
  background: #2f4050;
  border-radius: 50%;
  counter-increment: stepCount;
  content: counter(stepCount);
  line-height: 2em;
  position: absolute;
  text-align: center;
  left: -1.2em;
  top: 0em;
  width: 2em;
  height: 2em;
  font-size: 1.5em; }

li.vertical.active {
  border-left: .3em lightblue solid; }

li.vertical.active ~ li {
  border-left: .3em lightblue solid; }

li.vertical.active ~ li::after {
  background-color: lightblue; }

li.vertical:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  /* Shorthand: flex: 0 1 0; */ }

li.vertical span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
  background-color: white; }

.on-boarding-container {
  background-color: #f3f3f4; }

.input-range__slider {
  /*background: #20b426;
  border: 1px solid #20b426;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
  */
  background: url(/static/img/sprite-skin-modern.png) repeat-x;
  width: 11px;
  height: 18px;
  top: 31px;
  background-position: 0 -120px;
  display: block;
  cursor: default;
  margin-left: -0.5rem;
  margin-top: -0.65rem; }

.input-range__slider:active {
  transform: scale(1.3); }

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  font-size: 0.8em;
  left: 0; }

.input-range__label--max {
  font-size: 0.8em;
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -2rem;
  background: #20b426;
  padding: 1px 2px 1px 12px;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.333;
  text-shadow: none;
  border-radius: 4px;
  display: block;
  cursor: default;
  white-space: nowrap;
  font-weight: 400;
  border: 3px solid transparent;
  content: "";
  border-top-color: #20b426; }

.input-range__label-container {
  left: -50%;
  position: relative; }

.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }

.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #20b426; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

@charset "UTF-8";
#give-nps {
  background-color: #EEEEEE;
  border-top-color: #e3e3e3;
  border-bottom-color: #e3e3e3;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column; }
  #give-nps .nps-header {
    background-color: #5F5F5F;
    border-color: #3D4351;
    color: #3D4351;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 216px; }
    #give-nps .nps-header > div {
      border-radius: 50%;
      margin-top: 20px;
      margin-bottom: 20px; }
  @media (max-width: 992px) {
    #give-nps .nps-body {
      padding: 20px; } }
  #give-nps .nps-body h2 {
    font-size: 22px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
    color: #111111;
    text-align: center;
    margin-bottom: 20px; }
  #give-nps .nps-body p {
    font-size: 19px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 30px;
    color: #111111;
    text-align: center; }
  #give-nps .give-nps-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 15px; }
    @media (max-width: 506px) {
      #give-nps .give-nps-buttons {
        margin: 0 auto; } }
    #give-nps .give-nps-buttons button {
      text-align: center;
      padding: 1px 6px;
      width: 42px;
      height: 40px;
      margin-bottom: 10px;
      border-radius: 6px;
      background: #ffffff;
      border-color: #cccccc #cccccc #cccccc;
      border-style: solid;
      border-width: 1px 1px 3px;
      font-size: 18px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: bold;
      margin-right: 4px;
      cursor: pointer; }
    #give-nps .give-nps-buttons .disclaimers-container {
      width: 506px; }
      @media (max-width: 506px) {
        #give-nps .give-nps-buttons .disclaimers-container {
          width: 100%; } }
      #give-nps .give-nps-buttons .disclaimers-container .left-disclaimer {
        font-size: 17px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #fa5858;
        display: inline-block;
        width: 50%;
        text-align: left; }
      #give-nps .give-nps-buttons .disclaimers-container .right-disclaimer {
        font-size: 17px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #04b486;
        display: inline-block;
        width: 50%;
        text-align: right; }
  #give-nps .give-nps-gating {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 15px; }
    @media (max-width: 506px) {
      #give-nps .give-nps-gating {
        width: 320px !important;
        margin: 0 auto; } }
    #give-nps .give-nps-gating .gating-progress-bar {
      width: 70%; }
  #give-nps .powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px; }
    #give-nps .powered-by span {
      margin-right: 1em; }

.star {
  margin: 0 5px; }
  .star::before {
    color: #ffb300;
    content: '\2605'; }
  .star:focus::before, .star:hover::before {
    color: #ffb300;
    content: '\2605'; }
  .star:focus ~ .star::before,
  .star:hover ~ .star::before {
    color: gray;
    content: '\2605'; }

.container:not(:focus-within):not(:hover) .star::before {
  color: gray;
  content: '\2605'; }

.score-selected {
  color: #ffb300;
  font-size: xx-large;
  margin-right: 10px; }

.score-unselected {
  color: gray;
  font-size: xx-large;
  margin-right: 10px; }

.set-password {
  min-width: 100%;
  min-height: 100vh;
  background-color: #F3F3F4; }
  @media screen and (min-width: 768px) {
    .set-password {
      display: flex;
      justify-content: center;
      align-items: center; } }
  .set-password .submit-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 0; }
  .set-password .md-form {
    margin-bottom: 0; }
  .set-password .error-msg {
    color: var(--danger);
    font-size: 0.8rem; }
  .set-password .card {
    width: 100%; }
  .set-password_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 768px) {
      .set-password_container {
        min-width: 350px; } }
    @media screen and (max-width: 768px) {
      .set-password_container {
        margin: auto;
        width: 85%;
        padding: 1rem 0; } }
    .set-password_container .container-header {
      padding-top: 1rem; }
      .set-password_container .container-header .first-line {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; }
        .set-password_container .container-header .first-line h2 {
          font-size: 1.2rem;
          color: #000;
          font-weight: bold;
          margin: 0;
          padding: 0;
          margin-right: 1rem; }
        .set-password_container .container-header .first-line img {
          width: 40px;
          height: 40px; }
        @media screen and (max-width: 768px) {
          .set-password_container .container-header .first-line h2 {
            font-size: 1.1rem; }
          .set-password_container .container-header .first-line img {
            display: none; } }
      .set-password_container .container-header .second-line {
        text-align: center; }
  .set-password .image-and-upload-container {
    display: flex;
    justify-content: center; }
  .set-password #imageGalleryContainer {
    position: relative; }
    .set-password #imageGalleryContainer .change-logo-btn {
      position: absolute;
      right: -19px;
      bottom: -10px; }
  .set-password .form-fields-container {
    margin: 0;
    padding: 0; }
  .set-password #add-image {
    margin-top: 75px;
    margin-left: -15px;
    position: relative;
    border: none;
    background-color: #5DC3C6;
    border-radius: 5px;
    transition: 0.25s; }
  @media (min-width: 992px) {
    .set-password .image-cropper {
      width: 450px !important;
      height: 450px; } }
  @media (max-width: 992px) {
    .set-password .image-cropper {
      width: 100% !important;
      height: 70vh; } }

.left-comment {
  background-color: #EEEEEE;
  border-top-color: #e3e3e3;
  border-bottom-color: #e3e3e3;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column; }
  .left-comment_header {
    background-color: #5F5F5F;
    border-color: #3D4351;
    color: #3D4351;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem; }
    .left-comment_header > div {
      border-radius: 50%;
      margin-top: 20px;
      margin-bottom: 20px; }
  .left-comment .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem auto;
    text-align: center;
    color: #000;
    padding: 0 1rem; }
    .left-comment .info-container h2.title {
      font-weight: bold; }
      @media (max-width: 768px) {
        .left-comment .info-container h2.title {
          margin-top: 0; } }
    @media (min-width: 768px) {
      .left-comment .info-container {
        font-size: 1rem;
        width: 65%; } }
    .left-comment .info-container .actions-container {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .left-comment .info-container .private-comment {
      transition: 0.5s; }
      .left-comment .info-container .private-comment.hide {
        display: none;
        height: 0; }
    .left-comment .info-container .private-comment-input {
      background: #fff;
      margin: 6px;
      width: calc(100% - 12px);
      height: 120px;
      resize: none; }
    .left-comment .info-container .custom-question-text-input {
      background: #fff;
      width: 100%;
      height: 120px;
      resize: none;
      margin-top: -1.5rem;
      padding: 5px;
      text-align: left; }
    .left-comment .info-container .custom-question-radio-input {
      margin-left: -1.25rem;
      text-align: left; }
    .left-comment .info-container .custom-question-label {
      text-align: left;
      margin-top: 1rem; }
    .left-comment .info-container .review-btn {
      text-transform: none;
      border-radius: 3px;
      font-weight: 600;
      box-shadow: none;
      font-size: 14px;
      align-self: center;
      width: 250px; }
      @media (max-width: 768px) {
        .left-comment .info-container .review-btn {
          font-size: 13px; } }
    .left-comment .info-container .google-btn {
      background-color: #4285f4 !important; }
      .left-comment .info-container .google-btn img {
        height: 1.4em; }
  .left-comment .powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px; }
    .left-comment .powered-by span {
      margin-right: 1em; }
  .left-comment .stars span {
    font-size: 2rem;
    margin: 0 0.5rem;
    color: gray;
    cursor: pointer; }
    @media (max-width: 768px) {
      .left-comment .stars span {
        font-size: 1.5rem; } }
    .left-comment .stars span.selected {
      color: #FFC107; }

.fil5 {
  fill: none; }

.fil2 {
  fill: #EBE1D9; }

.fil3 {
  fill: white; }

.fil1 {
  fill: #383232;
  fill-rule: nonzero; }

.fil0 {
  fill: url(#id3); }

.fil4 {
  fill: url(#id4);
  fill-rule: nonzero; }

#give-nps {
  font-family: "Roboto"; }
  #give-nps .header {
    background: #cbb9aa;
    background: linear-gradient(90deg, #cbb9aa 0%, #e5dcd4 41%, white 100%);
    border-top: #ccb9aa 4px solid;
    border-bottom: #ccb9aa 4px solid;
    max-height: 8rem; }
  #give-nps .header .title {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    margin: 30px;
    text-align: center; }
  #give-nps svg {
    overflow: visible;
    min-width: 9rem;
    max-width: 10rem; }
  #give-nps button.review-btn {
    padding-left: 1rem !important;
    background-color: #a08f7d;
    text-transform: capitalize;
    font-weight: bold;
    min-width: 350px;
    text-align: left;
    font-size: large;
    color: white;
    white-space: nowrap; }
    #give-nps button.review-btn img, #give-nps button.review-btn i {
      max-width: 2rem; }
  #give-nps .bold.background-gray {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #292726;
    font-size: large;
    text-align: center;
    background-color: #EBE1D9;
    padding: 5px 10px;
    margin-top: 2rem; }
  #give-nps .main-content {
    text-align: center;
    max-width: 850px;
    margin: auto; }
  #give-nps .content {
    font-size: large;
    padding-top: 1rem;
    text-align: justify; }
  #give-nps .give-nps-buttons a.star {
    margin: 0 5px;
    font-size: 36pt;
    color: gray; }
  #give-nps .give-nps-buttons a.star:hover {
    color: #F9BB04; }
  #give-nps #private-comment-input {
    background-color: #EBE1D9;
    border: none;
    width: 80%;
    font-size: large;
    height: 30vh; }

