.keywords-container {

	margin-top: 30px;

	.keywords-table {
		width: 60%;
		margin-left: 20%;
	}

	.row-container {
		height: 100%;
	}

	.keyword-cell {
		font-size: large;
		align-self: center;
	}

	.keyword-delete {
		width: 100%;
		text-align: right;
	}
}