.left-comment {
    background-color: #EEEEEE;
    border-top-color: #e3e3e3;
    border-bottom-color: #e3e3e3;
    display: flex;
    min-height: 100vh;
    width: 100vw;
    flex-direction: column;
    &_header {
        background-color: #5F5F5F; 
        border-color: #3D4351; 
        color: #3D4351;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8rem;
        > div {
            border-radius: 50%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem auto;
        text-align: center;
        color: #000;
        padding: 0 1rem;
        h2.title {
            font-weight: bold;
            @media (max-width: 768px) {
                margin-top: 0;
            }
        }
        @media (min-width: 768px) {
            font-size: 1rem;
            width: 65%;
        } 
        .actions-container {
            display: flex;
            flex-direction: column;
			width: 100%;
        }
        .private-comment {
            transition: 0.5s;
            &.hide {
                display: none;
                height: 0;
            }
        }
        .private-comment-input {
            background: #fff;
            margin: 6px;
            width: calc(100% - 12px);
            height: 120px;
            resize: none;
        }
        .custom-question-text-input {
			background: #fff;
			width: 100%;
			height: 120px;
			resize: none;
			margin-top: -1.5rem;
			//margin-bottom: -1.5rem;
			padding: 5px;
			text-align: left;
		
        }
        .custom-question-radio-input {
			margin-left: -1.25rem;
			text-align: left;
        }

		.custom-question-label {
			text-align: left;
			margin-top: 1rem;
		}

        .review-btn {
            text-transform: none;
            border-radius: 3px;
            font-weight: 600;
            box-shadow: none;
            font-size: 14px;
			align-self: center;
			width: 250px;
            @media (max-width: 768px) {
                font-size: 13px;
            }
        }
        .google-btn {
            img {
                height: 1.4em;
            }
            background-color: #4285f4 !important;
        }
    }
    .powered-by {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: 10px;
        span {
            margin-right: 1em;
        }
    }

    .stars span {
        font-size: 2rem;
        margin: 0 0.5rem;
        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
        color:gray;
        &.selected {
            color: #FFC107;
        }
        cursor: pointer;
    }
    
}