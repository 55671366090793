#login-logo {
  max-width: 150px !important; }
  @media (max-width: 768px) {
    #login-logo {
      margin: auto; } }

.logo-container {
  display: flex;
  justify-content: center; }
  @media (max-width: 768px) {
    .logo-container {
      max-height: 100px; } }
