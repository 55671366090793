h4.subtitle {
  font-size: 1.2em; }

.hide-actions + ul {
  display: none; }

footer.page-footer {
  height: 9vh; }

#mainNavbarCollapse .dropdown-menu .dropdown-item:hover a {
  color: white; }

main {
  overflow-y: auto;
  margin-top: 10vh !important;
  min-height: 81vh; }

.metrics-container .alice-carousel__wrapper {
  height: 260px; }
  .metrics-container .alice-carousel__wrapper .alice-carousel__stage {
    height: 100%; }
    .metrics-container .alice-carousel__wrapper .alice-carousel__stage .alice-carousel__stage-item .card {
      height: 93%; }

.align-center {
  text-align: center; }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
    width: 600px; } }
