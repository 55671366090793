@charset "UTF-8";
@media (max-width: 920px) {
  .campaignPreviewerModal .modal-content {
    height: 98%; } }

#segmentFormInput {
  width: 39px; }
  #segmentFormInput .md-form {
    width: inherit !important; }

.campaign-card .form-error {
  color: red; }

.campaign-name {
  font-size: 14px !important;
  font-family: Arial !important;
  font-style: normal !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.6) !important; }

/* main image */
.image-container:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 0.5;
  z-index: 2; }

.image-container :not(img) {
  z-index: 2; }

.image-container img {
  z-index: 1; }

.image-container {
  position: relative; }

.notificationModalBody {
  font-size: 14px;
  line-height: 16px;
  padding: 18px !important; }

.notificationModalContent {
  background: #323232;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  opacity: 0.9;
  top: 89vh; }

.undoButtonNotifModal {
  color: #20BFC2;
  font-weight: 600 !important; }

/* la imagen del lado de edicion siempre tiene que ocupar lo mismo */
.card.face.back .image-container img {
  max-height: 100px;
  min-height: 100px;
  height: 100px; }

/* botones pegados abajo de la tarjeta */
.campaign-card .buttonbar {
  padding: 5px;
  width: 100%;
  position: absolute;
  bottom: 0px; }

/* fix para el range input, cuando tiene labels de dos renglones */
.campaign-card .md-form.md-range-input-fix label {
  position: relative;
  top: 25px;
  margin-top: 0px;
  margin-bottom: 10px; }

.campaign-card .md-form.md-range-input-fix {
  margin-top: -16px;
  margin-bottom: 0px; }

/* fixes de fonts en formularios */
.campaign-card .form-control, .campaign-card {
  font-size: 13px; }

.campaign-card .select-wrapper input.select-dropdown {
  font-size: 13px;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #495057; }

.campaign-card .select-wrapper + label.active {
  transform: translateY(-14px);
  font-size: .8rem;
  top: 1.7rem;
  left: 15px; }

.campaign-card .md-form label.active {
  color: #757575; }

/* fix de padding para el control de fecha */
.react-bootstrap-daterangepicker-container #reportrange {
  padding-left: 5px;
  padding-right: 5px; }

.modal-header h4 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

/* nombre de campaña centrado abajo en el header de tarjeta modo vista */
.campaign-header {
  display: inline-flex;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%; }

.campaign-header > .campaign-title {
  position: absolute;
  width: 70%;
  margin: 0px;
  padding: 0px;
  opacity: 0.90;
  margin-left: auto;
  margin-right: auto;
  bottom: 10px;
  left: 0;
  right: 0; }

/* centrar verticalmente el nombre de campaña en el modo edicion */
.campaign-header-edit {
  top: 0;
  bottom: 0;
  left: 10%;
  right: 0;
  margin: auto;
  position: absolute;
  height: fit-content; }

/* main image siempre ocupa todo el espacio */
.campaign-header > img {
  max-width: 100%;
  max-height: 100%; }

/* switch de activacion de campaña arriba a la izquierda del header */
.campaign-activate-switch {
  position: absolute;
  top: 5%;
  left: -0.7em;
  width: 80px;
  padding: 0px;
  width: 80px;
  display: inline-block; }

.campaign-activate-switch .switch label .lever {
  margin-right: 0px;
  margin-left: 5px; }

.campaign-activate-switch > .row > .col {
  padding: 2px; }

.activateCampaignCardContainer {
  width: 154px;
  height: 280px;
  border-radius: 5px;
  float: left; }
  .activateCampaignCardContainer:hover {
    background-color: #d3e6e1;
    cursor: pointer; }

#activateParagraph {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 0; }
  @media (max-width: 576px) {
    #activateParagraph {
      font-size: 12px; } }

#activateTitle {
  color: black;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px; }
  @media (max-width: 576px) {
    #activateTitle {
      font-size: 20px; } }

.activateCampaignCardComponent {
  display: inline-block !important;
  margin-right: 5px;
  margin-top: 8px; }
  .activateCampaignCardComponent .activateCampaignCardImage {
    width: 138px;
    height: 138px;
    border-radius: 4px;
    background-position: center;
    background-size: cover; }
  .activateCampaignCardComponent .activateCampaignCardTitle {
    color: black;
    margin: 10% 0% 7% 0%; }
  .activateCampaignCardComponent p {
    font-size: 13px;
    font-weight: normal;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical; }

.campaignsActionButton {
  font-size: 14px;
  font-weight: 600;
  box-shadow: none; }
  .campaignsActionButton:active, .campaignsActionButton:hover {
    box-shadow: none !important; }

@media (min-width: 1200px) {
  .noResultsActivateFirstCampaign {
    /*lg para arriba*/ }
    .noResultsActivateFirstCampaign svg + h1 {
      padding: 0;
      padding-bottom: 1rem; } }

@media (max-width: 768px) {
  .noResultsActivateFirstCampaign {
    /*sm para abajo*/ }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign {
      width: 100%;
      padding-top: 40px; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage + h1 {
      padding: 0;
      padding-bottom: 1rem; } }

@media (max-height: 700px) {
  .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage {
    display: flex;
    justify-content: center; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage svg {
      width: 250px;
      height: auto; } }

@media (max-height: 600px) {
  .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign {
    padding-top: 0; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign h1 {
      font-size: 20px; }
    .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage {
      display: flex;
      justify-content: center; }
      .noResultsActivateFirstCampaign .noResultsActivateFirstCampaign .noResultsActivateFirstCampaignImage svg {
        width: 150px;
        height: auto; } }

.noResultsActivateFirstCampaign svg {
  padding: 0;
  width: 317px;
  height: auto; }
  .noResultsActivateFirstCampaign svg + h1 {
    margin-bottom: 0;
    margin-top: 47px !important;
    padding: 0 !important; }

.noResultsActivateFirstCampaign h6 {
  margin-bottom: 0;
  margin-top: 8px !important;
  padding: 0 !important;
  font-size: 14px;
  font-weight: 400; }

.noResultsActivateFirstCampaign button {
  margin: 0;
  margin-top: 16px !important; }

.multipleCampaignCardContainer {
  width: 100%;
  float: left; }

.no-scroll {
  overflow-y: hidden !important; }

#collVisContainer .modal {
  z-index: 2999 !important; }

#collVisContainer .botbit-table-card-header > div {
  padding: 0 !important; }

#collVisContainer .lastEdited {
  max-width: 11%; }
  #collVisContainer .lastEdited .lastEditedContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  #collVisContainer .lastEdited > .table-title > div {
    padding-left: 2rem; }

#collVisContainer .no-results-container {
  margin-top: 45px; }

@media (max-width: 576px) {
  #collVisContainer #activateFirstCampaignDiv {
    margin-top: 5px; } }

#collVisContainer .minimalEditorModalContainer .modal {
  z-index: 2999 !important; }

#collVisContainer .background-smart {
  background-color: #e9aa43 !important; }

#collVisContainer .confirmationModal > .modal-content {
  min-height: inherit; }

#collVisContainer .mobileColumn .switchContent {
  right: 6px;
  position: absolute; }

#collVisContainer .mobileColumn .imgContainer {
  height: 100%;
  position: relative; }
  #collVisContainer .mobileColumn .imgContainer img {
    width: 105px;
    max-height: 200px;
    border-radius: 2%; }
  #collVisContainer .mobileColumn .imgContainer .smartCampaignBadge {
    right: 0;
    bottom: 0;
    font-size: large;
    position: absolute;
    box-shadow: none; }

#collVisContainer .mobileColumn .campaignInfo {
  margin-left: 13px;
  width: 50vw;
  color: #212121; }
  #collVisContainer .mobileColumn .campaignInfo p {
    line-height: 16px;
    margin: 0;
    margin-bottom: 3px;
    font-style: normal; }
  #collVisContainer .mobileColumn .campaignInfo .campaignName {
    height: auto;
    font-size: 14px;
    font-weight: 600; }
  #collVisContainer .mobileColumn .campaignInfo p:not(.campaignName):not(.effectivity) {
    font-weight: normal;
    font-size: 12px; }
  #collVisContainer .mobileColumn .campaignInfo .segmentStores {
    text-decoration: underline; }
  #collVisContainer .mobileColumn .campaignInfo .clientsThatReturned {
    font-weight: normal;
    font-size: 12px; }
  #collVisContainer .mobileColumn .campaignInfo .effectivity {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px; }

@media (max-width: 576px) {
  #collVisContainer .rowActionsNew {
    bottom: 8px;
    top: inherit; } }

#collVisContainer .botbit-action-button {
  font-size: 14px;
  font-weight: 600;
  box-shadow: none; }

#collVisContainer .botbit-actions-container {
  margin-top: -30px; }

#collVisContainer .botbit-table-card > .card-header:hover::after, #collVisContainer .botbit-table-expanded-row::after {
  display: none; }

#collVisContainer .card-header {
  padding: 17px 0px; }

#collVisContainer .card.botbit-table-card {
  background-color: inherit;
  box-shadow: none; }
  #collVisContainer .card.botbit-table-card:hover {
    background-color: #E9F6F3; }

/* valores de filtros en italica */
.filter-text {
  margin-bottom: 0.4em; }

.filter-text > .filter-value {
  font-style: italic; }

/* separacion entre secciones de la tarjeta modo vista*/
.campaign-panel {
  margin-top: 0.4em;
  margin-bottom: 0.4em; }

/* padding del contenido de la tarjeta en modo vista y modo edicion */
.campaign-card .card-body {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 8px; }
