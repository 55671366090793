.modal-88w {
  width: 88%;
  max-width: 88%;
}

.show-modal {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.user-modal {
  h1.modal-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: black;
    margin: 0;
  }
  .botbit-table .botbit-table-column {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .modal-body { 
    .container {
      margin-bottom: 60px;
    }
  }
  // .fixed-action-btn.active {
  //   margin-left: 0 !important;
  //   @media (min-width: 768px) {
  //     margin-left: auto !important;
  //     right: -250px !important;
  //   }
  // }
}
@media only screen and (max-width: 768px) {
  .user-modal {
    section {
      margin-top: 0;
    }
    .element-content {
      display: block;
    }
    .element-data-container {
      width: 100%;
    }
  }
}

#user-review-history {
  // width: 35%;
  margin-left: auto;
  margin-right: auto;
}

@media(max-width: 991px) {
  #user-review-history {
    width: 100%;
    padding: 15px;
  }
}

.review-history-modal {
  .modal-body {
    padding: 0 !important;
  }
}
