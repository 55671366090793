.dz-success-mark, .dz-error-mark {
  display: none; }

.clients-store-selector-action-container .select-in-dropdown-container {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1em; }

.clients-store-selector-action-container button {
  border-radius: 3px;
  text-transform: none;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  border-width: 1px !important;
  margin: 0 5px;
  padding: 5px 10px !important; }

@media (max-width: 992px) {
  .clients-store-selector-action-container {
    margin: 5px 0 0 0;
    width: 100%;
    float: left; }
    .clients-store-selector-action-container button {
      margin: 0 !important;
      padding: 15px 10px !important;
      width: 100%; } }

.client-actions-backdrop {
  z-index: 1000000 !important;
  display: block !important; }

div.fade + div[role="dialog"] {
  z-index: 1000000 !important; }

.user-reservation-form-input.errors input {
  border-color: var(--danger) !important; }

.user-reservation-form-input.errors label {
  color: var(--danger) !important; }

.tags-scroll .scroll-menu-arrow {
  visibility: hidden; }

@media (min-width: 768px) {
  .modal-lg {
    width: 800px !important; } }

.intl-tel-input {
  width: 100% !important; }
  @media (max-width: 768px) {
    .intl-tel-input {
      z-index: 1000000 !important; } }
  .intl-tel-input .country-list {
    z-index: 1000 !important; }
  .intl-tel-input input {
    padding-right: 0 !important;
    width: calc(100% - 52px); }
  .intl-tel-input .iti-flag {
    background-image: url("/static/img/flags.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .intl-tel-input .iti-flag {
      background-image: url("/static/img/flags@2x.png"); } }
