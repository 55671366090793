.campaignSubject {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignSubject .inputSpan {
    width: 80%;
    padding-top: 8px; }
    @media (max-width: 576px) {
      .campaignSubject .inputSpan {
        width: 100%; } }
  .campaignSubject .inputLabel {
    min-width: 140px;
    max-width: 140px;
    display: inline-block;
    font-weight: 600;
    padding-top: 8px; }
    @media (max-width: 576px) {
      .campaignSubject .inputLabel {
        width: 100%; } }
    @media (max-width: 576px) {
      .campaignSubject .inputLabel {
        width: 100%;
        max-width: 100%;
        margin-top: 10px; } }
  .campaignSubject .md-form > input {
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 0; }
  .campaignSubject .tags-input.has-error > .md-form {
    margin-bottom: 30px; }
