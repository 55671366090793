body.body-overflow-auto {
  overflow-y: auto !important; }

.footer-logo {
  max-height: 17px; }

.nav-item-right-arrow {
  margin-Top: 3px; }

.page-footer {
  margin-top: 12vh; }

.nav-header-avatar {
  max-height: 75px;
  max-width: 65px; }

.nav-header-logo {
  width: 75px; }

#navContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh; }
  #navContainer .user-settings-dropdown {
    margin-right: 5px; }
    #navContainer .user-settings-dropdown .nav-link {
      margin: -10px; }
    #navContainer .user-settings-dropdown .user-avatar-img {
      width: 35px;
      height: 35px;
      background-color: lightgray;
      padding: 4px; }
  #navContainer .invite-users-icon {
    margin-right: 8px;
    margin-left: 3px; }

@media (max-width: 768px) {
  .nav-header-select {
    min-width: 35vw;
    width: 35vw; }
    .nav-header-select .css-dopzeu-control,
    .nav-header-select .css-de7kfp-control,
    .nav-header-select .css-kj6f9i-menu,
    .nav-header-select .css-lvpw7o-control {
      width: 50% !important; }
    .nav-header-select > div:not(.css-1wy0on6):not(.css-16pqwjk-indicatorContainer),
    .nav-header-select > div > div:not(.css-1wy0on6):not(.css-16pqwjk-indicatorContainer),
    .nav-header-select > div > div > div:not(.css-1wy0on6):not(.css-16pqwjk-indicatorContainer) {
      min-width: 35vw;
      width: 35vw; } }

.nav-header-select-header {
  margin: 5px 5px 5px 20px; }

.nav-header-text-header {
  margin: 18px 5px 5px 20px; }

.nav-header-store-logo {
  height: 30px;
  max-height: 30px;
  margin-bottom: 4px;
  margin-left: 4px; }

.nav-header-select-icon {
  display: inline-block;
  text-align: center;
  color: #979B9D;
  margin-bottom: 1px; }

@media (max-width: 300px) {
  .nav.navbar-top-links li.dropdown {
    display: none; } }

#selectedStoreText {
  font-size: 16px;
  flex: 0 0 100%;
  max-width: 100%; }

.nav-header-select .row {
  flex-wrap: inherit; }

#HeaderReviewButtonFloatCircle a i {
  background-color: var(--deep-orange);
  color: #fff; }

body:not(.mini-navbar) .navbar-default {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: scroll;
  height: 100%; }
  body:not(.mini-navbar) .navbar-default::-webkit-scrollbar {
    display: none; }
