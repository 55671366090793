#main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
    font-size: x-large;
}

#inner-container {
    height: 20%;
    margin-top: 2%;
    text-align: center;
}

.page-heading {
    display: none;
}