.issue-type-edition {
    padding-bottom: 6rem;
    margin-top: 1rem;

	.title {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 33px;
		color: black;
	}

	.subtitle {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 16px;
		color: black;
		margin-right: 1em;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
	}

	.adjust-switch {
		margin-top: 11px;
	}

	.state-block {
		background-color: #ededed;
		margin-top: 5px;
	}

	.form-label {
		width: 90px;
	}

	.remove-state {
		align-self: center;
		cursor: pointer;
	}

}