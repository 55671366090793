.admin-invite {
    &.embedded {
        padding: 0 1rem;
    }
    &.page-view {
        .admin-invite_container {
        padding: 1rem 2rem;
        background-color: #fff;
        border-radius: 0.35rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        margin-top: 1rem;
        h2 {
            font-weight: bold;
        }
        }
        @media (max-width: 768px) {
            .admin-invite_container {
            padding: 0.5rem 1rem;
        }
        padding-bottom: 4rem;
        }
    }
    &_title {
        font-size: 1.6rem !important;
        color: #000 !important;
    }
    .submit-btn {
        margin: 0;
        margin-bottom: 2rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .error-msg {
        color: var(--danger);
        font-size: 0.8rem;
        margin-top: -0.6rem;
    }
    .md-form {
        margin-bottom: 0;
    }
    div[name=role] {
        margin-top: 2rem;
        input.select-dropdown {
            margin-bottom: 0.5rem;
            height: 1.375rem;
        }
        label.active {
            margin-top: -0.5rem;
        }
    }
    .role-container {
        margin: 0 0 1rem 0;
        padding: 0;
        .form-check {
            margin: 0;
            padding: 0;
        }
    }
}

.admin-invite-modal {
    @media (min-width: 768px) {
        max-width: 520px !important;
        width: 520px !important;
    }
    &_title {
        font-size: 1.4rem !important;
        color: #000 !important;
    }
    .modal-header {
        border-bottom: none !important;
    }
}