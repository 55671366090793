.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  max-height: 22px;
  height: 22px;
  font-size: 12px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
  padding: 0; }

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 9px;
  color: #ccc; }
