@media screen and (max-width: 480px) {
  .cards-list-sm {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent; }
    .cards-list-sm .profile-image {
      margin: 0 auto !important;
      float: none; }
      .cards-list-sm .profile-image img.rounded-circle {
        height: 95px;
        width: 95px; }
  .cards-list-sm thead {
    display: none; }
  .cards-list-sm tr {
    display: block;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(to left, #FCFCFC 95%, #EBEBEB);
    border: 2px solid #EBEBEB;
    border-radius: 10px;
    position: relative; }
    .cards-list-sm tr td.card-top-right-corner {
      position: absolute;
      top: 0;
      right: 0; }
  .cards-list-sm .card-sm > td {
    display: block;
    padding-left: 9%;
    border: none;
    text-align: left !important; }
  .cards-list-sm td::before {
    content: attr(data-label);
    margin-right: 0.5rem;
    font-weight: bold;
    display: inline-block; }
  tr.expanding-row > td {
    display: table !important;
    margin: auto !important;
    padding: 0 !important; } }

@media screen and (max-width: 768px) {
  .cards-list-md {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent; }
    .cards-list-md .profile-image {
      margin: 0 auto !important;
      float: none; }
      .cards-list-md .profile-image img.rounded-circle {
        height: 95px;
        width: 95px; }
  .cards-list-md thead {
    display: none; }
  .cards-list-md tr {
    display: block;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(to left, #FCFCFC 95%, #EBEBEB);
    border: 2px solid #EBEBEB;
    border-radius: 10px;
    position: relative; }
    .cards-list-md tr td.card-top-right-corner {
      position: absolute;
      top: 0;
      right: 0; }
  .cards-list-md .card-md > td {
    display: block;
    padding-left: 9%;
    border: none;
    text-align: left !important; }
  .cards-list-md td::before {
    content: attr(data-label);
    margin-right: 0.5rem;
    font-weight: bold;
    display: inline-block; }
  tr.expanding-row > td {
    display: table !important;
    margin: auto !important;
    padding: 0 !important; } }

@media screen and (max-width: 1024px) {
  .cards-list-lg {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    border: none;
    background-color: transparent; }
    .cards-list-lg .profile-image {
      margin: 0 auto !important;
      float: none; }
      .cards-list-lg .profile-image img.rounded-circle {
        height: 95px;
        width: 95px; }
  .cards-list-lg thead {
    display: none; }
  .cards-list-lg tr {
    display: block;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(to left, #FCFCFC 95%, #EBEBEB);
    border: 2px solid #EBEBEB;
    border-radius: 10px;
    position: relative; }
    .cards-list-lg tr td.card-top-right-corner {
      position: absolute;
      top: 0;
      right: 0; }
  .cards-list-lg .card-lg > td {
    display: block;
    padding-left: 9%;
    border: none;
    text-align: left !important; }
  .cards-list-lg td::before {
    content: attr(data-label);
    margin-right: 0.5rem;
    font-weight: bold;
    display: inline-block; }
  tr.expanding-row > td {
    display: table !important;
    margin: auto !important;
    padding: 0 !important; } }

tr.expanding-row, .expanding-row > td {
  background-color: #f3f3f4;
  background-image: none; }

.card-header .row .profile-image > img {
  height: 45px;
  width: 45px; }

.order > .dropup > .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 4px dashed;
  border-right: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent; }

div.react-bootstrap-table {
  padding: 0px; }
