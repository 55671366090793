.set-password {
    // background-color: #FFFFFF;
    min-width: 100%;
    min-height: 100vh;
    background-color: #F3F3F4;
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .submit-btn {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
    }
    .md-form {
        margin-bottom: 0;
    }
    // img {
    //     max-width: 90%;
    //     display: none;
    // }
    .error-msg {
        color: var(--danger);
        font-size: 0.8rem;
    }
    .card {
        width: 100%;
    }
    &_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 768px) {
            min-width: 350px;
        }
        @media screen and (max-width: 768px) {
            margin: auto;
            width: 85%;
            padding: 1rem 0;
        }
        .container-header {
            padding-top: 1rem;
            .first-line {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                h2 {
                    font-size: 1.2rem;
                    color: #000;
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                    margin-right: 1rem;
                }
                img {
                    width: 40px;
                    height: 40px;
                }
                @media screen and (max-width: 768px) {
                    h2 {
                    font-size: 1.1rem;
                    }
                    img {
                    display: none;
                    }
                }
            }
            .second-line {
                text-align: center;
            }
        }
    }
    .image-and-upload-container {
        display: flex;
        justify-content: center;
    }
    #imageGalleryContainer {
        position: relative;
        .change-logo-btn {
            position: absolute;
            right: -19px;
            bottom: -10px;
        }
    }

    .form-fields-container {
        margin: 0;
        padding: 0;
    }

    #add-image {
        margin-top: 75px;
        margin-left: -15px;
        position: relative;
        border: none;
        background-color: #5DC3C6;
        border-radius: 5px;
        transition: 0.25s;
    }

    @media(min-width: 992px) {

        .image-cropper {
            width: 450px !important;
            height: 450px;
        }
    }

    @media(max-width: 992px) {
        .image-cropper {
            width: 100% !important;
            height: 70vh;
        }
    }
}