@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap');


$beige1:#EBE1D9;
$beige2:#a08f7d;
$black:#292726;
.fil5 {
    fill: none
}

.fil2 {
    fill:$beige1;
}

.fil3 {
    fill: white
}

.fil1 {
    fill: #383232;
    fill-rule: nonzero
}

.fil0 {
    fill: url(#id3)
}

.fil4 {
    fill: url(#id4);
    fill-rule: nonzero
}
#give-nps{
    font-family: "Roboto";
    .header {
        background: rgb(203, 185, 170);
        background: linear-gradient(90deg, rgba(203, 185, 170, 1) 0%, rgba(229, 220, 212, 1) 41%, rgba(255, 255, 255, 1) 100%);
        border-top: #ccb9aa 4px solid;
        border-bottom: #ccb9aa 4px solid;
        max-height: 8rem;
    }

    .header .title {
        font-family: "Roboto", sans-serif;
        font-weight: 100;
        margin: 30px;
        text-align: center;
    }

    svg{
        overflow: visible;
        min-width:9rem;
        max-width: 10rem;
    }

    button.review-btn{
        padding-left: 1rem !important;
        background-color: $beige2;
        text-transform: capitalize;
        font-weight: bold;
        min-width: 350px;
        text-align: left;
        font-size: large;
        color: white;
        img, i{
            max-width: 2rem;
        }
        white-space: nowrap;
    }

    .bold.background-gray {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        color: $black;
        font-size: large;
        text-align: center;
        background-color:$beige1;
        padding: 5px 10px;
        margin-top: 2rem;
    }

    .main-content{
        text-align: center;
        max-width: 850px;
        margin: auto;
    }

    .google-btn{

    }

    .content{
        font-size: large;
        padding-top: 1rem;
        text-align: justify;
    }


    .give-nps-buttons {
        a.star{
            margin: 0 5px;
            font-size: 36pt;
            color: gray;
        }
        
        a.star:hover{
            color: #F9BB04;
        }
    }

    #private-comment-input{
        background-color: $beige1;
        border: none;
        width: 80%;
        font-size: large;
        height: 30vh;
    }
}