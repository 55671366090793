.reputation-table-container {
  width: 920px;
  height: 534px; }
  .reputation-table-container .reputation-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .reputation-table-container .period-selector {
    margin-right: 1rem;
    padding-top: 1rem; }
  .reputation-table-container .pagination-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center; }
  .reputation-table-container .reputation-table-title {
    width: 274px;
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    padding-bottom: 10px; }
  .reputation-table-container .reputation-table-title-tooltip-container {
    margin-top: 6px; }
  .reputation-table-container .reputation-table-title-container {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .reputation-table-container .reputation-table-title-span {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16.34px;
    letter-spacing: 0;
    color: #3F6586; }
  .reputation-table-container .period-selector-label-container .period-selector-label {
    font-size: 12px;
    font-weight: 600;
    margin-right: 1rem;
    line-height: 16.34px;
    color: #1A1A1A; }
  .reputation-table-container .period-selector-label-container .period-selector-select {
    width: 153px;
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 18px; }
    .reputation-table-container .period-selector-label-container .period-selector-select::-ms-expand {
      display: none; }
    .reputation-table-container .period-selector-label-container .period-selector-select:focus {
      outline: none;
      border: 1px solid #E3E3E3; }
  .reputation-table-container .custom-headers {
    display: flex;
    padding: 10px;
    justify-content: space-between; }
    .reputation-table-container .custom-headers .header-cell {
      flex: 1;
      font-weight: bold; }
  .reputation-table-container .botbit-table-card-header {
    background-color: #F8F9FA; }
  .reputation-table-container .reputation-table-details-button {
    text-decoration: none;
    border: none;
    cursor: pointer !important;
    z-index: 2;
    background-color: transparent; }
  .reputation-table-container .reputation-table-various-spans-number {
    font-size: 12px;
    font-weight: 400;
    color: #1F2D3D;
    line-height: 16.34px;
    text-align: center;
    margin-top: 0px; }
  .reputation-table-container .reputation-table-various-spans-number-rating-maps {
    font-size: 12px;
    font-weight: 400;
    color: #1F2D3D;
    line-height: 16.34px;
    text-align: center;
    margin-top: 10px; }
  .reputation-table-container .reputation-table-various-spans-stars-number {
    font-size: 12px;
    font-weight: 400;
    color: #1F2D3D;
    line-height: 16.34px;
    text-align: center;
    margin-top: 10px; }
  .reputation-table-container .reputation-table-various-spans-number-bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 16.34px;
    color: #1F2D3D;
    text-align: center; }
  .reputation-table-container .reputation-table-various-spans-number-bold-bigger {
    font-weight: 700;
    font-size: 14px;
    line-height: 19.07px;
    color: #1F2D3D;
    text-align: center;
    margin-top: 8px; }
  .reputation-table-container .performance-indicator__container {
    display: flex;
    align-items: center;
    gap: 4px; }
    .reputation-table-container .performance-indicator__container .performance-indicator__text {
      color: #000000; }
  .reputation-table-container .card-header.cursor-pointer:hover {
    background-color: transparent !important;
    cursor: default !important; }
  .reputation-table-container .botbit-table-card .card-header:hover::after {
    content: none !important; }
  .reputation-table-container .botbit-table {
    box-shadow: none !important; }
    .reputation-table-container .botbit-table .card {
      box-shadow: none !important;
      border-color: #E9ECEF !important; }
  .reputation-table-container .page-item.active .page-link {
    background-color: #868E96; }
    .reputation-table-container .page-item.active .page-link:focus {
      box-shadow: none; }
    .reputation-table-container .page-item.active .page-link:hover {
      background-color: #868E96; }
  .reputation-table-container .table-title {
    display: flex;
    align-items: flex-start; }
    .reputation-table-container .table-title .tooltip__container {
      margin-top: 24px;
      margin-left: 4px; }

.text-left {
  text-align: left !important; }
