.email-widget {
  width: 80%;
  margin-right: auto;
  margin-left: auto; }

.email-delivered-color {
  background-color: #4285f4; }

.email-open-count {
  background-color: #db4437; }

.email-clicked-count {
  background-color: #f4b400; }
