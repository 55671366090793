.local-seo-store-view-container{
    background: white;
    padding-top: 20px;
    width: 961px;
    height: 616px;
}


 

.local-seo-chart-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  
  .local-seo-chart-container {
    margin: 20px 0;
    min-height: 400px;
  }
  

  .local-seo-icon-and-subtitle{
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .google-maps-subtitle{
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
    line-height: 16.34px;
    color: #353C48;
  }

  .google-maps-subtitle-modal{
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.34px;
    color: #353C48;
  }