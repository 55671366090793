.messenger-wrapper {
  background-color: #ffffff;
  // height          : calc(100vh - 110px);

  .sections-container {
    // max-height: 100%;
    // height    : 100%;
    // display   : flex;

    .chats-container {
      overflow-y: scroll;
      max-height: 85vh;
      width   : 100%;

      @media (min-width: 768px) {
        width: 40%;
      }

      .chats-tabs{

        // border-bottom: var(--primary) 5px solid;
        border-top: var(--primary) 5px solid;
      }

      .chats-container-header {
        width: 100%;

        // @gus comentado en refactor header
        // .container {
        //   background-color: var(--primary);
        //   display         : flex;
        //   flex-direction  : row;
        //   align-items     : center;
        //   justify-content : space-between;
        // }

        .store-selector-container {
          display        : flex;
          flex-direction : row;
          align-items    : center;
          justify-content: space-between;
          padding        : 0.4rem;

          button {
            border-radius : 3px;
            text-transform: none;
            font-size     : 14px;
            line-height   : 19px;
            font-weight   : 600;
            border-width  : 1px !important;
            margin        : 0 5px;
            padding       : 5px 1rem;
          }
        }

        .conversation-filters-container {
          background-color: whitesmoke;

          .filter-label {
            display    : flex;
            align-items: center;
          }
        }

        .chats-tabs {
          margin : 0;

          ul.nav {
            margin    : 0;
            box-shadow: none;

            li {
              a {
                @media (max-width: 768px) {
                  padding: 7px 10px 7px 10px;
                }
              }
            }
          }
          .conversation-actions-container .filter-chips {
            max-width: unset;
            width: 100%;
            padding-top: 0.5rem;
            padding-bottom: 1rem;;
          }
          
          .filters-info{
            margin: 5px;

          }

          .filters-actions{
            .dropdown-menu{
              max-height: 70vh;
              overflow-y: scroll;
              &::-webkit-scrollbar {
                display: none;
              }
            
              /* Hide scrollbar for IE, Edge and Firefox */
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
            
            }
          }
          // @gus comentado en refactor header
          // .conversation-actions-container {
          //   //display         : flex;
          //   //color: #1ab394 !important;
          //   //background-color: transparent !important;

          //   a {
          //     margin-left : 0px;
          //     margin-right: 10px;
          //   }

          //   .filters button {
          //     color: #ffffff !important;
          //   }

          //   .filters .filters-form {
          //     background-color: #ffffff !important;
          //   }
          // }

        }

        .filters {
          .filters-first-line {
            margin-bottom: 0;
            margin-top   : 5px;
          }
        }
      }

      .chats-list {
        .conversation-item {
          align-items    : center;
          justify-content: space-between;
          width          : 100%;
          cursor         : pointer;
          border-bottom  : 1px solid #ccc;
          padding        : 0.4rem 0.6rem;

          .avatar-container {
            margin-right: 1rem;
          }

          .conversation-issue-assignment {
            text-align  : center;
            margin-left : 10px;
            margin-right: 10px;
          }

          .text-content {
            width: 100%;
            padding : 0.2rem 0;
            overflow: hidden;

            p {
              overflow     : hidden;
              white-space  : nowrap;
              text-overflow: ellipsis;
              margin       : 0;
              max-width    : 90%;
            }

            h4 {
              margin: 0;
            }
          }

          .conversation-details {
            span.time {
              display: block;
            }

          }

          &.selected-item {
            background-color: #ccc;
          }
        }
      }
    }

    .current-chat-container {
      width: 100%;

      @media (min-width: 768px) {
        width: 60%;
      }
    }
  }
}