.cron_builder {
  width: 100%;
  border: none;
  background: inherit;
  font-weight: normal;
  padding: 0; }
  .cron_builder .cron_builder_bordering {
    min-height: 129px; }
  .cron_builder [type="checkbox"]:not(:checked), .cron_builder [type="checkbox"]:checked, .cron_builder [type="radio"]:not(:checked), .cron_builder [type="radio"]:checked {
    position: unset !important;
    opacity: 1 !important;
    pointer-events: unset !important; }
  .cron_builder select {
    display: unset !important; }
  .cron_builder li.active {
    background: #EBF5F3; }
  .cron_builder li > a {
    color: #19AA89 !important;
    text-transform: initial !important;
    border: none !important;
    padding: 12px 40px !important; }

.cronExpressionBuilder {
  padding-bottom: 25px; }
  .cronExpressionBuilder .mainSelector {
    display: inline-block; }
    @media (max-width: 576px) {
      .cronExpressionBuilder .mainSelector {
        width: 100%; } }
  .cronExpressionBuilder > div > div {
    margin: 0; }
  .cronExpressionBuilder .DayPickerInput, .cronExpressionBuilder .rc-time-picker {
    padding: 0 10px; }
  .cronExpressionBuilder .rc-time-picker {
    display: inline-block;
    width: 102px; }
  .cronExpressionBuilder .sendEveryContainer {
    display: flex !important;
    align-items: center;
    min-height: 66px; }
    .cronExpressionBuilder .sendEveryContainer .everyInputContainer {
      display: flex;
      align-items: center; }
      .cronExpressionBuilder .sendEveryContainer .everyInputContainer .select-wrapper.mdb-select.md-form > input {
        margin: 0; }
      .cronExpressionBuilder .sendEveryContainer .everyInputContainer > div.md-form {
        display: inline-block;
        margin: 0;
        padding: 0 10px; }
        .cronExpressionBuilder .sendEveryContainer .everyInputContainer > div.md-form:not(.mdb-select) {
          width: 80px; }
      .cronExpressionBuilder .sendEveryContainer .everyInputContainer .rc-time-picker input {
        width: 100%; }
      @media (max-width: 576px) {
        .cronExpressionBuilder .sendEveryContainer .everyInputContainer ul.dropdown-content.select-dropdown.fadeElement.fadeIn {
          min-width: 120px; } }
    .cronExpressionBuilder .sendEveryContainer .monthlyOptionMobile {
      display: contents; }
      .cronExpressionBuilder .sendEveryContainer .monthlyOptionMobile .md-form {
        padding: 0 10px; }
