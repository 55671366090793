.source-thumbnail {
  width: 100%;
  height: 200px;
  &_image-container {
      border-bottom: 1px solid #ccc;
      .source-image {
          height: 180px;
          max-width: 98%;
          margin: auto;
          background-size: contain !important;
      }
  }
  .card-title {
      font-size: 20px;
      font-weight: 700;
  }
  .thumbnail-body {
    position: relative;
  }
  .status-badge {
    position: absolute;
    right: 5px;
    top: -25px;
  }
}

@media only screen and (max-width: 499px) {
  .source-thumbnail {
    width: 100%;
    &_image-container {
      .source-image {
        height: 120px;
        // max-width: 98%;
      }
    }
    .card-title {
      font-size: 14px;
      font-weight: bold !important;
      margin: 0;
      text-align: center !important;
    }
    .card-body {
      padding: 12px 10px;
    }
  }
}