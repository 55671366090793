.user-review-history {
    --side-pad: 20px;
    --side-pad-mobile: 10px;
    display: flex;
    flex-direction: column;
    margin: 10px 15px;
    width: 90%;
    @media (max-width: 768px) {
        margin: 0;
        width: 100%;
    }
    &_header {
        margin: 0;
        padding: 15px var(--side-pad);
        background-color: #137f69;
        color: #fff;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        @media (max-width: 768px) {
            padding: 15px var(--side-pad-mobile);
        }
        #user-details {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            // margin-top: 5%;
            .text-container {
                display: flex;
                flex-direction: column;
                span {
                    label {
                        margin: 0;
                        font-size: 0.8rem;
                        font-weight: 400;
                        b {
                            font-size: 1rem;
                        }
                        @media (max-width: 768px) {
                            font-size: 0.8rem;
                            b {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
            .profile-pic {
                border-radius: 100%;
                box-shadow: 0 3px 6px rgba(0,0,0,0.16);
                width: 45px;
                margin-right: 15px;
            }
            .premium-tag {
                color: #cbb679;
                margin-bottom: 0.8rem;
                // font-weight: bold;
                &-container {
                    width: 100%;
                    transition: 1s;
                    overflow: hidden;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .message-container {
        div {
            overflow: visible !important;
        }
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }


    .chat-messages {
        padding: var(--side-pad);
        background-color: #e1e1e1;
        div {
            overflow: visible!important;
        }
        @media (max-width: 768px) {
            padding: var(--side-pad-mobile);
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }

    .test {
    display: none;
    }

    .empty-state {
        margin: auto;
        font-size: 1.2rem;
        .noResultsImage {
                margin-bottom: 1rem;
                svg {
                @media (max-width: 768px) {
                    width: 250px;
                }
            }
        }
    }
}

