.conversation-dashboard-container {
    padding-bottom: 50px;
    margin-top: 1.5rem;

    .big-number {
        font-size: 3rem;
        font-weight: 900;
        margin-bottom: 10px;
        align-self: center;
    }

    .source-icon {
        width: 24px;
        height: 24px;
    }

    .channel-detail {
        margin-bottom: 10px;
        align-self: center;
        font-size: large;
        display: flex;
        .channel-subdetail {
            margin-left: 10px;
            text-align: center;
            margin-bottom: 0px;
            font-size: x-small;
        }
    }

    .conversation-insight-container {
        display: flex;
        justify-content: center ;
    }

    .conversation-insight {
        max-width: 200px;
        min-width: 200px;
    }

    .conversation-insight-container {
        justify-content: center;
    }

    .admin-stats-container {
        justify-content: center;
    }
    
    .cursor-pointer {
        cursor: default !important;
    }

    .admin-stats-table {
        max-width: 800px;
    }

    .admin-avatar {
        width: 35px;
    }

    .insight-title {
        text-align: center;
    }

    .cell-wrapper {
        display: table;
    }

    .cell-content {
        display: table-cell;
        vertical-align: middle;
    }

    .mobile-row_container {
        .mobile-row_heading {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .mobile-row_admin-avatar {
                margin-right: 1rem;
            }
        }

        .mobile-row_metrics-list {
            list-style: none;
            padding-left: calc(45px + 1rem);
        }

        .mobile-row_metrics-list_item {
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }

            .badge-default {
                background-color: #38B394 !important;
                float: right;
            }
        }
    }

}