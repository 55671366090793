.source-card {
  width: 100%;
  height: 430px; }
  .source-card .source-image-container {
    border-bottom: 1px solid grey;
    height: 140px; }
    .source-card .source-image-container .source-image {
      max-width: 90%;
      margin: auto;
      background-size: contain; }
  .source-card .card-title {
    font-size: 18px;
    font-weight: 700; }
  .source-card .card-body {
    padding: 1rem;
    display: flex;
    font-size: 14px;
    flex-direction: column; }
  .source-card .btn-container {
    display: flex;
    justify-content: center;
    margin-top: auto; }
  .source-card .shopify-button {
    background-color: #fafafa !important;
    display: flex;
    align-items: center;
    color: black !important; }
  .source-card .gmail-button {
    background-color: #4285F4 !important;
    display: flex;
    align-items: center; }
  .source-card .rdstation-button {
    background-color: #fafafa !important;
    display: flex;
    align-items: center;
    color: black !important; }
  .source-card .source-img {
    max-height: 35px;
    margin-right: 5px; }
