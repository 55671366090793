div.schedule label {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0.5rem;
  top: -1rem;
  color: #000; }

div.schedule input {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400; }

div.schedule label.active {
  top: 0; }

.topicTypeList ul {
  margin-top: 3rem;
  list-style: none; }

.fa-plus-square {
  padding-right: 10px; }
