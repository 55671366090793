#settings .margins-reduced .md-form {
  margin-top: 0.25em;
  margin-bottom: 0.75em; }

#settings .margins-reduced .md-form.md-outline {
  margin-top: 0.25;
  margin-bottom: 0.75em; }

#settings .is-invalid-important {
  border-color: red !important; }
  #settings .is-invalid-important input {
    border-color: red !important; }

#settings .md-outline input, #settings .md-outline > textarea {
  font-size: 11pt !important; }
  #settings .md-outline input + label, #settings .md-outline > textarea + label {
    font-size: 11pt !important; }
  #settings .md-outline input:focus, #settings .md-outline > textarea:focus {
    border-color: #1ab394 !important;
    box-shadow: inset 0px 0px 0px 1px #1ab394 !important; }
    #settings .md-outline input:focus + label, #settings .md-outline > textarea:focus + label {
      color: #1ab394 !important; }

#settings .btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429; }

#settings .dropdown-menu {
  left: -50px !important; }
