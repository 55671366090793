#main-reactivate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .checkbox-label::before {
        top: 0.125rem;
    }
    
    .checkbox-label::after {
        top: 0.125rem;
    }
    
    #storesContainer {
        margin-top: 5%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    
    
    #title {
        font-size: large;
    }

    #push {
        height: 30px;
    }
    
    @media(max-width: 992px) {
        #storesContainer {
            min-height: 140px;
            margin-top: 10%;
        }
    
        #title {
            margin-top: 5%;
            font-size:  medium;
        }

        #push {
            height: 75px;
        }
    }

    @media(min-width: 991px) {
        #reactivateButtonContainer {
            margin-top: 5%;
        }
    }
}