.clientsAnalyticsCollVisContainer{
    .mobile-column{
        display: flex;
        align-items: center;    
        .mobile-image-container{
            width: 10%;
            display: inline-block;
            margin-right: 16px;
        }
        .mobile-info-container{
            display: inline-block;
            width: 80%;
            span{
                display: block;
            }
        } 
    }
    .seeActivityBtn{
        padding: 5px 13px;
        width: max-content;
        text-transform: inherit;
        font-weight: 600;
    }
    .avatarField{
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-position: center; 
        background-size: cover;
    }
  .botbit-table .botbit-table-card-header .table-title >div{
    font-size: 12px;
    font-weight: normal;
  }
  .collection-visualizer-header{
    margin-bottom: 34px;
    &.mobile {
        margin: 0;
    }
    .collection-visualizer-title-container{
        width: 100%;
        text-align: center;
        .collection-visualizer-title{
            font-weight: 300;
            font-size: 25px;
            @media (max-width: 576px) {
                font-size: 18px;
            }
            margin-right: 0;
        }
    }
    .botbit-action-button{
        padding: 7px 13px;
    }
  }
  .header-actions-container.mobile{
      margin: 0;
  }
  .botbit-table-card{
      font-weight: 600;
      color: black;
      font-size: 12px;
      box-shadow: none;
      background: inherit;
      > .card-header:hover::after, .botbit-table-expanded-row::after {
          display: none;
      } 
      &:hover{
          background-color: #E9F6F3;
      }
  }
}