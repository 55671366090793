.minimal-leaderboard {
    &_card {
        padding: 10px;
        margin: 0 0.3rem;
        .admins-container {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: flex-end;
            .admin-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                &_container {
                    position: relative;
                    .admin-medal {
                        font-size: 1.2rem;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        @media (min-width: 768px) {
                            font-size: 1.5rem;
                        }
                    }
                }
                .total-sents {
                    display: block;
                    @media (min-width: 768px) {
                        font-size: 1.2rem;
                    }
                }
                img {
                    object-fit: cover;
                    object-position: center;
                    width: 68px;
                    aspect-ratio: 1 / 1;
                    @media (min-width: 768px) {
                        width: 75px;
                    }
                }
                &:nth-child(1) {
                    order: 2;
                    margin-bottom: 1rem;
                }
                &:nth-child(2) {
                    order: 1;
                }
                &:nth-child(3) {
                    order: 3;
                }
            }
        }
    }
    .minpodium-empty-state {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}