$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .conversation-creation {
    .new-contact {
      width: 100%;
    }
    .autocomplete-input {
      margin-left: 0px !important;
    }
    .react-autosuggest__suggestions-container {
      position: relative !important;
    }
  }
}
.conversation-creation {
  background-color: rgba($color: white, $alpha: 0.6);
  #cancel-creation {
    position: absolute;
    z-index: 1;
    padding: 0;
    right: 10px;
  }
  .pl-075 {
    padding-left: 0.75rem !important;
  }
  .md-form {
    margin-bottom: 0.4rem;
    label {
      color: rgb(103, 106, 108);
    }
  }
  .react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    background-color: white;
    .react-autosuggest__suggestion--highlighted {
      background-color: lavender;
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
