.campaignFromToContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  display: inline-block;
  width: 100%;
  margin-right: 0;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  font-weight: 600; }
  .campaignFromToContainer div > span {
    width: auto;
    padding-top: 8px; }
  .campaignFromToContainer .fromOrTo {
    float: left;
    width: 100%; }
  .campaignFromToContainer .fromToLabel {
    min-width: 140px;
    max-width: 140px;
    display: inline-block;
    font-weight: 600; }
    @media (max-width: 576px) {
      .campaignFromToContainer .fromToLabel {
        width: 100%; } }
    @media (max-width: 576px) {
      .campaignFromToContainer .fromToLabel {
        width: 100%;
        max-width: 100%;
        margin-top: 10px; } }
  .campaignFromToContainer .fromToMessage {
    word-break: break-all;
    max-width: 100%; }
  .campaignFromToContainer .replyToAddress .error {
    border-color: var(--danger) !important; }
    .campaignFromToContainer .replyToAddress .error:focus {
      box-shadow: 0 1px 0 0 var(--danger) !important; }
  .campaignFromToContainer .replyToAddress label {
    color: var(--danger);
    font-weight: 400;
    bottom: -7px; }
  .campaignFromToContainer #fromEmailSelector {
    display: inline-block;
    width: 35%; }
    @media (max-width: 576px) {
      .campaignFromToContainer #fromEmailSelector {
        width: 100%; } }
    @media (min-width: 576px) {
      .campaignFromToContainer #fromEmailSelector input {
        margin-bottom: 0; } }
    .campaignFromToContainer #fromEmailSelector .caret {
      top: inherit; }
    .campaignFromToContainer #fromEmailSelector + div {
      /* input container */
      display: inline-block;
      margin-left: 5%;
      width: 60%;
      position: relative; }
      .campaignFromToContainer #fromEmailSelector + div .error {
        border-color: var(--danger) !important; }
        .campaignFromToContainer #fromEmailSelector + div .error:focus {
          box-shadow: 0 1px 0 0 var(--danger) !important; }
      @media (max-width: 576px) {
        .campaignFromToContainer #fromEmailSelector + div {
          width: 100%;
          margin-left: 0; }
          .campaignFromToContainer #fromEmailSelector + div .md-form {
            margin: 0; } }
      .campaignFromToContainer #fromEmailSelector + div label {
        position: absolute;
        color: var(--danger);
        font-weight: 400;
        bottom: -7px; }
  .campaignFromToContainer .fromEmailCompanyLabel {
    font-weight: 400; }
