.gbp-details-container {
  .grid-container {
    display: grid;
    grid-template-columns: 758px 410px;
    gap: 32px;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
  }

  .first-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .checkbox-input-example {
    background-color: #c8f3e0 !important;
    border: 1px solid #00974e !important;
    border-radius: 4px !important;
    width: 14px;
    height: 14px;
    
  }

  .checkbox-label-example {
    font-weight: 400;
    font-size: 12px;
    color: #1a1a1a;
  }

  .p-modal-body {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }

  .title-text {
    margin-bottom: 0.5rem;
  }

  .title-img {
    margin-bottom: 0.5rem;
  }

  .insignia-container-header {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .insignia-no-applied {
    background-color: #fcefde;
    border: 1px solid #ff9407;
    border-bottom: none;
    border-radius: 4px;
  }

  .insignia-field .message-footer {
    border: 1px solid #ff9407 !important;
    border-top: none !important;
  }

  .insignia-applied {
    background-color: #e5e7e9;
    border: 1px solid #e5e7e9;
    border-radius: 4px;
  }

  // .first-column .row-1 {
  //   width: 758px;
  //   height: auto;
  //   min-height: 99px;
  //   background-color: #f1f1f1;
  //   gap: 12px;
  // }

  // .first-column .row-2 {
  //   width: 758px;
  //   height: auto;
  //   min-height: 109px;
  //   padding: 0px 8px 0px 0px;
  //   gap: 8px;
  //   background-color: #e8e8e8;
  // }

  .outstanding-container {
    padding: 12px 12px 12px 0;
    margin-bottom: 12px;
  }

  .outstanding-score-container {
    align-items: center;
    flex-direction: column;
    width: 750px;
    padding-left: 12px;
    align-items: flex-start;
    padding: 13px;
    border-radius: 4px;
  }

  .outstanding-score-link {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }

  .first-column .row-3 {
    width: 757px;
    height: auto;
    min-height: 106px;
    gap: 8px;
    background-color: #dcdcdc;
  }

  .first-column .row-4 {
    width: 757px;
    height: auto;
    min-height: 103px;
    gap: 8px;
    background-color: #d3d3d3;
  }

  .first-column .row-5 {
    width: 757px;
    height: auto;
    min-height: 272px;
    gap: 8px;
    background-color: #c9c9c9;
  }

  .first-column .row-6 {
    width: 757px;
    height: auto;
    min-height: 112px;
    gap: 8px;
    background-color: #bfbfbf;
  }

  .first-column .row-7 {
    width: 757px;
    height: auto;
    min-height: 78px;
    gap: 8px;
    background-color: #b3b3b3;
  }

  .first-column .row-8 {
    width: 757px;
    height: auto;
    min-height: 83px;
    gap: 8px;
    background-color: #a9a9a9;
  }

  .second-column {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .second-column .row-9 {
    width: 100%;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }
  .second-column .row-9 iframe {
    width: 100%;
    height: 100%;
    max-height: 250px;
    border: none;
  }

  .second-column .row-10 {
    width: 420px;
    height: 389px;
    // max-height: 389.13px
    // background-color: #ececec;
    padding: 0px 0px 30px 0px;
    gap: 12px;
    border: 0px 0px 1px 0px;
    gap: 12px;
  }

  .input-container {
    position: relative;
    border: none !important;
    width: 100%;
  }

  .children-container {
    .input-prefix {
      border-top: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      border-right: none;
      border-bottom: none;
      border-radius: 0 0 0 0;
      background-color: white;
      padding-top: 9px;
    }
  }

  .form-group{
    .input-prefix {
      border: 1px solid #d9d9d9;
      border-radius: 0;
      background-color: white;
      border-right: none;
      padding: 9px;
    }
    .custom-text-input {
      border-radius: 0;
      border: 1px solid #d9d9d9;
      border-left: none;
    }
  }

  .edit-button {
    position: absolute;
    background-color: transparent;
    color: #2383e2;
    top: 17%;
    right: 1%;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 10px;
    border: none;
    cursor: pointer;
  }

  .upload-button2 {
    position: absolute;
    background-color: transparent;
    
    color: #13589D;
    font-weight: 600 !important;
    top: 17%;
    right: 1%;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 10px;
    border: none;
    cursor: pointer;
  }

  .action-buttons {
    position: absolute;
    top: 17%;
    right: 1%;
    margin-right: 10px;
    display: flex;
    gap: 12px;
  }
  .action-buttons-text-area {
    position: absolute;
    top: 85%;
    right: 2%;
    background-color: transparent;
    font-size: 14px;
    color: #2383e2;
    font-weight: normal;
    border: none;
    cursor: pointer;
  }

  .cancel-button {
    background: none;
    border: none;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: right;
    color: #9291a5;
    cursor: pointer;
    padding: 4px 10px;
  }

  .apply-button {
    background: none;
    border: none;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: right;
    color: #2383e2;
    cursor: pointer;
    padding: 4px 10px;
    text-decoration: underline;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  //

  .button-back {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 4px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 10px 10px 0px;
    border: none;
    width: fit-content;
    cursor: pointer;
  }

  .button-back:hover {
    color: #1b6bba;
  }

  .card-categories {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 12px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    //box-sizing: border-box;
    //border-radius: 0px 0px 4px 4px;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16), 0px -1px 0px rgba(0, 0, 0, 0);
  }

  .card-button-categories {
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: normal;
    margin-right: 10px;
    margin-top: 10px;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    width: 35%;
    cursor: pointer;
  }

  .card-button-modal-1 {
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    width: auto;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  }

  .card-button-modal-2 {
    background-color: white;
    color: #2383e2;
    border: 1px solid #2383e2 !important;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    width: auto;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  }

  .card-button-modal:disabled {
    background-color: #2383f5;
    color: white;
    cursor: not-allowed;
    opacity: 0.4;
  }

  .no-highlighted-button-textarea {
    display: flex;
    align-items: center;
    gap: 2px;  // Espacio entre elementos
  }
  
  .character-counter {
    font-size: 12px;
    font-weight: 600 !important;
    position: absolute;
    right: 675px;
    
    &.error {
      color: #ff4444;
    }
    
    &.success {
      color: #9291a5;
    }
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .modal-custom-size {
    width: 405px;
  }

  .justify-around {
    display: flex;
    justify-content: space-around;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .modal-content {
    // height: 100%;
  }

  .modal-categories {
    max-width: none !important;
    width: 600px !important;
    height: 617px !important;
    margin: auto !important;
    
    .modal-footer {
      border-radius: 4px !important;
    }
  }

  //Modal insignia
  .modal-insignia-spinner-title {
    text-align: center;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32.68px;
    color: #1a1a1a;
    margin: 0px;
    width: 75%;
  }
  .modal-insignia-container-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 40px;
  }
  .modal-insignia-container-spinner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 40px 0px;
    gap: 8px;
  }

  .modal-insignia-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px;
    gap: 8px;
    width: 100%;
  }
  .modal-insignia-content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
  .modal-insignia-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }
  .modal-group-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
  }
  .modal-danger-message {
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    color: #e12429;
    margin-bottom: 0px;
  }
  .modal-danger-message-bold {
    font-weight: 700;
  }
  .modal-insignia-bonus-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  .modal-insignia {
    width: 775px !important;
    height: 400px !important;
    border-radius: 8px;
    padding: 24px;
  }
  .modal-insignia-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: left;
    color: #1a1a1a;
    margin: 0px;
  }
  .modal-insignia-subtitle {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 0px;
  }
  .modal-insignia-text {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 0px;
  }
  .modal-insignia-bonus {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #13589d;
    margin-bottom: 0px;
  }
  .modal-insignia-button {
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    width: 150px;
    cursor: pointer;
  }
  .modal-insignia-button:hover {
    background-color: #2078d0;
  }

  .modal-insignia-outline-button {
    border: 1px solid #2383e2;
    border-radius: 4px;
    padding: 4px 10px;
    font-weight: 600;
    color: #2383e2;
    width: 150px;
    cursor: pointer;
  }

  .flex-row-modal {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .margin-0 {
    margin: 0px;
  }

  .w-50 {
    width: 50%;
  }

  .w-75 {
    width: 75%;
  }

  .d-flex-column {
    display: flex;
    flex-direction: column;
  }

  .d-flex-row {
    display: flex;
    flex-direction: row;
  }

  .gap-2 {
    gap: 8px;
  }

  .gap-4 {
    gap: 16px;
  }

  .m-0 {
    margin: 0px;
  }

  .mt-4 {
    margin-top: 8px;
  }

  .modal-categories-header {
    border-bottom: none;
    padding-bottom: 0px;
  }

  .modal-pt-0 {
    padding-top: 0px !important;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .pt-0 {
    padding-top: 0px;
  }

  .pb-0 {
    padding-bottom: 0px;
  }

  .spacer {
    padding: 16px 0px 16px 0px;
  }

  .chips-success {
    background-color: #c8f3e0;
    border: 1px solid #00974e !important;
    border-radius: 4px !important;
    padding: 1px 8px;
    font-weight: 600;
    color: #1a1a1a;
  }

  .chips-secondary {
    background-color: #ffffff;
    border: 1px solid #e5e7e9 !important;
    border-radius: 4px !important;
    padding: 1px 8px;
    font-weight: 600;
    color: #1a1a1a;
  }

  .bg-suggestions {
    background-color: #edf6ff;
    border-radius: 6px;
    padding: 16px;
    width: 100%;
  }

  .bg-current {
    border-radius: 6px;
    padding: 16px;
  }

  .font-bold-600 {
    font-weight: 600;
    color: #1a1a1a;
  }

  .font-normal {
    font-weight: 400;
    color: #676a6c;
    margin-bottom: 0px !important;
  }

  .mb-0-important {
    margin-bottom: 0px !important;
  }

  .mt-0-important {
    margin-top: 0px !important;
  }

  .subtitle-modal {
    font-weight: 400;
    color: #1a1a1a;
    font-size: 12px;
  }

  .modal-finish {
    width: 583px !important;
    height: 361px !important;
    border-radius: 8px !important;
  }

  .modal-finish-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
  }

  .modal-finish-title {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
  }

  .modal-insignia-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
  }

  .modal-finish-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }

  .modal-finish-bonus {
    display: flex;
    border-radius: 24px;
    background-color: #13589d;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    padding: 0px 20px;
  }

  .modal-insignia-container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #13589d;
    border-radius: 4px;
  }

  .verification-container {
    margin-top: 20px;
    width: 750px;
    height: 43px;
    padding: 8px 12px 8px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
  }

  .verification-left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .verification-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .verification-right {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .tutorial-text {
    color: #1eb8ff;
    font-weight: 500;
    padding: 8px;
    padding: 8px;
  }

  .card-categories {
    background-color: #ffffff;
  }
  .actionable-editor-card {
    padding: 12px 12px 12px 0px;
  }
  .save-and-return {
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    width: 175px;
    justify-self: flex-end;
    transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  }
  .save-and-return:hover {
    background-color: #1b6bba;
    color: white;
  }

  .return-to-list-insignia {
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    width: 175px;
  }

  .return-to-list-insignia:hover {
    background-color: #1b6bba;
    color: white;
  }

  .title-categories {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.34px;
    color: #1a1a1a;
  }
  .highlight-insignia {
    background-color: #fcefde;
    border: 1px solid #dc8a1d;
  }
  //Local insignia
  // Incompleto
  .container-alert-insignia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f7d5ab;
    border-radius: 4px;
    gap: 12px;
    margin-bottom: 20px;
  }
  .container-alert-insignia-success {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #edf6ff;
    border-radius: 4px;
    gap: 12px;
    margin-bottom: 20px;
  }
  .container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 46px;
    background-color: #13589d;
    border-radius: 4px 0px 0px 4px;
  }
  .mini-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 70%;
  }
  .alert-title-insignia {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #1a1a1a;
  }
  .button-alert-insignia {
    padding: 4px 16px;
    border-radius: 3px;
    font-weight: 600;
    background-color: #2e4050;
    border: 0px;
    color: white;
    margin-right: 8px;
    cursor: pointer;
  }
  .button-alert-insignia:hover {
    background-color: #24323e;
  }
  .circle-categories {
    width: 22px;
    height: 22px;
    background: #D9D9D9;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .cross-categories-1 {
    border: 1px solid var(--Light-Text, #1F2D3D);
    width: 14px; 
    height: 2px;
    transform: rotate(45deg); 
    position: absolute;
    border-radius: 2px;
  }
  .cross-categories-2 {
    border: 1px solid var(--Light-Text, #1F2D3D);
    width: 14px; 
    height: 2px;
    transform: rotate(-45deg); 
    position: absolute;
    border-radius: 2px;
  }

  .additional-text {
    align-self: center;
    font-size: 12px;
    color: #1a1a1a;
    font-weight: 400;
    margin-top: 16px;
    width: 150px;
  }

  .suggestions-and-advice-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
  }

  //estilos logo
  .logo-container-gbp {
    width: 754px;
    min-height: 127px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #d9d9d9;
    border-radius: 4px 4px 0 0;
    overflow: hidden;

  }

  .logo-content-gbp {
    font-weight: 400;
    font-size: 16px;
    line-height: 21.79px;
    width: 100%;
    height: auto;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1A1A1A;
    background-color: #FFFFFF;
  }

  .logo-recommendation-text {
    text-align: center;
    font-size: 12px;
    color: #1A1A1A;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    .recommendation-label {
      font-weight: 700;
    }
  }

  .logo-preview-container {
    width: 100%;
    height: 127px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    background-color: #FFFFFF;
   
    
  

    .company-logo {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 0;
    }
  }

 
  .card-example-highlighted,
  .input-example-highlighted,
  .textarea-example-no-highlighted,
  .logo-container-gbp,
  .card-categories,
  .input-container,
  .logo-preview-container {
    border-radius: 0 !important;
    
    
  }

  
  .logo-container-gbp {
    border-radius: 0 !important;
    border-bottom: none !important;
    
    
  }

  .card-categories {
    border-bottom: none !important;
  }

  .actionable-editor-card .children-container {
    .textarea-example-no-highlighted.insignia-no-applied {
      border-radius: 4px 4px 0 0 !important;
      background-color: #fcefde !important;
      border: 1px solid #ff9407 !important;
      border-bottom: none !important;
    }
  }
}

.tooltip {
    &__views {
        text-decoration: underline;
        text-decoration-style: solid;
        color: #9291A5;
        text-decoration-thickness: 1px;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
    }

    &__views:hover {
        color: #858496;
    }

    &__container {    
        position: relative;
        display: inline-block;
    }

    &__actionable {
        position: absolute;
        top: calc(-100% - 40px);
        font-size: 12px !important;
        transform: translateX(-35%);
        padding: 10px;
        z-index: 1;
        background-color: #FFFFFF;
        min-width: 400px;
        border-radius: 4px;
        color: #1A1A1A;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        pointer-events: auto;
    }
    &__normal-text {
        font-weight: 400;
        font-size: 12px;
    }
    &__text-bold {
        font-weight: bold;
        font-size: 12px;
    }

    &__text-highlighted {
        color: #2383E2;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
        font-size: 12px;
    }
    &__text-highlighted:hover {
        color: #1d70c3;
    }
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
  display: block;
}
