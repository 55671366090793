.reply-warning {
  margin-top: -20px;
  font-size: 0.9em;
  color: #969696; }

.biggerFont {
  font-size: 140%; }

.botbit-review-reply {
  border-left: 3px solid #e5e5e5;
  padding: 5px 0 0 12px;
  line-height: 18px; }

.botbit-generated-review, .botbit-review-testimonial {
  background: #F5F9FA; }

.botbit-generated-review > .card-header:hover::after, .botbit-generated-review .botbit-table-expanded-row::after {
  background: #33A0B4; }

.cursor-pointer, .botbit-link {
  cursor: pointer; }

.botbit-link {
  color: #1c84c6; }

.row-title {
  font-weight: bold;
  font-size: 1.1em;
  padding-bottom: 0.25em; }

.is-invalid-important {
  border-color: red !important; }

.available-tags-title {
  font-size: 1rem;
  font-weight: bold; }

.margins-reduced > .md-form {
  margin-top: 0.25em;
  margin-bottom: 0.75em; }

.margins-reduced > .md-form.md-outline {
  margin-top: 0.25;
  margin-bottom: 0.75em; }

.md-outline input, .md-outline > textarea {
  font-size: 11pt !important; }
  .md-outline input + label, .md-outline > textarea + label {
    font-size: 11pt !important; }
  .md-outline input:focus, .md-outline > textarea:focus {
    border-color: #1ab394 !important;
    box-shadow: inset 0px 0px 0px 1px #1ab394 !important; }
    .md-outline input:focus + label, .md-outline > textarea:focus + label {
      color: #1ab394 !important; }

.answer-stat {
  font-style: italic;
  font-size: 11px; }

.review-text {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  font-weight: 300 !important; }
  .review-text .btn-floating {
    border-radius: 50%;
    box-shadow: none; }
  .review-text strong {
    font-weight: bold; }
  .review-text .btn-agregar-template {
    color: #676a6c !important; }
  .review-text label {
    font-weight: 300; }

.review-info {
  font-size: 0.9rem; }
  .review-info strong {
    font-weight: bold; }
