ul.horizontal {
  align-content: center;
  align-items: center;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-size: 1em; }

li.horizontal {
  background: #2f4050;
  display: flex;
  flex-grow: 1;
  height: .3em;
  position: relative; }

li.horizontal::before {
  color: white;
  background: #2f4050;
  border-radius: 50%;
  counter-increment: stepCount;
  content: counter(stepCount);
  left: -2em;
  line-height: 2em;
  position: absolute;
  text-align: center;
  top: -.85em;
  width: 2em;
  height: 2em;
  font-size: 1.5em; }

li.horizontal.active {
  background-color: lightblue; }

li.horizontal.active ~ li {
  background-color: lightblue; }

li.horizontal.active ~ li::before {
  background-color: lightblue; }

li.horizontal:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  /* Shorthand: flex: 0 1 0; */ }

/****** VERTICAL******* */
ul.vertical {
  align-content: flex-start;
  align-items: flex-start;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  flex-direction: column; }

li.vertical {
  display: flex;
  background: white;
  flex-grow: 1;
  height: 6em;
  position: relative;
  border-left: .3em #2f4050 solid;
  padding-left: 1.5em;
  font-size: 1em; }

li.vertical::after {
  color: white;
  background: #2f4050;
  border-radius: 50%;
  counter-increment: stepCount;
  content: counter(stepCount);
  line-height: 2em;
  position: absolute;
  text-align: center;
  left: -1.2em;
  top: 0em;
  width: 2em;
  height: 2em;
  font-size: 1.5em; }

li.vertical.active {
  border-left: .3em lightblue solid; }

li.vertical.active ~ li {
  border-left: .3em lightblue solid; }

li.vertical.active ~ li::after {
  background-color: lightblue; }

li.vertical:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  /* Shorthand: flex: 0 1 0; */ }

li.vertical span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
  background-color: white; }
