.task-disabled {
    background: #e0e0e0;
}

.task-title {
    font-weight: bold;
    font-size: 1.5em;
}

.task-title-disabled {
    font-weight: bold;
    font-size: 1.5em;
    color: gray;
}

.arrow-disabled {
    color: gray;
}

.task-content {
    padding: 1rem;
}

.width-md-50 {
    @media (min-width : 768px) {
        width: 50%;
    }
}