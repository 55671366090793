.data-card-container {
  margin-bottom: 20px;
  .data-cards {
    display: flex;
    justify-content: space-evenly;
    min-height: 220px;
    .data-card {
      width: 32%;
      @media (max-width: 991px) {
        width: 100%;
        margin: auto;
      }
    }
    .empty-state {
    @media (max-width: 576px) {
      height: 300px;
    }
    }
  }
  .data-cards-footer {
    background: #d0e0e3;
    padding: 10px;
    margin-top: 15px;
    color: black;
    font-weight: 400;
    a {
      color: #337ab7;
      cursor: pointer;
      border-bottom: 1px solid;
    }
  }

  .alice-carousel__wrapper {
    @media(max-width: 991px) {
      padding-right: 0 !important;
    }
  }

  .alice-carousel__stage-item {
    @media(max-width: 991px) {
      width: 100% !important;
      padding: 0 15px;
    }
  }

  .alice-carousel {
    @media(max-width: 991px) {
      width: calc(100% + 30px) !important;
    }
  }

  .dashboard-element-container {
    @media(max-width: 991px) {
      width: 100% !important;
      padding: 0 15px;
    }
  }

  .filters-first-line mobile {
    padding: 0 15 px;
  }

  .mobile header-actions-container {
    padding: 0 15 px;
  }
}
