@mixin card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc( 100vh - 101px );
    margin-top: 0;
    margin-bottom: 0;
}
@mixin card-styles {
    width: 880px;
    padding: 50px;
    border-radius: 15px;
    @media (max-width: 992px) {
        padding: 25px;
    }
}