.messenger-core {
  height               : 85vh;
  background-image     : url('/static/img/whatsapp-background.png');
  background-position  : 0% 0%;
  background-size      : auto auto;
  background-repeat    : repeat;
  background-origin    : padding-box;
  background-clip      : border-box;
  background-attachment: scroll;
  background-color     : transparent;
  position             : relative;

  .btn-flat.btn.Ripple-parent.single-default-action.btn-circle {
    color: white !important;
  }

  .conversation-header {
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-content : space-between;
    background-color: var(--primary);
    color           : #ffffff;
    padding         : 0.5rem 0.8rem;
    margin-bottom   : 1rem;

    .conversation-info {
      display       : flex;
      flex-direction: row;
      align-items   : center;

      .avatar-container {
        margin-right: 1rem;
      }
    }

    .conversation-actions {
      display       : flex;
      flex-direction: row;
      align-items   : center;

      a[role='button'] {
        margin: 0;

        @media (max-width: 768px) {
          width: 32px;
        }
      }
    }

    .collapse.show,
    .collapsing {
      position        : absolute;
      background-color: white;
      top             : 1.5rem;
      z-index         : 1;
      width           : 100%;
      border-radius   : 0 0 0.25rem 0.25rem;
    }

    .card {
      max-width: 300px;
      height   : 1.7rem;
      cursor   : pointer;

      #header {
        display: block !important;
        margin : auto;
        width  : 100%;

        &>div {
          width     : 100%;
          text-align: center;
        }

        .fa {
          display: none;
        }
      }
    }

    .not-collapsed-label {
      text-overflow: ellipsis;
      overflow     : hidden;
      white-space  : nowrap;
      width        : 100%;
      display      : inline-block;
      padding      : 3px;
    }
  }

  .administrator-selector-container {
    .select-in-dropdown-container {
      // max-height: 60vh;
      width     : 20vw;
      overflow-y: auto;
      padding   : 1em;
    }

    .key-administrator-selector {
      border-radius   : 3px;
      text-transform  : none;
      font-size       : 14px;
      line-height     : 19px;
      font-weight     : 600;
      border-width    : 1px !important;
      margin          : 0 5px;
      padding         : 5px 10px !important;
      background-color: white !important;
    }

    @media (max-width: 992px) {
      margin: 5px 0 0 0;
      width : 100%;
      float : left;

      .key-administrator-selector {
        margin : 0 !important;
        padding: 15px 10px !important;
        width  : 100%;
      }
    }
  }

  .image-file-input {
    position: absolute;
    overflow: hidden;
    height  : 0px;
    width   : 0px;
    opacity : 0;
    left    : 0px;
    top     : 0px;
    display : none;
  }

  .img-previewer-container {
    display         : flex;
    align-items     : center;
    position        : fixed;
    padding         : 0px 60px 0px 60px;
    left            : 0;
    top             : 0;
    width           : 100%;
    height          : 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index         : 10000;
    box-sizing      : border-box;

    .close-icon {
      color      : white;
      position   : absolute;
      top        : 15px;
      right      : 15px;
      font-size  : 40px;
      font-weight: bold;
      opacity    : 0.2;
      cursor     : pointer;
    }

    .img-container {
      margin    : auto;
      padding   : 0;
      width     : 90%;
      height    : 100%;
      max-height: 100%;
      text-align: center;

      .img-object {
        height         : 100%;
        display        : flex;
        align-items    : center;
        justify-content: center;

        img {
          max-height         : 100%;
          max-width          : 100%;
          user-select        : none;
          -moz-user-select   : none;
          -webkit-user-select: none;
        }

        embed {
          max-height         : 100%;
          max-width          : 100%;
          height             : 80%;
          width              : 100%;
          user-select        : none;
          -moz-user-select   : none;
          -webkit-user-select: none;
        }
      }
    }
  }

  .messages-container {
    overflow  : auto;
    max-height: 63%;

    ul {
      padding: 0;

      .msg-container {
        overflow     : auto;
        margin-bottom: 0.5rem;
      }
    }

    .msg-bubble {
      max-width       : 70%;
      padding         : 0.4rem 0.8rem;
      float           : left;
      background-color: #ffffff;
      border-radius   : 0.5rem 0.5rem 0.5rem 0;
      box-shadow      : rgba($color: #000000, $alpha: 0.1) 1px 1px 6px;
      list-style      : none;
      color           : #000000;
      font-weight     : 400;
      margin          : 0 1rem;
      word-break      : break-word;
      position        : relative;

      .img-message-container {
        display        : flex;
        justify-content: center;
        align-items    : center;

        .img-message {
          width     : auto;
          height    : auto;
          max-width : 100%;
          max-height: 300px;
          object-fit: contain;
        }
      }

      .message-audio-container {
        display          : block;
        //justify-content: center;
        align-items      : left;

        @media (max-width: 500px) {
          .message-audio {
            //margin: 0 !important;
            //padding: 15px 10px !important;
            //max-width: 235px;
            transform       : scale(0.75);
            transform-origin: left;
          }
        }
      }

      .message-file-container {
        .message-file-preview {
          text-align: center;

          img {
            max-width : 60px;
            max-height: 60px;
          }
        }

        .message-file-description-container {
          max-width: 200px;
          overflow : hidden;

          .message-file-description {
            white-space  : nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }
        }

      }


      .botbit-image {
        cursor: pointer;
      }

      &.direction-out {
        float           : right;
        border-radius   : 0.5rem 0.5rem 0 0.5rem;
        background-color: #1ab394;
        color           : #ffffff;
      }

      &.direction-none {
        background-color: rgba(240, 240, 240, 0.7);
        border-radius   : 0.5rem;
        width           : 80%;
        max-width       : 80%;
        text-align      : center;
        margin          : 0 10%;
      }

      .viewed-for {
        display  : block;
        font-size: 0.6rem;
        opacity  : 0.7;
      }

      .check-icon {
        position : absolute;
        right    : 5px;
        bottom   : 5px;
        font-size: 0.6rem;

        &.icon-blue {
          color: #0b30ea;
        }

        &.icon-gray {
          opacity: 0.7;
        }
      }
    }

    .referred-message-wrapper {
      background-color: rgba(0, 0, 0, 0.12);
      padding         : 3px;
      border-radius   : 0.5rem 0.5rem 0 0;
      padding-bottom  : 1px;

      li.msg-container {
        margin: 0 !important;

        span.msg-bubble {
          max-width : unset !important;
          box-shadow: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  .custom-input-container {
    width   : 100%;
    position: absolute;
    bottom  : 0;
    padding : 0.5rem;

    .text-input-bar {
      display         : flex;
      flex-direction  : column;
      width           : 100%;
      position        : relative;
      background-color: #ffffff;
      border          : 2px solid rgba(0, 0, 0, 0.36);
      border-radius   : 5px;
      transition      : 0.3s;

      &:focus,
      &:active {
        border-color: var(--primary);
      }

      .textarea-container {
        width: 100%;

        .md-form {
          margin: 0;

          textarea {
            resize          : none;
            margin          : 0;
            background-color: none;
            border          : none;
            padding-left    : 0;
            padding         : 0.5rem 0.725rem;

            &:hover,
            &:focus {
              border      : none !important;
              border-color: unset !important;
              box-shadow  : unset !important;
            }
          }
        }
      }

      .actions-row {
        display        : flex;
        width          : 100%;
        flex-direction : row;
        align-items    : center;
        justify-content: space-between;
        border-top     : 2px solid rgba(0, 0, 0, 0.12);

        .input-actions {
          display       : flex;
          flex-direction: row;

          &.left {
            flex: 1;

            .btn-flat {
              @media screen and (min-width: 768px) {
                margin-left   : 0.6rem;
                font-size     : 0.8rem;
                text-transform: unset;
              }
            }
          }
        }

        .dropdown {
          .dropdown-menu {
            transform            : translateY(-0.6rem) !important;
            max-height           : 70vh;
            overflow-y           : auto;
            min-width            : 100% !important;
            background-attachment: local, local, scroll, scroll;
          }
        }

        .template-selector-trigger {
          position : absolute;
          overflow : hidden;
          height   : 0px;
          width    : 0px;
          opacity  : 0;
          left     : 0px;
          top      : 0px;
          display  : none;
          transform: translate(1rem, -5rem);
        }

        a.btn-flat[role='button'] {
          padding         : 0;
          margin          : 0;
          background-color: unset;
          color           : rgba(0, 0, 0, 0.42);

          i {
            color    : inherit;
            font-size: 1.2rem;
          }
        }
      }
    }

    .emoji-picker-react {
      position: absolute;
      bottom  : 2.5rem;
      left    : 0.5rem;
      z-index : 1;
    }
  }

  #ReactSimpleImageViewer {
    z-index         : 10000;
    background-color: rgba($color: #000000, $alpha: 0.8);
  }

  .img-previewer-input-container {
    position : absolute;
    width    : 480px;
    max-width: 95%;
    bottom   : 5px;
    left     : 50%;
    transform: translateX(-50%);

    .custom-input-container {
      left: 0;

      .text-input-bar {
        background-color: #ffffff;
      }
    }
  }

  .chat-empty-state {
    width           : 100%;
    height          : 100%;
    display         : flex;
    flex-direction  : column;
    flex            : 1;
    justify-content : center;
    align-items     : center;
    background-color: #ffffff;
    border-left     : 1px solid #ccc;
  }

  .assign-conversation {
    .dropdown-content {
      max-height: 60vh;
    }
  }
}

.lds-ellipsis {
  display  : inline-block;
  position : relative;
  width    : 80px;
  height   : 80px;
  margin   : auto;
  left     : 50%;
  transform: translateX(-50%);

  div {
    position                 : absolute;
    top                      : 33px;
    width                    : 0.5rem;
    height                   : 0.5rem;
    border-radius            : 50%;
    background               : var(--primary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left     : 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  div:nth-child(2) {
    left     : 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(3) {
    left     : 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(4) {
    left     : 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
}