@charset "UTF-8";
#give-nps {
  background-color: #EEEEEE;
  border-top-color: #e3e3e3;
  border-bottom-color: #e3e3e3;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column; }
  #give-nps .nps-header {
    background-color: #5F5F5F;
    border-color: #3D4351;
    color: #3D4351;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 216px; }
    #give-nps .nps-header > div {
      border-radius: 50%;
      margin-top: 20px;
      margin-bottom: 20px; }
  @media (max-width: 992px) {
    #give-nps .nps-body {
      padding: 20px; } }
  #give-nps .nps-body h2 {
    font-size: 22px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
    color: #111111;
    text-align: center;
    margin-bottom: 20px; }
  #give-nps .nps-body p {
    font-size: 19px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 30px;
    color: #111111;
    text-align: center; }
  #give-nps .give-nps-buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 15px; }
    @media (max-width: 506px) {
      #give-nps .give-nps-buttons {
        margin: 0 auto; } }
    #give-nps .give-nps-buttons button {
      text-align: center;
      padding: 1px 6px;
      width: 42px;
      height: 40px;
      margin-bottom: 10px;
      border-radius: 6px;
      background: #ffffff;
      border-color: #cccccc #cccccc #cccccc;
      border-style: solid;
      border-width: 1px 1px 3px;
      font-size: 18px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: bold;
      margin-right: 4px;
      cursor: pointer; }
    #give-nps .give-nps-buttons .disclaimers-container {
      width: 506px; }
      @media (max-width: 506px) {
        #give-nps .give-nps-buttons .disclaimers-container {
          width: 100%; } }
      #give-nps .give-nps-buttons .disclaimers-container .left-disclaimer {
        font-size: 17px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #fa5858;
        display: inline-block;
        width: 50%;
        text-align: left; }
      #give-nps .give-nps-buttons .disclaimers-container .right-disclaimer {
        font-size: 17px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #04b486;
        display: inline-block;
        width: 50%;
        text-align: right; }
  #give-nps .give-nps-gating {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 15px; }
    @media (max-width: 506px) {
      #give-nps .give-nps-gating {
        width: 320px !important;
        margin: 0 auto; } }
    #give-nps .give-nps-gating .gating-progress-bar {
      width: 70%; }
  #give-nps .powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px; }
    #give-nps .powered-by span {
      margin-right: 1em; }

.star {
  margin: 0 5px; }
  .star::before {
    color: #ffb300;
    content: '★'; }
  .star:focus::before, .star:hover::before {
    color: #ffb300;
    content: '★'; }
  .star:focus ~ .star::before,
  .star:hover ~ .star::before {
    color: gray;
    content: '★'; }

.container:not(:focus-within):not(:hover) .star::before {
  color: gray;
  content: '★'; }

.score-selected {
  color: #ffb300;
  font-size: xx-large;
  margin-right: 10px; }

.score-unselected {
  color: gray;
  font-size: xx-large;
  margin-right: 10px; }
