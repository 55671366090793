#reviews-widget {
    padding: 15px;
    @media (min-width: 992px) {
        .filters {
            padding: 15px;
        }
    }

    .premium-tag {
        color: #ac9437;
        margin-bottom: 0.8rem;
        font-weight: 400;
        margin-top: -1rem;
        margin-bottom: 1rem;
        &-container {
            width: 100%;
            transition: 1s;
            overflow: hidden;
            font-size: 0.8rem;
        }
    }

    .header-actions-container {
        margin-right: auto !important;
    }

    .filters-form {
        margin-top: 0 !important;
    }

    .filters-button {
        padding: 10px 30px;
    }

    .filters-first-line {
        margin-bottom: 0;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .separator {    
        margin-top: 0;
    }

    .code {
        @media(min-width: 992px) {
            width: 80%;
        }
    }

    textarea {
        display: none;
    }

    .title {
        font-weight: bold;
        @media(max-width: 991px) {
            font-size: 20px;
        }
        color: black;
        margin-bottom: 25px;
    }

    @media(max-width: 991px) {
        .md-form {
            margin-bottom: 0;
        }
    }
}