.close-modal-button-local-seo-chart-modal {
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border: none;
    width: 72px;
    height: 32px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0;
    color: #FFFFFF;

    span {
        width: 40px;
        height: 16px;
    }
}

.modal-body {
    overflow-x: hidden;
}

.modal-footer{
    border: none;
}

.local-seo-chart-modal-container{
    background: white;
    padding: 20px;
    width: 961px;
    height: 416px;

    .local-seo-chart-modal-header-title {
      margin-top: 40px;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 42px;
      line-height: 100%;
      letter-spacing: 0;
      color: #000000;
    }
  }



 

  .local-seo-chart-header-container-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .local-seo-chart-container {
        margin: 20px 0;
        min-height: 400px;
      }
      
    
      .local-seo-icon-and-subtitle{
        display: flex;
        align-items: center;
        gap: 2px;
      }
    
      .google-maps-subtitle-modal{
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 16.34px;
        color: #353C48;
      }

  }
  
  
