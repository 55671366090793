.gbp-container {
  padding-bottom: 5rem;
  /* first-config-page-container */
  /* spinner */
  /* error-page-container */
  color: #1a1a1a !important; }
  .gbp-container .botbit-table-actions > .dropdown .botbit-dropdown-content {
    min-width: 400px; }
  .gbp-container .header-actions-container.mobile {
    margin-bottom: 1rem; }
  .gbp-container .badge-info {
    background-color: #4b89ff !important; }
  .gbp-container .text-blue {
    color: #1eb8ff; }
  .gbp-container .text-black {
    color: #1a1a1a; }
  .gbp-container .first-config-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh; }
  .gbp-container .first-config-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px; }
  .gbp-container .first-config-page-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 90px;
    margin-bottom: 100px; }
  .gbp-container .first-config-page-body-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 330px; }
  .gbp-container .first-config-page-body-card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 130px;
    width: 300px; }
  .gbp-container .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .gbp-container .spinner {
    animation: spin 1s linear infinite; }
  .gbp-container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%; }
  .gbp-container .title-text {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .gbp-container .last-update {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }
  .gbp-container .loading-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    gap: 50px; }
  .gbp-container .loading-page-header {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .gbp-container .loading-page-body {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .gbp-container .error-page-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 90vh; }
  .gbp-container .error-page-background {
    background-color: transparent; }
  .gbp-container .error-page-background > * {
    opacity: 0.5;
    pointer-events: none; }
  .gbp-container .error-page-front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; }
  .gbp-container .error-page-header {
    display: flex; }
  .gbp-container .error-page-body {
    display: flex; }
  .gbp-container .botbit-table-actions > .dropdown .botbit-dropdown-content {
    min-width: 400px; }
  .gbp-container .header-actions-container.mobile {
    margin-bottom: 1rem; }
  .gbp-container .badge-info {
    background-color: #4b89ff !important; }
  .gbp-container .botbit-table .botbit-table-card-header {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    background-color: transparent;
    font-weight: bold; }
    .gbp-container .botbit-table .botbit-table-card-header .table-title {
      width: 100%; }
      .gbp-container .botbit-table .botbit-table-card-header .table-title > div {
        width: 100%; }
  .gbp-container .text-center.botbit-table-column > div {
    width: 100%; }
  .gbp-container .v2-wrapper.dropdown .dropdown-toggle .dropdown-toggle-label {
    width: 90%; }
  .gbp-container h1 {
    font-family: Open Sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    text-align: left; }
  .gbp-container p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left; }
  .gbp-container h2 {
    font-family: Open Sans;
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;
    text-align: left; }
  .gbp-container h3 {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: left; }
  .gbp-container h4 {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 58px;
    text-align: left; }
  .gbp-container h6 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #1a1a1a; }
  .gbp-container h5,
  .gbp-container .input-prefix {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left; }
  .gbp-container .botbit-table .score-container {
    background-color: transparent; }
  .gbp-container .botbit-table .botbit-table-card .element-content .row-container {
    display: flex;
    align-items: center; }
  .gbp-container .filterContainer {
    display: flex;
    margin-top: 1rem; }
  .gbp-container .metric-container {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 1rem;
    max-width: 154px; }
  .gbp-container .metric-display-container {
    justify-content: flex-start !important; }
  .gbp-container .outstanding-score-container {
    padding-left: 1rem;
    gap: 1.5rem;
    min-height: 121px;
    min-width: 251px;
    align-items: flex-start;
    border-radius: 4px;
    justify-content: center; }
  .gbp-container .caption-container {
    gap: 0px; }
  .gbp-container .outstanding-container {
    align-items: stretch;
    gap: 0px !important;
    padding: 10px 0px;
    max-width: 251px; }
  .gbp-container .caption-metrics-container {
    min-height: 121px;
    height: 121px;
    gap: 0px; }
  .gbp-container .outstanding-score-link {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: 700 !important; }
  .gbp-container .score-value {
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: left; }
  .gbp-container .score-value-porcentage {
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: left; }
  .gbp-container .score-value-container {
    padding: 0px 12px; }
  .gbp-container .score-table .score-value-porcentage {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px; }
  .gbp-container .score-table .score-value {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px; }
  .gbp-container .refresh-button {
    background-color: #2383e2;
    cursor: pointer;
    color: white;
    width: 32px;
    height: 32px;
    padding: 4px 8px 4px 8px;
    gap: 10px;
    border-radius: 3px;
    border: none; }
  .gbp-container .cursor-pointer {
    cursor: default !important; }
  .gbp-container .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
  .gbp-container .table-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #2383e2;
    color: white;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 75%; }
  .gbp-container .fs-14 {
    font-size: 14px; }
  .gbp-container .cta-improve-impact-list {
    margin: 0px !important; }
  .gbp-container .cta-improve-impact-list__title {
    font-family: Open Sans !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    text-align: left !important;
    color: #1a1a1a !important;
    margin-bottom: 0px !important; }
  .gbp-container__insignia {
    border: 2px solid #dc8a1d;
    border-top: 0px; }
  .gbp-container__header-insignia {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffc9534a;
    color: #1a1a1a;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 2px solid #dc8a1d;
    border-bottom: 0px;
    gap: 8px; }
  .gbp-container__message-insignia {
    font-size: 14px;
    font-weight: 400; }
  .gbp-container__container-icon-insignia {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #13589d;
    border-radius: 3px;
    padding: 2px; }
  .gbp-container .all-updated-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1a1a1a; }
  .gbp-container .cta-improve-impact-list {
    margin: 0;
    margin-right: 0.5rem; }
  .gbp-container .filters .filters-and-more-actions.mobile.mobile:not(.more-actions) .filters-actions-container .botbit-dropdown {
    width: 100%; }
  .gbp-container .filters .filters-first-line {
    margin-bottom: 10px;
    color: white; }
  .gbp-container .progress-bar-container {
    background-color: #afafaf;
    color: #ffffff; }
    .gbp-container .progress-bar-container .color-progress-bar {
      background-color: #00c000;
      color: #ffffff; }

.botbit-table__highlighted {
  background-color: #FFFCF5;
  border: 2px solid #dc8a1d;
  border-top: 0px; }

.botbit-table__header-insignia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFC9534A;
  color: #1A1A1A;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid #dc8a1d;
  border-bottom: 0px;
  gap: 8px; }

.botbit-table__message-insignia {
  font-size: 14px;
  font-weight: 400; }

.botbit-table__container-icon-insignia {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: #13589D;
  border-radius: 3px;
  padding: 2px; }

.botbit-table .btn-actionable-insignia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #2E4050;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 75%; }
