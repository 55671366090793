.MuiTooltip-popper {
  font-size: 15px; }

.campaignValidityContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  display: inline-block;
  position: relative; }
  @media (max-width: 576px) {
    .campaignValidityContainer {
      display: block;
      padding-bottom: 25px; } }
  .campaignValidityContainer .validityLabel {
    font-weight: 600;
    min-width: 0;
    display: inline-block;
    margin-right: 7px;
    line-height: 47px; }
  .campaignValidityContainer .wtValidToContainer {
    display: inline-block;
    position: relative; }
    .campaignValidityContainer .wtValidToContainer.hasError {
      margin-bottom: 9px; }
      .campaignValidityContainer .wtValidToContainer.hasError .DayPickerInput button {
        border-bottom: 2px solid var(--danger); }
      .campaignValidityContainer .wtValidToContainer.hasError .error-message {
        position: absolute;
        left: 0;
        bottom: -23px;
        color: var(--danger);
        width: max-content; }
  .campaignValidityContainer .validityInputContainer {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px; }
    .campaignValidityContainer .validityInputContainer .validityInput {
      max-width: 45px;
      margin-bottom: 0; }
    .campaignValidityContainer .validityInputContainer span:not(.error-message) {
      margin-left: 10px;
      margin-bottom: 6px; }
    .campaignValidityContainer .validityInputContainer.hasError .validityInput {
      border-color: var(--danger) !important; }
      .campaignValidityContainer .validityInputContainer.hasError .validityInput:focus {
        box-shadow: 0 1px 0 0 var(--danger); }
    .campaignValidityContainer .validityInputContainer .error-message {
      position: absolute;
      top: 44px;
      left: 148px;
      right: 0;
      width: 230px;
      color: var(--danger); }
      @media (max-width: 576px) {
        .campaignValidityContainer .validityInputContainer .error-message {
          left: 73px; } }
  .campaignValidityContainer .DayPickerInput {
    margin-right: 15px; }
    .campaignValidityContainer .DayPickerInput .dayPickerTrigger {
      color: black;
      border: none;
      background: none;
      padding: 7px 8px;
      border-bottom: 1px solid #ced4da;
      width: 100%; }
      .campaignValidityContainer .DayPickerInput .dayPickerTrigger::placeholder {
        color: black; }
      .campaignValidityContainer .DayPickerInput .dayPickerTrigger:focus {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
        outline: none !important;
        border-bottom: 2px solid var(--primary); }
    .campaignValidityContainer .DayPickerInput .DayPickerInput-OverlayWrapper {
      z-index: 3; }
    .campaignValidityContainer .DayPickerInput .DayPicker-Footer {
      display: flex;
      justify-content: center; }
  .campaignValidityContainer .validityDisclaimer {
    color: dodgerblue; }
