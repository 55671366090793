.google-views-graph {
  position: relative;
  margin-top: 0 !important; }
  .google-views-graph #botbit-logo-floating {
    display: none; }
    .google-views-graph #botbit-logo-floating img {
      width: 50px; }
      @media (max-width: 996px) {
        .google-views-graph #botbit-logo-floating img {
          width: 35px; } }
  .google-views-graph .empty-state {
    height: 300px; }
  .google-views-graph .graph-card {
    display: flex;
    justify-content: space-between; }
    .google-views-graph .graph-card .disclaimer {
      font-size: 10px; }
