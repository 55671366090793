.qr-settings-container {
  .col {
    padding: 0 0.8rem;
  }
  .row {
    margin-bottom: 1rem;
    // align-items: center;
  }
  .md-form {
    // width: 100%;
    margin: 0;
  }
}