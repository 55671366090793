.actionable-side-bar-container {
  display: flex;
  flex-direction: column;
  width: 375px;
  gap: 32px;
  border-radius: 8px;
  border-width: 1px;
  padding-top: 18px;
  padding-right: 12px;
  padding-bottom: 18px;
  padding-left: 12px;
  background-color: #F3F6FB;
  border: 1px solid #E9E9E9; }
  .actionable-side-bar-container .section-header__title__h4 {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #1A1A1A;
    margin-top: 0px; }
  .actionable-side-bar-container .score-and-actionable-container {
    width: 351px;
    height: 277px;
    display: flex;
    flex-direction: column;
    gap: 44px; }
    .actionable-side-bar-container .score-and-actionable-container .title-and-board-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 351px;
      height: 204px;
      gap: 24px; }
      .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .side-bar-title {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-align: center;
        color: #1A1A1A; }
      .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container {
        display: flex;
        flex-direction: column;
        gap: 24px; }
        .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container {
          display: flex;
          align-items: center;
          width: 290px;
          height: 116px;
          gap: 41px; }
          .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .chart-container {
            width: 116px;
            height: 116px; }
          .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container {
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 133px;
            height: 74px;
            background-color: #F3F6FB; }
            .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container .score-above-p {
              margin-bottom: 0px;
              font-family: Open Sans;
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0%;
              color: #1A1A1A; }
            .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container .score-span {
              font-family: Open Sans;
              font-weight: 700;
              font-size: 36px;
              line-height: 100%;
              letter-spacing: 0%;
              vertical-align: middle;
              color: #1A1A1A; }
            .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .score-and-graphic-container .score-container .score-below-p {
              margin-bottom: 0px;
              font-family: Open Sans;
              font-weight: 400;
              font-size: 12px;
              line-height: 100%;
              letter-spacing: 0%;
              text-align: center;
              color: #1A1A1A; }
        .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .help-message {
          margin: 0;
          font-family: Open Sans;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0%;
          color: #1F2D3D; }
          .actionable-side-bar-container .score-and-actionable-container .title-and-board-container .score-graphic-and-helpmsg-container .help-message .help-message-span {
            font-family: Open Sans;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0%;
            text-decoration: underline;
            text-decoration-style: solid;
            text-decoration-offset: 0%;
            text-decoration-thickness: 5.5%; }
    .actionable-side-bar-container .score-and-actionable-container .todo-actionable-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px 0 30px;
      width: 351px;
      height: 29px;
      gap: 4px; }
      .actionable-side-bar-container .score-and-actionable-container .todo-actionable-container .text-and-tooltip-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 297px;
        height: 19px;
        border-radius: 12px;
        padding-right: 8px;
        padding-left: 8px; }
        .actionable-side-bar-container .score-and-actionable-container .todo-actionable-container .text-and-tooltip-container .todo-actionable-text {
          margin-bottom: 0px;
          font-family: Open Sans;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0%;
          text-align: center;
          color: #1A1A1A; }
  .actionable-side-bar-container .actionable-list-container {
    width: 334px;
    gap: 32px; }
    .actionable-side-bar-container .actionable-list-container .empty-actionables-message-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 334px;
      height: 98px;
      gap: 16px;
      color: #1A1A1A;
      margin-top: 140px;
      margin-bottom: 140px; }
      .actionable-side-bar-container .actionable-list-container .empty-actionables-message-container .empty-actionables-message-span {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center; }
      .actionable-side-bar-container .actionable-list-container .empty-actionables-message-container .empty-actionables-message {
        font-family: Open Sans;
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center; }
    .actionable-side-bar-container .actionable-list-container .reputation-list-container .cta-list .cta-item .cta-item__icon-container .cta-item__percentage {
      font-family: Open Sans;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0%;
      color: #AA2B64; }
    .actionable-side-bar-container .actionable-list-container .loading-spinner-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 334px;
      height: 98px;
      gap: 16px;
      margin-top: 140px;
      margin-bottom: 140px; }
      .actionable-side-bar-container .actionable-list-container .loading-spinner-container .loading-actionables-message {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1A1A1A; }
  .actionable-side-bar-container .cta-item {
    display: flex;
    width: 334px;
    /*height: 66px;*/
    justify-content: space-between;
    border-radius: 4px;
    padding: 12px;
    max-width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 6px 0px #0000000D;
    justify-content: start; }
    .actionable-side-bar-container .cta-item .cta-item__details {
      max-width: 177px;
      /*height: 42px;*/
      gap: 3px;
      margin-right: 45px;
      white-space: normal;
      overflow-wrap: break-word; }
      .actionable-side-bar-container .cta-item .cta-item__details .cta-item__title {
        display: block;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-width: 100%;
        font-family: Open Sans;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1A1A1A; }
      .actionable-side-bar-container .cta-item .cta-item__details .cta-item__subtitle {
        font-family: Open Sans;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1A1A1A; }
    .actionable-side-bar-container .cta-item .cta-item__icon-container {
      display: flex;
      justify-content: end;
      gap: 10px; }
      .actionable-side-bar-container .cta-item .cta-item__icon-container .cta-item__percentage {
        font-family: Open Sans;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #1F858B;
        width: 48px;
        text-align: right; }
