$negative-color: rgb(148, 43, 25);
$positive-color: rgb(26, 117, 26);
$neutral-color : rgb(105, 105, 105);

$color-0 : #EB6951;
$color-1 : #E07150;
$color-2 : #D67A50;
$color-3 : #CB824F;
$color-4 : #C18B4E;
$color-5 : #B6934E;
$color-6 : #AB9B4D;
$color-7 : #A1A44C;
$color-8 : #96AC4B;
$color-9 : #8CB54B;
$color-10: #81BD4A;

.sentiment-container {

	max-width    : 100%;
	padding-left : 0;
	padding-right: 0;

	.fontSize-12px {
		font-size: 12px;
	}

	.div-centered {
		display        : flex;
		align-items    : center;
		justify-content: center;
	}

	.fixed-height-350 {
		height: 350px;
	}

	.fixed-height-725 {
		height: 725px;
	}

	.fixed-height-400 {
		height: 400px;
	}

	.bottom-comment-fixed {
		position: absolute;
		bottom  : 10px;
	}

	.last-update-box {
		font-style: italic;
	}

	.principal-title {
		margin-top : 5px;
		font-weight: 800;
		font-size  : 16px;
		margin-left: 5px;
	}

	.store-ranking-section {
		.nav-tabs {
			li {
				a {
					padding: 5px;
				}
			}
		}

		.tab-content {
			padding: 5px;
		}

		.store-card-item-1 {
			margin-top: 0.3rem;
		}

		.store-card {
			margin-left : 0.3rem;
			margin-right: 0.3rem;

			.store-card-body {
				padding: 0.3rem;
			}
		}

		.positive-mentions {
			color: $positive-color;
		}

		.neutral-mentions {
			color: $neutral-color;
		}

		.negative-mentions {
			color: $negative-color;
		}

		.info {
			color    : dimgray;
			font-size: smaller;
		}

		.apexcharts-tooltip-content {
			font-size: 12px;
			margin   : 5px;
		}

	}

	.card-content {
		padding: 10px;

		.keyword-help-link {
			color : #337ab7;
			cursor: pointer;
		}
	}

	.insight {
		text-align   : center;
		font-size    : larger;
		margin-bottom: 5px;
		font-weight  : bold;
	}

	.month-calendar {
		top       : 12%;
		position  : relative;
		text-align: -webkit-center;
	}

	.keyword-summary-item {
		text-align: center;

		.keyword {
			font-size  : 14px;
			font-weight: 600;
			cursor     : pointer;

			&:hover {
				//filter: saturate(0.5);
				filter: brightness(50%)
			}
		}

		.selected-keyword {
			font-size      : 14px;
			font-weight    : 900;
			text-decoration: underline;
		}
	}

	.gradient-help-container {
		margin-top: 20px;

		.gradient {
			display       : flex;
			flex-direction: column;
			align-items   : center;

			.bar {
				display: flex;
				width  : 80%;
				height : 10px;

				.fixed-box {
					width : 20%;
					height: 100%;
				}
			}

			.labels {
				display        : flex;
				justify-content: space-between;
				width          : 80%;

				.bottom-label {
					display       : inline-block;
					width         : 95%;
					text-transform: lowercase;
					font-size     : 12px;
					font-style    : italic;
				}

				.left-label {
					text-align: left;
				}

				.right-label {
					text-align: right;
				}
			}
		}

	}

	.sentiment-day-summary {
		.mentions-count {
			font-weight: 800;
			font-size  : 13px;
		}

		.red-circle {
			color    : $negative-color;
			font-size: 16px;
		}

		.green-circle {
			color    : $positive-color;
			font-size: 16px;
		}

		.gray-circle {
			color    : $neutral-color;
			font-size: 16px;
		}

		.black-circle {
			color    : black;
			font-size: 16px;
		}
	}

	.sentiment-day-carousel-container {
		padding : 0px 0px;
		position: relative;

		.slide-btn {
			position     : absolute;
			top          : 150px;
			font-size    : 14px;
			font-weight  : 900;
			color        : black;
			cursor       : pointer;
			border-radius: 50%;
			border-width : 1px;
			width        : 30px;
			height       : 30px;

			&:hover {
				background-color: white;
			}
		}

		.slide-btn-prev {
			left: -10px;
		}

		.slide-btn-next {
			right: -10px;
		}

		.slide-btn-hide {
			display: none;
		}
	}

	.sentiment-day-card-container {
		display        : flex;
		justify-content: center;

		.border-card {
			border-width: 1px;
		}

		.border-positive {
			border-color: lighten($positive-color, 20%);
		}

		.border-negative {
			border-color: lighten($negative-color, 20%);
		}

		.border-neutral {
			border-color: lighten($neutral-color, 20%);
		}

		.sentiment-day-card {
			margin   : 5px;
			max-width: 350px;

			.day-card-keywords-container-positive {
				border-top: 1px dashed lightgray;
			}

			.reviewer-name {
				white-space  : nowrap;
				overflow     : hidden;
				text-overflow: ellipsis;
			}

			.border-left-positive {
				border-left-color: lighten($positive-color, 30%);
			}

			.border-left-negative {
				border-left-color: lighten($negative-color, 30%);
			}

			.border-left-neutral {
				border-left-color: lighten($neutral-color, 30%);
			}

			.day-card-keywords-container {
				display             : flex;
				align-items         : center;
				// margin-top       : 5px;
				min-height          : 45px;
				padding-left        : 10px;
				border-left-width   : 3px;
				border-left-style   : solid;
				// margin-bottom    : 10px;

				border-bottom: 1px dashed lightgray;
				border-right : 1px dashed lightgray;

				.sentiment {
					width      : 90px;
					min-width  : 90px;
					max-width  : 90px;
					color      : darkgray;
					font-weight: bold;
					font-size  : 14px;
				}

				.words {
					flex-grow  : 1;
					font-weight: 600;
				}
			}

			.col-in-the-middle {
				padding-left : 5px;
				padding-right: 5px;
			}

			.profile-image {
				width       : 35px;
				height      : 35px;
				margin-right: 10px;
			}

			.review-card-body {
				padding: 0.5rem;
			}

			.review-global-sentiment {
				font-size  : 14px;
				font-weight: 800;
			}

			.positive-keyword {
				color: $positive-color;
			}

			.neutral-keyword {
				color: $neutral-color;
			}

			.negative-keyword {
				color: $negative-color;
			}

			.send-feedback-action {
				// color: blue;
				cursor     : pointer;
				margin-left: 5px;
			}

			.other-feedback {
				color: lightgray
			}

			.good-feedback {
				color: green
			}

			.bad-feedback {
				color: red
			}

			.store-name {
				font-weight: bold;
				padding-top: 10px;
			}

			.moment {
				font-size  : 10px;
				font-weight: bold;
			}
		}
	}

	/** Calendar background and text styles */
	.calendar-other-month {
		background: #f0f0f0;
		color     : #f0f0f0;
	}

	.calendar-no-stats {
		background: #D2D2D2 !important
	}

	.calendar-day-number-color {
		color: black
	}

	.background-zero-to-ten-0 {
		background: $color-0
	}

	.background-zero-to-ten-1 {
		background: $color-1
	}

	.background-zero-to-ten-2 {
		background: $color-2
	}

	.background-zero-to-ten-3 {
		background: $color-3
	}

	.background-zero-to-ten-4 {
		background: $color-4
	}

	.background-zero-to-ten-5 {
		background: $color-5
	}

	.background-zero-to-ten-6 {
		background: $color-6
	}

	.background-zero-to-ten-7 {
		background: $color-7
	}

	.background-zero-to-ten-8 {
		background: $color-8
	}

	.background-zero-to-ten-9 {
		background: $color-9
	}

	.background-zero-to-ten-10 {
		background: $color-10
	}

	.background-only-neutrals {
		background-color: gray;
	}

	.foreground-zero-to-ten-0 {
		color: $color-0
	}

	.foreground-zero-to-ten-1 {
		color: $color-1
	}

	.foreground-zero-to-ten-2 {
		color: $color-2
	}

	.foreground-zero-to-ten-3 {
		color: $color-3
	}

	.foreground-zero-to-ten-4 {
		color: $color-4
	}

	.foreground-zero-to-ten-5 {
		color: $color-5
	}

	.foreground-zero-to-ten-6 {
		color: $color-6
	}

	.foreground-zero-to-ten-7 {
		color: $color-7
	}

	.foreground-zero-to-ten-8 {
		color: $color-8
	}

	.foreground-zero-to-ten-9 {
		color: $color-9
	}

	.foreground-zero-to-ten-10 {
		color: $color-10
	}


}