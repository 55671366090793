.pack-purchase-button {
  font-size: 0.9rem;
  white-space: normal;
  word-wrap: break-word; }

.pack-purchase-pop-quantity-row {
  margin-top: 0.3rem; }

.pack-purchase-pop-button-col {
  position: relative; }

iframe {
  height: 85vh;
  border: none; }

.column-logo {
  width: 100%; }

.purchase-button {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: smaller; }

.text-cell {
  display: flex;
  justify-content: center;
  align-items: center; }

.mobile-row {
  padding: 5%; }

.mobile-column {
  display: flex;
  justify-content: center;
  align-items: center; }

.mobile-expand-row {
  padding: 5%; }

.mobile-purchase-button {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 0;
  font-size: smaller; }

.header-actions-container.mobile {
  margin: 0; }

.container.botbit-table.fluid {
  margin: 0; }

.center-image-container {
  display: flex;
  margin: auto; }

.fade.modal.show.top {
  z-index: 1000000 !important; }

div.mp-modal_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px; }
  div.mp-modal_header h4 {
    color: #000;
    font-size: 1.3rem; }

.modal-dialog {
  margin-bottom: auto !important; }
