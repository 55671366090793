.campaignSegmentContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignSegmentContainer #segmentDaysInput {
    width: 25px;
    padding: 0 0 0 8px;
    margin-left: 9px; }
  @media (min-width: 576px) {
    .campaignSegmentContainer #targetStoresParent {
      max-width: 95%; } }
  .campaignSegmentContainer #segmentTargetStores {
    display: contents;
    color: #19aa89;
    font-weight: 600; }
  .campaignSegmentContainer #segmentDescription {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000; }
