.triggerBtn {
  left: 108px;
  position: absolute;
  bottom: -8px; }

.deleteBtn {
  left: 152px;
  position: absolute;
  bottom: -3px; }

.row .img-thumbnail {
  margin-left: 12px; }
