.ray-spinner {

.red-spinner {
    border-color: #ff0000; 
  }
  
  .blue-spinner {
    border-color: #13589D; 
  }
  
  .grey-spinner {
    border-color: #808080; 
  }
  
  .green-spinner {
    border-color: #008000; 
  }
  
  .yellow-spinner {
    border-color: #ffff00; 
  }
  
  .purple-spinner {
    border-color: #800080; 
  }
  
  .orange-spinner {
    border-color: #ffa500; 
  }
  
  .pink-spinner {
    border-color: #ffc0cb; 
  }
  
  .teal-spinner {
    border-color: #008080; 
  }
  
  .brown-spinner {
    border-color: #a52a2a; 
  }
  
  .cyan-spinner {
    border-color: #00ffff; 
  }
  
  .lime-spinner {
    border-color: #00ff00; 
  }
  
    
}

