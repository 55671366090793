@import "../CommonStyles.scss";

#finish-integration {
    @include card-container;
    // padding-bottom: 70px;
    .finish-integration-card {
        @include card-styles;
        @media (max-width: 992px) {
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }
    #result-message {
        h1, h2 {
            text-align: center;
            color: black;
        }
        h1 { 
            margin-top: 0;
            font-weight: 700;
        }
        .result-image-construct {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .source-logo {
                margin-right: 70px;
                width: 180px;
            }
            .botbit-logo {
                margin-left: 70px;
                width: 180px;
            }
            @media (max-width: 992px) {
                flex-direction: column;
                .source-logo {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
                .botbit-logo {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
            .result-icon {
                position: relative;
                width: 100px;
                display: flex;
                justify-content: center;
                .plug-image {
                    width: 100px;
                    @media (max-width: 992px) {
                        width: 85px;
                    }
                    
                }
                .state-icon {
                    position: absolute;
                    left: -10px;
                    top: -20px;
                    @media (max-width: 992px) {
                        left: 3px;
                        top: -5px;                      
                    }
                    i {
                        color: #00ff00;
                        &.fa-times-circle {
                            color: red;
                        }
                        @media (max-width: 992px) {
                            font-size: 95px;   
                        }
                    }
                }
            }
        }
    }
}