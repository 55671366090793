.documentation-container {
  border: 2px solid #ccc;
  overflow: auto;
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  img {
    margin: auto;
    margin-bottom: 15px;
    width: 100%;
    max-width: 500px;
    display: block;
  }
  &.no-border {
    border: none;
  }
}