.testEmailModalBody{
    overflow: visible !important;
    .modalTitle{
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .modalButtons{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 13px;
        margin-top: 20px;
        button{
            background: #19AA89;
            box-shadow: none;
            border-radius: 3px;
            padding: 10px 43px;
            text-transform: initial;
            font-weight: 600;
            margin-right: 18px;
        }
        a{
            color: #19AA89;
        }
    }
    #extraEmailsInput{
        // >label{
        //     color: var(--danger);
        //     font-weight: 400;
        // }
        >div{
            margin-bottom: 0;
            input.error{
                border-bottom: 1px solid var(--danger);
                &:focus{box-shadow: none;}
            }
            // >label{
            //     color: var(--danger);
            //     font-weight: 400;
            // }
        }
        &.error{
            label{
                color: var(--danger);
                font-weight: 400;
            }
        }
    }
}