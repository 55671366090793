.segmentCollVis {
  margin-top: 39px; }
  @media (max-width: 576px) {
    .segmentCollVis {
      margin-top: 35px; } }
  @media (max-width: 576px) {
    .segmentCollVis #bigTableContainer {
      display: flex;
      flex-direction: column; } }
  .segmentCollVis #bigTableContainer > div > .row {
    margin-right: 0;
    margin-left: 0; }
  .segmentCollVis #bigTableContainer .botbit-table {
    padding: 0 !important; }
    .segmentCollVis #bigTableContainer .botbit-table .botbit-table-card-header {
      padding-bottom: 22px; }
      .segmentCollVis #bigTableContainer .botbit-table .botbit-table-card-header .table-title > div {
        font-size: 12px;
        font-weight: normal; }
  .segmentCollVis .segmentMobile {
    display: flex;
    font-size: 14px; }
    .segmentCollVis .segmentMobile .w-90 {
      width: 90% !important; }
    .segmentCollVis .segmentMobile.storesColumn {
      padding-right: 12px; }
    .segmentCollVis .segmentMobile.nameColumn {
      padding-right: 17px; }
      .segmentCollVis .segmentMobile.nameColumn .segmentRadioButton > .form-check > label {
        padding: 0;
        padding-right: 30px;
        margin: 0 !important; }
      .segmentCollVis .segmentMobile.nameColumn > div:not(.segmentRadioButton) > span {
        display: block; }
        .segmentCollVis .segmentMobile.nameColumn > div:not(.segmentRadioButton) > span:nth-child(2) {
          font-weight: normal; }
  .segmentCollVis .collection-visualizer-header {
    margin-bottom: 34px; }
    .segmentCollVis .collection-visualizer-header.mobile {
      margin: 0; }
    .segmentCollVis .collection-visualizer-header .collection-visualizer-title-container {
      margin-right: 52px; }
    .segmentCollVis .collection-visualizer-header .botbit-action-button {
      padding: 7px 13px; }
  .segmentCollVis .botbit-table-card {
    font-weight: 600;
    color: black;
    font-size: 12px;
    box-shadow: none;
    background: inherit; }
    .segmentCollVis .botbit-table-card > .card-header:hover::after, .segmentCollVis .botbit-table-card .botbit-table-expanded-row::after {
      display: none; }
    .segmentCollVis .botbit-table-card:hover {
      background-color: #E9F6F3; }
  .segmentCollVis .botbit-actions-container .dropdown button.dropdown-item i {
    width: 25px;
    text-align: center; }
  .segmentCollVis .segmentRadioButton > .form-check {
    margin: 0 !important;
    padding: 0; }
  .segmentCollVis .segmentDetailContainer {
    padding: 20px;
    font-size: 16px;
    font-weight: 500; }
    .segmentCollVis .segmentDetailContainer h4 {
      font-size: 18px; }
    .segmentCollVis .segmentDetailContainer .filter-text {
      font-size: 16px;
      font-weight: 500; }
