.local-seo-store-view-and-period-selector-and-store-selector-container{
display: grid;
width: 961px;
height: 616px;
grid-template-columns: repeat(20, 1fr);
gap: 5px;

  .location-selector-label-container{

    display: flex;
    align-items: center;
    gap: 2px;
    
    .location-selector-label{
      font-size: 12px;
      font-weight: 600;
      margin-right: 1rem;
      line-height: 16.34px;
      color: #1A1A1A;
      margin-bottom: 1rem;
    }
    .location-selector-select{
      width: 153px;
      height: 40px;
      border-radius: 4px;
      padding: 10px 16px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      margin-right: 1rem;
      margin-bottom: 1rem;
      background-color: #FFFFFF;
      border: 1px solid #E3E3E3;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 18px;
      
      &::-ms-expand {
        display: none;
      }
    
      &:focus {
        outline: none;
        border: 1px solid #E3E3E3;
      }
    }
    }

  .local-seo-store-view-container {
    grid-column: 1 / 20;
    grid-row: 1;   
  }

  .location-selector{
    grid-column: 9 / 15;  
    grid-row: 1; 
    justify-self: end;   
    padding-top: 3.5rem;
    z-index: 1;
  }

  


  .period-selector {
    grid-column: 14 / 20; 
    grid-row: 1;        
    justify-self: end;  
    padding-top: 3.5rem;
    z-index: 1;
    
  }
}

.local-seo-store-view-and-period-selector-container {
  display: grid;
  width: 961px;  
  height: 500px; 
  grid-template-columns: repeat(20, 1fr);
  gap: 15px;
  padding-top: 30px;
 
  .local-seo-store-view-container {
    grid-column: 1 / 20; 
    grid-row:1;     
  }

  
  .period-selector {
    grid-column: 14 / 20; 
    grid-row: 1;        
    justify-self: end;  
    padding-top: 20px;
    z-index: 1;
  }
}

.dashboard-v2-table-and-period-selector-container{
  display: grid;
  width: 100%;
  max-width: 920px;
  grid-template-columns: repeat(20, 1fr);
  gap: 15px;
  padding-top: 1rem;


  .dashboard-v2-table-container{
    grid-column: 1 / 20; 
    grid-row: 1;  
  }

  .period-selector {
    grid-column: 14 / 21; 
    grid-row: 1;
    padding-top: 10px;
    margin-top: 5px;
    z-index: 1;
  }

}





.period-selector-label-container{

display: flex;
align-items: center;
gap: 2px;

.period-selector-label{
  font-size: 12px;
  font-weight: 600;
  margin-right: 1rem;
  line-height: 16.34px;
  color: #1A1A1A;
  margin-bottom: 1rem;
}
.period-selector-select{
  width: 153px;
  height: 40px;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9298_2541)'%3E%3Crect width='9.6' height='9.6' transform='translate(4 4)' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M8.52902 10.9616L5.53152 7.5354C5.17777 7.13227 5.46527 6.49915 6.00214 6.49915L11.9971 6.49915C12.1173 6.49904 12.2349 6.53358 12.336 6.59861C12.437 6.66364 12.5171 6.75642 12.5668 6.86583C12.6165 6.97524 12.6335 7.09664 12.616 7.21551C12.5984 7.33437 12.5469 7.44565 12.4678 7.53602L9.47027 10.961C9.4116 11.0282 9.33926 11.082 9.25808 11.1188C9.17691 11.1557 9.08879 11.1748 8.99964 11.1748C8.91049 11.1748 8.82237 11.1557 8.7412 11.1188C8.66003 11.082 8.58768 11.0282 8.52902 10.961V10.9616Z' fill='%231A1A1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9298_2541'%3E%3Crect width='9.6' height='9.6' fill='white' transform='translate(4 4)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 18px;
  
  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
    border: 1px solid #E3E3E3;
  }
}
}

.dashboard-v2-grid-container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  padding: 20px;
  min-height: 100vh;
  background-color: white;

  .dashboard-v2-titles-and-tables-and-charts-container {
    width: 100%;
  }

  .dashboard-v2-header-title-and-kpis-container{

    h2{
      font-family: 'Open Sans';
      font-size: 36px;
      font-weight: 700;
      line-height: 100%;
      color: #1A1A1A;
    }

    h5{
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      color: #1A1A1A;
    }
    .dashboard-v2-header-title-container{
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    
  }

  .dashboard-v2-actionables-container {
    background-color: white;
  
    border-radius: 4px;
    max-width: 375px;
    

    .dashboard-v2-actionables-container-title{
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 21.78px;
      color: #1A1A1A;
    }
  }

  .dashboard-v2-local-seo-table-and-title-container{
    padding-top: 0.75rem;
    
  }
  .dashboard-v2-reputation-table-and-title-container{
    padding-top: 4rem;
    
  }

  .dashboard-v2-local-seo-chart-single-store-period-selector-container{
    
  }
  .dashboard-v2-reputation-store-view-selector{
    margin-top: -0.5rem;
    white-space: nowrap;
    z-index: 1;
  }

  .dashboard-v2-reputation-table-period-selector-container{
    margin-top: -0.5rem;
    white-space: nowrap;
    z-index: 1;
  }

  .dashboard-v2-global-metrics-container {
    background-color: #fff;
    width: 920px;
    
    
    .card__score{
      color: #1A1A1A;
    }
  
    .dashboard-v2-global-metrics-header {
      background-color: #DEE3E8;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.75rem;
      margin-top: 1.75rem;
      
      h2 {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        color: #1a1a1a;
        margin: 0;
      }
    }
  
    .dashboard-v2-global-metrics-content-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      width: 920px;

      .cards-row {
        .card__metrics:nth-child(1) {
          width: 183px;
        }
        
        .card__metrics:nth-child(2) {
          width: 170px;
        }
        
        .card__metrics:nth-child(3) {
          width: 212px;
        }
        
        .card__metrics:nth-child(4) {
          width: 185px;
        }
        
        .card__metrics:nth-child(5) {
          width: 170px;
        }
        
      }
  
    }
    .dashboard-v2-global-metrics-period-selector-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 0.4rem;
      order: 2;
    }
    .dashboard-v2-global-metrics-store-selector-container {
      margin-top: 0.4rem;
      margin-left: 9rem;
      order: 1;
    }
  }

  .data-group-tabs__container{
    background-color: #fff;
  }
  .data-group-tabs__tab{
    background-color: #fff;
  }

  .data-group-tabs__content{
    background-color: #fff;
  }


}