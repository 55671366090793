.LOWCard {
  background: #ED5564; }

.MEDIUMCard {
  background: var(--warning); }

.HIGHCard {
  background: #20BFC2; }

.noDeliveryCard {
  background: linear-gradient(246.62deg, #63D5FF -58.04%, rgba(176, 102, 254, 0.630208) 269.73%, rgba(196, 196, 196, 0) 460.36%); }

#dateApplyBtn {
  margin: 0;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: initial; }
  @media (max-width: 991px) {
    #dateApplyBtn {
      position: fixed;
      bottom: 53px; } }

#analyticsEmptyStateContainer {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  #analyticsEmptyStateContainer svg {
    width: 317px;
    height: auto; }
    @media (max-width: 991px) {
      #analyticsEmptyStateContainer svg {
        width: 250px; } }
  #analyticsEmptyStateContainer span {
    font-size: 30px;
    margin-top: 30px;
    font-weight: 100; }

#analyticsContainer {
  padding: 0 60px;
  padding-bottom: 100px; }
  #analyticsContainer .clientsAnalyticsModal {
    max-width: 1250px; }
    #analyticsContainer .clientsAnalyticsModal .modal-content {
      max-height: 100vh; }
    #analyticsContainer .clientsAnalyticsModal i {
      font-size: 16px;
      cursor: pointer; }
    #analyticsContainer .clientsAnalyticsModal #close-modal {
      margin-top: 20px;
      margin-left: 20px; }
  #analyticsContainer .minimalEditorModal .modal-body {
    padding: 0; }
    #analyticsContainer .minimalEditorModal .modal-body #messageNotCustomModal .modal-body {
      padding: 1rem; }
  #analyticsContainer .deletionModal > .modal-content {
    min-height: inherit; }
  #analyticsContainer .deletionModal .modal-body p:first-child {
    font-size: 20px;
    line-height: 28px;
    color: #ED5565;
    font-weight: 600; }
  #analyticsContainer .deletionModal .modal-body p:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.541327); }
  #analyticsContainer .deletionModal .modal-body > div {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    #analyticsContainer .deletionModal .modal-body > div button {
      border-radius: 3px;
      box-shadow: none;
      text-transform: initial;
      font-size: 14px;
      line-height: 19px;
      text-align: center; }
    #analyticsContainer .deletionModal .modal-body > div button.link-styled {
      font-size: 14px;
      line-height: 19px;
      color: #19AA89;
      font-weight: 600;
      margin-right: 10px;
      margin-left: 14px;
      background: none;
      border: none;
      cursor: pointer; }
  #analyticsContainer .modal #closeModalIcon {
    padding: 22px 0 15px 20px;
    font-size: 18px;
    width: 100%; }
  #analyticsContainer .modal #timePeriodModalTitle {
    padding-left: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000; }
  #analyticsContainer .modal #botbit-timeperiod-selector {
    margin-top: 15px; }
  #analyticsContainer .modal #expandedWithCustomSelector > div:nth-child(2) {
    height: calc(57vh - 7px);
    overflow-y: auto; }
  #analyticsContainer .webPreviewerModal .modal-body {
    overflow-y: hidden; }
  @media (max-width: 991px) {
    #analyticsContainer {
      padding: 0;
      padding-bottom: 50px; } }
  #analyticsContainer .analyticsHeader #deployDateWarning {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    background-color: #FFF3CD;
    padding: 12px;
    border-radius: 5px;
    margin: 30px 0; }
    #analyticsContainer .analyticsHeader #deployDateWarning > div:first-child {
      display: flex;
      align-items: center;
      padding-right: 10px; }
      #analyticsContainer .analyticsHeader #deployDateWarning > div:first-child i {
        font-size: 26px; }
    #analyticsContainer .analyticsHeader #deployDateWarning > div:nth-child(2) span {
      font-size: 14px;
      font-weight: 400; }
      #analyticsContainer .analyticsHeader #deployDateWarning > div:nth-child(2) span a {
        color: dodgerblue; }
  #analyticsContainer .analyticsHeader .dropdown > button.dropdown-toggle {
    box-shadow: none;
    color: grey;
    background-color: inherit !important;
    padding: 0; }
  #analyticsContainer .analyticsHeader #nameBadgeContainer {
    margin-top: 14px; }
    #analyticsContainer .analyticsHeader #nameBadgeContainer .mobileSwitch {
      float: right;
      margin-top: 6px;
      margin-right: -16px; }
    #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer {
      width: 335px;
      display: flex;
      justify-content: flex-end; }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer .dropdown-menu {
        padding: 0;
        min-width: 303px; }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer > button {
        box-shadow: none !important;
        text-transform: inherit;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        border-radius: 4px;
        padding: 0 !important;
        width: 204px;
        height: 35px;
        border: 1px solid #1ab394 !important; }
      @media (max-height: 728px) {
        #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer #expandedWithCustomSelector > div:nth-child(2) {
          height: 227px;
          overflow-y: auto; } }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer #botbit-timeperiod-selector {
        padding-bottom: 8px; }
      #analyticsContainer .analyticsHeader #nameBadgeContainer .dateDropdownContainer.show > button.dropdown-toggle {
        background-color: #EBF5F3 !important;
        color: #1ab394 !important;
        border-color: #EBF5F3 !important;
        box-shadow: none !important; }
  #analyticsContainer .analyticsHeader .badge {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 4px 11px;
    display: block; }
  #analyticsContainer .analyticsHeader .greenText {
    line-height: 16px;
    color: #19AA89; }
  #analyticsContainer .analyticsHeader .delete {
    font-weight: 500;
    color: red; }
  #analyticsContainer .analyticsHeader .headerActions {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;
    color: #19AA89;
    font-weight: 600; }
    #analyticsContainer .analyticsHeader .headerActions span {
      cursor: pointer; }
  #analyticsContainer .analyticsHeader #backIcon {
    color: black;
    cursor: pointer;
    font-size: 18px;
    padding-top: 4px; }
  #analyticsContainer .analyticsHeader #campaignName {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000; }
  #analyticsContainer .analyticsHeader #timePeriodLabel {
    text-transform: initial;
    font-size: 14px;
    color: #19AA89;
    line-height: 19px;
    font-weight: 600; }
  #analyticsContainer .analyticsHeader i.fa.fa-cog {
    font-size: 16px;
    margin-left: 16px; }
  #analyticsContainer .campaignSummary {
    margin-top: 20px; }
    #analyticsContainer .campaignSummary.isSmart {
      margin-top: 40px; }
    @media (max-width: 991px) {
      #analyticsContainer .campaignSummary {
        margin-top: 35px; }
        #analyticsContainer .campaignSummary.isSmart {
          margin-top: 50px; } }
    #analyticsContainer .campaignSummary #bodyCards {
      display: flex;
      margin-top: 1.5rem;
      min-height: 337px; }
      @media (max-width: 991px) {
        #analyticsContainer .campaignSummary #bodyCards {
          height: auto; } }
      #analyticsContainer .campaignSummary #bodyCards #effectivityCard {
        display: inline-block;
        width: 23%;
        border-radius: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 46px 22px 0px 22px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard {
            width: 49%;
            padding: 35px 14px 16px 14px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px; }
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard div:first-child {
              margin-bottom: 21px; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #effectivity {
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          display: block; }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #noDeliveryEffectivity {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px; }
          @media screen {
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard #noDeliveryEffectivity {
              font-weight: 600; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #effectivityDesc {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 25px;
          display: block;
          line-height: 28px;
          position: relative; }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #effectivityDesc > i {
            font-size: 21px;
            height: 100%;
            top: 4px;
            right: 0;
            position: absolute; }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi {
          padding-top: 27px; }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > span {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            width: 80%; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > span {
                width: 100%;
                margin: 0;
                font-weight: 600; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-exclamation-circle {
            right: 0px;
            font-size: 18px;
            top: 30px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-exclamation-circle {
                top: 5px;
                left: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-money-bill-alt {
            right: -5px;
            font-size: 18px;
            top: 30px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-money-bill-alt {
                top: 5px;
                left: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-check-circle {
            right: -6px;
            top: 42px;
            font-size: 13px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #roi > i.fa-check-circle {
                top: 16px;
                left: 18px; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          padding-top: 17px; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough {
              padding-top: 35px;
              font-weight: 600; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.d-block {
            width: 80%; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.d-block {
                width: 100%;
                margin-right: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.position-absolute {
            right: -4px;
            top: 20px;
            font-size: 18px;
            font-weight: 700; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #walkthrough > span.position-absolute {
                left: 0;
                top: 15px; } }
        #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate {
          padding-top: 17px;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate {
              padding-top: 37px;
              font-weight: 600; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > span.d-block {
            width: 80%; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > span.d-block {
                width: 100%;
                margin-right: 0; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-exclamation-circle {
            right: 1px;
            font-size: 18px;
            top: 20px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-exclamation-circle {
                left: 0;
                top: 13px; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-envelope {
            right: 1px;
            font-size: 18px;
            top: 20px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-envelope {
                left: 0;
                top: 13px; } }
          #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-check-circle {
            right: -6px;
            top: 33px;
            font-size: 13px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #effectivityCard #sendRate > i.fa-check-circle {
                left: 11px;
                top: 24px; } }
      #analyticsContainer .campaignSummary #bodyCards #firstCard {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: white;
        display: inline-block;
        color: black;
        margin-left: 2%;
        border-radius: 4px 0px 0px 4px;
        width: 39%;
        height: auto;
        border-right: 1px solid #EBEBEB;
        padding: 30px 3%; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #bodyCards #firstCard {
            width: 49%;
            padding: 0;
            border-radius: 7px; } }
        #analyticsContainer .campaignSummary #bodyCards #firstCard .campaignMainImage {
          width: 100%;
          border-radius: 4px;
          height: 114px;
          background-size: cover;
          background-position: center; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #firstCard .campaignMainImage {
              height: 96px;
              border-radius: inherit;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0; } }
        #analyticsContainer .campaignSummary #bodyCards #firstCard #campaignMessage {
          width: 100%;
          word-break: break-word;
          contain: content;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          font-weight: 600; }
        #analyticsContainer .campaignSummary #bodyCards #firstCard strong {
          color: #19AA89;
          font-weight: 600; }
        #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts {
          padding: 15px 12px; }
          @media (max-width: 991px) {
            #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts {
              height: calc( 100% - 100px);
              display: flex;
              flex-direction: column;
              justify-content: space-between; } }
          #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts > span:not(#campaignMessage) {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin-top: 19px; }
            @media (max-width: 991px) {
              #analyticsContainer .campaignSummary #bodyCards #firstCard #firstCardTexts > span:not(#campaignMessage) {
                font-size: 14px;
                line-height: 19px;
                margin-top: 9px; } }
    #analyticsContainer .campaignSummary #secondCard {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: white;
      display: inline-block;
      color: black;
      width: 36%;
      border-radius: 0px 4px 4px 0;
      padding: 5% 5% 3% 5%;
      position: relative; }
      @media (max-width: 991px) {
        #analyticsContainer .campaignSummary #secondCard {
          width: 100%;
          margin-top: 11px;
          border-radius: 4px;
          padding: 17px 28px; } }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError {
        font-size: 22px; }
        #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError > i {
          color: red;
          font-size: 40px;
          margin-bottom: 17px; }
        #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError a {
          color: dodgerblue; }
        #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #targetedUsersError span.link-styled {
          color: #19AA89;
          font-weight: 600;
          margin-right: 10px;
          text-decoration: none;
          background: none;
          border: none;
          cursor: pointer; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity .preloader-wrapper.active {
        width: 25px;
        height: 25px;
        margin-right: 15px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity > i {
        font-size: 13px;
        color: dodgerblue;
        margin-left: 8px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity #clientsNumber {
        font-size: 20px;
        font-weight: 600; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity .couldRecieveCampaign {
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        font-size: 17px;
        margin-bottom: 16px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #clientsQuantity strong {
        font-size: 30px;
        font-weight: 600; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts span#clientsQuantity + span {
        font-size: 17px;
        margin-bottom: 16px; }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .campaignSegmentContainer {
        font-size: 13px !important;
        line-height: 16px !important;
        color: #000000 !important;
        font-weight: 400 !important; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .campaignSegmentContainer {
            font-size: 14px;
            line-height: 19px; } }
      #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .filter-text {
        font-size: 12px !important; }
        @media (max-width: 991px) {
          #analyticsContainer .campaignSummary #secondCard #secondCardTexts #segmentinfo, #analyticsContainer .campaignSummary #secondCard #secondCardTexts .filter-text {
            font-size: 14px !important; } }
  #analyticsContainer #title {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000; }
    @media (max-width: 991px) {
      #analyticsContainer #title {
        font-size: 20px; } }
  #analyticsContainer #campaignStatus #emptyTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    justify-content: center; }
  #analyticsContainer #campaignStatus #chartContainer {
    margin-top: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    #analyticsContainer #campaignStatus #chartContainer table {
      border-bottom: none; }
  #analyticsContainer #campaignStatus .statusDesc {
    background: white;
    height: 200px;
    width: 99.8%;
    margin-left: 0.2%;
    display: flex; }
    #analyticsContainer #campaignStatus .statusDesc button.seeClientsBtn {
      text-transform: inherit;
      background-color: white !important;
      border-radius: 5px;
      padding: 7px 16px;
      position: absolute;
      bottom: 13px;
      font-weight: 500;
      font-weight: 600;
      font-size: 14px;
      color: #676A6C;
      border: 1px solid #676A6C;
      box-shadow: none; }
      @media (max-width: 991px) {
        #analyticsContainer #campaignStatus .statusDesc button.seeClientsBtn {
          bottom: 0;
          font-size: 0.7em; } }
    @media (max-width: 991px) {
      #analyticsContainer #campaignStatus .statusDesc {
        height: 148px;
        border-radius: 5px; } }
    #analyticsContainer #campaignStatus .statusDesc > div {
      width: calc(100%/3);
      height: 100%;
      display: inline-block;
      border-right: 1px solid #EBEBEB;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991px) {
        #analyticsContainer #campaignStatus .statusDesc > div {
          width: 50%;
          border-right: 1px solid #D5D5D5; } }
      #analyticsContainer #campaignStatus .statusDesc > div.noWalkthrough {
        width: 50%; }
        @media (max-width: 991px) {
          #analyticsContainer #campaignStatus .statusDesc > div.noWalkthrough {
            width: 50%; } }
      #analyticsContainer #campaignStatus .statusDesc > div > span {
        display: block; }
      #analyticsContainer #campaignStatus .statusDesc > div > span:first-child {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #676A6C; }
      @media (max-width: 992px) {
        #analyticsContainer #campaignStatus .statusDesc > div strong {
          font-size: 16px !important; } }
      #analyticsContainer #campaignStatus .statusDesc > div strong {
        font-size: 30px;
        line-height: 36px;
        color: #000000;
        font-weight: 600; }
      #analyticsContainer #campaignStatus .statusDesc > div > span:nth-child(2) {
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        font-weight: 500; }
      #analyticsContainer #campaignStatus .statusDesc > div > span:nth-child(3) {
        font-size: 14px;
        line-height: 18px;
        color: #676A6C;
        font-weight: 600; }
    #analyticsContainer #campaignStatus .statusDesc #returned {
      border-right: none;
      position: relative; }
    #analyticsContainer #campaignStatus .statusDesc #recieved {
      position: relative; }
    #analyticsContainer #campaignStatus .statusDesc #opened {
      position: relative; }
    #analyticsContainer #campaignStatus .statusDesc #clicked {
      position: relative; }
    @media (max-width: 991px) {
      #analyticsContainer #campaignStatus .statusDesc #returned, #analyticsContainer #campaignStatus .statusDesc #opened, #analyticsContainer #campaignStatus .statusDesc #clicked, #analyticsContainer #campaignStatus .statusDesc #recieved {
        display: flex;
        justify-content: flex-end; }
      #analyticsContainer #campaignStatus .statusDesc #returned span:nth-child(1), #analyticsContainer #campaignStatus .statusDesc #opened span:nth-child(1), #analyticsContainer #campaignStatus .statusDesc #clicked span:nth-child(1), #analyticsContainer #campaignStatus .statusDesc #recieved span:nth-child(1) {
        margin-top: 25px; }
      #analyticsContainer #campaignStatus .statusDesc #returned span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #opened span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #clicked span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #recieved span:nth-child(3) {
        text-align: center; } }
    @media (min-width: 992px) {
      #analyticsContainer #campaignStatus .statusDesc #returned span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #opened span:nth-child(2), #analyticsContainer #campaignStatus .statusDesc #clicked span:nth-child(2) {
        margin-bottom: 18px; } }
  #analyticsContainer #campaignStatus #statusDescSub {
    height: 81px;
    background: #F6F7F9;
    display: flex;
    border-radius: 0 0 6px 6px; }
    #analyticsContainer #campaignStatus #statusDescSub .badRate {
      background: #FDECF0; }
    #analyticsContainer #campaignStatus #statusDescSub > div {
      padding: 0 30px;
      width: calc(100%/3);
      height: 100%;
      border-right: 1px solid #EBEBEB;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      font-weight: 400; }
    #analyticsContainer #campaignStatus #statusDescSub i {
      position: absolute;
      left: -17px;
      top: 1px; }

#backIcon.closeModalIcon {
  padding: 22px 0 15px 20px;
  font-size: 18px;
  width: 100%;
  color: black;
  cursor: pointer;
  font-size: 18px;
  padding-top: 4px; }
