.question-stats-container {
	.stats-card-header {

		// height: 64px;
		.stats-card-title {
			white-space  : nowrap;
			overflow     : hidden;
			text-overflow: ellipsis;
			margin: 0;
		}
	}

	.stats-card-body {
		// height: 300px;
		padding: 0.25rem;
	}
}