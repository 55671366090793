.card-list {
  display: flex;

  &.rows {
    flex-direction: row;
    gap: 32px;
  }

  &.columns {
    flex-direction: column;
    gap: 1%;
  }
}

.card-list.columns .card-container {
  gap: 8px;

  .title-and-description {
    display: inline;
    gap: 8px;
  }

  .card-title {
    padding-left: 1%;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    color: #000000;
    display: inline-block;
  }

  .card-description {
    padding-left: 10%;
    font-size: 14px;
    line-height: 19.07px;
    text-align: left;
    font-weight: 500;
    color: #000000;
  }
}

.card-list.rows .card-container {
  width: 290px;
  height: 200px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;

  .title-and-description {
    display: inline;
    gap: 8px;
  }

  .card-title {
    padding-left: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    color: #000000;
    display: inline-block;
  }

  .card-description {
    padding-left: 0;
    font-size: 14px;
    line-height: 19.07px;
    color: #000000;
  }
}

.card-0 {
  padding: 0px;
  margin: 0px;
}

.card-description-video {
  margin-bottom: 8px;
  padding-left: 0;
  font-size: 14px;
  line-height: 19.07px;
  font-weight: 500;
  color: #000000;
}

.card-video {
  width: 100%;
  height: 100%;
  top: 35px;
  padding: 8px 0px 0px 0px;
  gap: 0px;
  padding: 20px;
  border-radius: 4px;
  justify-content: space-between;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16), 0px -1px 0px rgba(0, 0, 0, 0);
}
