.col-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.column-container {
  display: flex !important;
  align-items: center; }

.google-header-icon {
  height: 1.4em !important; }

.google-header-button {
  background-color: #4285f4 !important; }

.container {
  margin-top: 30px; }

.socialaccounts-active-badge {
  margin-left: 10px; }

.no-results-container {
  margin-top: 2rem; }

@media only screen and (max-width: 499px) {
  .socialaccounts-active-badge {
    margin-left: 0 !important;
    margin-bottom: 10px; } }
