.documentation-modal {
  .back-button {
    color: black;
      font-size: 20px;
      cursor: pointer;
      margin-bottom: 5px;
  }
  .btn {
    display: block;
    margin: auto;
  }
}