.orders-iframe {
  transition: width 0.4s;
  width: calc( 100vw - 220px);
  height: calc( 100vh - 102px);
  padding: 0;
  margin: 0;
  margin-left: -15px; }
  @media (max-width: 991px) {
    .orders-iframe {
      width: 100vw;
      height: calc( 100vh - 101px); } }

@media (max-width: 991px) {
  .mini-navbar .orders-iframe {
    width: calc(100vw - 69px); } }
