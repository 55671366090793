.campaignCompanyContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignCompanyContainer .fieldWrapper {
    display: flex; }
    @media (max-width: 576px) {
      .campaignCompanyContainer .fieldWrapper {
        display: block; } }
    .campaignCompanyContainer .fieldWrapper .companyLabel {
      display: block;
      min-width: 140px;
      max-width: 140px;
      display: inline-block;
      font-weight: 600; }
      @media (max-width: 576px) {
        .campaignCompanyContainer .fieldWrapper .companyLabel {
          width: 100%;
          max-width: 100%;
          margin-top: 10px; } }
      @media (max-width: 576px) {
        .campaignCompanyContainer .fieldWrapper .companyLabel {
          margin-bottom: 10px; } }
