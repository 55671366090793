@media (min-width: 768px) {
  .captive-graph {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; } }

.captive-graph .loader {
  height: 36vh; }

.captive-graph .filters .filters-and-more-actions.mobile.mobile:not(.more-actions) .filters-actions-container .botbit-dropdown .btn {
  padding: 5px !important;
  border-color: #1ab394 !important;
  border-radius: 3px;
  width: 100%; }

.captive-graph .empty-state {
  height: 380px; }

.captive-graph .filters-first-line {
  margin-bottom: 0 !important; }

.captive-graph .filters .filters-and-more-actions.mobile.mobile:not(.more-actions) .filters-actions-container .botbit-dropdown {
  width: 100%; }

.captive-graph .registration-total {
  color: #000;
  font-weight: bold;
  font-size: 1.6rem; }
  @media (max-width: 768px) {
    .captive-graph .registration-total {
      font-size: 1.2rem; } }

.captive-graph .graph-card {
  display: flex;
  justify-content: space-between;
  padding: 10px; }
  .captive-graph .graph-card .card-footer {
    font-size: 11px;
    border: none;
    color: #000;
    font-weight: 500;
    min-height: 45px;
    padding: .5rem;
    margin-top: 5px;
    background-color: #f3f3f3 !important; }

.captive-graph-view {
  padding-bottom: 4rem;
  margin: 1rem 1rem 0 0; }
  @media (max-width: 768px) {
    .captive-graph-view {
      margin-right: 0; } }
