.configurations-container{
  @media (max-width: 991px) {
    padding: 0;
  }
  *{
    color: black;
  }
  .parent>h3{
    margin-bottom: 10px;
  }
  .childs p{
    &:hover{
      background: #FBFBFB;
    }
    cursor: pointer;
    margin-bottom: 4px;
    background: #fff;
    border-radius: .25rem;
    padding: .5rem 0;
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
    img{
      width: 32px;
      margin-right: 13px;
    }
  }
}