.company-container {
	padding-bottom: 5rem;

	&_head {
		display        : flex;
		flex-direction : row;
		flex-wrap      : wrap;
		justify-content: space-between;
		align-items    : center;
		margin-bottom  : 0.8rem;

		h1.page-title {
			font-family: 'Open Sans';
			font-style : normal;
			font-weight: bold;
			font-size  : 24px;
			line-height: 33px;
			color      : black;
			margin     : 0;
		}

		button.btn {
			text-transform: none;
			border-radius : 3px;
			font-weight   : 600;
			box-shadow    : none;
		}
	}

	&_header {
		width         : 100%;
		display       : flex;
		flex-direction: column;
		align-items   : center;
	}

	&_header-actions,
	&_header-actions:hover,
	&_header-actions:focus,
	&_header-actions:active {
		color         : #A7B1C1 !important;
		border        : 1px solid #A7B1C1 !important;
		border-radius : 3px;
		text-transform: none;
		font-size     : 14px !important;
		// line-height: 19px;
		font-weight   : 600;
		// position: static !important;
	}

	&_logo {
		img {
			max-width: 120px;
		}
	}

	&_title {
		text-transform: uppercase;
		color         : #000;

		h2 {
			font-weight: bold !important;
			font-size  : 0.9rem !important;
		}
	}

	&-section {
		padding      : 0 1rem;
		width        : 100%;
		margin-bottom: 1rem;

		hr {
			margin: 0.5rem 0 !important;
		}
	}

	&_image-container {
		display       : flex;
		flex-direction: column;
		align-items   : center;
		margin-bottom : 0.8rem;

		img {
			width        : 100%;
			border-radius: 3px;
			margin       : auto;
		}

		a {
			width    : 50%;
			max-width: 120px;
		}
	}

	&_actions-row {
		display       : flex;
		flex-direction: row;
		align-items   : center;
	}

	&_color-box {
		display       : block;
		width: 30px;
		height: 30px;
	}
}

@media only screen and (max-width: 576px) {
	.company-container {
		&_title {
			h1 {
				font-size: 1rem;
			}
		}

		&_actions-row {
			.btn {
				padding: 15px 15px;
			}
		}
	}
}