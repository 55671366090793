.issue-dashboard-container {
    padding-bottom: 50px;
    margin-top: 1.5rem;

    .issues-container {
        // justify-content: center;
        display: inline-block;
    }

    .mobile-contact-button{
        position: absolute;
        right: 0;
        top: 0;
    }

}