#new-campaign {
  font-family: Open Sans;
  font-style: normal; }
  #new-campaign #activateFirstCampaignDiv {
    margin: 0 !important; }
    #new-campaign #activateFirstCampaignDiv .multipleCampaignCardContainer {
      margin: 0 !important; }
    #new-campaign #activateFirstCampaignDiv > h3 {
      margin-bottom: 15px; }
  #new-campaign h3 {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 50px; }
  #new-campaign .back-button {
    color: black;
    font-size: 16px;
    margin-top: 20px; }
  #new-campaign .new-campaign-header {
    margin-top: 22px; }
    #new-campaign .new-campaign-header h1 {
      margin: 0;
      color: black;
      font-family: Open Sans;
      font-size: 24px;
      font-weight: 600;
      display: inline-block; }
    #new-campaign .new-campaign-header .select-company {
      display: inline-block;
      margin: 0;
      margin-left: 8px; }
      #new-campaign .new-campaign-header .select-company .select-dropdown,
      #new-campaign .new-campaign-header .select-company .caret {
        color: var(--primary) !important;
        border-color: var(--primary) !important; }
      #new-campaign .new-campaign-header .select-company .select-dropdown {
        font-size: 24px; }
      @media (max-width: 576px) {
        #new-campaign .new-campaign-header .select-company {
          margin-left: 0; } }
  #new-campaign #customCampaignsContainer {
    margin-top: 30px; }
    #new-campaign #customCampaignsContainer .nav-item {
      border-left: 0; }
      #new-campaign #customCampaignsContainer .nav-item.active, #new-campaign #customCampaignsContainer .nav-item:hover {
        background: none; }
        #new-campaign #customCampaignsContainer .nav-item.active .nav-link, #new-campaign #customCampaignsContainer .nav-item:hover .nav-link {
          border-color: #20bfc2;
          color: #20bfc2 !important; }
    #new-campaign #customCampaignsContainer .nav-link {
      padding: 14px 40px;
      border-color: white;
      color: #676a6c; }
    @media (max-width: 576px) {
      #new-campaign #customCampaignsContainer .nav-item {
        max-width: 33.3333%; }
        #new-campaign #customCampaignsContainer .nav-item .nav-link {
          padding: 14px; } }
    #new-campaign #customCampaignsContainer .tab-content {
      box-shadow: none;
      background-color: inherit; }
    #new-campaign #customCampaignsContainer .nav {
      margin-top: 0 !important; }
    #new-campaign #customCampaignsContainer .nav li:first-child {
      margin-left: 0; }
    #new-campaign #customCampaignsContainer .nav li a {
      text-transform: inherit;
      font-size: 16px;
      line-height: 19px;
      color: #676a6c; }
    @media (max-width: 576px) {
      #new-campaign #customCampaignsContainer .nav li > ul {
        display: flex;
        justify-content: center; } }
  #new-campaign .smart-campaign-wizard-container {
    max-width: 750px;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #000000 !important; }
    @media (max-width: 950px) {
      #new-campaign .smart-campaign-wizard-container {
        padding: 0; } }
    #new-campaign .smart-campaign-wizard-container .title {
      font-weight: 300;
      font-size: 30px;
      line-height: 36px;
      margin-top: 60px;
      color: black;
      margin-bottom: 25px; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .title {
          margin-top: 15px; } }
    #new-campaign .smart-campaign-wizard-container .sendDatesContainer {
      text-transform: lowercase; }
    #new-campaign .smart-campaign-wizard-container .subject-message-and-button-form .tags-input input {
      font-weight: 600;
      color: black;
      font-size: 16px;
      line-height: 22px; }
    #new-campaign .smart-campaign-wizard-container .subject-message-and-button-form .inputLabel {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: black; }
    #new-campaign .smart-campaign-wizard-container .campaignBanner > svg {
      position: fixed;
      right: 2%;
      bottom: 5%;
      max-width: 20%;
      height: auto; }
      @media (max-width: 950px) {
        #new-campaign .smart-campaign-wizard-container .campaignBanner > svg {
          display: none; } }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .md-range-input-fix {
      margin: 0; }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentDescription {
      display: flex;
      align-items: center; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentDescription {
          display: block; } }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentFormInput .md-form {
      margin: 0;
      margin-left: 10px; }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer #segmentFormInput + .d-inline-block {
      margin-left: 15px; }
    #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .campaignCompanyAndStoreSelector {
      margin-top: 10px; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .campaignCompanyAndStoreSelector #storeSelector {
          margin-top: 0; }
        #new-campaign .smart-campaign-wizard-container .campaignSegmentContainer .campaignCompanyAndStoreSelector #segmentOptionSelector {
          width: 100% !important; } }
    #new-campaign .smart-campaign-wizard-container .campaignButton {
      width: fit-content; }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm div:not(.error) .mdb-select {
      color: var(--primary); }
      #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm div:not(.error) .mdb-select input:not(#selectSearchInput),
      #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm div:not(.error) .mdb-select .caret {
        color: var(--primary); }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm .mdb-select .select-dropdown {
      padding: 0 5px; }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm input:not(#selectSearchInput).form-control {
      color: var(--primary); }
      #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm input:not(#selectSearchInput).form-control + label {
        color: var(--primary); }
    #new-campaign .smart-campaign-wizard-container .storeSegmentAndBenefitForm .campaignBenefitContainer .productSelector #discount {
      padding-bottom: 8px; }
    #new-campaign .smart-campaign-wizard-container .fromToDisplayForm {
      padding-bottom: 27px; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .fromToDisplayForm #fromEmailSelector + div .md-form {
          margin-bottom: 1.5rem; } }
    #new-campaign .smart-campaign-wizard-container .benefitContainer {
      width: 75%;
      display: inline-block; }
      @media (max-width: 576px) {
        #new-campaign .smart-campaign-wizard-container .benefitContainer {
          width: 100%; } }
      #new-campaign .smart-campaign-wizard-container .benefitContainer .productSelector {
        padding-bottom: 40px; }
    #new-campaign .smart-campaign-wizard-container .btn {
      text-transform: none; }
  @media (max-width: 576px) {
    #new-campaign .activateCampaignCardComponent {
      margin-right: 0; } }
  #new-campaign #exitModal {
    border-radius: 5px;
    overflow-y: hidden; }
    #new-campaign #exitModal > .modal-dialog > .modal-content {
      padding: 8px; }
      @media (max-width: 576px) {
        #new-campaign #exitModal > .modal-dialog > .modal-content {
          padding: 3px !important; } }
      @media (min-width: 576px) {
        #new-campaign #exitModal > .modal-dialog > .modal-content {
          bottom: 20vh;
          margin: auto;
          width: 465px; } }
    @media (min-width: 576px) {
      #new-campaign #exitModal .botbit-modal.modal-dialog-centered {
        bottom: 40%;
        display: inherit;
        align-items: inherit; } }
    #new-campaign #exitModal .modal-content {
      min-height: 0 !important; }

.new-campaign-template-card {
  border-radius: 5px;
  width: 100%;
  padding: 11px; }
  .new-campaign-template-card:hover {
    background-color: #e9f6f3;
    cursor: pointer; }
  .new-campaign-template-card .previewImage {
    height: 132px;
    background-size: cover;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box; }
  .new-campaign-template-card img {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box; }
  .new-campaign-template-card span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: black;
    display: block;
    margin-top: 17px; }

.campaign-name-tilt {
  color: var(--primary); }
