.login {
  background-color: #F3F3F4 !important;
  font-family: open sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  position: absolute; }

.login-set {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.authenticated {
  padding-right: 0;
  padding-left: 0;
  background-color: #2f4050; }

#wrapper {
  background-color: #2f4050; }

.collection-visualizer-header.mobile {
  display: block; }
  .collection-visualizer-header.mobile .botbit-action-button {
    width: 100%;
    margin: 0;
    margin-top: 10px; }

.reactivate-modal {
  max-width: 800px; }

.modal:not(.client-action-modal) {
  display: flex !important;
  align-items: center; }

.filters-first-line.mobile {
  display: block;
  margin-bottom: 0 !important;
  text-align: left; }
  .filters-first-line.mobile .botbit-action-button {
    width: 100%;
    margin: 0;
    margin-top: 10px; }

@media (min-width: 992px) {
  #reactivate-modal-container .modal-content {
    width: 600px; }
  #reactivate-modal-container .modal-dialog {
    margin-top: auto; }
  #reactivate-modal-container .modal {
    display: flex !important; } }

@media (max-width: 991px) {
  .filters-title-container {
    margin-right: 0 !important; }
  .modal-body {
    max-height: 100% !important;
    width: 100vw !important; } }

.padding-5px {
  padding: 5px !important; }

img.rounded-circle {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1 / 1; }
