#integrations .botbit-table {
  margin-top: 40px; }

#integrations .facebook_messenger_instagram-button {
  background-color: #1877F2 !important;
  display: flex;
  align-items: center;
  justify-content: center; }

#integrations .facebook_messenger_instagram-icon {
  height: 75%; }

@media only screen and (max-width: 499px) {
  #integrations .botbit-table {
    margin-top: 0; }
  .collection-visualizer-header .collection-visualizer-title {
    font-size: 20px;
    margin-bottom: 10px; }
  .collection-visualizer-header .collection-visualizer-subtitle {
    margin-bottom: 30px; } }
