@media (min-width: 576px) {
  .modal-dialog {
    max-width: none !important;
    width: 987px !important;
    margin: auto !important;
  }

  .card-container {
    padding-right: 0px;
  }

  .score-screen-container {
    gap: 12px;
  }

  .score-container-current {
    display: flex;
    background-color: rgba(255, 255, 255, 0.4);
    height: 110px;
    gap: 12px;
    padding-top: 2rem;
  }

  .score-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }

  .score-icon {
    flex-shrink: 0;
    filter: blur(2px);
  }

  .score-text {
    font-size: 18px;
    font-weight: 600;
    color: #9291a5;
    text-align: left;
    filter: blur(2px);
  }

  .progress-bar {
    width: 100%;
    filter: blur(2px);
    background-color: #e5e7e9;
  }

  .even-row {
    background-color: #f8f8fa;
  }

  .odd-row {
    background-color: white;
  }

  .item-list {
    display: flex;
  }

  .item-item {
    display: flex;
    margin-bottom: 10px;
  }

  .item-icon {
    margin-right: 10px;
  }

  .item-description {
    display: flex;
    font-size: 16px;
    color: #333;
    padding-right: 10px;
    line-height: normal;
    font-weight: 300 !important;
  }
}

.row-value {
  font-weight: bold;
  font-size: 18px;
  color: #1a1a1a;
}

.row-description {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.row-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.row-tbody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.no-border {
  border: none !important;
}
