.podiumWrapper {
    margin-top: 3rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
    }

    // First place
    .podium-first {
        order: 1;
        margin-bottom: 2rem;
        margin-top: -2rem;

        @media (max-width: 768px) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    @media (min-width: 768px) {
        .col-sm-4 {
            &:nth-child(3) {
                order: 2
            }
        }
    }

    .card {
        cursor: pointer;
        @media (max-width: 768px) {
            margin-top: 2.5rem;
            margin-bottom: .5rem;
        }

        img {
            background-color: #fff;
            box-shadow: 0 0 1rem 0 rgba(black, 0.2), 0 1rem 1rem -0.5rem rgba(black, 0.3);
			position: absolute;
            margin-left: auto;
            margin-right: auto;
            top: -2rem;
            left: 0;
            right: 0;
            text-align: center;
            width: 100px;
            height: 100px;
        }

		.pos {
            font-size: 1.2rem;
            
            span {
                display: block;
                font-weight: 900;
                font-size: 2rem;
            }
        }
    }

    .card-body {
        margin-top: 3.5rem;
        margin-bottom: 1rem;
    }

    // Slider
    @media (max-width: 768px) {
        .alice-carousel__wrapper {
            padding-right: 0 !important;
        }
    }
}
