.bestDay {
  border: 2px solid #E9AA43; }

.daysCard {
  text-align: center;
  height: 394px;
  width: 31%;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-top: 35px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  position: relative; }
  @media (max-width: 992px) {
    .daysCard {
      width: 95%;
      height: 394px;
      margin: 7px 0px; } }
  .daysCard span {
    display: block; }
  .daysCard .timeDetail {
    margin-top: 25px; }
    .daysCard .timeDetail .restOfDaySpan {
      bottom: 18px;
      position: absolute;
      left: 0;
      right: 0; }
      .daysCard .timeDetail .restOfDaySpan strong {
        color: #CCCCCC; }
  .daysCard .chartInfo {
    display: flex;
    justify-content: center;
    margin-top: 31px;
    position: relative; }
    .daysCard .chartInfo > div:nth-child(2) {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .daysCard .chartInfo > div:nth-child(2) > span:first-child {
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        font-weight: 600; }
      .daysCard .chartInfo > div:nth-child(2) > span:nth-child(2) {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #000000; }
  .daysCard .dateInfo {
    min-height: 60px; }
    .daysCard .dateInfo > :first-child {
      font-size: 30px;
      line-height: 41px;
      color: #000000;
      font-weight: 600; }
    .daysCard .dateInfo > :nth-child(2) {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #676A6C; }
      .daysCard .dateInfo > :nth-child(2) > i {
        color: #E9AA43;
        font-size: 14px;
        line-height: 16px;
        margin-right: 6px; }
