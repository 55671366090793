@import "../CommonStyles.scss";
#integrations-pre-optin {
  @include card-container;
  .card {
    @include card-styles;
    .integrations-pre-optin_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .integrations-pre-optin_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .title {
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
    input {
      width: 18rem;
    }
    .link-like-btn {
      text-decoration: none;
      color: var(--default);
    }
    .connect-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .field-with-error {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    .compiledPrefixAndSufix {
      display: none;
    }
    
    .prefix, .sufix {
      padding: 0 0.4rem;
      color: #444;
      font-size: 14px;
    }
    .page-heading {
      display: none;
    }
    .md-form {
      margin: 0.4rem 0;
    }
    .error-message {
      color: red;
      font-size: 14px; 
    }
  }
}
@media (max-width: 499px) {
  #integrations-pre-optin {
    .card {
      .prefix, .sufix {
        display: none;
      }
      input {
        width: 100%;
      }
      .field-with-error {
        width: 100%;
      }
      .compiledPrefixAndSufix {
        display: block;
        width: 100%;
      }
      .connect-field {
        flex-direction: column;
        align-items: flex-start;
      }
      .md-form {
        width: 100%;
      }
    }
  }
}