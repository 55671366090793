.testEmailModal {
  overflow: visible !important; }

@media (max-width: 576px) {
  #maximal-editor .campaignNotSavedModal .modal-content {
    height: 26vh;
    min-height: 0; } }

#maximal-editor .campaignNotSavedModal .modalTitle {
  font-size: 20px;
  line-height: 28px;
  color: black; }

#maximal-editor .campaignNotSavedModal .modalContent {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px; }

#maximal-editor .campaignNotSavedModal .modalButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 13px;
  margin-top: 20px; }
  #maximal-editor .campaignNotSavedModal .modalButtons button {
    background: #19AA89;
    box-shadow: none;
    border-radius: 3px;
    padding: 10px 43px;
    text-transform: initial;
    font-weight: 600;
    margin-right: 18px; }
  #maximal-editor .campaignNotSavedModal .modalButtons a {
    color: #19AA89; }

#maximal-editor #toastContent div:first-child {
  margin-top: 2px; }

#maximal-editor > .container {
  font-family: Open Sans;
  padding-top: 22px;
  padding-bottom: 22px;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  font-weight: 400; }
  @media (max-width: 576px) {
    #maximal-editor > .container {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  #maximal-editor > .container .previewerModal .modal-body {
    max-height: 100vh !important; }
  #maximal-editor > .container #header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    #maximal-editor > .container #header .right-side .primary-actions .botbit-action-link {
      margin-right: 15px; }
    @media (max-width: 576px) {
      #maximal-editor > .container #header .right-side {
        background-color: white;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        padding: 12px 0px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14); }
        #maximal-editor > .container #header .right-side .botbit-actions-container {
          width: 100%; }
        #maximal-editor > .container #header .right-side .primary-actions {
          display: flex;
          justify-content: space-evenly; }
          #maximal-editor > .container #header .right-side .primary-actions > .dropdown {
            height: 100%;
            width: 42%; }
            #maximal-editor > .container #header .right-side .primary-actions > .dropdown button {
              width: 100%;
              padding: 11px; }
          #maximal-editor > .container #header .right-side .primary-actions > button {
            padding: 0;
            width: 42%;
            padding: 13px 0; }
        #maximal-editor > .container #header .right-side button:not(.dropdown-item) {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-transform: initial;
          box-shadow: none;
          border-radius: 3px;
          margin: 0; } }
    #maximal-editor > .container #header .left-side {
      max-width: calc( 100% - 577px); }
      @media (max-width: 576px) {
        #maximal-editor > .container #header .left-side {
          max-width: 100%;
          width: 100%; } }
      #maximal-editor > .container #header .left-side .smartBadge {
        padding: 5px 13px;
        font-size: 13px; }
      #maximal-editor > .container #header .left-side .icon-container i {
        color: black;
        font-size: 16px;
        margin-bottom: 25px;
        cursor: pointer; }
      #maximal-editor > .container #header .left-side .titleContainer {
        width: 100%;
        display: flex;
        align-items: center; }
        #maximal-editor > .container #header .left-side .titleContainer h1 {
          margin: 0;
          font-size: 24px;
          color: black;
          font-weight: 600;
          margin-bottom: 5px; }
  #maximal-editor > .container .classic-tabs .nav li:first-child {
    margin-left: 0; }
  #maximal-editor > .container #tabsContainer {
    margin-top: 40px; }
    #maximal-editor > .container #tabsContainer .fixed-action-btn {
      bottom: 67px !important; }
    #maximal-editor > .container #tabsContainer > .nav {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    #maximal-editor > .container #tabsContainer > .tab-content {
      padding: 0 !important; }
      @media (max-width: 576px) {
        #maximal-editor > .container #tabsContainer > .tab-content {
          padding: 0 !important; } }
    #maximal-editor > .container #tabsContainer .segmentCollVis .collection-visualizer-title {
      font-weight: 300;
      font-size: 20px; }
    #maximal-editor > .container #tabsContainer .section-tab-item {
      border-left: 0;
      background: none; }
      #maximal-editor > .container #tabsContainer .section-tab-item.active .nav-link, #maximal-editor > .container #tabsContainer .section-tab-item:hover .nav-link {
        border-color: #20BFC2;
        color: #20BFC2 !important; }
        #maximal-editor > .container #tabsContainer .section-tab-item.active .nav-link.invalidTab, #maximal-editor > .container #tabsContainer .section-tab-item:hover .nav-link.invalidTab {
          border-color: var(--danger) !important;
          color: var(--danger) !important; }
      #maximal-editor > .container #tabsContainer .section-tab-item .nav-link {
        padding: 14px 19px;
        border-color: transparent;
        color: #676A6C;
        text-transform: none;
        font-size: 16px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .section-tab-item .nav-link {
            font-size: 14px; } }
        #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i {
          margin-left: 12px;
          font-size: 18px; }
          #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i.fa-check-circle {
            color: #20BFC2; }
          #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i.fa-exclamation-circle {
            color: var(--danger); }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .section-tab-item .nav-link i {
              margin-left: 0;
              font-size: 14px; } }
    @media (max-width: 576px) {
      #maximal-editor > .container #tabsContainer .section-tab-item {
        width: 33.3333%; }
        #maximal-editor > .container #tabsContainer .section-tab-item .nav-link {
          padding: 13px 0px; } }
    #maximal-editor > .container #tabsContainer .tab-content {
      box-shadow: none;
      background-color: inherit; }
    #maximal-editor > .container #tabsContainer .email-content {
      margin-top: 30px; }
      #maximal-editor > .container #tabsContainer .email-content .card {
        padding-bottom: 39px !important; }
      #maximal-editor > .container #tabsContainer .email-content .md-form {
        margin-top: 0px;
        margin-bottom: 0px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo {
        display: flex;
        align-items: baseline; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo .replyToAddress {
          width: 70%; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer .fromOrTo .replyToAddress {
              width: 100%; } }
      #maximal-editor > .container #tabsContainer .email-content .campaignFromToContainer #fromEmailSelector + div label {
        bottom: -20px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignMessage, #maximal-editor > .container #tabsContainer .email-content .campaignSubject {
        display: flex;
        align-items: center;
        margin-top: 20px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .campaignMessage, #maximal-editor > .container #tabsContainer .email-content .campaignSubject {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignMessage .input-with-tags, #maximal-editor > .container #tabsContainer .email-content .campaignSubject .input-with-tags {
          width: -webkit-fill-available; }
          #maximal-editor > .container #tabsContainer .email-content .campaignMessage .input-with-tags .error-message, #maximal-editor > .container #tabsContainer .email-content .campaignSubject .input-with-tags .error-message {
            bottom: -16px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignSubject {
        margin-top: 15px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignCompanyContainer {
        margin-bottom: 20px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignBenefitContainer #discount {
        font-weight: 400; }
      #maximal-editor > .container #tabsContainer .email-content .campaignValidityContainer {
        margin-top: 15px; }
      #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer {
        display: flex;
        align-items: center;
        margin-top: 33px;
        padding-bottom: 30px;
        width: 100%;
        height: auto; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer {
            position: relative; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer .error-message {
          color: var(--danger);
          position: absolute;
          bottom: 27px;
          left: 24px;
          width: 100%; }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer .hasError img {
          border: 1px solid var(--danger); }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer .hasError #imageGalleryContainer > button {
          background-color: var(--danger) !important; }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer img {
          float: left !important;
          margin-left: 0 !important;
          margin-top: 0 !important; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer img {
              float: right !important; } }
        #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer #imageGalleryContainer {
          flex-direction: inherit;
          justify-content: inherit;
          display: inline-block;
          position: absolute;
          bottom: 41px;
          left: 105px;
          width: auto; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer #imageGalleryContainer {
              left: unset;
              right: -24px; } }
          #maximal-editor > .container #tabsContainer .email-content .campaignImageContainer #imageGalleryContainer .nav-link {
            border-bottom: 0; }
      #maximal-editor > .container #tabsContainer .email-content .inputLabel {
        min-width: 140px;
        max-width: 140px;
        display: inline-block;
        font-weight: 600; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .inputLabel {
            width: 100%;
            max-width: 100%;
            margin-top: 10px; } }
      #maximal-editor > .container #tabsContainer .email-content .buttonContainer {
        display: flex;
        align-items: center;
        margin-top: 17px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .buttonContainer {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .buttonContainer .campaignButton div:first-child {
          margin-bottom: -15px; }
      #maximal-editor > .container #tabsContainer .email-content .benefitContainer {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .benefitContainer {
            width: 100%;
            display: block; } }
      #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer {
        margin-top: 25px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer {
            margin-top: 0; } }
        #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer #countAsWtSwitch {
          display: inline-block;
          margin-left: 20px; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content #countAsWtContainer #countAsWtSwitch {
              margin-top: 17px;
              margin-left: -11px;
              display: block; } }
      #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer {
        margin-top: 31px;
        display: flex; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer {
            display: block;
            margin-top: 10px; } }
        #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer span.inputLabel {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center; }
          #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer span.inputLabel i {
            margin-left: 5px;
            color: #1e90ff; }
        #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown {
          width: 320px; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown {
              margin: 0;
              margin-top: 8px;
              width: 100%; } }
          #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown button {
            border: none !important;
            box-shadow: none;
            border-radius: 0;
            color: black !important;
            padding: 0;
            text-transform: capitalize;
            border-bottom: 1px solid #ced4da !important;
            padding: 0 27px; }
            @media (max-width: 576px) {
              #maximal-editor > .container #tabsContainer .email-content #wtFromToContainer div.wtFromToDropdown button {
                padding-left: 0;
                margin-left: 0;
                width: 100%;
                padding: 0;
                margin: 5px 0 0 0; } }
      #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer {
        display: flex;
        align-items: center;
        margin-top: 26px; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer {
            display: block; } }
        #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyLabel {
          font-weight: 600; }
        #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer {
          display: flex;
          align-items: center; }
          @media (max-width: 576px) {
            #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer .usersNotReelegibleDaysQttyInput {
              margin-left: 0; } }
          #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer input {
            margin-bottom: 0;
            max-width: 45px;
            margin: 0 10px; }
          #maximal-editor > .container #tabsContainer .email-content .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer span {
            font-weight: 600; }
      #maximal-editor > .container #tabsContainer .email-content #html-editor-container {
        margin-top: 20px; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container #htmlContentDisclaimer {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          display: flex;
          background-color: #FFF3CD;
          padding: 12px;
          border-radius: 5px;
          margin: 30px 0; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container .custom-toolbar-extra a {
          display: flex;
          align-items: center; }
          #maximal-editor > .container #tabsContainer .email-content #html-editor-container .custom-toolbar-extra a span {
            padding: 0 !important; }
          #maximal-editor > .container #tabsContainer .email-content #html-editor-container .custom-toolbar-extra a i {
            font-family: "Font Awesome 5 Free";
            font-size: 21px;
            font-weight: 900; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container #tags-container i {
          font-size: 16px; }
        #maximal-editor > .container #tabsContainer .email-content #html-editor-container #imageGalleryContainer {
          padding: 0 !important; }
      #maximal-editor > .container #tabsContainer .email-content .tags-modal .p-2 {
        padding: 0 !important; }
      #maximal-editor > .container #tabsContainer .email-content .tags-modal .form-check label {
        padding-left: 0; }
        #maximal-editor > .container #tabsContainer .email-content .tags-modal .form-check label:before {
          display: none; }
      #maximal-editor > .container #tabsContainer .email-content .deliveries-modal .botbit-expanded-select li img {
        width: 90%; }
      #maximal-editor > .container #tabsContainer .email-content .deliveries-modal .form-check label {
        padding-left: 0; }
        #maximal-editor > .container #tabsContainer .email-content .deliveries-modal .form-check label:before {
          display: none; }
    #maximal-editor > .container #tabsContainer .fromDisplayContainer {
      margin-top: 32px; }
      #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer {
        display: flex;
        margin-bottom: 17px;
        position: relative; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer {
            display: inherit; }
            #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer .md-form {
              margin-top: 17px; } }
        #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer .md-form {
          width: 100%; }
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer .md-form input {
            padding-top: 0;
            margin: 0; }
        #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError {
          margin-bottom: 35px; }
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError input {
            border-bottom: 1px solid var(--danger);
            box-shadow: none; }
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError .error-message {
            color: var(--danger);
            position: absolute;
            left: 140px;
            top: 35px; }
            @media (max-width: 576px) {
              #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayInputContainer.hasError .error-message {
                top: 79px;
                left: 0; } }
      #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayLabel {
        min-width: 140px;
        max-width: 140px;
        display: inline-block;
        font-weight: 600; }
        @media (max-width: 576px) {
          #maximal-editor > .container #tabsContainer .fromDisplayContainer .fromDisplayLabel {
            width: 100%;
            max-width: 100%;
            margin-top: 10px; } }
  #maximal-editor > .container .deliverySettings {
    margin-top: 30px; }
    #maximal-editor > .container .deliverySettings .deliveryTitle {
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      color: #000000; }
    #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 26px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 25px; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard > div:first-child {
        font-size: 34px; }
        #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard > div:first-child i {
          color: var(--primary);
          margin-bottom: 7px; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard span {
        margin-left: 27px; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard input.select-dropdown {
        position: initial; }
      #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard .promocodeSelect {
        width: 100%;
        padding-left: 20px; }
        #maximal-editor > .container .deliverySettings .promocodesContainer .promocodeCard .promocodeSelect input {
          font-weight: 600; }
    #maximal-editor > .container .deliverySettings .deliveryContainer #inactiveCampaignDisclaimer {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      display: flex;
      background-color: #FFF3CD;
      padding: 12px;
      border-radius: 5px;
      margin: 30px 0;
      display: flex;
      align-items: center; }
      #maximal-editor > .container .deliverySettings .deliveryContainer #inactiveCampaignDisclaimer i {
        font-size: 25px;
        margin-right: 13px; }
    #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 26px; }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer {
          padding-left: 18px; }
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .rc-time-picker {
            margin-left: 0px; } }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError {
          position: relative;
          margin-bottom: 10px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .DayPickerInput input, #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .rc-time-picker input {
        border-bottom: 2px solid var(--danger); }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .error-message {
        font-weight: 400;
        color: var(--danger);
        margin-left: 13px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer.hasError .error-message {
            position: absolute;
            left: 0;
            bottom: -22px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyContainer {
        margin-top: 20px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .error-message {
            width: 100%;
            right: -8px; } }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyContainer {
            margin-bottom: 17px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendOnceOnContainer .usersNotReelegibleDaysQttyInput {
        margin: 0px; }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards {
          padding-right: 15px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .fa.fa-angle-down.fa-lg {
        display: none; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .fa.fa-angle-up.fa-lg {
        display: none; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: white; }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer > div {
          padding: 1rem; }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer .form-check {
          margin: 0 !important;
          display: flex;
          align-items: center;
          width: 44px;
          padding-right: 46px;
          padding-left: 20px !important;
          padding-top: 20px; }
          @media (max-width: 576px) {
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .optionContainer .form-check {
              padding-right: 35px; } }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .DayPickerInput {
          width: 76%; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .DayPickerInput > input {
        border: none;
        padding: 7px 8px;
        border-bottom: 2px solid var(--primary);
        width: 100%; }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .mobileFromToCron .rc-time-picker {
          width: 76%;
          margin-left: 27px; } }
      @media (max-width: 576px) {
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .rc-time-picker {
          width: 76%;
          margin-left: 16px; } }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .rc-time-picker input {
        border: none;
        border-bottom: 2px solid var(--primary);
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        border-radius: 0;
        color: black;
        padding: 7px; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .bbCollapsable #collapse {
        padding: 5px 54px 16px 54px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .bbCollapsable #collapse {
            padding: 5px 16px; } }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .bbCollapsable #collapse > div {
          padding: 0 !important; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer {
        display: flex; }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer {
          display: flex; }
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer .md-form {
            width: 68px;
            margin: 0;
            margin-top: -13px;
            padding: 0 9px; }
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError {
            padding-bottom: 18px;
            position: relative; }
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .usersNotReelegibleDaysQttyInput {
              border-bottom: 1px solid var(--danger); }
              #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .usersNotReelegibleDaysQttyInput:focus {
                box-shadow: none; }
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .usersNotReelegibleDaysQttyContainer .usersNotReelegibleDaysQttyInputContainer.hasError .error-message {
              right: -60px;
              position: absolute;
              top: 31px;
              color: var(--danger);
              font-weight: 400; }
      #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer {
        display: block;
        margin-bottom: 20px; }
        @media (max-width: 576px) {
          #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError {
            position: relative;
            margin-bottom: 27px; } }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .DayPickerInput input, #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .rc-time-picker input {
          border-bottom: 2px solid var(--danger); }
        #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .error-message {
          font-weight: 400;
          color: var(--danger);
          margin-left: 13px; }
          @media (max-width: 576px) {
            #maximal-editor > .container .deliverySettings .deliveryContainer .optionsCards .sendPeriodicallyCollapsable .activeToContainer.hasError .error-message {
              position: absolute;
              left: 0;
              bottom: -22px; } }
  #maximal-editor > .container .deletionModal > .modal-content {
    min-height: inherit; }
  #maximal-editor > .container .deletionModal .modal-body p:first-child {
    font-size: 20px;
    line-height: 28px;
    color: #ED5565;
    font-weight: 600; }
  #maximal-editor > .container .deletionModal .modal-body p:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.541327); }
  #maximal-editor > .container .deletionModal .modal-body > div {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    #maximal-editor > .container .deletionModal .modal-body > div button {
      border-radius: 3px;
      box-shadow: none;
      text-transform: initial;
      font-size: 14px;
      line-height: 19px;
      text-align: center; }
      #maximal-editor > .container .deletionModal .modal-body > div button.link-styled {
        font-size: 14px;
        line-height: 19px;
        color: #19AA89;
        font-weight: 600;
        margin-right: 10px;
        margin-left: 14px;
        text-decoration: none; }
    #maximal-editor > .container .deletionModal .modal-body > div a {
      font-size: 14px;
      line-height: 19px;
      color: #19AA89;
      font-weight: 600;
      margin-right: 10px;
      margin-left: 14px; }
