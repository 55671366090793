.menu {
  cursor: pointer;
  z-index: 10000;
  margin-top: -1rem;
  margin-left: -1rem;
  min-width: 180px; }

.menu-content > *:hover {
  border-left: 3px solid transparent; }

.animated-short {
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
