.campaignBenefitContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important; }
  .campaignBenefitContainer .benefitSelector {
    width: 25%;
    display: inline-block; }
    @media (max-width: 576px) {
      .campaignBenefitContainer .benefitSelector {
        width: 100%;
        display: block;
        margin: 19px 0 35px 0 !important; } }
  .campaignBenefitContainer .productSelector {
    width: 25%;
    margin-left: 5% !important;
    display: inline-block; }
    .campaignBenefitContainer .productSelector input#discount {
      padding-bottom: 8px; }
    @media (max-width: 576px) {
      .campaignBenefitContainer .productSelector {
        width: 100%;
        margin-left: 0 !important;
        display: block;
        padding-bottom: 15px; } }
    .campaignBenefitContainer .productSelector .md-form {
      margin: 0 !important; }
  .campaignBenefitContainer .customProductNameInput {
    display: inline-block;
    margin-left: 4%;
    width: 40%; }
    @media (max-width: 576px) {
      .campaignBenefitContainer .customProductNameInput {
        width: 100%;
        margin-left: 0;
        display: block;
        padding-bottom: 15px; } }
    .campaignBenefitContainer .customProductNameInput input {
      margin-bottom: 0; }
      .campaignBenefitContainer .customProductNameInput input + label {
        font-size: 11pt;
        font-weight: 400;
        color: black; }
    .campaignBenefitContainer .customProductNameInput.hasError input {
      border-color: var(--danger) !important; }
      .campaignBenefitContainer .customProductNameInput.hasError input:focus {
        box-shadow: 0 1px 0 0 var(--danger); }
      .campaignBenefitContainer .customProductNameInput.hasError input + label {
        color: var(--danger) !important; }
    .campaignBenefitContainer .customProductNameInput.hasError .errorLabel {
      position: absolute;
      font-weight: 400; }
  .campaignBenefitContainer #discount.hasError {
    border-color: var(--danger) !important; }
    .campaignBenefitContainer #discount.hasError:focus {
      box-shadow: 0 1px 0 0 var(--danger); }
    .campaignBenefitContainer #discount.hasError + label {
      color: var(--danger) !important; }
