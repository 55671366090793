.input-range__slider {
  /*background: #20b426;
  border: 1px solid #20b426;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
  */
  background: url(/static/img/sprite-skin-modern.png) repeat-x;
  width: 11px;
  height: 18px;
  top: 31px;
  background-position: 0 -120px;
  display: block;
  cursor: default;
  margin-left: -0.5rem;
  margin-top: -0.65rem; }

.input-range__slider:active {
  transform: scale(1.3); }

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  font-size: 0.8em;
  left: 0; }

.input-range__label--max {
  font-size: 0.8em;
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -2rem;
  background: #20b426;
  padding: 1px 2px 1px 12px;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.333;
  text-shadow: none;
  border-radius: 4px;
  display: block;
  cursor: default;
  white-space: nowrap;
  font-weight: 400;
  border: 3px solid transparent;
  content: "";
  border-top-color: #20b426; }

.input-range__label-container {
  left: -50%;
  position: relative; }

.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }

.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #20b426; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }
