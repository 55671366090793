.spinner-v2 { 
  .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .loader {
    border: 8px solid #2383E2;
    border-left-color: transparent;
    border-radius: 50%;
  }

  .loader {
    border: 8px solid #2383E2;
    border-left-color: transparent;
    width: 80px;
    height: 80px;
  }

  .loader {
    border: 8px solid #2383E2;
    border-left-color: transparent;
    width: 80px;
    animation: spin89345 1s linear infinite;
  }

  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}