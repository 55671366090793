.source-card-modal {
  .modal-body {
    padding: 0 !important;
    .source-card {
      box-shadow: none !important;
      position: absolute;
      top: 0;
    }
    .back-button {
      color: black;
      font-size: 20px;
      cursor: pointer;
      position: fixed;
      z-index: 1000;
      top: 1rem;
      left: 1rem;
    }
  }
}