.minimalEditorContainer {
  font-family: Open Sans !important; }
  .minimalEditorContainer .inputLabel {
    font-weight: 600;
    min-width: 140px; }
    @media (max-width: 576px) {
      .minimalEditorContainer .inputLabel {
        margin-bottom: 6px;
        min-width: 100%; } }
  .minimalEditorContainer .campaignMessage {
    margin-top: 8px;
    margin-bottom: 10px; }
  .minimalEditorContainer .testEmailModalParent .modal-content {
    min-height: 0 !important;
    color: black;
    padding: 20px; }
    .minimalEditorContainer .testEmailModalParent .modal-content strong {
      font-weight: 600; }
  .minimalEditorContainer .container {
    padding: 0; }
  .minimalEditorContainer .modalSaveBtnsFooter {
    justify-content: space-around;
    padding: 9px 9px 60px 9px;
    border-top: 1px solid #E7E7E7;
    position: sticky;
    background: white;
    left: 0;
    width: 100%; }
    .minimalEditorContainer .modalSaveBtnsFooter .btn {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 10px 20px; }
    .minimalEditorContainer .modalSaveBtnsFooter > button {
      width: 46%; }
    .minimalEditorContainer .modalSaveBtnsFooter > .btn-outline-primary {
      width: 49%; }
  .minimalEditorContainer .modalBtnsFooter {
    justify-content: space-around;
    padding: 9px 9px 60px 9px;
    border-top: 1px solid #E7E7E7;
    position: sticky;
    background: white;
    left: 0;
    width: 100%; }
    .minimalEditorContainer .modalBtnsFooter .btn {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 10px 20px; }
    .minimalEditorContainer .modalBtnsFooter > button {
      width: 44%; }
      @media (max-width: 576px) {
        .minimalEditorContainer .modalBtnsFooter > button.previsualizeBtn {
          line-height: 23px; } }
    .minimalEditorContainer .modalBtnsFooter > .dropdown {
      width: 49%; }
      .minimalEditorContainer .modalBtnsFooter > .dropdown > button {
        width: 100%; }
  .minimalEditorContainer #backIcon {
    padding: 22px 0 0 0;
    color: black;
    font-size: 16px; }
    @media (max-width: 576px) {
      .minimalEditorContainer #backIcon {
        padding-left: 16px; } }
  @media (max-width: 500px) {
    .minimalEditorContainer > .modal-content > .modal-body {
      padding: 0; }
      .minimalEditorContainer > .modal-content > .modal-body > .container {
        padding: 0; } }
  .minimalEditorContainer .md-form.md-range-input-fix > .active {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000; }
  .minimalEditorContainer .no-scroll {
    overflow-y: hidden !important; }
  .minimalEditorContainer #imageGalleryContainer {
    display: inherit;
    flex-direction: inherit;
    justify-content: inherit;
    display: inline-block;
    position: absolute;
    bottom: -3px;
    right: -4px;
    width: auto; }
  .minimalEditorContainer #messageNotCustomModal {
    border-radius: 5px;
    overflow-y: hidden; }
    .minimalEditorContainer #messageNotCustomModal > .modal-dialog > .modal-content {
      padding: 8px; }
      @media (max-width: 576px) {
        .minimalEditorContainer #messageNotCustomModal > .modal-dialog > .modal-content {
          padding: 3px !important; } }
      @media (min-width: 576px) {
        .minimalEditorContainer #messageNotCustomModal > .modal-dialog > .modal-content {
          bottom: 20vh;
          left: 37vw;
          width: 465px; } }
    @media (min-width: 576px) {
      .minimalEditorContainer #messageNotCustomModal .botbit-modal.modal-dialog-centered {
        display: inherit;
        align-items: inherit; } }
    .minimalEditorContainer #messageNotCustomModal .modal-content {
      min-height: 0 !important; }
  .minimalEditorContainer #bodyContainer {
    max-width: 745px;
    padding-bottom: 20px; }
    @media (max-width: 576px) {
      .minimalEditorContainer #bodyContainer {
        height: calc( 100vh - 185px);
        overflow-y: auto;
        padding-bottom: 19px;
        padding-left: 15px;
        padding-right: 15px; } }
    .minimalEditorContainer #bodyContainer > .image {
      padding: 25px 15px; }
  .minimalEditorContainer .saveCloseBtn {
    display: flex;
    justify-content: flex-end; }
    .minimalEditorContainer .saveCloseBtn > button {
      padding: 0 22px 0 22px;
      height: 36px;
      font-weight: 600;
      font-size: 14px;
      border-width: 1px !important; }
  .minimalEditorContainer .editorBoxContainer {
    border: 1px solid #ccc;
    padding: 14px 20px;
    width: 100%;
    position: relative;
    border-radius: 4px;
    max-height: 400px;
    transition: 1s max-height; }
    .minimalEditorContainer .editorBoxContainer.big {
      max-height: 401px; }
      @media (max-width: 576px) {
        .minimalEditorContainer .editorBoxContainer.big {
          border: none;
          border-radius: none;
          max-height: none; } }
    @media (max-width: 576px) {
      .minimalEditorContainer .editorBoxContainer .editingTitle {
        font-weight: bold !important;
        font-size: 24px !important;
        line-height: 33px !important;
        color: #000000 !important; } }
    .minimalEditorContainer .editorBoxContainer .boxTitle {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 9px; }
      @media (max-width: 576px) {
        .minimalEditorContainer .editorBoxContainer .boxTitle {
          max-width: 90%; } }
    .minimalEditorContainer .editorBoxContainer > i {
      color: #19AA89;
      position: absolute;
      right: 21px;
      top: 21px;
      cursor: pointer; }
    .minimalEditorContainer .editorBoxContainer .content {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 19px !important;
      color: #000000 !important; }
    .minimalEditorContainer .editorBoxContainer .greenHighlighted {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #19AA89; }
    .minimalEditorContainer .editorBoxContainer .md-form {
      margin: 0; }
    .minimalEditorContainer .editorBoxContainer .campaignFromToContainer .fromOrTo {
      padding-bottom: 10px; }
    .minimalEditorContainer .editorBoxContainer .campaignFromToContainer #fromEmailSelector + div label {
      bottom: -20px; }
    .minimalEditorContainer .editorBoxContainer .fromDisplayInputContainer {
      position: relative; }
      .minimalEditorContainer .editorBoxContainer .fromDisplayInputContainer.hasError input {
        border-bottom: 1px solid var(--danger);
        box-shadow: none; }
      .minimalEditorContainer .editorBoxContainer .fromDisplayInputContainer.hasError .error-message {
        color: var(--danger);
        position: absolute;
        top: 87px; }
    .minimalEditorContainer .editorBoxContainer .fromDisplayLabel {
      min-width: 140px;
      max-width: 140px;
      display: inline-block;
      font-weight: 600; }
      @media (max-width: 576px) {
        .minimalEditorContainer .editorBoxContainer .fromDisplayLabel {
          width: 100%;
          max-width: 100%;
          margin-top: 10px; } }
  .minimalEditorContainer .saveCancelBtn {
    padding: 0 24px 0 24px;
    height: 33px;
    font-weight: 600; }
  .minimalEditorContainer .btn {
    box-shadow: none !important;
    text-transform: inherit !important; }
  .minimalEditorContainer #webPreviewerContainer .btn {
    text-transform: uppercase !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important; }
  .minimalEditorContainer .badge-warning {
    padding: 5px 14px;
    font-size: 12px;
    font-weight: 600; }
  .minimalEditorContainer .minimalEditorHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600; }
    @media (max-width: 576px) {
      .minimalEditorContainer .minimalEditorHeader {
        width: 100%;
        margin-top: inherit; } }
    .minimalEditorContainer .minimalEditorHeader .btn {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      width: 165px;
      padding: 13px 0; }
      .minimalEditorContainer .minimalEditorHeader .btn.btn-primary {
        line-height: 23px; }
    .minimalEditorContainer .minimalEditorHeader .editCampaignTitle {
      width: 100%;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
      display: inline-block; }
      @media (max-width: 576px) {
        .minimalEditorContainer .minimalEditorHeader .editCampaignTitle {
          width: 100%; } }
      @media (max-width: 576px) {
        .minimalEditorContainer .minimalEditorHeader .editCampaignTitle > span:not(.badge) {
          width: 75%;
          display: block;
          float: left; } }
    .minimalEditorContainer .minimalEditorHeader .smartBadge {
      display: block;
      width: fit-content; }
      @media (max-width: 576px) {
        .minimalEditorContainer .minimalEditorHeader .smartBadge {
          height: 100%; } }
    .minimalEditorContainer .minimalEditorHeader .maximalEditorBtn {
      padding-left: 4%;
      padding-right: 4%;
      width: 160px; }
  .minimalEditorContainer .closeModalIcon {
    cursor: pointer; }

.webPreviewerModal .modal-title {
  display: flex; }
  .webPreviewerModal .modal-title .back-button {
    width: 18px;
    margin-bottom: 5px;
    margin-right: 15px; }
    .webPreviewerModal .modal-title .back-button i {
      font-size: 16px;
      color: black; }

.webPreviewerModal #webPreviewerContainer {
  height: 87vh !important; }

.loading-overlay {
  z-index: 999999; }
