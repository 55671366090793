#integrations-list {
    .column-container {
        display: inline-flex !important;
    }

    .shopify-header-button {
        background-color: #fafafa !important;
        display: flex;
        align-items: center;
        color: black;
        justify-content: center;
    }

    .shopify-header-icon {
        font-size: 1.5em !important;
    }

    .gmail-header-button {
        background-color: #4285F4 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .gmail-header-icon {
        font-size: 2em !important;
    }

    .rdstation-header-button {
        background-color: #fafafa !important;
        display: flex;
        align-items: center;
        color: black;
        justify-content: center;
    }    

    .facebook_messenger_instagram-header-button {
        background-color: #1877F2 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .facebook_messenger_instagram-header-icon {
        font-size: 1.5em !important;
    }

    @media(max-width: 991px) {
        .header-actions-container {
            display: none !important;
        }
    }
}
