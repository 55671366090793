.local-seo-chart-wrapper {
  background: white;
  padding: 20px 20px 20px 0px;
  width: 961px;
  height: 516px; }
  .local-seo-chart-wrapper .local-seo-chart-header-title {
    margin: 0;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 42px;
    line-height: 100%;
    letter-spacing: 0;
    color: #000000; }
