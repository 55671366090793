#campaignNameContainer {
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000000 !important;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 15px; }
  @media (max-width: 576px) {
    #campaignNameContainer {
      display: block; } }
  #campaignNameContainer .md-form {
    width: 100%; }
    #campaignNameContainer .md-form input.hasError {
      border-color: var(--danger) !important; }
      #campaignNameContainer .md-form input.hasError:focus {
        box-shadow: 0 1px 0 0 var(--danger); }
  #campaignNameContainer label {
    min-width: 140px;
    max-width: 140px;
    display: inline-block;
    font-weight: 600; }
    @media (max-width: 576px) {
      #campaignNameContainer label {
        width: 100%;
        max-width: 100%;
        margin-top: 10px; } }
  #campaignNameContainer .error-message {
    color: var(--danger);
    position: absolute;
    bottom: 0;
    left: 140px; }
    @media (max-width: 576px) {
      #campaignNameContainer .error-message {
        left: 0; } }
  #campaignNameContainer #readOnlyLabel {
    font-weight: unset; }
