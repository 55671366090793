.chart-container {
    .chart-and-info-container {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column-reverse;
            padding-bottom: 4rem;
        }
    }
    .data-container {
        .details-cards {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 12rem;
            @media (max-width: 768px) {
                height: unset;
                // flex-direction: column;
            }
            .funnel-detail-card {
                height: 100%;
                // width: 33.33%;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: center;
                background-color: #ffffff;
                @media (max-width: 768px) {
                    width: calc(100%/3);
                    padding: 8px;
                    height: 8.5rem;
                    color: #000000;
                    font-size: 0.7rem;
                    text-align: center;
                    .btn.btn-sm {
                        padding: 0.2rem 1rem;
                        font-size: 0.64rem;
                    }
                }
                .card-content {
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #000000;
                @media (max-width: 768px) {
                    font-size: 1.2rem;
                }
                }
            }
        }
    }
}

.funnel-detail-modal {
    .modal-content {
        @media (min-width: 768px) {
            height: 90vh;
        }
    }
}