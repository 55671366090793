.loading-overlay  {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0, 0.4);
  overflow-x: hidden;
  display: block;
}

.loading-container  {
  margin: auto;
  width: 400px;
  // height: 400px;
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  @media (max-width: 768px) {
    left: 50%;
    transform: perspective(1px) translate(-50%, -50%);
  }
}