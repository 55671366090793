.review-request {
    width: 580px;

    .md-form {
        width: 100%;
    }

    .whatsapp-btn {
        background-color: #25D366 !important;
        min-width: 16rem;
    }

    &.no-animation {
        transition: none !important;
    }

    .intl-tel-input {
        @media (max-width: 768px) {
            z-index: 1000000 !important;
        }
        
        width: 100% !important;
        
        .country-list {
            z-index: 1000 !important;
        }
        
        input {
            padding-right: 0 !important;
            width: calc(100% - 52px);
        }
    }

    .form-field {
        margin: 2.5rem 0;

        input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .md-form {
            margin: 0;
        }
        .form-error {
            display: block;
            color: var(--danger);
        }
        .md-form-label {
            position: absolute;
            top: 0;
            left: 0;
            cursor: text;
            transition: transform .2s ease-out, color .2s ease-out;
            transform: translateY(12px);
            transform-origin: 0% 100%;
            font-size: 11pt;
            font-weight: 400;
            color: black;
            &.active {
                transform: translateY(-16px) scale(0.8);
                font-size: 1rem;
                &.focused {
                    color: var(--primary);
                }
            }
        }
    }

    .submit-btn {
        display: block;
        margin: 3rem auto 0 auto;
        min-width: 18rem;
    }

    .submit-btn-2 {
        display: block;
        margin: 1.5rem auto;
        min-width: 18rem;

    }

    .modal-content {
        max-height: calc(100vh - 28px *2);
    }

    // new styles
    .modal-content {

        .modal-header{
            border: none;
        }

        // Header - Close
        .modal-header .close {
            padding: 0.5rem .8rem 0 .8rem;
        }

        // Content
        .modal-body{
            padding-top: 0;
            max-height: none;
            overflow-y: initial;
        }

        .botbit-select{
            margin-bottom: 3rem;
        }

        // Select input
        .select-wrapper > label.mdb-main-label.active {
            font-size: 1rem;
            text-transform: uppercase;
        }

        .select-wrapper input.select-dropdown{
            font-size: 1.6rem;
            text-transform: uppercase;
            top: 19px;
        }

        // Label inputs
        .form-control + label {
            font-size: 1rem;
            text-transform: uppercase;
        }

        // Input text and tel
        .md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
        .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]){
            border-bottom: 1px solid #ced4da;
            box-shadow: 0 1px 0 0 #ced4da;
        }
        .md-form input[type="text"]:not(.browser-default){
            color: #000;
            font-size: 1.6rem;
        }

        // on focus
        .md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label{
            color: rgba(0, 0, 0, 0.7);
        }
        .md-form label.active {
            transform: translateY(-25px);
        }

        // ----------

        // Phone number label
        .md-form label.active {
            color: #000;
            font-size: 1rem;
            font-weight: normal;
            text-transform: uppercase;
        }

        .storeOnly {
            color: #000;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: normal;
            margin-bottom: -23px;
            border-bottom: 1px solid #ced4da;

            span {
                color: #000;
                display: block;
                font-size: 1.6rem;
            }
        }

        .intl-tel-input.allow-dropdown input{
            font-size: 1.6rem;
        }

    }

    // Modal minions
    &.rr-cheerup{
        p{
            font-size: 1rem;
        }

        .modal-content .modal-body{
            overflow-y: auto;
        }
    }

}

.rr-cheerup {
    img {
        margin: auto;
        position: relative;
        left: 50%;  
        transform: translateX(-50%);    
    }
    .step-btn {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}