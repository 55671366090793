.data-card {
  &.loading {
    justify-content: center;
  }
  .card-body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin: 0;
    }
    .data-card-body {
      .data-alert {
        padding-top: 5rem;
      }
      h2 {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 25px;
        &.GOOD {
          color: #3F7B25;
        }
        &.MEDIUM {
          color: #F4CE59;
        }
        &.BAD {
          color: #FF0000;
        }
      }
      .small-icon {
        font-size: 12px;
      }
      .star-ratings {
        margin-bottom: .5em;
      }
      h3 {
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
  h2 {
    font-size: 21px;
  }
  h4 {
    margin-top: 0;
  }

  .small-title {
    min-height: unset !important;
  }

  background: white;
  width: 100%;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 180px;
  border-radius: 0.25rem;
  @media (max-width: 991px) {
    width: 100%;
  }
  i {
    font-size: 22px;
    display: inline-table;
    padding: 5px 7px;
    border-radius: 40px;
    &.arrow-up {
      color: var(--primary);
      border: 3px solid var(--primary);
    }
    &.arrow-down {
      color: red;
      border: 3px solid red;
    }
    &.minus {
      color: gray;
      border: 3px solid gray;
    }
  }
  .card-footer {
    font-size: 11px;
    border: none;
    color: black;
    font-weight: 500;
    // min-height: 45px;
    padding: .4rem;
    &.green {
      background-color: #D3F0D7 !important;
    }
    &.grey {
      background-color: #F3F3F3 !important;
    }
    &.compact {
      margin: 0;
    }
    @media (max-width: 991px) {
      // min-height: 8vh;
    }
    margin-top: 10px;
    a {
      color: #337ab7;
      cursor: pointer;
      border-bottom: 1px solid;
    }
    h3 {
      font-size: 13px;
    }
  }

  // .totalUnsatisfiedClients {
  //   > h4 {
  //     padding-bottom: 1rem;
  //   }
  // }
  // .averageScoreFromAllSources {
  //   > h4 {
  //     padding-bottom: 0.8rem;
  //   }
  // }
  // .answeredReviews {
  //   > h4 {
  //     padding-bottom: 1rem;
  //   }
  // }
  .sents-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .first-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      .detailed {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 11px;
        font-weight: bold;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    
    .sent-number {
      font-size: 40px !important;   
    }

    .variation-icon {
      font-size: 10px;
      padding: 0;
    }
  }
}
