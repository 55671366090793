.match-source-with-stores {
    display: flex;
    flex-direction: column;
    align-items: center;
    .completed-or-not {
        border-radius: 50%;
        color: white;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(min-width: 992px) {
            width: 30px;
            height: 30px;
            font-size: 17px;
        }
        &.completed {
            background: var(--success);
        }
        &.incomplete {
            background: var(--danger);
        }
    }
    .mb-0 .table-title {
        margin-bottom: 10px;
        font-weight: 700;
    }
    .modal.show.top, .modal {
        z-index: 4000 !important;
    }
    h1 {
        font-size: 22px;
        margin-bottom: 50px;
    }
}