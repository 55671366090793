.api-config-table {
  border: 1px solid black;
  background-color: black;
  padding: 5px;
  table-layout: fixed;
  width: 100%; }

.api-config-title {
  width: 20%; }

.api-config-value {
  width: 80%; }

@media (min-width: 992px) {
  .api-config-table {
    width: 70%; } }

@media (min-width: 1200px) {
  .api-config-table {
    width: 60%; } }
