.campaignMessage .benefitMessageContainer {
  display: flex;
  align-items: center;
  min-width: 80%; }
  @media (max-width: 576px) {
    .campaignMessage .benefitMessageContainer {
      display: block; } }
  .campaignMessage .benefitMessageContainer .benefitMessageInput {
    position: relative;
    width: 65% !important; }
    .campaignMessage .benefitMessageContainer .benefitMessageInput .tags-input {
      width: 100%; }
      .campaignMessage .benefitMessageContainer .benefitMessageInput .tags-input .md-form {
        margin: 0; }
    .campaignMessage .benefitMessageContainer .benefitMessageInput input {
      margin-bottom: -2px; }
      @media (max-width: 576px) {
        .campaignMessage .benefitMessageContainer .benefitMessageInput input {
          margin-bottom: 7px; } }
    .campaignMessage .benefitMessageContainer .benefitMessageInput.hasError input {
      border-bottom: 1px solid var(--danger);
      box-shadow: none; }
    @media (max-width: 576px) {
      .campaignMessage .benefitMessageContainer .benefitMessageInput {
        margin-top: 10px;
        width: 100% !important; } }
    .campaignMessage .benefitMessageContainer .benefitMessageInput .error-message {
      position: absolute;
      left: 0;
      bottom: -26px;
      color: var(--danger); }
      @media (max-width: 576px) {
        .campaignMessage .benefitMessageContainer .benefitMessageInput .error-message {
          bottom: -18px; } }
  .campaignMessage .benefitMessageContainer .messageBenefitLabel {
    width: 65%;
    display: flex;
    font-weight: 400;
    align-items: center; }
    @media (max-width: 576px) {
      .campaignMessage .benefitMessageContainer .messageBenefitLabel {
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px; } }
  .campaignMessage .benefitMessageContainer .benefitMessageIsCustomSelector {
    width: 30%;
    margin-right: 5%; }
    @media (max-width: 576px) {
      .campaignMessage .benefitMessageContainer .benefitMessageIsCustomSelector {
        width: 100%; } }
    .campaignMessage .benefitMessageContainer .benefitMessageIsCustomSelector input {
      margin-bottom: 0; }
