.alice-carousel__dots {
  margin-top: 5px; }

.reviews-summary-card-3-sources {
  height: 210px; }

.reviews-summary-card-2-sources {
  height: 170px; }

.chart-summary-row {
  height: 130px; }

.super-admin-card {
  height: 285px; }

.dashboard-loading {
  height: 252px; }

.detractors-loading {
  height: 265px; }

.reviews-loading {
  height: 220px; }

.summary-legend {
  font-size: 10px;
  color: #6d6d6d;
  padding-top: 10px; }

.minor-paddings {
  padding: 0 0 0 0.5em; }

.reviews-summary-card h1, .reviews-summary-card h2 {
  font-weight: 400;
  margin-top: 0; }

.reviews-summary-card h1 {
  font-size: 40px; }

.summary-tooltip {
  padding: 5px 5px 5px 5px;
  font-family: 'Arial';
  white-space: nowrap; }

.summary-tooltip-big {
  font-size: 12px;
  font-weight: bold; }

.summary-tooltip-small {
  font-size: 9px; }

.auto-reply-color {
  color: red; }

.manual-reply-color {
  color: blue; }
